import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Select, Switch, Input, Button, Typography, Tag } from "antd"
import LevelChart from "../LevelChart"
import {
    ProjectOutlined
} from '@ant-design/icons'
const { Title, Paragraph, Text, Link } = Typography;

const filtersSpan = {
    row: {
        1: {
            ticker:
                { xxl: 3, xl: 3, lg: 3, md: 4, sm: 5, xs: 6 },
            timeFrame:
                { xxl: 7, xl: 7, lg: 6, md: 6, sm: 19, xs: 18 },
            levels:
                { xxl: 14, xl: 14, lg: 15, md: 14, sm: 24, xs: 24 },
        },
        2: {
            ticker:
                { xxl: 4, xl: 5, lg: 5, md: 6, sm: 6, xs: 6 },
            timeFrame:
                { xxl: 7, xl: 8, lg: 8, md: 18, sm: 18, xs: 18 },
            levels:
                { xxl: 13, xl: 11, lg: 11, md: 24, sm: 24, xs: 24 },
        },
        3: {
            ticker:
                { xxl: 6, xl: 6, lg: 6, md: 6, sm: 6, xs: 6 },
            timeFrame:
                { xxl: 18, xl: 18, lg: 18, md: 18, sm: 18, xs: 18 },
            levels:
                { xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
        },

        4: {
            ticker:
                { xxl: 8, xl: 8, lg: 8, md: 8, sm: 8, xs: 8 },
            timeFrame:
                { xxl: 16, xl: 16, lg: 16, md: 16, sm: 16, xs: 16 },
            levels:
                { xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
        }

    },
    timeFrame: {
        prefix: {
            1: {
                xxl: 6, xl: 7, lg: 10, md: 12, sm: 7, xs: 8
            },
            2: { 
                xxl: 12, xl: 12, lg: 10, md: 10, sm: 7, xs: 8 
            },
            3: {
                xxl: 8, xl: 8, lg: 8, md: 12, sm: 12, xs: 12
            },
            4: {
                xxl: 10, xl: 12, lg: 12, md: 12, sm: 12, xs: 12
            }
        },
        select: {
            1: {
                xxl: 18, xl: 17, lg: 14, md: 12, sm: 17, xs: 16
            },
            2: { 
                xxl: 12, xl: 12, lg: 14, md: 14, sm: 17, xs: 16 
            },
            3: {
                xxl: 16, xl: 16, lg: 16, md: 12, sm: 12, xs: 12
            },
            4: {
                xxl: 14, xl: 12, lg: 12, md: 12, sm: 12, xs: 12
            }
        }
    },
    levels: {
        prefix: {
            1: {
                xxl: 4, xl: 4, lg: 5, md: 6, sm: 5, xs: 6
            },
            2: {
                xxl: 7, xl: 9, lg: 5, md: 6, sm: 6, xs: 6
            },
            3: {
                xxl: 7, xl: 6, lg: 6, md: 6, sm: 6, xs: 6
            },
            4: {
                xxl: 8, xl: 8, lg: 8, md: 8, sm: 8, xs: 8
            }
        },
        select: {
            1: {
                xxl: 20, xl: 20, lg: 19, md: 18, sm: 19, xs: 18
            },
            2: {
                xxl: 17, xl: 15, lg: 19, md: 18, sm: 18, xs: 18
            },
            3: {
                xxl: 17, xl: 18, lg: 18, md: 18, sm: 18, xs: 18
            },
            4: {
                xxl: 16, xl: 16, lg: 16, md: 16, sm: 16, xs: 16
            }
        }
    }
}

let CardData = {
    0: { ticker: '', timeFrame: '', levels: [] },
    1: { ticker: '', timeFrame: '', levels: [] },
    2: { ticker: '', timeFrame: '', levels: [] },
    3: { ticker: '', timeFrame: '', levels: [] },
    4: { ticker: '', timeFrame: '', levels: [] },
    5: { ticker: '', timeFrame: '', levels: [] },
    6: { ticker: '', timeFrame: '', levels: [] },
    7: { ticker: '', timeFrame: '', levels: [] },
    8: { ticker: '', timeFrame: '', levels: [] },
    9: { ticker: '', timeFrame: '', levels: [] },
}

const LevelChartCard = (props) => {
    //console.log(props)
    const id = props.id
    //console.log(id)

    const [tickerDefault, setTickerDefault] = useState(props.ticker)
    const [tickerSelect, setTickerSelect] = useState(
        CardData[id].ticker.length > 0 ? CardData[id].ticker : props.ticker
    )

    const [timeFrameDefault, setTimeFrameDefault] = useState(props.timeFrameDefault)
    const [timeFrameSelect, setTimeFrameSelect] = useState(
        CardData[id].timeFrame.length > 0 ? CardData[id].timeFrame : props.timeFrameDefault
    )

    const [levelsDefault, setLevelsDefault] = useState(props.levelsDefault)

    const [levelsSelect, setLevelsSelect] = useState(
        CardData[id].levels.length > 0 ? CardData[id].levels : ["minute_15"]
    )

    const [tickerData, setTickerData] = useState(
        props.data[
        CardData[id].ticker.length > 0 ? CardData[id].ticker : props.ticker
        ]
    )

    const [priceData, setPriceData] = useState(
        props.data[
            CardData[id].ticker.length > 0 ? CardData[id].ticker : props.ticker
        ].priceData[
        CardData[id].timeFrame.length > 0 ? CardData[id].timeFrame : props.timeFrameDefault
        ]
    )

    const layoutType = props.layoutType

    useEffect(() => {
    }, [])


    const onChangeTicker = (e) => {
        CardData[id].ticker = e
        setTickerData(props.data[e])
        setPriceData(props.data[e].priceData[timeFrameSelect])
        setTickerSelect(e)
    }

    const onChangeTimeFrame = (e) => {
        CardData[id].timeFrame = e
        setTimeFrameSelect(e)
        setPriceData(props.data[tickerSelect].priceData[e])
    }

    const onChangeLevels = (e) => {
        CardData[id].levels = e
        setLevelsSelect(e)
    }


    return (<>
        <Card

            size="small" title={<>
                <Row >
                    <Col
                        xxl={{ span: filtersSpan.row[layoutType].ticker.xxl }}
                        xl={{ span: filtersSpan.row[layoutType].ticker.xl }}
                        lg={{ span: filtersSpan.row[layoutType].ticker.lg }}
                        md={{ span: filtersSpan.row[layoutType].ticker.md }}
                        sm={{ span: filtersSpan.row[layoutType].ticker.sm }}
                        xs={{ span: filtersSpan.row[layoutType].ticker.xs }}
                        style={{ marginBottom: 5 }}
                    >
                        <Select
                            defaultValue={tickerDefault}
                            options={props.tickersOptions}
                            onChange={onChangeTicker}
                            value={tickerSelect}
                            style={{ width: '100%', fontWeight: 600, fontSize: 17, paddingLeft: 3, marginRight: 0 }}
                        >
                        </Select>
                    </Col>

                    <Col
                        xxl={{ span: filtersSpan.row[layoutType].timeFrame.xxl }}
                        xl={{ span: filtersSpan.row[layoutType].timeFrame.xl }}
                        lg={{ span: filtersSpan.row[layoutType].timeFrame.lg }}
                        md={{ span: filtersSpan.row[layoutType].timeFrame.md }}
                        sm={{ span: filtersSpan.row[layoutType].timeFrame.sm }}
                        xs={{ span: filtersSpan.row[layoutType].timeFrame.xs }}
                    >
                        <Row align='middle'>
                            {/*TimeFrame prefix*/}
                            <Col
                                xxl={{ span: filtersSpan.timeFrame.prefix[layoutType].xxl }}
                                xl={{ span: filtersSpan.timeFrame.prefix[layoutType].xl }}
                                lg={{ span: filtersSpan.timeFrame.prefix[layoutType].lg }}
                                md={{ span: filtersSpan.timeFrame.prefix[layoutType].md }}
                                sm={{ span: filtersSpan.timeFrame.prefix[layoutType].sm }}
                                xs={{ span: filtersSpan.timeFrame.prefix[layoutType].xs }}
                                style={{ paddingRight: 0 }}
                            >
                                <Button
                                    className='levels-pre-select'
                                    style={{ width: '100%', background: "#303030" }}
                                >
                                    <Text>TimeFrame:</Text>
                                </Button>

                            </Col>
                            {/*TimeFrame select*/}
                            <Col
                                xxl={{ span: filtersSpan.timeFrame.select[layoutType].xxl }}
                                xl={{ span: filtersSpan.timeFrame.select[layoutType].xl }}
                                lg={{ span: filtersSpan.timeFrame.select[layoutType].lg }}
                                md={{ span: filtersSpan.timeFrame.select[layoutType].md }}
                                sm={{ span: filtersSpan.timeFrame.select[layoutType].sm }}
                                xs={{ span: filtersSpan.timeFrame.select[layoutType].xs }}
                                style={{ paddingLeft: 0 }}
                            >
                                <Select
                                    defaultValue={timeFrameDefault}
                                    options={props.timeFrameOptions}
                                    onChange={onChangeTimeFrame}
                                    value={timeFrameSelect}
                                    style={{ width: '100%' }}
                                >
                                </Select>
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        xxl={{ span: filtersSpan.row[layoutType].levels.xxl }}
                        xl={{ span: filtersSpan.row[layoutType].levels.xl }}
                        lg={{ span: filtersSpan.row[layoutType].levels.lg }}
                        md={{ span: filtersSpan.row[layoutType].levels.md }}
                        sm={{ span: filtersSpan.row[layoutType].levels.sm }}
                        xs={{ span: filtersSpan.row[layoutType].levels.xs }}
                    >
                        <Row align='middle'>
                            <Col
                                xxl={{ span: filtersSpan.levels.prefix[layoutType].xxl }}
                                xl={{ span: filtersSpan.levels.prefix[layoutType].xl }}
                                lg={{ span: filtersSpan.levels.prefix[layoutType].lg }}
                                md={{ span: filtersSpan.levels.prefix[layoutType].md }}
                                sm={{ span: filtersSpan.levels.prefix[layoutType].sm }}
                                xs={{ span: filtersSpan.levels.prefix[layoutType].xs }}
                                style={{ paddingRight: 0 }}
                            >
                                <Button
                                    className='levels-pre-select'
                                    style={{ width: '100%', background: "#303030" }}
                                >
                                    <Text>Уровни с TF:</Text>
                                </Button>

                            </Col>
                            <Col
                                xxl={{ span: filtersSpan.levels.select[layoutType].xxl }}
                                xl={{ span: filtersSpan.levels.select[layoutType].xl }}
                                lg={{ span: filtersSpan.levels.select[layoutType].lg }}
                                md={{ span: filtersSpan.levels.select[layoutType].md }}
                                sm={{ span: filtersSpan.levels.select[layoutType].sm }}
                                xs={{ span: filtersSpan.levels.select[layoutType].xs }}
                                style={{ paddingLeft: 0 }}
                            >
                                <Select
                                    mode="multiple"
                                    defaultValue={levelsDefault}
                                    placeholder="SR levels"
                                    allowClear={true}
                                    options={props.timeFrameOptions}
                                    onChange={onChangeLevels}
                                    value={levelsSelect}
                                    style={{ width: '100%' }}
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </>}>
            <LevelChart
                style={{ marginRight: 15, width: '100%', marginBottom: 10, height: 350 }}
                tickerData={tickerData}
                timeFrame={timeFrameSelect}
                levels={levelsSelect}
                priceData={priceData}
            >
            </LevelChart>

        </Card >
    </>)

}

export default LevelChartCard

/*
<LevelChart
                GlobalShowLevel={props.GlobalShowLevel}
                
                style={{ marginRight: 15, width: '100%', marginBottom: 10, height: 300 }}
                data={TickerPriceData}>
            </LevelChart>

*/