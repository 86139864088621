export const STRs = {
    options_page_title: 'Поток опционов',
    options_page_description: `Денежный поток опционов можно использовать в качестве стратегического инструмента в вашем торговом арсенале, 
    но вы должны иметь некоторый опыт работы с ним, чтобы полностью оценить его силу.`,

    option_chart_top_call_more_70_title: `TOP CALL (>70%)`,
    option_chart_top_call_more_70_descr: `Инструменты с перевесом по сумме покупок в CALL>70% по сравнению с PUT. Минимальная сумма 5 млн $`,


    flow_page_title: 'Поток опционов по отдельным инструментам',
    flow_page_description: `Денежный поток опционов по отдельным инструментам можно использовать в качестве стратегического инструмента в вашем торговом арсенале, 
    но вы должны иметь некоторый опыт работы с ним, чтобы полностью оценить его силу.`,

    darkpool_page_title: 'DarkPool рынок',
    darkpool_page_description: `DarkPool заказы размещаются только на частных биржах и они доступны только для учреждений, 
    к которым не может получить доступ широкая публика. 
    На данный момент, по оценкам, около 40% всей институциональной торговли происходит на этих «теневых» биржах. 
    Когда крупные инвесторы хотят торговать крупными пакетами акций, 
    они будут покупать или продавать свои позиции крупным банкам или фирмам,
    которые могут взять на себя эту большую премию и обеспечить более быструю ликвидность,
    а также снизить транзакционные издержки.`,
    darkpool_chart_title: 'По дням vs SPY',
    darkpool_chart_description: `Потоки даркпула показывают сравнение между объемом даркпула и ценовым движением.
    Большие всплески объема даркпула могут означать начало сильного тренда. 
    Темный поток рынка просто собирает данные темного пула для ETF и индексов и сравнивает их с ценой акций SPY.`,
    trial_descr :`Добро пожаловать на нашу платформу опционной аналитики! Мы рады предложить вам возможность попробовать нашу платформу и увидеть ее преимущества самостоятельно с бесплатной пробной версией на 7 дней.

    Для активации вашей пробной версии просто нажмите кнопку «Активировать» и перейдите в  Telegram бот и нажмите кнопку "Старт". Ваш доступ к платформе будет активирован на 7 дней, что даст вам достаточно времени, чтобы изучить все наши функции и инструменты.
    
    Не упустите эту возможность повысить свой уровень торговли. Начните свою бесплатную пробную версию сейчас и увидите разницу, которую наша платформа может сделать для вашего успеха!`,

    trial_d_p1: `Добро пожаловать на нашу платформу опционной аналитики! Мы рады предложить вам возможность попробовать нашу платформу и увидеть ее преимущества самостоятельно с бесплатной пробной версией.
    `,
    trial_d_p2: `Для активации вашей пробной версии просто нажмите кнопку «Активировать» и перейдите в  Telegram бот и нажмите кнопку "Старт". Ваш доступ к платформе будет активирован, что даст вам достаточно времени, чтобы изучить все наши функции и инструменты.`,
    trial_d_p3: `Не упустите эту возможность повысить свой уровень торговли. Начните свою бесплатную пробную версию сейчас и увидите разницу, которую наша платформа может сделать для вашего успеха!`

}

export const modalPayments = {
    step0: {
        title: 'Тарифы',
        description: `При оплате за 3 месяца в подарок обучающий курс.
        При оплате за 12 месяцев 50% суммы идет на выделенный трейдинговый счет с разделением дохода.`,
        tarifs: {
            1: {
                title: '12<br>месяцев',
                description: `— Ежедневные живые трейдинг трансляции
                — Доступ к платформе spy2money на 12 месяцев
                — Общий чат с участниками
                — Обучающий курс в подарок (запуск в феврале)
                — Личная консультация в подарок
                — 50% суммы на специальный счет для торговли с разделением дохода пополам`
            }
        }
    }
}