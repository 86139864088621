import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Select, Switch, Spin } from 'antd';
import {
    ProjectOutlined
} from '@ant-design/icons'
import moment from 'moment';
import AlaSQL from 'alasql';

import { URLs } from '../../urls';
import * as CONFIG from './config'
import * as QUERYS from './querys'
import LevelChartCard from '../components/LevelChartCard';

const PageLevels = (props) => {

    const [TickersData, setTickersData] = useState(null)
    const [loadingLevelsData, setLoadingLevelsData] = useState(false)

    function getLeveLsData() {
        setLoadingLevelsData(true)
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getLevelsDataQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                if (resp) {
                    if (resp.data) {
                        const respData = resp.data
                        const tickers = AlaSQL(`select distinct ticker from ? `, [respData])
                        if (tickers) {
                            if (tickers.length > 0) {
                                let tickersArr = []
                                tickers.map(ticker => {
                                    tickersArr.push(ticker.ticker)
                                })
                                let tickersOptions = []
                                tickers.map(ticker => {
                                    tickersOptions.push({ value: ticker.ticker })
                                })
                                let tickersObj = {
                                    tickersArr: tickersArr,
                                    tickersOptions: tickersOptions,
                                    data: {}
                                }
                                tickersArr.map(ticker => {
                                    tickersObj.data[ticker] = {
                                        priceData: {
                                            minute_5: [],
                                            minute_15: [],
                                            minute_30: [],
                                            hour_1: [],
                                            hour_4: [],
                                            day_1: [],
                                            week_1: [],
                                            //month_1: []
                                        },
                                        levelData: {
                                            minute_5: [],
                                            minute_15: [],
                                            minute_30: [],
                                            hour_1: [],
                                            hour_4: [],
                                            day_1: [],
                                            week_1: [],
                                            //month_1: []
                                        }
                                    }
                                })

                                respData.forEach((respRow, index) => {
                                    let ticker = respRow.ticker
                                    let timeFrame = respRow.timeFrame
                                    let value = respRow.value
                                    let priceData = ((respRow.priceData).split(',')).map(Number)
                                    let priceDataChart = []
                                    let levelData = ((respRow.levels).split(',')).map(Number)
                                    let timeArr = []
                                    let option = `${timeFrame}_${value}`
                                    if (timeFrame === 'day' || timeFrame === 'month') {
                                        timeArr = ((respRow.timeArr).split(','))
                                    } else {
                                        timeArr = ((respRow.timeArr).split(',')).map(Number)
                                    }
                                    timeArr.map((time, i) => {
                                        let tTime = moment()
                                        if (timeFrame === 'day' || timeFrame === 'month') {
                                            tTime = moment(time).unix()
                                        } else {
                                            tTime = time
                                        }
                                        priceDataChart.push({
                                            value: priceData[i],
                                            time: tTime
                                        })
                                    })
                                    let levelArr = []
                                    levelData.map(level => {
                                        levelArr.push({ value: level })
                                    })
                                    if (tickersObj.data[ticker]) {
                                        tickersObj.data[ticker].priceData[option] = priceDataChart
                                        tickersObj.data[ticker].levelData[option] = levelArr

                                    }
                                })
                                setTickersData(tickersObj)
                                setLoadingLevelsData(false)
                            }
                        }
                    }
                }
            })
        //setLoadingLevelsData(false)
    }


    useEffect(() => {
        getLeveLsData()
    }, [])

    const onClick = () => {
        getLeveLsData()
    }

    const LevelsContent = (e) => {
        const props = e.TickersData
        const layoutType = {
            1: { xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
            2: { xxl: 12, xl: 12, lg: 24, md: 24, sm: 24, xs: 24 },
            3: { xxl: 8, xl: 12, lg: 24, md: 24, sm: 24, xs: 24 },
            4: { xxl: 6, xl: 12, lg: 24, md: 24, sm: 24, xs: 24 }
        }
        if (props) {
            return (<>
                {(props.tickersArr).map((ticker, i) =>
                    i <= 50 ?

                        <Col
                            xxl={{ span: layoutType[e.LayoutType].xxl }}
                            xl={{ span: layoutType[e.LayoutType].xl }}
                            lg={{ span: layoutType[e.LayoutType].lg }}
                            md={{ span: layoutType[e.LayoutType].md }}
                            sm={{ span: layoutType[e.LayoutType].sm }}
                            xs={{ span: layoutType[e.LayoutType].xs }}
                            key={props.id}
                            align="middle"
                            style={{ marginBottom: 10, paddingRight: 5 }}
                        >
                            <Spin spinning={loadingLevelsData} style={{ width: '100%' }} >
                                <LevelChartCard
                                    id={i}
                                    key={props.id}
                                    style={{ marginRight: 15, width: '100%', marginBottom: 10, height: 300 }}
                                    data={props.data}
                                    ticker={ticker}
                                    tickersArr={props.tickersArr}
                                    tickersOptions={props.tickersOptions}
                                    layoutType={e.LayoutType}
                                    timeFrameDefault={"minute_15"}
                                    timeFrameOptions={[
                                        //{ value: 'month_1', label: 'Месяц' },
                                        { value: 'week_1', label: 'Неделя' },
                                        { value: 'day_1', label: 'День' },
                                        { value: 'hour_4', label: '4 часа' },
                                        { value: 'hour_1', label: '1 час' },
                                        { value: 'minute_30', label: '30 минут' },
                                        { value: 'minute_15', label: '15 минут' },
                                        { value: 'minute_5', label: '5 минут' }
                                    ]}
                                    levelOptions={{
                                        month: [
                                            { value: 1 }
                                        ]
                                    }}
                                    levelsDefault={["minute_15"]}
                                >
                                </LevelChartCard>
                            </Spin>
                        </Col>

                        : <></>
                )}
            </>)
        } else {
            return (<>no ticker data</>)
        }
    }


    const [CardsLayoutType, setCardsLayoutType] = useState(1)

    const onChangeLayoutType = (e) => {
        //console.log(e)
        setCardsLayoutType(e)
    }

    return (
        <>

            <Row className='filter_row'>
                <Col
                    xxl={{ span: 21 }}
                    xl={{ span: 20 }}
                    md={{ span: 18 }}
                    xs={{ span: 10 }}
                    style={{ marginTop: 2, marginBottom: 2 }}
                >
                </Col>


                <Col style={{ marginTop: 2, }}
                    xxl={{ span: 1 }}
                    xl={{ span: 1 }}
                    md={{ span: 2 }}
                    xs={{ span: 4 }} >
                    < Select defaultValue={{ value: <ProjectOutlined />, label: 'Lucy (101)' }}
                        options={
                            [
                                { value: 1, label: <><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17" width="19" height="17"><path fill="currentColor" d="M2.5 1C1.67 1 1 1.67 1 2.5v12c0 .83.67 1.5 1.5 1.5h14c.83 0 1.5-.67 1.5-1.5v-12c0-.83-.67-1.5-1.5-1.5h-14ZM0 2.5A2.5 2.5 0 0 1 2.5 0h14A2.5 2.5 0 0 1 19 2.5v12a2.5 2.5 0 0 1-2.5 2.5h-14A2.5 2.5 0 0 1 0 14.5v-12Z"></path></svg></>, },
                                { value: 2, label: <><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17" width="19" height="17"><path fill="currentColor" fill-rule="evenodd" d="M1 2.5C1 1.67 1.67 1 2.5 1H9v15H2.5A1.5 1.5 0 0 1 1 14.5v-12ZM10 16h6.5c.83 0 1.5-.67 1.5-1.5v-12c0-.83-.67-1.5-1.5-1.5H10v15ZM2.5 0A2.5 2.5 0 0 0 0 2.5v12A2.5 2.5 0 0 0 2.5 17h14a2.5 2.5 0 0 0 2.5-2.5v-12A2.5 2.5 0 0 0 16.5 0h-14Z"></path></svg></>, },
                                { value: 3, label: <><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17" width="19" height="17"><path fill="currentColor" fill-rule="evenodd" d="M1 2.5C1 1.67 1.67 1 2.5 1H6v15H2.5A1.5 1.5 0 0 1 1 14.5v-12ZM7 16h5V1H7v15Zm6-15v15h3.5c.83 0 1.5-.67 1.5-1.5v-12c0-.83-.67-1.5-1.5-1.5H13ZM2.5 0A2.5 2.5 0 0 0 0 2.5v12A2.5 2.5 0 0 0 2.5 17h14a2.5 2.5 0 0 0 2.5-2.5v-12A2.5 2.5 0 0 0 16.5 0h-14Z"></path></svg></>, },
                                { value: 4, label: <><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17" width="19" height="17"><path fill="currentColor" fill-rule="evenodd" d="M1 2.5C1 1.67 1.67 1 2.5 1H4v15H2.5A1.5 1.5 0 0 1 1 14.5v-12ZM5 16h4V1H5v15Zm5-15v15h4V1h-4Zm5 0v15h1.5c.83 0 1.5-.67 1.5-1.5v-12c0-.83-.67-1.5-1.5-1.5H15ZM2.5 0A2.5 2.5 0 0 0 0 2.5v12A2.5 2.5 0 0 0 2.5 17h14a2.5 2.5 0 0 0 2.5-2.5v-12A2.5 2.5 0 0 0 16.5 0h-14Z"></path></svg></>, },
                            ]}
                        onChange={onChangeLayoutType}
                        value={CardsLayoutType}
                        style={{ width: '100%' }}
                    ></Select >
                </Col>

                <Col style={{ marginTop: 2 }}
                    xxl={{ span: 2 }}
                    xl={{ span: 3 }}
                    md={{ span: 4 }}
                    xs={{ span: 10 }} >
                    <Button
                        style={{ width: '100%' }}
                        onClick={onClick}>Обновить</Button>
                </Col>
            </Row>





            <Row gutter={4} style={{ marginLeft: 3 }}>
                <LevelsContent
                    TickersData={TickersData}
                    LayoutType={CardsLayoutType}
                ></LevelsContent>
            </Row>
        </>
    )
}

export default PageLevels