import React from "react"

import { useState } from "react";
import { Card, Modal, Typography } from "antd";

import { AgChartsReact } from 'ag-charts-react';

const { Text } = Typography;
const AntCardAgChart = (props) => {

    const cardOptions = props.card
    const chartOptions = props.options



    return (
        <>
            

            <div className="site-card-border-less-wrapper">
                <Card title={cardOptions.title} bordered={false} >
                    <Text type="secondary" >
                        {cardOptions.description}
                    </Text>
                    <div className="example-wrapper ag-theme-alpine" style={{ height: 500 }}>
                        <div className="wrapper">
                            <div style={{ height: 500 }}>
                                <AgChartsReact
                                    className='wrapper'
                                    options={chartOptions}

                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>


        </>
    )
}

export default AntCardAgChart