import React from "react"
import { Col, Row, Card, Badge } from "antd"
import GaugeChartMetr from "../../../components/GaugeChartMetr"

const GaugeChartMetrs = ({ pct_15_min, pct_60_min, pct_1_day, pct_otm_call, pct_otm_put }) => {

    return (<Row>
        <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 8 }}
            align={'center'} a>
            <GaugeChartMetr
                id="m15min"
                nrOfLevels={10}
                title={{
                    enable: true, pos: 'top',
                    value: '15 мин'
                }}
                percent={pct_15_min}
            ></GaugeChartMetr>
        </Col>

        <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 8 }}
            align={'center'}>
            <GaugeChartMetr
                id="m60min"
                nrOfLevels={10}
                title={{
                    enable: true, pos: 'top',
                    value: '60 мин'
                }}
                percent={pct_60_min}
            ></GaugeChartMetr>
        </Col>
        <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 8 }}
            align={'center'}>
            <GaugeChartMetr
                id="m1day"
                nrOfLevels={10}
                title={{
                    enable: true, pos: 'top',
                    value: '1 день'
                }}
                percent={pct_1_day}
            ></GaugeChartMetr>
        </Col>
        <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 12 }}
            align={'center'}>
            <GaugeChartMetr
                id="mOtmCall"
                nrOfLevels={5}
                title={{
                    enable: true, pos: 'top',
                    value: 'OTM CALL'
                }}
                percent={pct_otm_call}
            ></GaugeChartMetr>
        </Col>
        <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 12 }}
            align={'center'}>
            <GaugeChartMetr
                id="mOtmPut"
                nrOfLevels={5}
                title={{
                    enable: true, pos: 'top',
                    value: 'OTM PUT'
                }}
                arcWidth={0}
                cornerRadius={0}
                colors={{ from: "#229954", to: "#E74C3C" }}
                percent={pct_otm_put}
            ></GaugeChartMetr>
        </Col>
    </Row>)

}

export default GaugeChartMetrs