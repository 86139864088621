import React from "react"
import { Card, Col, Row } from "antd"
import LightWeightChartLineMini from "../../../components/LightWeightChartLineMini"

const MiniChart = (props) => {
    const params = props.props
    return (
        <Card>
            <LightWeightChartLineMini props={params}></LightWeightChartLineMini>
        </Card>
    )
}

export default MiniChart