import moment from "moment";
import { Tag } from "antd";


export const defaultRange = {
    from: moment().startOf("month").format('yyyy-MM-DD'),
    to: moment().endOf("month").format('yyyy-MM-DD'),
    unix_from: moment().startOf("month").unix(),
    unix_to: moment().endOf("month").unix(),
    dateFormat: 'DD-MM-YYYY'
};


export const defaultParamsFilter = {
    selectedSymbol: 'main',
    timeData: {
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY')
        },
        from: moment().startOf("day"),
        to: moment().endOf("day"),
        unix: {
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix()
        },
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',

    }
}

export const defaultParamsChart = {
    selectedSymbol: 'SPY'
}

export const gefaultParamsStorage = {
    paramsFilter: defaultParamsFilter
}