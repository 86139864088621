import React from "react"
import { Card, Col, Row } from "antd"
import MiniChart from "../MinChart"

const MiniCardChart = (props) => {
    const params = props.props
    const premium = params.premium > 1000000 ? `${(params.premium / 1000000).toFixed(1)}m` : (
        params.premium > 1000 ? `${(params.premium / 1000).toFixed(1)}k` :params.premium
    )
    return (
        <Card
            style={props.style}
        >
            <Row align="middle" style={{ width: '100%' }}>
                <Col span={10} >
                    <Row>
                        <Col span={24} >{params.ticker}</Col>
                        <Col span={24} >{premium}</Col>
                    </Row>
                </Col>
                <Col span={14}>
                    <MiniChart props={params.data}></MiniChart>
                </Col>
            </Row>
        </Card>
    )
}

export default MiniCardChart

//style={{ width: '100%' }}