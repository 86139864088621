import React from "react"
import moment from "moment";
import { Tag } from "antd";
import {
    RiseOutlined, FallOutlined
} from '@ant-design/icons';

const where_timeZone = ` where timeZone !='America/New_York' `

export const defaultMainSettings = {
    type: 'default',
    sessionStart: '09:30',
    sessionTimeStamp: 0,
    selectedSetting: 'time'
}

export const TableTypeOptions = [{
    value: 'SWEEP',
}, {
    value: 'BLOCK',
}, {
    value: 'SPLIT',
}]

export const OptionTypesColor = {
    'SWEEP': 'gold',
    'BLOCK': 'lime',
    'SPLIT': 'green'
}
export const contractDetailsOptions = [{
    value: 'OTM',
}, {
    value: 'ITM',
}, {
    value: 'ATM',
}]


export const mainChartDaysPeriodOptions = [{
    value: '7_days',
}, {
    value: '15_days',
}, {
    value: '30_days',
}]

export const defaultRange = {
    from: moment().startOf("month").format('yyyy-MM-DD'),
    to: moment().endOf("month").format('yyyy-MM-DD'),
    unix_from: moment().startOf("month").unix(),
    unix_to: moment().endOf("month").unix(),
    dateFormat: 'DD-MM-YYYY'
};

export const defaultTimeData = () => {
    const visual_from = moment().startOf("day").format('DD.MM.YYYY')
    const visual_to = moment().endOf("day").format('DD.MM.YYYY')
    const caption = visual_from + " - " + visual_to;
    return ({
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY'),
            caption: caption
        },
        from: moment().utc().startOf("day"),
        to: moment().utc().endOf("day"),
        unix: {
            from: moment().utc().startOf("day").unix(),
            to: moment().utc().endOf("day").unix()
        },
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',
    })
}

export const defaultParamsFilter = {
    beforeExpiration: 10,
    beforeExpirationCondition: '<=',
    premiumСondition: '>=',
    signalWorkCheck: false,
    premiumLimit: 100000,
    contractDetails: ['OTM'],
    selectedSymbol: 'main',
    selectedType: ['SWEEP'],

    map_selectedType: ['SWEEP'],
    map_moneyTypes: ['OTM'],
    map_premiumCondition: '>=',
    map_premiumLimit: 100000,
    map_beforeExpiration: 10,
    map_beforeExpirationCondition: '<=',


    mainChartDaysPeriod: [],
    showExpired: false,
    timeData: {
        value: 'today',
        work: {
            date_from: moment().startOf("day").format('YYYY-MM-DD'),
            date_to: moment().endOf("day").format('YYYY-MM-DD')
        },
        visual: {
            date_from: moment().startOf("day").format('DD.MM.YYYY'),
            date_to: moment().endOf("day").format('DD.MM.YYYY')
        },
        from: moment().startOf("day"),
        to: moment().endOf("day"),
        unix: {
            from: moment().startOf("day").unix(),
            to: moment().endOf("day").unix()
        },
        from_to: [
            moment().startOf("day").format('YYYY-MM-DD'),
            moment().endOf("day").format('YYYY-MM-DD')
        ],
        dateFormat: 'DD-MM-YYYY',
        selectedOption: 'today',

    }
}

export const defaultParamsChart = {
    selectedSymbol: 'SPY'
}

export const gefaultParamsStorage = {
    paramsFilter: defaultParamsFilter,
    paramsChart: defaultParamsChart
}

export function getBDQuerySymbolsList(props) {
    //console.log('getBDQuerySymbolsList')
    //console.log(props)
    let timeRange = props.paramsFilter.timeData;
    let q = `select *,
    case
     when symbol ='SPY' then 1
     when symbol ='QQQ' then 2
     when symbol ='SOXL' then 3 
     when symbol ='LABU' then 4 
            when symbol ='AAPL' then 5 
            when symbol ='MSFT' then 6 
            else 10 
            end as rating 
        from 
        (select  symbol from ( select distinct symbol 
            from ( 
            SELECT symbol, toUnixTimestamp(concat(dateString,':00')) unix
            FROM OptionFlow.FlowLine
            ) a where unix >= ${timeRange.unix.from} and unix <= ${timeRange.unix.to}
            ) a ) a order by rating`
    return q
}



export function getCallPutQuery(props) {
    return `select *
    from 
    
    (select * 
    from 
    
    (
    select 
    type,
    section,
    outOfMoney,
    sum(premium) premium,
    sum(premium_c) premium_c,
    sum(premium_p) premium_p,
    sum(premium_c_exp_d10) premium_c_exp_d10, 
    sum(premium_c_exp_d40) premium_c_exp_d40,
    sum(premium_c_exp_d60) premium_c_exp_d60,
    sum(premium_c_exp_m_d60) premium_c_exp_m_d60, 
    
    sum(premium_p_exp_d10) premium_p_exp_d10, 
    sum(premium_p_exp_d40) premium_p_exp_d40,
    sum(premium_p_exp_d60) premium_p_exp_d60,
    sum(premium_p_exp_m_d60) premium_p_exp_m_d60
    
    from 
    (select
    
    type,
    section,
    outOfMoney,
    premium,
    premium_c,
    premium_p,
    case when (optionType  ='C' and t1<=10) then premium_c else 0 end as premium_c_exp_d10, 
    case when (optionType  ='C' and t1>10 and t1<= 40) then premium_c else 0 end as premium_c_exp_d40,
    case when (optionType  ='C' and t1>40 and t1<= 60) then premium_c else 0 end as premium_c_exp_d60,
    case when (optionType  ='C' and t1>=60) then premium_c else 0 end as premium_c_exp_m_d60, 
    
    case when (optionType  ='P' and t1<=10) then premium_p else 0 end as premium_p_exp_d10, 
    case when (optionType  ='P' and t1>10 and t1<= 40) then premium_p else 0 end as premium_p_exp_d40,
    case when (optionType  ='P' and t1>40 and t1<= 60) then premium_p else 0 end as premium_p_exp_d60,
    case when (optionType  ='P' and t1>=60) then premium_p else 0 end as premium_p_exp_m_d60
    
    from 
    (SELECT 
        distinct
    symbol as type, 
    section,
    case when outOfMoney =true then 1 else 0 end
    outOfMoney,
    optionType,
    premium, 
    case when optionType = 'C' then premium else 0 end premium_c,
    case when optionType = 'P' then premium else 0 end premium_p,
    round(((expiry/1000)  - toUnixTimestamp(now()))/60/60/24,2) t1
    FROM OptionFlow.options2 
    ${where_timeZone}
    and
    timestamp >= ${props.time_range.unix_from * 1000} and timestamp <= ${props.time_range.unix_to * 1000} 
    ) a
    ) a
    group by type, section, outOfMoney
    ) a 
    ) a
    order by premium desc
    
    LIMIT 100000 FORMAT JSON`
}

export function getTableQuery(props) {
    let t_range = props.time_range;
    //return `select * from BarChart.VolumeLeaders5 order by volpremium desc LIMIT 1000 FORMAT JSON`
    //1662670800 and timestamp_bd <= 1662757199
    return `select * from (select timestamp_bd_expiry -timestamp_now timestamp_now_between, * 
        from (select timestamp_bd_expiry-timestamp_bd timestamp_bd_between, * 
            from (select * from (
        select distinct
        toUnixTimestamp(now()) timestamp_now, 
        timestamp/1000 timestamp_bd, 
        expiry/1000 timestamp_bd_expiry, 
        symbol, 
        case 
            when optionType = 'C' then 'Call'
            when optionType = 'P' then 'Put' 
            else 'Err' 
        end as optionType, 
        round(premium, 1) premium, strike, 
        toInt64(volume) volume, 
        oi, unusual, outOfMoney, exch 
        FROM OptionFlow.options2 
        ${where_timeZone}
        and
         type in ('SWEEP') and premium >= 100000 ) a 
        where timestamp_bd >= ${t_range.unix_from} and timestamp_bd <= ${t_range.unix_to} ) a ) a 
        where timestamp_bd_between > 0 ) a 
        where timestamp_now_between <= 864000
        order by timestamp_bd desc 
        limit 1000  FORMAT JSON`
}

function fenceArr(array) {
    let out = ''
    array.forEach(element => {
        out = out + `'${element}' ,`
    });
    //console.log(out)
    return out
}


function fenceArr2(array) {
    let out = ''
    array.forEach((element, i) => {
        out = out + `'${element}' ,`
    });
    out = `( ${out} )`
    let out2 = out.replace(', )', ' )')
    //console.log(out)
    return out2
}

export function getLightChartQuery(props) {
    let timeRange = props.paramsFilter.timeData;
    let q = `
    select 
        DateTime datetime_us,  
        DateTime datetime, 
        round(call/1000000,1) call2, 
        round(put/1000000,1) put , 
        price , 
        unix, 
        expiration
        from (
            select distinct * 
                from ( 
                    SELECT *,  
                    toUnixTimestamp(concat(DateTime,':00')) unix
                    
                    from marketflow.all2 
                    ) a 
                    where 
                        unix >= ${timeRange.unix.from} 
                        and unix <= ${timeRange.unix.to} 
                        and expiration in ('',-1)
            ) a
    order by datetime  `
    //console.log(q)
    //date_add(hour, 10, parseDateTimeBestEffortOrNull(DateTime)) datetime,
    //DateTime datetime date_add(hour, 10, parseDateTimeBestEffortOrNull(DateTime)) datetime,
    return q
}

export function getLightChartQueryExtra(props) {
    //console.log(props)
    const mainChartDaysPeriod = props.paramsFilter.mainChartDaysPeriod ? props.paramsFilter.mainChartDaysPeriod : []
    let expirationWhereStr2 = ''
    if (mainChartDaysPeriod) {
        if (mainChartDaysPeriod.length > 0) {
            expirationWhereStr2 = `where expirationType in ${fenceArr2(mainChartDaysPeriod)}  `
        }
    }
    let timeRange = props.paramsFilter.timeData;
    let q = `
    select 
        DateTime datetime_us,  
        DateTime datetime, 
        round(call/1000000,1) call2, 
        round(put/1000000,1) put , 
        price , 
        unix, 
        expirationType
        from (
            select distinct * 
                from ( 
                    SELECT *,  
                    toUnixTimestamp(concat(DateTime,':00')) unix,
                    case when expiration in (7) then '7_days'
                        when expiration in (15) then '15_days'
                        when expiration in (30) then '30_days'
                        else 'main'
                    end as expirationType
                    from marketflow.all2 
                    ) a 
                    where 
                        unix >= ${timeRange.unix.from} 
                        and unix <= ${timeRange.unix.to} 
                        and expiration not in ('',-1)
            ) a
        ${expirationWhereStr2}
    order by datetime  `
    //console.log(q)
    //date_add(hour, 10, parseDateTimeBestEffortOrNull(DateTime)) datetime,
    //DateTime datetime date_add(hour, 10, parseDateTimeBestEffortOrNull(DateTime)) datetime,
    return q
}

function numberCellFormatter(params) {
    return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function unixCellFormatter(params) {
    return moment.unix(params.value).format("YYYY-MM-DD");
}
const symbol_w = 85

function genOutTime(props) {
    let out = ''
    let seconds = props.seconds
    if (seconds < 0) {
        out = '- '
        seconds = Math.abs(seconds)
    }

    if (seconds < 60) {
        out = out + seconds + ' сек'
    }
    if (seconds >= 60 && seconds < 3599) {
        let minutes = Math.trunc(seconds / 60)
        let sec_out = Math.abs(seconds - minutes * 60)
        out = out + minutes + ' м ' + sec_out + ' сек'
    }
    if (seconds >= 3600) {
        let days = Math.trunc(seconds / 60 / 60 / 24)
        let hours = Math.trunc((seconds - days * 24 * 60 * 60) / 60 / 60)
        let minutes = Math.trunc((seconds - days * 24 * 60 * 60 - hours * 60 * 60) / 60)
        let day_str = days < 1 ? '' : (days + ' дн ')
        let hour_str = hours < 1 ? '' : (hours + ' ч ')
        out = out + `${day_str}${hour_str}${minutes} м`
    }


    return (out)
}


export function getColDef(props) {
    switch (props.type) {
        case 't1': return [
            {
                headerName: 'OT',
                headerTooltip: "Option type",
                field: 'type',
                width: 80,
                cellRenderer: (data) => {
                    let out = <></> //<Tag color="success">Call</Tag>
                    if (data.value === 'SWEEP') {
                        out = <Tag color="gold">SWEEP</Tag>
                    }
                    if (data.value === 'BLOCK') {
                        out = <Tag color="lime">BLOCK</Tag>
                    }
                    if (data.value === 'SPLIT') {
                        out = <Tag color="green">SPLIT</Tag>
                    }
                    return (out)
                }
            },
            {
                headerName: 'MT',
                headerTooltip: "Money type",
                field: 'money_type',
                width: 80,
                cellRenderer: (data) => {
                    let out = <></> //<Tag color="success">Call</Tag>
                    if (data.value === 'ATM') {
                        out = <Tag color="green">ATM</Tag>
                    }
                    if (data.value === 'OTM') {
                        out = <Tag color="gold">OTM</Tag>
                    }
                    if (data.value === 'ITM') {
                        out = <Tag color="blue">ITM</Tag>
                    }
                    return (out)
                }
            },/*{
            headerName: 'OTM',
            field: 'outOfMoney',
            width: 70,
            cellRenderer: (data) => {
                let smile = <></>
                if (data.value) { smile = <>💲</> }
                return (<>{smile}</>)
            }
        },
        {
            headerName: 'OTM pct',
            field: 'otm_pct',
            width: 70
        },*/


            {
                headerName: 'Type',
                field: 'optionType',
                width: 90,
                cellRenderer: (data) => {
                    let out = <Tag color="success">Call</Tag>
                    if (data.value === 'Put') { out = <Tag color="error">Put</Tag> }
                    return (<>{out}</>)
                }
            },
            {
                headerName: 'Symbol',
                field: 'symbol',
                width: symbol_w
            },
            {
                headerName: 'Strike',
                field: 'strike',
                width: 80,
            },
            {
                headerName: 'Spot',
                field: 'spot',
                width: 80,
            }, {
                headerName: 'Время с',
                headerTooltip: "Время с поступления",
                field: 'timestamp_now_enter',
                width: 120,
                cellRenderer: (data) => {
                    return genOutTime({ seconds: data.value })
                }
            }, {
                headerName: 'Дата эксп',
                headerTooltip: "Дата экспирации",
                field: 'timestamp_bd_expiry',
                width: 120,
                valueFormatter: unixCellFormatter
            }, {
                headerName: 'До эксп',
                headerTooltip: "Время до экспирации",
                field: 'timestamp_now_between',
                width: 120,
                cellRenderer: (data) => {
                    return genOutTime({ seconds: data.value })
                }
            },
            /*
                    {
                        headerName: 'ivRate',
                        field: "volumeOpenInterestRatio",
                        //     cellClass: 'number',
                        width: 80,
                        //     valueFormatter: numberCellFormatter,
             
                    },  */
            {
                field: "volume",
                headerName: 'Vol',
                cellClass: 'number',
                width: 120,
                valueFormatter: numberCellFormatter,
                //cellRenderer: (data) => {return numberCellFormatter(data)}
                //width: 90,
            },
            {
                field: "oi",
                headerName: 'OI',
                cellClass: 'number',
                valueFormatter: numberCellFormatter,
                width: 120,
            }, {
                headerName: 'Vol>OI',
                field: 'unusual',
                width: 120,
                cellRenderer: (data) => {
                    let star = <></>
                    if (data.value) { star = <>⭐️</> }
                    return (<>{star}</>)
                }
            }, {
                headerName: 'Sentiment',
                field: 'sentiment',
                width: 120,
                cellRenderer: (data) => {
                    let star = <></>

                    if (data.value === "+") {
                        star =
                            <Tag color="success"><RiseOutlined
                                style={{
                                    color: '#49aa19',
                                    fontSize: 20
                                }}
                            /></Tag>
                    }
                    if (data.value === "-") {
                        star =
                            <Tag color="error"><FallOutlined
                                style={{
                                    color: '#a61d24',
                                    fontSize: 20
                                }}
                            /></Tag>
                    }

                    return (<>{star}</>)
                }

            }, {
                field: 'premium',
                headerName: 'Сумма',
                initialFlex: 90,
                cellClass: 'number',

                valueFormatter: numberCellFormatter,
                cellRenderer: 'agAnimateShowChangeCellRenderer',
                enableCellChangeFlash: true,
                initialPinned: 'right',
                width: 120,
            },
        ]

        case 'signals': return [
            {
                headerName: 'Date',
                headerTooltip: "Date",
                field: 'timestamp',
                cellRenderer: (data) => {
                    return moment.unix(data.value).utc().format('YYYY-MM-DD HH:mm')
                }

            },

            {
                headerName: 'Type',
                headerTooltip: "Type",
                field: 'type',

                cellRenderer: (data) => {
                    let out = <Tag color="success">LONG</Tag>
                    if (data.value === 'short') { out = <Tag color="error">SHORT</Tag> }
                    return (<>{out}</>)
                }
            },
            {
                headerName: 'Price',
                headerTooltip: "Price",
                field: 'price',

            },
            {
                headerName: 'Ideal exit price',
                headerTooltip: "Ideal exit price",
                field: 'price_ideal_exit',

            },
            {
                headerName: 'Price, pct',
                headerTooltip: "Price, pct",
                field: 'price_pct',
                cellRenderer: (data) => {
                    return `${data.value} %`

                }

            },
        ]

        default: return []
    }



}