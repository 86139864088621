import AlaSQL from "alasql"

export function genSignalsData(props) {
    //console.log(props)
    const callData = props.callData
    const putData = props.putData
    const priceData = props.priceData

    let priceDataUpd = []
    if (props.priceData) {
        if (props.priceData.length > 0) {
            priceData.forEach(e => {
                priceDataUpd.push({
                    time: e.time,
                    value: e.value,
                    val: e.value
                })
            });
        }
    }

    let out = []
    if (callData.length > 0) {
        let m_sell = []
        let call_0 = callData[0].value
        let put_0 = putData[0].value
        // call > put =>
        let status_0 = call_0 > put_0 ? true : false

        for (let i = 1; i < callData.length; i++) {
            let timestamp = callData[i].time
            let call = callData[i].value
            let put = putData[i].value
            let price = priceData[i].value


            //console.log(call)
            if (status_0 && call < put) {
                const type = 'short'
                let min_price_sql = AlaSQL(`select min(val) min_val from ? 
                where time >= ${timestamp}                
                `, [priceDataUpd])
                let min_price = min_price_sql[0].min_val
                let sell_pct = (100 - ((price / min_price) * 100)).toFixed(2) * -1

                status_0 = false
                out.push({
                    id: String(`${timestamp}_${type}_${price}`),
                    timestamp: timestamp, call: call, put: put,
                    price: price,
                    price_ideal_exit: min_price,
                    price_pct: sell_pct,
                    type: type
                })
            }
            if (!status_0 && call > put) {
                const type = 'long'
                let max_price_sql = AlaSQL(`select max(val) max_val from ? 
            where time >= ${timestamp}                
            `, [priceDataUpd])
                let max_price = max_price_sql[0].max_val
                let buy_pct = (100 - ((price / max_price) * 100)).toFixed(2)

                status_0 = true
                out.push({
                    id: String(`${timestamp}_${type}_${price}`),
                    timestamp: timestamp, call: call, put: put,
                    price: price,
                    price_ideal_exit: max_price,
                    price_pct: buy_pct,
                    type: type
                })
            }
        }
    }
    let out2 = AlaSQL('select * from ? order by timestamp desc', [out])
    //console.log('out')
    //console.log(out)
    return out2
}

export function checkNewSignal(signalDataOld, signalDataNew) {
    let outObj = {
        status: 'null',
        data: []
    }
    const a = signalDataOld
    //console.log(a)
    const b = signalDataNew
    //console.log(b)
    const outArr = AlaSQL(' select * from ? as b left join ? as a on a.timestamp =b.timestamp  where a.timestamp is NULL', [b, a])
    if (outArr.length > 0) {
        outObj.status = 'ok'
        outObj.data = outArr
    }
    return outObj
}


export function checkNewSignal_2(signalDataOld, signalDataNew) {
    let outObj = {
        status: 'null',
        data: []
    }
    const a = signalDataOld
    //console.log(a)
    const b = signalDataNew
    //console.log(b)
    const outArr = AlaSQL(' select * from ? as b left join ? as a on a.id = b.id  where a.timestamp is NULL', [b, a])
    if (outArr.length > 0) {
        outObj.status = 'ok'
        outObj.data = outArr
    }
    console.log('outObj')
    console.log(outObj)
    return outObj
}