import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';

const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',

    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',

    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',

    price_color: 'white',
}

const LightWeightChartLineMini = (props) => {
    //console.log(props)
    const params = props.props
    const data = params

    const chartContainerRef = useRef();
    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({  width: chartContainerRef.current.clientWidth });
            /*if(chartContainerRef){
                if(chartContainerRef.current){
                    if(chartContainerRef.current.clientWidth){
                        chart.applyOptions({  width: chartContainerRef.current.clientWidth });
                        //chart.timeScale().fitContent()
                    }
                }
            }*/
        };
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: 35,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: false
            },
            rightPriceScale: {
                visible: false
            },
            timeScale: {
                visible: false,
                timeVisible: false
            }
        });


        //const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };
        //const chart = createChart(document.getElementById('container'), chartOptions);
        const areaSeries = chart.addLineSeries({
            color: '#229954'
            //topColor: '#2962FF', 
            //bottomColor: 'rgba(41, 98, 255, 0.28)' 
        });
        areaSeries.setData(data.callData);

        const putSeries = chart.addLineSeries({
            color: '#E74C3C',
            lineWidth: 2,
            type: 'solid',
            //lineStyle: 2, // LineStyle.Dashed
            axisLabelVisible: true,

        })
        putSeries.setData(data.putData)

        chart.timeScale().fitContent();
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data]);


    return (
        <>
            <div
                ref={chartContainerRef}
            />
        </>
    )
}

export default LightWeightChartLineMini;