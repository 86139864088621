import React, { Component, useState, useRef, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import { NavLink, Link, Navigate, useLocation, Outlet } from 'react-router-dom'
import { render } from 'react-dom';
import { Routes, Route } from 'react-router-dom';
import moment from "moment-timezone";
import dayjs from 'dayjs';
import {
    Affix,
    Badge, Cascader,
    Breadcrumb, DatePicker, Dropdown, Card, Divider, Col, InputNumber, Layout, Menu, Modal,
    notification,

    Progress, Tooltip,
    Row, Statistic,
    Select, Space, Spin, Switch,
    Typography, Tag, Table, Button
} from "antd";

import {
    ArrowUpOutlined, ArrowDownOutlined,
    VideoCameraOutlined, YoutubeOutlined, ReloadOutlined
} from '@ant-design/icons'
import 'antd/dist/antd.dark.css';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { Player } from "video-react";
import "video-react/dist/video-react.css";
import YouTube from 'react-youtube';
import ModalVideo from 'react-modal-video'

import * as agCharts from 'ag-charts-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import { AgChartsReact } from 'ag-charts-react';
import { AgGridReact } from 'ag-grid-react';
import * as CONFIG from './config'
import * as CONFIG_FLOW from '../Flow/config'
import * as QUERYS from './querys'
import * as TAPI from './tapi'
import * as SIGNALS from './functions/signals'

import * as Data from '.././data'
import * as API from '../../libraries/API'
import * as Dates from '../../libraries/DatesOld'
import AlaSQL from "alasql";
import { URLs } from '../../urls';
import { STRs } from '../strings';

import LightWeightChart from '../../components/LightWeightChart';
import LightWeightChartExtra from '../../components/LightWeightChartExtra';
import GaugeChartMetr from '../../components/GaugeChartMetr';
import AntSelect from '../../components/AntSelect';
import AntDateSelectOptions from '../../components/AntDateSelectOptions';
import AntCardAgChart from '../../components/AntCardAgChart';
import { ContinuousScale } from 'ag-charts-community/dist/cjs/es5/scale/continuousScale';
import signalLong from '../Files/signalLong.wav'
import signalShort from '../Files/signalShort.wav'
import MiniChartsBar from '../components/MiniChartsBar';
import LightChartExpirations from '../components/LightChartExpirations';
import GaugeChartMetrs from '../components/GaugeChartMetrs';
import { pixelPageView } from "../../services/pixel";
import Clue from '../components/Clue';
import TitleExtra from '../components/TitleExtra';

const { Text } = Typography;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { SHOW_CHILD } = Cascader;

const defaultDateFormat = 'DD-MM-YYYY';


let date_to_use = '07/29/2022'
let barTopCallMaxPremium = {}
let barTopPutMaxPremium = {}
let barTopEtfMaxPremium = {}
let barTopStockMaxPremium = {}
let barTopEtfShortMaxPremium = {}

const barStr = {
    'premium_c_exp_d10': 'до 10 дн',
    'premium_c_exp_d40': 'от 10 до 40 дн',
    'premium_c_exp_d60': 'от 40 до 60 дн',
    'premium_c_exp_m_d60': 'от 60 дн',
    'premium_p_exp_d10': 'до 10 дн',
    'premium_p_exp_d40': 'от 10 до 40 дн',
    'premium_p_exp_d60': 'от 40 до 60 дн',
    'premium_p_exp_m_d60': 'от 60 дн'
}

const Str = {
    top_etf_title: 'ТОП ОБЬЕМЫ ПО ETF',
    top_etf_descr: 'description',
    short_etfs_arr: `('VIXY','UVXY','VIX','YANG','WEBS','TZA','TWM','TECS','SZK','SWEB','SSG','SRS','SQQQ','SPXU','SPXS','SPDN','SOXS','SOGU','SMN','SKF','SINV','SIJ','SH','SEF','SDS','SDP','SDD','SCC','SBB','SARK','RXD','RWM','REW','QID','PSQ','OILD','NRGD','MZZ','MYY','MJIN','IRBA','HISF','HIBS','HDGE','GNAF','GDXD','FXP','FNGZ','FNGD','FLYU','FAZ','EWV','EUM','ERY','EPV','EMTY','EFZ','EFU','EEV','EDZ','DXD','DWSH','DUST','DUG','DRV','DOG','DDG','CLDS','BZQ','BNKD','BIS','BERZ')`,
    top_stock_title: 'ТОП ОБЬЕМЫ ПО АКЦИЯМ',
    top_stock_descr: 'description',
    top_etf_short_title: 'ТОП ОБЬЕМЫ ПО SHORT ETF',
    top_etf_short_descr: 'description',
    lchart_title: 'Рыночный поток опционов',
    lchart_descr: 'Совокупная чистая премия от всех акций на рынке по сравнению с ценой акций SPY должна использоваться для прогнозирования направления рынка.',
    space: ' ',
    legend_put: ' Put    ',
    legend_call: ' Call    ',
    legend_price: ' Price    ',
    bar_mln_postfix: 'm',
    bar_bln_postfix: 'b'
}

const MenuItems = [{
    key: 'options',
    label: 'Опционы',
    to: 'options'
}, {
    key: 'flow',
    label: 'Мой поток',
    to: 'flow'
}]




const onClickNotification = () => {
    // console.log('onClickNotification')
}



const pagePosition = 'options';


const updateActiveColor = "#52c41a"//"red" //"#52c41a"
const updateModeConfig = {
    '5': { auto: true, interval: 30000, color: updateActiveColor, flow: true }, // 30 sec,
    '1': { auto: true, interval: 1000000, color: "white", flow: false },
    '2': { auto: true, interval: 60000, color: updateActiveColor, flow: false },
    '3': { auto: true, interval: 180000, color: updateActiveColor, flow: false },
    '4': { auto: true, interval: 300000, color: updateActiveColor, flow: false },
}

//let options_updateMode = '1'
let checkUpdateMode = ''
let SignalsData2 = []

let options_signals_default = []


String.prototype.hashCode = function () {
    var hash = 0,
        i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}


//let ChartDataExpiration = []
const PageOptions = (props) => {

    let options_updateMode = localStorage.getItem(`main_settings`) ? (
        (JSON.parse(localStorage.getItem(`main_settings`))).options_updateMode ?
            (JSON.parse(localStorage.getItem(`main_settings`))).options_updateMode
            : props.settings.options_updateMode
    ) : props.settings.options_updateMode
    const ytestRef = useRef();
    let options_signals = localStorage.getItem(`options_signals`) ? (
        (JSON.parse(localStorage.getItem(`options_signals`))) ?
            (JSON.parse(localStorage.getItem(`options_signals`)))
            : options_signals_default
    ) : options_signals_default
    const [userInfo] = useState(props.userInfo)
    const [translationConfig] = useState(props.translationConfig)
    const [pageConfigs] = useState(props.pageConfigs)
    const [signalWorkCheck, setSignalWorkCheck] = useState(false);
    const [SettingsMain, setSettingsMain] = useState(
        localStorage.getItem(`${pagePosition}_settings`) ? JSON.parse(localStorage.getItem(`${pagePosition}_settings`)) :
            CONFIG.defaultMainSettings
    )
    const [MainTableSwitchShowExpired, setMainTableSwitchShowExpired] = useState(false)
    const [blockTableFilterSymbols, setBlockTableFilterSymbols] = useState([])
    const [ModalTableStatisticCall, setModalTableStatisticCall] = useState(0)
    const [ModalTableStatisticPut, setModalTableStatisticPut] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalNotificationOpen, setModalNotificationOpen] = useState(false);
    const [modalSymbol, setModalSymbol] = useState(null);
    const [isLearnModalOpen, setIsLearnModalOpen] = useState(false);
    const gridRef = useRef();
    const gridRefSignals = useRef();
    const gridRefModalTable = useRef();
    const [RangePickerValue, setRangePickerValue] = useState(null)
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(Dates.initDateRange())
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [ChartData, setChartData] = useState({
        callData: [{ time: 1659681000, value: 0 }],
        putData: [{ time: 1659681000, value: 0 }],
        priceData: [{ time: 1659681000, value: 0 }]
    })
    const [SignalsTableColDef, setSignalsTableColDef] = useState(CONFIG.getColDef({ type: 'signals' }));
    const [SignalsData, setSignalsData] = useState([])
    const [ParamsLoaded, setParamsLoaded] = useState(false)
    const [loadings, setLoadings] = useState(true);
    const [ParamsFilter, setParamsFilter] = useState(
        JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
            : CONFIG.defaultParamsFilter
    )
    const [ParamsChart, setParamsChart] = useState(CONFIG.defaultParamsChart)
    const [ParamsStorage, setParamsStorage] = useState(CONFIG.gefaultParamsStorage)
    const [ChartDataExpiration, setChartDataExpiration] = useState([])
    const [BarChartData, setBarChatData] = useState()
    const [BarMiniLineChartsData, setBarMiniLineChartsData] = useState([])


    function getStorageData() {
        let props = {}
        let paramsFilter = localStorage.getItem(`${pagePosition}_params_filter`)
        if (paramsFilter) {
            props.paramsFilter = JSON.parse(paramsFilter)
            if (props.paramsFilter.timeData.value === 'fixed') {
            } else {
                props.paramsFilter.timeData = DateSelectOptionsRaw[props.paramsFilter.timeData.value]
            }
            if (props.paramsFilter.showExpired) {
                if (props.paramsFilter.showExpired === false) {
                    props.paramsFilter.showExpired = false
                    setMainTableSwitchShowExpired(false)
                } else {
                    setMainTableSwitchShowExpired(props.paramsFilter.showExpired)
                }
            }
            setParamsFilter(props.paramsFilter)
        } else {
            props.paramsFilter = ParamsFilter
        }
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(props.paramsFilter))
        let paramsChart = localStorage.getItem(`${pagePosition}_params_chart`)
        if (paramsChart) {
            props.paramsChart = JSON.parse(paramsChart)
            setParamsChart(JSON.parse(props.paramsChart))
        } else {
            props.paramsChart = ParamsChart
        }
        try {
            const settingsMain = localStorage.getItem(`main_settings`)
            let sets = {}
            if (settingsMain) {
                sets = JSON.parse(settingsMain)
                setSettingsMain(sets)
                props.settingsMain = sets
            } else {
                props.settingsMain = SettingsMain
            }
        } catch (e) {

        }
        setParamsStorage(props)
        return props
    }


    function saveStorageData() { localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(ParamsFilter)) }
    function saveMainSettings(settingsMain) { localStorage.setItem(`main_settings`, JSON.stringify(settingsMain)) }

    const [MainTableSwitchShowExpiredDisabled, setMainTableSwitchShowExpiredDisabled] = useState(false)
    const onChangeMainTableSwitchShowExpired = (e) => {
        setMainTableSwitchShowExpiredDisabled(true)
        // setMainTableSwitchShowExpired(!e)
        let paramsFilter = ParamsFilter
        paramsFilter.showExpired = e
        setParamsFilter(paramsFilter)
        saveStorageData()
        getTableData({ paramsFilter: paramsFilter })
    }

    const [MainTableSwitchShowSentimentDisabled, setMainTableSwitchShowSentimentDisabled] = useState(false)
    const onChangeMainTableSwitchShowSentiment = (e) => {
        setMainTableSwitchShowSentimentDisabled(true)
        // setMainTableSwitchShowExpired(!e)
        let paramsFilter = ParamsFilter
        paramsFilter.showSentiment = e
        setParamsFilter(paramsFilter)
        saveStorageData()
        getTableData({ paramsFilter: paramsFilter })
    }


    useEffect(() => {
        let props = {}
        if (!ParamsLoaded) {
            props = getStorageData()
            updateDataNew(props)
            setParamsLoaded(true)
        }
    }, [])



    // block_modal
    const [ModalOptionTypes, setModalOptionTypes] = useState([])
    const [ModalFilterPremiumCondition, setModalFilterPremiumCondition] = useState('>=')
    const [ModalFilterPremiumLimit, setModalFilterPremiumLimit] = useState(1)
    const [ModalGaugeChartData, setModalGaugeChartData] = useState({
        pct_15_min: 0,
        //pct_30_min: 0,
        pct_60_min: 0,
        pct_1_day: 0
    })
    const smW = 500
    window.addEventListener('resize', (event) => {
        MainTableSizeToFit()
    })

    // optionType
    const ModalOptionTypesRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const color = CONFIG.OptionTypesColor
        return (
            <Tag
                color={color[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };
    const onChangeModalOptionTypes = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        getModalTableData({
            symbol: modalSymbol, timeData: paramsFilter.timeData,
            //symbol: modalSymbol ? modalSymbol : 'SPX', timeData: paramsFilter.timeData,
            filter: paramsFilter,
            optionTypes: e,
            premiumLimit: ModalFilterPremiumLimit,
            premiumCondition: ModalFilterPremiumCondition,
            settingsMain: SettingsMain
        });
        setModalOptionTypes(e)
    }

    // premium
    const onChangeModalFilterPremiumCondition = (e) => {
        getModalTableData({
            symbol: modalSymbol, timeData: ParamsFilter.timeData,
            //symbol: modalSymbol ? modalSymbol : 'SPX', timeData: ParamsFilter.timeData,
            filter: ParamsFilter,
            optionTypes: ModalOptionTypes,
            premiumLimit: ModalFilterPremiumLimit,
            premiumCondition: e,
            settingsMain: SettingsMain

        });
        setModalFilterPremiumCondition(e)
    }

    const ModalFilterPremiumBefore = () => {
        return (
            <Select
                defaultValue=">="
                style={{ width: 65, }}
                onChange={onChangeModalFilterPremiumCondition}
                value={ModalFilterPremiumCondition ? ModalFilterPremiumCondition : '>='}
                options={[{
                    value: '>='
                }, {
                    value: '<='
                }]}
            >
            </Select>
        )
    }

    const onChangeModalFilterPremiumLimit = (e) => {
        getModalTableData({
            symbol: modalSymbol ? modalSymbol : 'SPX', timeData: ParamsFilter.timeData,
            filter: ParamsFilter,
            optionTypes: ModalOptionTypes,
            premiumLimit: e,
            premiumCondition: ModalFilterPremiumCondition,
            settingsMain: SettingsMain
        });
        setModalFilterPremiumLimit(e)
    };


    function updateDataNew(props) {
        //console.log('updateDataNew')
        //console.log(props)
        getSymbolsData(props)
        getBlockTableSymbols(props)
        if (!updateModeConfig[options_updateMode].auto) {
            pixelPageView(userInfo ? (userInfo.data ? (userInfo.data.email ? userInfo.data.email : 'undefined email') : 'undefined data') : 'undefined info')
        }
    }

    const [BarMiniLineChartsDataLoading, setBarMiniLineChartsDataLoading] = useState(false)
    function getBarMiniLineChartsData(props) {
        setBarMiniLineChartsDataLoading(true)

        fetch(URLs.bd_url, {
            method: 'POST',
            body: QUERYS.getBarMiniLineChartsQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                if (resp) {
                    if (resp.data) {

                        const resp_data = resp.data
                        const a = AlaSQL(`select symbol, max(unix) unix_max, min(unix) unix_min from ? 
                        group by symbol
                         `, [resp_data])
                        const out = AlaSQL(`select *, 
                        case when unix = unix_max then 'max'
                        else 'min' end as tType
                        from ( select * from ? as a left join ? as b 
                        on a.symbol = b.symbol  
                        and b.unix in ( a.unix_max, a.unix_min ) ) a
                        order by unix
                        `, [a, resp_data])
                        //console.log(out)

                        if (out) {
                            if (out.length > 0) {
                                const tickers = {}
                                out.forEach(row => {
                                    tickers[row.symbol] = { callData: [], putData: [] }
                                })
                                Object.keys(tickers).forEach(tkey => {
                                    const ticker = tkey
                                    out.forEach(row => {
                                        let rTicker = row.symbol
                                        let tType = row.tType
                                        if (ticker === rTicker) {
                                            const call = row.c //=== 0 ? 0 : Number((row.c / 1000).toFixed(0))
                                            const put = row.p //=== 0 ? 0 : Number((row.p / 1000).toFixed(0))
                                            if (tType === 'max') {
                                                tickers[ticker].callData.push({ value: call, time: row.unix_max })
                                                tickers[ticker].putData.push({ value: put, time: row.unix_max })
                                            }
                                            if (tType === 'min') {
                                                tickers[ticker].callData.push({ value: call, time: row.unix_min })
                                                tickers[ticker].putData.push({ value: put, time: row.unix_min })
                                            }
                                        }
                                    })
                                })
                                //console.log(tickers)
                                const barData = []
                                Object.keys(tickers).forEach(tkey => {
                                    const tRow = tickers[tkey]
                                    barData.push({
                                        ticker: tkey,
                                        //premium: 20,
                                        data: {
                                            callData: tRow.callData,
                                            putData: tRow.putData
                                        }
                                    })

                                })
                                //console.log(barData)

                                fetch(URLs.bd_url, {
                                    method: 'POST',
                                    body: QUERYS.getBarMiniLineChartsTotalsQuery({ timeData: props.paramsFilter.timeData, tickers: Object.keys(tickers) })
                                })
                                    .then((resp_json) => resp_json.json())
                                    .then((resp) => {
                                        if (resp) {
                                            if (resp.data) {
                                                const totalData = resp.data
                                                //console.log(resp)
                                                const out = AlaSQL(`select * from (select * from ? as a left join ? as b 
                                                    on a.ticker = b.symbol ) where premium is not null order by premium desc
                                                    `, [barData, totalData])
                                                //console.log(out)
                                                if (out) {
                                                    if (out.length > 0) {
                                                        setBarMiniLineChartsData(out)
                                                    }
                                                }
                                            }
                                        }
                                    })
                            } else {
                                setBarMiniLineChartsData([])
                            }
                        }
                    }
                }
                setBarMiniLineChartsDataLoading(false)
            })
    }


    const [symbolListLoaded, setSymbolListLoaded] = useState(false)
    const [symbolListData, setSymbolListData] = useState([{ symbol: 'SPY' }, { symbol: 'QQQ' }])
    const [symbolListValue, setSymbolListValue] = useState(null)

    function getSymbolsData(props) {
        setLoadings(true);
        fetch(URLs.bd_url, {
            method: 'POST',
            body: CONFIG.getBDQuerySymbolsList(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                let paramsFilter = props.paramsFilter
                let resp_0 = [{ value: 'main', label: 'Общий' }]
                if (resp.data) {
                    if (resp.data.length > 0) {
                        resp.data.forEach(row => {
                            resp_0.push({ value: row.symbol, label: row.symbol })
                        })
                    } else {
                        //setSymbolListValue('main')
                        paramsFilter.selectedSymbol = 'main'
                    }
                }
                //paramsFilter.showExpired = MainTableSwitchShowExpired
                setSymbolListData(resp_0)
                setSymbolListLoaded(true)
                getLightChartData({ paramsFilter: paramsFilter, settingsMain: props.settingsMain });
                getLightChartDataExtra({ paramsFilter: paramsFilter, settingsMain: props.settingsMain });
                getTableData({ paramsFilter: paramsFilter });
                getTopCallData({ paramsFilter: paramsFilter });
                setParamsFilter(paramsFilter)
                getBarMiniLineChartsData(props)
                saveStorageData()
                if (isModalOpen) {
                    getModalBarData({
                        symbol: modalSymbol, timeData: paramsFilter.timeData,
                        //symbol: modalSymbol ? modalSymbol : 'SPX', timeData: paramsFilter.timeData,
                        settingsMain: props.settingsMain
                    })
                    getModalTableData({
                        symbol: modalSymbol, timeData: paramsFilter.timeData,
                        //symbol: modalSymbol ? modalSymbol : 'SPX', timeData: paramsFilter.timeData,
                        filter: paramsFilter,
                        optionTypes: ModalOptionTypes,
                        premiumLimit: ModalFilterPremiumLimit,
                        premiumCondition: ModalFilterPremiumCondition,
                        settingsMain: props.settingsMain
                    });
                }

            })
            .catch(e => {
                // console.log(e)
            })
        setLoadings(false);
        //gridRef.current.api.sizeColumnsToFit();
    }


    function getBlockTableSymbols(props) {
        //setLoadings(true);
        fetch(URLs.bd_url, {
            method: 'POST',
            body: QUERYS.getBlockTableSymbols(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                if (resp) {

                    const data = resp.data
                    let symbols = []
                    data.forEach(row => {
                        symbols.push({ value: row.symbol })
                    })
                    setBlockTableFilterSymbols(symbols)
                }
            })
    }


    const [GaugeChartData, setGaugeChartData] = useState({
        pct_15_min: 0,
        pct_30_min: 0,
        pct_60_min: 0,
        pct_1_day: 0
    })
    const [GaugeChartOtmData, setGaugeChartOtmData] = useState({
        pct_otm_call: 0,
        pct_otm_put: 0
    })


    const [defaultRange, setDefaultRange] = useState({
        from: moment().startOf("month"),
        unix_from: moment().startOf("month").unix(),
        to: moment().endOf("month"),
        unix_to: moment().endOf("month").unix(),
        dateFormat: 'DD-MM-YYYY'
    })


    const [autoUpdate, setAutoUpdate] = useState(
        updateModeConfig[options_updateMode].auto
    )


    const [autoUpdateInterval, setAutoUpdateInterval] = useState(
        updateModeConfig[options_updateMode].interval
    )

    //const [flowApi, setFlowApi] = useState(false)


    function onMenuClick(e) {
        //console.log('onMenuClick')
        //console.log(new Date())
        let key = e.key

        //setAutoUpdateSelectedKey(String(key))
        options_updateMode = key
        //console.log(' onMenuClick key')
        //console.log(key)
        let settingsMain = SettingsMain
        settingsMain.options_updateMode = key

        saveMainSettings(settingsMain)

        const config = updateModeConfig[key]
        //console.log('config')
        //console.log(config)

        //setAutoUpdate(config.auto)
        setAutoUpdateInterval(config.interval)

        //setAutoUpdateColor(config.color)
        //setFlowApi(config.flow)

        updateDataNew({ paramsFilter: ParamsFilter, settingsMain: settingsMain })
        //setCheckUpdateMode(key)

        checkUpdateMode = key
    }


    const updateModeMenuItems = [
        { key: '1', label: 'Обновлять вручную', },
        { key: '5', label: 'Каждые 30 сек', },
        { key: '2', label: 'Каждую минуту', },
        { key: '3', label: 'Каждые 3 минуты', },
        { key: '4', label: 'Каждые 5 минут', },
    ]

    const menuProps = (props) => {
        return {
            items: updateModeMenuItems,
            selectable: true,
            defaultSelectedKeys: ['1'],
            selectedKeys: [props.selected],
            onClick: onMenuClick,
        }
    };

    useEffect(() => {
        setSettingsMain(props.settings)
        if (props.toUpdatePage) {
            onMenuClick({ key: props.settings.options_updateMode })
            updateDataNew({ paramsFilter: ParamsFilter, settingsMain: props.settings })
        }
    }, [props]) // <-- here put the parameter to listen


    const [TopCallData, setTopCallData] = useState()
    const [TopPutData, setTopPutData] = useState()



    const [DateSelectRawOptions, setDateSelectRawOptions] = useState(Dates.initDateRange())
    const [RangesRawOptions] = useState(Dates.initDateRange())


    const [t1ColDef, setT1ColDef] = useState(CONFIG.getColDef({ type: 't1' }));
    const [t1RowData, setT1RowData] = useState([]);

    const [ModalTableColDef, setModalTableColDef] = useState(CONFIG.getColDef({ type: 't1' }));
    const [ModalTableData, setModalTableData] = useState([]);

    const [barModalTitle, setBarModalTitle] = useState('Default')
    const [signalModalTitle, setsignalModalTitle] = useState('Сигналы')
    const [barModalChartData, setBarModalChartData] = useState([{
        close: 0,
        high: 0,
        low: 0,
        open: 0,
        time: 1667838600
    }])
    const [tableRowSelectedData, setTableRowSelectedata] = useState()
    const [barModalLoading, setBarModalLoding] = useState(false)
    const [showBarModal, setShowBarModal] = useState(true)

    function getModalTableData(props) {
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getModalTableDataQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                const data_0 = AlaSQL(QUERYS.getModalTableDataQuery1(), [resp.data])
                setModalTableData(data_0)
                //console.log(data_0)

                const totalCall = AlaSQL(`select sum(premium) pr  from ? where optionType = 'Call'  `, [data_0])
                //console.log(totalCall)
                if (totalCall) {
                    if (totalCall.length > 0) {
                        const oPr = totalCall[0].pr
                        const pr = oPr > 1000000 ? `${Number((oPr / 1000000).toFixed(1))}m` : (
                            oPr > 1000 ? `${Number((oPr / 1000).toFixed(1))}k` : oPr
                        )
                        setModalTableStatisticCall(pr)
                    }
                }

                const totalPut = AlaSQL(`select sum(premium) pr  from ? where optionType = 'Put'  `, [data_0])
                //console.log(totalCall)
                if (totalPut) {
                    if (totalPut.length > 0) {
                        const oPr = totalPut[0].pr
                        const pr = oPr > 1000000 ? `${Number((oPr / 1000000).toFixed(1))}m` : (
                            oPr > 1000 ? `${Number((oPr / 1000).toFixed(1))}k` : oPr
                        )
                        setModalTableStatisticPut(pr)
                    }
                }

                let timestamp_bd_max = (AlaSQL(`select max(timestamp_bd) timestamp_bd_max from ? `, [data_0]))[0]
                    .timestamp_bd_max

                if (timestamp_bd_max) {
                    let progress = AlaSQL(QUERYS.geModalTableDataQuery2({
                        paramsFilter: props.paramsFilter,
                        timestamp_bd_max: timestamp_bd_max,
                        short_etfs_arr: Str.short_etfs_arr
                    }), [data_0])
                    //console.log(data_0)
                    //console.log(progress)
                    setModalGaugeChartData({
                        pct_15_min: (Number(progress[0].pct_15_min)).toFixed(2),
                        //pct_30_min: (Number(progress[0].pct_30_min)).toFixed(2),
                        pct_60_min: (Number(progress[0].pct_60_min)).toFixed(2),
                        pct_1_day: (Number(progress[0].pct_1_day)).toFixed(2)
                    })
                } else {
                    /*setModalGaugeChartData({
                        pct_15_min: 0,
                        //pct_30_min: 0,
                        pct_60_min: 0,
                        pct_1_day: 0
                    })*/
                }
                //setT1RowData(data_0)
                //console.log(data_0)

                if (gridRefModalTable) {
                    if (gridRefModalTable.current) {
                        if (gridRefModalTable.current.api) {
                            gridRefModalTable.current.api.sizeColumnsToFit()
                        }
                    }
                }
            })
    }

    function getModalBarData(props) {
        //console.log(props)
        setBarModalLoding(true)
        const interval_min = 15
        const query = `https://api.twelvedata.com/time_series?apikey=19f499760a2742719ac1859db39dd32d&interval=${interval_min}min&format=JSON`
            + `&symbol=${props.symbol}`
            + '&dp=2&outputsize=1300'
            + '&timezone=America/New_York'
        fetch(query, {
            method: 'GET'
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                if (resp) {
                    //console.log(resp)
                    if (resp.status === 'ok') {
                        const data = resp.values
                        //console.log(data)
                        let prepare = []
                        data.forEach(row => {
                            prepare.push({
                                //datetime: row.datetime,
                                time: moment.utc(row.datetime).unix() + props.settingsMain.sessionTimeStamp,
                                open: parseFloat(row.open),
                                high: parseFloat(row.high),
                                low: parseFloat(row.low),
                                close: parseFloat(row.close),
                                //volume: row.volume
                            })
                        })
                        //console.log(prepare)
                        //where time <= ${props.timeData.unix.to}
                        const data_1 = AlaSQL(`select * from ? order by time`, [prepare])

                        //where time >= ${props.timeData.unix.from} and time <= ${props.timeData.unix.to}

                        //console.log(data_1)
                        setShowBarModal(true)
                        setBarModalChartData(data_1)
                        setBarModalLoding(false)

                    } else {
                        setShowBarModal(false)
                        //console.log('error twelvedata')
                        //console.log(resp.message)
                        setBarModalLoding(false)
                        //console.log(resp)
                    }
                }
            })
    }

    const showModal = (props) => {
        setBarModalChartData([{
            close: 0,
            high: 0,
            low: 0,
            open: 0,
            time: 1667838600
        }])
        //console.log('showModal')
        //console.log(props)
        const filters = props.filters
        const timeData = filters.timeData
        const symbol = props.event.datum.type;
        setModalSymbol(symbol)
        //setBarModalTitle(`${symbol} ${timeData.visual.date_from}-${timeData.visual.date_to}`)
        setBarModalTitle(<>
            <Row>
                <Col span={12}>
                    <Title level={4} strong style={{ margin: 0, paddingTop: 2, paddingBottom: 2 }} >{symbol}
                    </Title>
                </Col>

            </Row>
        </>
        )
        /*
    <Col span={11} align='right'>
                    <Title level={5} style={{ margin: 0, paddingTop: 2, paddingBottom: 2 }} >
                        {timeData.work.date_from} - {timeData.work.date_to}
                    </Title>
                </Col>
        */
        setIsModalOpen(true)
        getModalBarData({ symbol: symbol, timeData: timeData, settingsMain: SettingsMain })
        //console.log(ModalFilterPremiumCondition)
        getModalTableData({
            symbol: symbol,
            timeData: timeData,
            filter: ParamsFilter,
            optionTypes: ModalOptionTypes,
            premiumLimit: ModalFilterPremiumLimit,
            premiumCondition: ModalFilterPremiumCondition,
            settingsMain: SettingsMain
        });

    };

    const handleOk = () => {
        setIsModalOpen(false);
        setTableRowSelectedata(null)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setTableRowSelectedata(null)
    };

    const BarListeners = (event) => {
        showModal({ event: event, data: BarChartData, filters: ParamsFilter })
    }

    const barCutPct = 0.1;
    const BarLabel = (props) => {
        if (props.row_max) {
            if (props.row_max.premium) {
                const premium = props.row_max.premium / 1000000
                const value = props.params.value === undefined ? 0 : props.params.value.toFixed(1);
                if (value >= premium * barCutPct) {
                    if (value >= 100) {
                        return (value / 1000).toFixed(1) + Str.bar_bln_postfix
                    } else
                        return value + Str.bar_mln_postfix
                } else return ''
            } else return ''
        } else return ''
    }

    let BarOptions = {
        options: {
            theme: {
                baseTheme: 'ag-default-dark',
                overrides: {
                    bar: {
                        series: {
                            strokeWidth: 0,
                            highlightStyle: {
                                series: {
                                    strokeWidth: 1,
                                    dimOpacity: 0.3,
                                },
                            },
                        },
                    },
                },
            },
            autoSize: true,
            data: TopCallData,
            series: [{
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d10',
                yName: barStr['premium_c_exp_d10'],
                stacked: true,
                fill: '#229954',
                tooltip: {
                    renderer: function (params) {
                        //console.log(params)
                        const datum = params.datum
                        const otm_pct = (datum[`${params.yKey}_otm`] / (datum[`${params.yKey}_otm`] + datum[`${params.yKey}_oem`]) * 100).toFixed(1)
                        return '<div class="ag-chart-tooltip-title" '
                            + 'style="background-color:'

                            + params.color + '">'
                            + params.xValue + '</div>'
                            + '<div class="ag-chart-tooltip-content">'
                            + 'Общая '
                            + params.yValue + 'M $ ' + params.yName + `
                                <br>
                                OTM:  ${otm_pct} %
                                </div>`;
                    }
                },
                label: {
                    fontWeight: 'bold',
                    color: 'white',
                    formatter: function (params) {
                        return BarLabel({ row_max: barTopCallMaxPremium, params: params })
                    }
                },
                listeners: {
                    nodeClick: (event) => {
                        BarListeners(event)
                    }
                }
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d40',
                yName: barStr['premium_c_exp_d40'],
                stacked: true,
                fill: '#27AE60',

            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d60',
                yName: barStr['premium_c_exp_d60'],
                stacked: true,
                fill: '#52BE80'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_m_d60',
                yName: barStr['premium_c_exp_m_d60'],
                stacked: true,
                fill: '#7DCEA0'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d10',
                yName: barStr['premium_p_exp_d10'],
                stacked: true,
                fill: '#E74C3C'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d40',
                yName: barStr['premium_p_exp_d40'],
                stacked: true,
                fill: '#EC7063'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d60',
                yName: barStr['premium_p_exp_d60'],
                stacked: true,
                fill: '#F1948A'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_m_d60',
                yName: barStr['premium_p_exp_m_d60'],
                stacked: true,
                fill: '#F5B7B1'
            }],
            axes: [{
                type: 'category',
                position: 'left',
            }, {
                type: 'number',
                position: 'top',
            }],
            legend: {
                position: 'bottom',
            },
        },

    }
    const [options, setOptions] = useState(BarOptions)

    let BarOptionsPut = {
        options: {
            theme: {
                baseTheme: 'ag-default-dark',
                overrides: {
                    bar: {
                        series: {
                            strokeWidth: 0,
                            highlightStyle: {
                                series: {
                                    strokeWidth: 1,
                                    dimOpacity: 0.3,
                                },
                            },
                        },
                    },
                },
            },
            autoSize: true,
            data: TopPutData,
            series: [{
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d10',
                yName: barStr['premium_p_exp_d10'],
                stacked: true,
                fill: '#E74C3C',
                tooltip: {
                    renderer: function (params) {
                        //console.log(params)
                        const datum = params.datum
                        const otm_pct = (datum[`${params.yKey}_otm`] / (datum[`${params.yKey}_otm`] + datum[`${params.yKey}_oem`]) * 100).toFixed(1)
                        return '<div class="ag-chart-tooltip-title" '
                            + 'style="background-color:'
                            + params.color + '">'
                            + params.xValue + '</div>'
                            + '<div class="ag-chart-tooltip-content">'
                            + 'Общая '
                            + params.yValue + 'M $ ' + params.yName + `
                            <br>
                            OTM:  ${otm_pct} %
                            </div>`;
                    }
                },
                label: {
                    fontWeight: 'bold',
                    color: 'white',
                    formatter: function (params) {
                        return BarLabel({ row_max: barTopPutMaxPremium, params: params })
                    }
                },
                listeners: {
                    nodeClick: (event) => {
                        BarListeners(event)
                    }
                }
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d40',
                yName: barStr['premium_p_exp_d40'],
                stacked: true,
                fill: '#EC7063'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d60',
                yName: barStr['premium_p_exp_d60'],
                stacked: true,
                fill: '#F1948A'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_m_d60',
                yName: barStr['premium_p_exp_m_d60'],
                stacked: true,
                fill: '#F5B7B1'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d10',
                yName: barStr['premium_c_exp_d10'],
                stacked: true,
                fill: '#229954'
            },

            {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d40',
                yName: barStr['premium_c_exp_d40'],
                stacked: true,
                fill: '#27AE60'
            },
            {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d60',
                yName: barStr['premium_c_exp_d60'],
                stacked: true,
                fill: '#52BE80'
            },
            {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_m_d60',
                yName: barStr['premium_c_exp_m_d60'],
                stacked: true,
                fill: '#7DCEA0'
            }

            ],

            axes: [
                {
                    type: 'category',
                    position: 'left',
                },
                {
                    type: 'number',
                    position: 'top',
                },
            ],
            legend: {
                position: 'bottom',
            },
        },

    }
    const [optionsPut, setOptionsPut] = useState(BarOptionsPut)

    const [TopEtfData, setTopEtfData] = useState()
    let BarOptionsEtf = {
        options: {
            theme: {
                baseTheme: 'ag-default-dark',
                overrides: {
                    bar: {
                        series: {
                            strokeWidth: 0,
                            highlightStyle: {
                                series: {
                                    strokeWidth: 1,
                                    dimOpacity: 0.3,
                                },
                            },
                        },
                    },
                },
            },
            autoSize: true,
            data: TopEtfData,
            series: [

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d10',
                    yName: barStr['premium_c_exp_d10'],
                    stacked: true,
                    fill: '#229954',
                    tooltip: {
                        renderer: function (params) {
                            //console.log(params)
                            const datum = params.datum
                            const otm_pct = (datum[`${params.yKey}_otm`] / (datum[`${params.yKey}_otm`] + datum[`${params.yKey}_oem`]) * 100).toFixed(1)
                            return '<div class="ag-chart-tooltip-title" '
                                + 'style="background-color:'

                                + params.color + '">'
                                + params.xValue + '</div>'
                                + '<div class="ag-chart-tooltip-content">'
                                + 'Общая '
                                + params.yValue + 'M $ ' + params.yName + `
                                <br>
                                OTM:  ${otm_pct} %
                                </div>`;
                        }
                    },
                    label: {
                        fontWeight: 'bold',
                        color: 'white',
                        formatter: function (params) {
                            return BarLabel({ row_max: barTopEtfMaxPremium, params: params })
                        }
                    },
                    listeners: {
                        nodeClick: (event) => {
                            BarListeners(event)
                        }
                    }
                },

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d40',
                    yName: barStr['premium_c_exp_d40'],
                    stacked: true,
                    fill: '#27AE60',

                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d60',
                    yName: barStr['premium_c_exp_d60'],
                    stacked: true,
                    fill: '#52BE80'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_m_d60',
                    yName: barStr['premium_c_exp_m_d60'],
                    stacked: true,
                    fill: '#7DCEA0'
                },

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d10',
                    yName: barStr['premium_p_exp_d10'],
                    stacked: true,
                    fill: '#E74C3C'
                },

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d40',
                    yName: barStr['premium_p_exp_d40'],
                    stacked: true,
                    fill: '#EC7063'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d60',
                    yName: barStr['premium_p_exp_d60'],
                    stacked: true,
                    fill: '#F1948A'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_m_d60',
                    yName: barStr['premium_p_exp_m_d60'],
                    stacked: true,
                    fill: '#F5B7B1'
                }

            ],

            axes: [
                {
                    type: 'category',
                    position: 'left',
                },
                {
                    type: 'number',
                    position: 'top',
                },
            ],
            legend: {
                position: 'bottom',
            },

        },

    }
    const [optionsEtf, setOptionsEtf] = useState(BarOptionsEtf)

    const [TopStockData, setTopStockData] = useState()
    let BarOptionsStock = {
        options: {

            theme: {
                baseTheme: 'ag-default-dark',
                overrides: {
                    bar: {
                        series: {
                            strokeWidth: 0,
                            highlightStyle: {
                                series: {
                                    strokeWidth: 1,
                                    dimOpacity: 0.3,
                                },
                            },
                        },
                    },
                },
            },
            autoSize: true,
            data: TopStockData,
            series: [

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d10',
                    yName: barStr['premium_c_exp_d10'],
                    stacked: true,
                    fill: '#229954',
                    tooltip: {
                        renderer: function (params) {
                            //console.log(params)
                            const datum = params.datum
                            const otm_pct = (datum[`${params.yKey}_otm`] / (datum[`${params.yKey}_otm`] + datum[`${params.yKey}_oem`]) * 100).toFixed(1)
                            return '<div class="ag-chart-tooltip-title" '
                                + 'style="background-color:'
                                + params.color + '">'
                                + params.xValue + '</div>'
                                + '<div class="ag-chart-tooltip-content">'
                                + 'Общая '
                                + params.yValue + 'M $ ' + params.yName + `
                                <br>
                                OTM:  ${otm_pct} %
                                </div>`;
                        }
                    },
                    label: {
                        fontWeight: 'bold',
                        color: 'white',
                        formatter: function (params) {
                            return BarLabel({ row_max: barTopStockMaxPremium, params: params })
                        }
                    },
                    listeners: {
                        nodeClick: (event) => {
                            BarListeners(event)
                        }
                    }
                }, {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d40',
                    yName: barStr['premium_c_exp_d40'],
                    stacked: true,
                    fill: '#27AE60',

                }, {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_d60',
                    yName: barStr['premium_c_exp_d60'],
                    stacked: true,
                    fill: '#52BE80'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_c_exp_m_d60',
                    yName: barStr['premium_c_exp_m_d60'],
                    stacked: true,
                    fill: '#7DCEA0'
                },

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d10',
                    yName: barStr['premium_p_exp_d10'],
                    stacked: true,
                    fill: '#E74C3C'
                },

                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d40',
                    yName: barStr['premium_p_exp_d40'],
                    stacked: true,
                    fill: '#EC7063'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_d60',
                    yName: barStr['premium_p_exp_d60'],
                    stacked: true,
                    fill: '#F1948A'
                },
                {
                    type: 'bar',
                    xKey: 'type',
                    yKey: 'premium_p_exp_m_d60',
                    yName: barStr['premium_p_exp_m_d60'],
                    stacked: true,
                    fill: '#F5B7B1'
                }

            ],
            axes: [{
                type: 'category',
                position: 'left',
            }, {
                type: 'number',
                position: 'top',
            },
            ],
            legend: {
                position: 'bottom',
            }
        },

    }
    const [optionsStock, setOptionsStock] = useState(BarOptionsStock)

    const [TopEtfShortData, setTopEtfShortData] = useState()
    let BarOptionsEtfShort = {
        options: {
            theme: {
                baseTheme: 'ag-default-dark',
                overrides: {
                    bar: {
                        series: {
                            strokeWidth: 0,
                            highlightStyle: {
                                series: {
                                    strokeWidth: 1,
                                    dimOpacity: 0.3,
                                },
                            },
                        },
                    },
                },
            },
            autoSize: true,
            data: TopEtfShortData,
            series: [{
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d10',
                yName: barStr['premium_c_exp_d10'],
                stacked: true,
                fill: '#229954',
                tooltip: {
                    renderer: function (params) {
                        //console.log(params)
                        const datum = params.datum
                        const otm_pct = (datum[`${params.yKey}_otm`] / (datum[`${params.yKey}_otm`] + datum[`${params.yKey}_oem`]) * 100).toFixed(1)
                        return '<div class="ag-chart-tooltip-title" '
                            + 'style="background-color:'

                            + params.color + '">'
                            + params.xValue + '</div>'
                            + '<div class="ag-chart-tooltip-content">'
                            + 'Общая '
                            + params.yValue + 'M $ ' + params.yName + `
                                <br>
                                OTM:  ${otm_pct} %
                                </div>`;
                    }
                },
                label: {
                    fontWeight: 'bold',
                    color: 'white',
                    formatter: function (params) {
                        return BarLabel({ row_max: barTopEtfShortMaxPremium, params: params })
                    }
                },
                listeners: {
                    nodeClick: (event) => {
                        BarListeners(event)
                    }
                }
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d40',
                yName: barStr['premium_c_exp_d40'],
                stacked: true,
                fill: '#27AE60',

            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_d60',
                yName: barStr['premium_c_exp_d60'],
                stacked: true,
                fill: '#52BE80'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_c_exp_m_d60',
                yName: barStr['premium_c_exp_m_d60'],
                stacked: true,
                fill: '#7DCEA0'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d10',
                yName: barStr['premium_p_exp_d10'],
                stacked: true,
                fill: '#E74C3C'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d40',
                yName: barStr['premium_p_exp_d40'],
                stacked: true,
                fill: '#EC7063'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_d60',
                yName: barStr['premium_p_exp_d60'],
                stacked: true,
                fill: '#F1948A'
            }, {
                type: 'bar',
                xKey: 'type',
                yKey: 'premium_p_exp_m_d60',
                yName: barStr['premium_p_exp_m_d60'],
                stacked: true,
                fill: '#F5B7B1'
            }
            ],
            axes: [{
                type: 'category',
                position: 'left',
            },
            {
                type: 'number',
                position: 'top',
            },
            ],
            legend: {
                position: 'bottom',
            }
        },

    }
    const [optionsEtfShort, setOptionsEtfShort] = useState(BarOptionsEtfShort)



    function updateData(TimeData) {
        setLoadings(true);
        //getTopCallData({ time_range: TimeData });
        //getLightChartData({ time_range: TimeData, limit: test_limit })
        //getTableData({ time_range: TimeData })
        getTableData({ paramsFilter: ParamsFilter })
    }

    const [PickerRangesValue, setPickerRangesValue] = useState()

    useEffect(() => {
        const config = updateModeConfig[options_updateMode]
        const autoUpdate = config.auto
        const props = getStorageData()
        if (autoUpdate) {
            const interval = setInterval(() => {

                updateDataNew({ paramsFilter: props.paramsFilter, settingsMain: SettingsMain })
            }, autoUpdateInterval);
            return () => clearInterval(interval);
        }
    }, [autoUpdateInterval]);


    function getFormatedDatesByPeriod(zone, format, date_start, date_end) {
        let dateArray = new Array();
        let currentDate = new Date(date_start);
        let count = 0
        while (currentDate <= new Date(date_end)) {
            dateArray.push({
                date: moment(currentDate).format(format),
                pos: count
            });
            currentDate = moment(currentDate).add(1, 'days');
            count++
        }
        return dateArray;
    }

    function summLine(props) {
        let ds_call = props.data

        let data_call_split = []
        ds_call.forEach(row => {
            let dObj = (row.datetime).split(' ')
            data_call_split.push({
                unix: row.unix,
                date: dObj[0],
                time: dObj[1],
                val: row[props.value]
            })
        })
        //console.log(data_call_split)
        const dates_d = AlaSQL(`select distinct date from ?`, [data_call_split])
        //console.log(dates_d)
        const date_start = dates_d[0].date
        //console.log(date_start)
        const query_start = `select * from ? where date = '${date_start}' order by time`
        //console.log(query_start)
        const arr_start = AlaSQL(query_start, [data_call_split])
        //console.log(arr_start)
        let last_val = arr_start[arr_start.length - 1].val
        //console.log(last_val)
        let new_out_arr = arr_start;
        for (let i = 1;
            //i < 2;
            i < dates_d.length;
            i++) {
            let date = dates_d[i].date
            //console.log(date)
            let query_next = `select * from ? where date = '${date}' order by time`
            //console.log(query_next)
            let arr_next = AlaSQL(query_next, [data_call_split])
            //console.log(arr_next)
            let new_arr = []
            arr_next.forEach(row => {
                new_arr.push({
                    unix: row.unix,
                    date: row.date,
                    time: row.time,
                    val: row.val + last_val
                })
            })
            new_out_arr = new_out_arr.concat(new_arr)
            //console.log('new_arr')
            //console.log(new_arr)
            last_val = new_arr[new_arr.length - 1].val
            //console.log(last_val)
        }
        //console.log('new_out_arr')
        //console.log(new_out_arr)
        let call_data_new = []
        new_out_arr.forEach(row => {
            call_data_new.push({
                time: row.unix, //moment(row.date + ' ' + row.time).tz('America/New_York').unix(),
                //time2: new Date(row.date + ' ' + row.time).toLocaleString('en-US', { timeZone: 'America/New_York' }),
                //date: (row.date + ' ' + row.time),
                val: row.val
            })
        });
        //console.log('call_data_new')
        //console.log(call_data_new)
        let qwer = AlaSQL('select * from ? order by time', [call_data_new])
        let call_data_new2 = []
        qwer.forEach(row => {
            call_data_new2.push({
                time: row.time + props.sessionStartTimestamp,
                value: row.val
            })
        });
        return call_data_new2
        //call_data = call_data_new2
        //console.log(call_data_new2)
    }

    function prepareLightChartData(props) {
        //console.log(props)
        const settingsMain = props.params.settingsMain;
        const data = props.data
        let call_data = []
        let put_data = []
        let price_data = []
        const ds_data = AlaSQL('select distinct datetime, unix, call2, put, price from ? order by unix', [data])

        const ds_call = AlaSQL(' select unix, datetime, max(call2) call2 from ? group by unix, datetime ', [ds_data])
        const ds_put = AlaSQL('select unix, datetime,  max(put) put from ? group by unix, datetime', [ds_data])
        const ds_price = AlaSQL('select unix, datetime,  max(price) price from ? group by unix, datetime', [ds_data])
        //console.log('ds_call')
        //console.log(ds_call)
        //console.log(call_data)
        const dates_range = props.params.paramsFilter.timeData.work
        const dates_arr = getFormatedDatesByPeriod('zone', 'YYYY-MM-DD', dates_range.date_from, dates_range.date_to)
        //console.log(dates_arr)
        //const dates_arr_reverse = dates_arr.reverse()
        //console.log(dates_arr_reverse)
        const sessionStartTimestamp = settingsMain.sessionTimeStamp
        if (dates_arr.length > 1) {
            call_data = summLine({ data: ds_call, value: 'call2', sessionStartTimestamp: sessionStartTimestamp })
            put_data = summLine({ data: ds_put, value: 'put', sessionStartTimestamp: sessionStartTimestamp })
        } else {
            ds_call.forEach(row => {
                call_data.push({
                    //time: moment(row.datetime).unix() + sessionStartTimestamp,
                    time: row.unix + sessionStartTimestamp,
                    value: row.call2
                })
            });
            ds_put.forEach(row => {
                put_data.push({
                    //time: moment(row.datetime).unix() + sessionStartTimestamp,
                    time: row.unix + sessionStartTimestamp,
                    value: row.put
                })
            });
        }
        ds_price.forEach(row => {
            price_data.push({
                //time: moment(row.datetime).unix() + sessionStartTimestamp,
                time: row.unix + sessionStartTimestamp,
                value: row.price
            })
        });
        return {
            callData: call_data,
            putData: put_data,
            priceData: price_data
        }
    }


    function getLightChartData(props) {
        const paramsFilter = props.paramsFilter
        //console.log(paramsFilter)
        const settingsMain = props.settingsMain
        const selectedSymbol = props.paramsFilter.selectedSymbol
        let query = QUERYS.getDataBySymbol(props)
        if (selectedSymbol === 'main') {
            query = CONFIG.getLightChartQuery(props)
        }
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: query
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log('paramsFilter.signalWorkCheck')
                //console.log(paramsFilter.signalWorkCheck)
                if (resp.data) {
                    let chart_data = ChartData
                    if ((resp.data).length > 0) {
                        const prepare = prepareLightChartData({ data: resp.data, params: props })
                        const chartData = {
                            callData: prepare.callData,
                            putData: prepare.putData,
                            priceData: prepare.priceData
                        }
                        chart_data = chartData
                        setChartData(chart_data)

                        const genSignalsData = SIGNALS.genSignalsData(chart_data) // gen

                        if (genSignalsData) {
                            if (genSignalsData.length > 0) {

                                const optSignals = localStorage.getItem(`options_signals`) ? (
                                    (JSON.parse(localStorage.getItem(`options_signals`))) ?
                                        (JSON.parse(localStorage.getItem(`options_signals`)))
                                        : options_signals_default
                                ) : options_signals_default

                                let sAJoin = AlaSQL(`select * from (select * from ? gen left join ? as old on gen.id = old.id  where old.id is null)
                                
                                `, [genSignalsData, optSignals])


                                //console.log('options_signals')
                                //console.log(options_signals)

                                //console.log(' sAJoin')
                                //console.log(sAJoin)

                                if (sAJoin) {
                                    if (sAJoin.length > 0) {

                                        // notif
                                        function notif(notifData) {

                                            if (settingsMain.signalsNotificationOn) {
                                                const signalsSoundOn = settingsMain.signalsSoundOn
                                                notifData.forEach(row => {
                                                    //console.log(row)
                                                    const e = { sType: row.type, description: row.price, title: row.type }
                                                    openNotificationWithIcon(e)
                                                    if (signalsSoundOn) {
                                                        const signalsSoundVolume = settingsMain.signalsSoundVolume
                                                        playSoundByType({ type: row.type, volume: signalsSoundVolume })
                                                    }
                                                })
                                            }

                                        }

                                        notif(sAJoin)

                                        // write
                                        const newIds = AlaSQL('select id from ? ', [sAJoin])
                                        //console.log(' newIds')
                                        //console.log(newIds)

                                        const writeIds = AlaSQL('select * from ? nw left join ? as lcl on nw.id = lcl.id where lcl.id is   null', [newIds, options_signals])
                                        //console.log(' writeIds')
                                        //console.log(writeIds)


                                        if (writeIds) {
                                            if (writeIds.length > 0) {
                                                const concatIds = options_signals.concat(writeIds)
                                                //console.log('concatIds')
                                                //console.log(concatIds)
                                                options_signals = writeIds
                                                //console.log(' options_signals_new')
                                                //console.log(options_signals)
                                                localStorage.setItem(`options_signals`, JSON.stringify(concatIds))
                                            }
                                        }


                                    }
                                }

                            }
                        }

                        setSignalsData(genSignalsData)

                    } else {
                        setSignalsData([])

                        setChartData({
                            callData: [],
                            putData: [],
                            priceData: []
                        })
                    }
                }
            })
    }





    const [LoadingsLightChartDataExtra, setLoadingsLightChartDataExtra] = useState(false)
    function getLightChartDataExtra(props) {
        const expirationPeriods = props.paramsFilter.mainChartDaysPeriod
        if (expirationPeriods) {
            if (expirationPeriods.length > 0) {
                setLoadingsLightChartDataExtra(true)
                const query = CONFIG.getLightChartQueryExtra(props)
                fetch(URLs.bd_url, {
                    method: 'POST',
                    headers: URLs.bd_headers,
                    body: query
                })
                    .then((resp_json) => resp_json.json())
                    .then((resp) => {
                        let outArr = []
                        if (resp.data) {
                            if (resp.data.length > 0) {
                                const reapData = resp.data;
                                expirationPeriods.forEach(period => {
                                    const sqlData = AlaSQL(`select * from ? where expirationType = '${period}' `, [reapData])
                                    const prepareData = prepareLightChartData({ data: sqlData, params: props })
                                    outArr.push({ data: prepareData, period: period })
                                })
                            }
                        }
                        setChartDataExpiration(outArr)
                        setLoadingsLightChartDataExtra(false)
                    })
            } else {
                setChartDataExpiration([])
            }
        }
    }


    const MainTableSizeToFit = useCallback(() => {
        if (gridRef) {
            if (gridRef.current) {
                if (gridRef.current.api) {
                    const wiw = window.innerWidth
                    if (wiw > smW) {
                        gridRef.current.api.sizeColumnsToFit()
                    }
                }
            }
        }
    }, []);

    const autoSizeAll = useCallback((e, skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const sizeToFitMain = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const sizeToFitSignals = useCallback(() => {
        gridRefSignals.current.api.sizeColumnsToFit();
    }, []);

    const sizeToFitModalTable = useCallback(() => {
        gridRefModalTable.current.api.sizeColumnsToFit();
    }, []);

    const autoSizeAllSignals = useCallback((e, skipHeader) => {
        const allColumnIds = [];
        gridRefSignals.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRefSignals.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const TypeTagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const color = {
            'SWEEP': 'gold',
            'BLOCK': 'lime',
            'SPLIT': 'green'
        }
        return (
            <Tag
                color={color[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };

    const onChangeTableSymbols = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedTableSymbols = e
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }

    const onChangeTableType = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.selectedType = e
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }

    const onChangeTablePremium = (value) => {
        let paramsFilter = ParamsFilter
        paramsFilter.premiumLimit = value
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    };

    const onChangePremiumBefore = (e) => {
        let paramsFilter = ParamsFilter
        paramsFilter.premiumСondition = e
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }

    const SelectBefore = () => {
        return (
            <Select
                defaultValue=">="
                style={{ width: 65, }}
                onChange={onChangePremiumBefore}
                value={ParamsFilter.premiumСondition}
                options={[{
                    value: '>='
                }, {
                    value: '<='
                }]}
            >
            </Select>
        )
    }

    //block_before_expiration

    const onChangeBeforeExpiration = (value) => {
        //console.log(value)
        let paramsFilter = ParamsFilter
        paramsFilter.beforeExpiration = value
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    };
    const onChangeBeforeExpirationCondition = (e) => {
        let paramsFilter = ParamsFilter
        paramsFilter.beforeExpirationCondition = e
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }
    const SelectBeforeExpiration = () => {
        return (
            <Select
                defaultValue=">="
                style={{ width: 65, }}
                onChange={onChangeBeforeExpirationCondition}
                value={ParamsFilter.beforeExpirationCondition}
                options={[{
                    value: '>='
                }, {
                    value: '<='
                }]}
            >
            </Select>
        )
    }

    //block_days_type
    const DaysPeriodRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const color = {
            '7_days': 'gold',
            '15_days': 'blue',
            '30_days': 'green'
        }
        const descp = {
            '7_days': '7 дней',
            '15_days': '15 дней',
            '30_days': '30 дней'
        }
        return (
            <Tag
                color={color[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {descp[label]}
            </Tag>
        );
    };
    const onChangeMainChartDaysPeriod = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.mainChartDaysPeriod = e
        setParamsFilter(paramsFilter)
        saveStorageData()

        //getTableData({ paramsFilter: paramsFilter })
        getLightChartData({ paramsFilter: paramsFilter, settingsMain: SettingsMain });
        getLightChartDataExtra({ paramsFilter: paramsFilter, settingsMain: SettingsMain });

    }


    //block_contract_details
    const ContractDetailsRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const color = {
            'OTM': 'gold',
            'ITM': 'blue',
            'ATM': 'green'
        }
        return (
            <Tag
                color={color[value]}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    };
    const onChangeContractDetails = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.contractDetails = e
        getTableData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }


    //block_table 
    function getTableData(props) {
        setMainTableSwitchShowExpiredDisabled(true)
        setMainTableSwitchShowSentimentDisabled(true)
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getTableDataQuery0(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                const data00 = resp.data;
                let data0 = data00
                if (!props.paramsFilter.showExpired) {
                    let dataExpired = AlaSQL('select * from ? where timestamp_now_between > 0', [data0]) //filters: symbol
                    //console.log(data0)
                    data0 = dataExpired
                }
                if (props.paramsFilter.showSentiment) {
                    let dataSentiment = AlaSQL(`select * from ? where sentiment in ('+', '-') `, [data0]) //filters: symbol
                    //console.log(data0)
                    data0 = dataSentiment
                }
                let g2_data = AlaSQL(QUERYS.getTableDataGauge2Query(props), [data0]) //filters: symbol
                //let summ = AlaSQL('select sum(premium) from ? ', [data0]) //filters: symbol
                //console.log(summ)
                if (g2_data) {
                    const g2_data_0 = g2_data[0]
                    if (g2_data_0) {
                        //console.log(g2_data_0)
                        setGaugeChartOtmData({
                            pct_otm_call: g2_data_0.premium_call_pct ? (Number(g2_data_0.premium_call_pct)).toFixed(2) : 0,
                            pct_otm_put: g2_data_0.premium_put_pct ? (Number(g2_data_0.premium_put_pct)).toFixed(2) : 0
                        })
                    }
                }
                let t1_data = AlaSQL(QUERYS.getTableDataQuery1(props), [data0]) //filters: symbol, money_type
                setT1RowData(t1_data)
                //console.log(t1_data)
                //gauge 1
                let timestamp_bd_max = (AlaSQL(`select max(timestamp_bd) timestamp_bd_max from ? `, [t1_data]))[0]
                    .timestamp_bd_max
                //console.log(timestamp_bd_max)
                if (timestamp_bd_max) {
                    let progress = AlaSQL(QUERYS.getTableDataQuery2({
                        paramsFilter: props.paramsFilter,
                        timestamp_bd_max: timestamp_bd_max,
                        short_etfs_arr: Str.short_etfs_arr
                    }), [t1_data])
                    //console.log(progress)
                    setGaugeChartData({
                        pct_15_min: (Number(progress[0].pct_15_min)).toFixed(2),
                        pct_30_min: (Number(progress[0].pct_30_min)).toFixed(2),
                        pct_60_min: (Number(progress[0].pct_60_min)).toFixed(2),
                        pct_1_day: (Number(progress[0].pct_1_day)).toFixed(2)
                    })
                } else {
                    setGaugeChartData({
                        pct_15_min: 0,
                        pct_30_min: 0,
                        pct_60_min: 0,
                        pct_1_day: 0
                    })
                }
            })
        //gridRef.current.api.sizeColumnsToFit();
        setMainTableSwitchShowExpiredDisabled(false)
        setMainTableSwitchShowSentimentDisabled(false)
        MainTableSizeToFit()
    }


    // M a p //

    function mapUpdate(paramsFilter) {
        getTopCallData({ paramsFilter: paramsFilter })
        setParamsFilter(paramsFilter)
        saveStorageData()
    }

    // ==================================================
    const onChangeMapTopPutType = (e) => {
        let paramsFilter = ParamsFilter
        paramsFilter.map_selectedType = e
        mapUpdate(paramsFilter)
    }

    // map change premium
    const onChangeMapPremiumBefore = (e) => {
        let paramsFilter = ParamsFilter
        paramsFilter.map_premiumCondition = e
        mapUpdate(paramsFilter)
    }
    const MapSelectPremiumBefore = () => {
        return (
            <Select
                defaultValue=">="
                style={{ width: 65, }}
                onChange={onChangeMapPremiumBefore}
                value={ParamsFilter.map_premiumCondition}
                options={[{ value: '>=' }, { value: '<=' }]}
            >
            </Select>
        )
    }
    const onChangeMapPremium = (value) => {
        let paramsFilter = ParamsFilter
        paramsFilter.map_premiumLimit = value
        mapUpdate(paramsFilter)
    };


    // map change money types
    const onChangeMapMoneyTypes = (e) => {
        //console.log(e)
        let paramsFilter = ParamsFilter
        paramsFilter.map_moneyTypes = e
        mapUpdate(paramsFilter)
    }

    // map change expiration
    const onChangeMapBeforeExpirationCondition = (e) => {
        let paramsFilter = ParamsFilter
        paramsFilter.map_beforeExpirationCondition = e
        mapUpdate(paramsFilter)
    }
    const MapSelectBeforeExpiration = () => {
        return (
            <Select
                defaultValue=">="
                style={{ width: 65, }}
                onChange={onChangeMapBeforeExpirationCondition}
                value={ParamsFilter.map_beforeExpirationCondition}
                options={[{ value: '>=' }, { value: '<=' }]}
            />
        )
    }
    const onChangeMapBeforeExpiration = (value) => {
        //console.log(value)
        let paramsFilter = ParamsFilter
        paramsFilter.map_beforeExpiration = value
        mapUpdate(paramsFilter)
    };

    // ==================================================


    function getTopCallData(props) {
        setLoadings(true)
        fetch(URLs.bd_url, {
            method: 'POST',
            headers: URLs.bd_headers,
            body: QUERYS.getCallPutQuery00(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {

                //console.log(resp.data)

                const data0 = AlaSQL(QUERYS.getCallPutQuery0A(), [resp.data])

                let prepare = AlaSQL(QUERYS.getCallPutQuery1(), [data0])
                //console.log(prepare)

                // TOP CALL
                let top_call_data = AlaSQL(QUERYS.getCallPutQuery_Call(), [prepare])
                //console.log(top_call_data)
                //setBarTopCallMaxPremium(top_call_data[0])
                barTopCallMaxPremium = top_call_data[0]
                setTopCallData(top_call_data)
                BarOptions.options.data = top_call_data
                setOptions(BarOptions)
                setBarChatData(resp.data)

                // TOP PUT
                let top_put_data = AlaSQL(QUERYS.getCallPutQuery_Put(), [prepare])
                //console.log(top_put_data)
                barTopPutMaxPremium = top_put_data[0]
                setTopPutData(top_put_data)
                BarOptionsPut.options.data = top_put_data
                setOptionsPut(BarOptionsPut)


                // BAR ETF INDEX
                let top_etf_data = AlaSQL(QUERYS.getCallPutQuery_Etf({ short_etfs_arr: Str.short_etfs_arr }), [data0])
                barTopEtfMaxPremium = top_etf_data[0]
                setTopEtfData(top_etf_data)
                BarOptionsEtf.options.data = top_etf_data
                setOptionsEtf(BarOptionsEtf)

                // BAR STOCK
                let top_stock_data = AlaSQL(QUERYS.getCallPutQuery_Stock(), [data0])
                barTopStockMaxPremium = top_stock_data[0]
                setTopStockData(top_stock_data)
                BarOptionsStock.options.data = top_stock_data
                setOptionsStock(BarOptionsStock)

                // BAR ETF INDEX
                let top_etf_short_data = AlaSQL(QUERYS.getCallPutQuery_EtfShort({ short_etfs_arr: Str.short_etfs_arr }), [data0])
                //console.log(top_etf_short_data)
                barTopEtfShortMaxPremium = top_etf_short_data[0]
                setTopEtfShortData(top_etf_short_data)
                BarOptionsEtfShort.options.data = top_etf_short_data
                setOptionsEtfShort(BarOptionsEtfShort)

                //let prepare0 = AlaSQL(QUERYS.getCallPutQuery01(props), [resp.data])
                //let prepare = AlaSQL(QUERYS.getCallPutQuery1(), [resp.data])
                //console.log(prepare)

                /* 

                

                

*/

            })

        setLoadings(false)
    }




    const onChangeRangePicker = (value) => {
        console.log(value)
        console.log(PickerRangesValue)


        //console.log(DateSelectOptionsValue)
        let paramsFilter = ParamsFilter;

        //let unix_from = Number((((value[0]._d).getTime()) / 1000).toFixed(0))
        //let unix_to = Number((((value[1]._d).getTime()) / 1000).toFixed(0)) + 86399

        const date_from = moment(value[0]._d).format('YYYY-MM-DD')
        const date_to = moment(value[1]._d).format('YYYY-MM-DD')

        let time_from = date_from + ' 00:00:00'
        let time_to = date_to + ' 23:59:59'

        let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
        let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to

        paramsFilter.timeData = {
            from: moment(value[0]._d),
            to: moment(value[1]._d),
            value: 'fixed',
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: date_from,
                date_to: date_to
            }
        }
        //console.log(paramsFilter.timeData)
        if (DateSelectOptionsValue === 'fixed') {
            setRangePickerValue(value)
        } else {
            setDateSelectOptionsValue('fixed')
        }
        getSymbolsData({ paramsFilter: paramsFilter, settingsMain: SettingsMain })
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))


        //localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
    }



    // AntDateSelectOptions
    const onChangeAntDateSelectOptions = (e) => {
        //console.log(e)
        const pickerRangeValue = DateSelectOptionsRaw[e]
        //console.log(pickerRangeValue)



        let paramsFilter = ParamsFilter;
        const time_from = pickerRangeValue.work.date_from + ' 00:00:00'
        const time_to = pickerRangeValue.work.date_to + ' 23:59:59'
        setDateSelectOptionsValue(e)

        //console.log(moment(pickerRangeValue.visual.date_from, defaultDateFormat))
        //console.log(moment(pickerRangeValue.visual.date_to, defaultDateFormat))

        if (e === 'today') {
            setSignalWorkCheck(true)
            paramsFilter.signalWorkCheck = true
        } else {
            setSignalWorkCheck(false)
            paramsFilter.signalWorkCheck = false
        }

        if (e === 'fixed') {
            paramsFilter.timeData = pickerRangeValue
        } else {
            const mFromTo = [
                moment(moment(pickerRangeValue.work.date_from), defaultDateFormat),
                moment(moment(pickerRangeValue.work.date_to), defaultDateFormat)
            ]
            setRangePickerValue(mFromTo)
            //console.log(mFromTo)
            let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
            let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to
            let timeData = {
                from: moment(pickerRangeValue.work.date_from),
                to: moment(pickerRangeValue.work.date_to),
                //from_to: mFromTo,
                value: e,
                work: pickerRangeValue.work,
                visual: pickerRangeValue.visual,
                unix: {
                    from: unix_from,
                    to: unix_to
                }
            }
            paramsFilter.timeData = timeData
            updateDataNew({ paramsFilter: paramsFilter, settingsMain: SettingsMain })
        }
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(paramsFilter))
        setParamsFilter(paramsFilter)
    }


    const onChangeSymbolSelect = (value) => {
        let paramsFilter = ParamsFilter
        paramsFilter.selectedSymbol = value

        getLightChartData({ paramsFilter: paramsFilter, settingsMain: SettingsMain });
        //getTableData({ paramsFilter: paramsFilter })
        //getTopCallData({ paramsFilter: paramsFilter });

        setParamsFilter(paramsFilter)
        saveStorageData()
    };
    const containerStyle = useMemo(() => ({ width: '100%', height: 540 }), []);
    const gridStyle = useMemo(() => ({ height: 540, width: '100%' }), []);


    const [rowData, setModalRowData] = useState([
        { make: "zzz", premium: 0 },
    ]);

    const [columnDefs] = useState([
        { field: 'type' },
        { field: 'premium' }
    ])





    const YouTubeRef = useRef()
    const showLearnModal = () => {
        //console.log('showLearnModal')

        setIsLearnModalOpen(true);
    };
    const handleLearnOk = () => {
        setIsLearnModalOpen(false);
    };
    const handleLearnCancel = () => {
        //console.log('handleLearnCancel')
        setIsLearnModalOpen(false);
        //console.log(YouTubeRef)
        //YouTubeRef.current.pauseVideo();
    };

    const opts = {
        height: 450, width: '100%',

        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    function _onReady(event) {
        // access to player in all event handlers via event.target
        //console.log(event)
        event.target.pauseVideo();
    }

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const onStateChange = (event) => {
        // access to player in all event handlers via event.target
        //event.target.pauseVideo();
        //console.log(event)
    }



    const onClickButtonSignal = () => {
        SIGNALS.genSignalsData(ChartData)
        setModalNotificationOpen(true)
        gridRefSignals.current.api.sizeColumnsToFit();
    }

    const onCancelNotification = () => {
        setModalNotificationOpen(false);
    };

    function playSoundByType(props) {
        const volume = props.volume / 100
        if (props.type === 'long') {
            let audio = new Audio(signalLong);
            audio.volume = volume
            audio.play()
        }
        if (props.type === 'short') {
            let audio = new Audio(signalShort);
            audio.volume = volume
            audio.play()
        }
    }
    const openNotificationWithIcon = (e) => {
        const sType = e.sType;
        const NIcon = (props) => {
            if (props.sType === 'short') return <ArrowDownOutlined style={{ color: "red" }} />
            return <ArrowUpOutlined style={{ color: "green" }} />
        }

        notification['info']({
            icon: <NIcon sType={sType}></NIcon>,
            message: e.title,
            description: e.description,
            placement: 'bottomRigth',
            onClick() {
                //console.log('clicked')
                SIGNALS.genSignalsData(ChartData)
                setModalNotificationOpen(true)

            }
        });
    };

    const onClickTranslation = (props) => {
        //console.log(props)
        if (userInfo.pay_status === 'activated') {
            //console.log(translationConfig.data.url)
            const translationUrl = translationConfig.data.url
            window.open(translationUrl)
            //return <Navigate to="/app/options" replace />;
        }
    }



    return (
        < >

            <Row style={{ margin: 4 }}></Row>



            <Row gutter={4} style={{ marginLeft: 3 }}>
                <Col xl={{ span: 18 }} md={{ span: 16 }} xs={{ span: 24 }} >
                    <Tooltip
                        overlayStyle={{ maxWidth: '70%' }}
                        placement="bottomLeft" title={STRs.options_page_description}>
                        <Title level={4}>{STRs.options_page_title}</Title>
                    </Tooltip>
                </Col>
                <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 12 }}  >
                    <Button style={{ width: '100%', background: '#0E72ED' }}
                        icon={<VideoCameraOutlined></VideoCameraOutlined>}
                        //href={'https://us02web.zoom.us/j/85051291701?pwd=aDQ5NkxLNURIbjBUMUlPd0hKdFB1Zz09'}
                        //target="_blank"
                        onClick={(e) => onClickTranslation(translationConfig)}
                    >
                        Трансляция
                    </Button>
                </Col>

                <Col xl={{ span: 3 }} md={{ span: 4 }} xs={{ span: 12 }}  >
                    <Modal title="Инструкция"
                        width='70%'
                        open={isLearnModalOpen}
                        footer={null}
                        bodyStyle={{ height: 500 }}
                        onCancel={handleLearnCancel}>
                        <YouTube
                            syle={{ height: 500, width: '100%' }}
                            ref={YouTubeRef}
                            videoId="UtDRNegjH0A"
                            opts={opts}
                            onReady={onPlayerReady}
                        />
                    </Modal>

                    <Button style={{ width: '100%', background: 'red' }}
                        icon={<YoutubeOutlined />}
                        onClick={showLearnModal}
                    >
                        Инструкция
                    </Button>


                </Col>
            </Row>


            <Row className='filter_row'>
                <Col xl={{ span: 7 }} md={{ span: 0 }} xs={{ span: 24 }}
                    style={{ marginTop: 2, marginBottom: 2 }}
                >
                </Col>

                <Col xl={{ span: 5 }} md={{ span: 8 }} xs={{ span: 24 }}
                    style={{ marginTop: 2, marginBottom: 2 }}
                >
                    <Select
                        placeholder="Expirations"
                        mode="multiple"
                        showArrow
                        allowClear
                        tagRender={DaysPeriodRender}
                        value={ParamsFilter.mainChartDaysPeriod ? ParamsFilter.mainChartDaysPeriod : []}
                        defaultValue={[]}
                        style={{ width: '100%' }}
                        options={CONFIG.mainChartDaysPeriodOptions}
                        onChange={onChangeMainChartDaysPeriod}
                    />
                </Col>

                <Col style={{ marginTop: 2, marginBottom: 2 }}
                    xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 24 }}     >
                    <RangePicker
                        locale={locale}
                        style={{ width: '100%' }}
                        //defaultValue={ParamsFilter.timeData.from_to}
                        defaultValue={[
                            moment(moment(ParamsFilter.timeData.from), defaultDateFormat),
                            moment(moment(ParamsFilter.timeData.to), defaultDateFormat),
                        ]}
                        value={RangePickerValue || [
                            moment(moment(ParamsFilter.timeData.from), defaultDateFormat),
                            moment(moment(ParamsFilter.timeData.to), defaultDateFormat),
                        ]}
                        //value={RangePickerValue }
                        onChange={value => onChangeRangePicker(value)}
                    />
                </Col>

                <Col style={{ marginTop: 2, }}
                    xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 21 }} >
                    <AntDateSelectOptions
                        defaultValue={ParamsFilter.timeData.value}
                        options={DateSelectOptionsRaw}
                        onChange={onChangeAntDateSelectOptions}
                        value={DateSelectOptionsValue}
                    ></AntDateSelectOptions>
                </Col>

                <Col style={{ marginTop: 2 }}
                    xl={{ span: 2 }} md={{ span: 2 }} xs={{ span: 3 }} >
                    <Dropdown
                        menu={menuProps({ selected: options_updateMode })}    >
                        <Button align="right" style={{ width: '100%' }} loading={loadings} >
                            <ReloadOutlined style={{
                                color: updateModeConfig[options_updateMode].color

                            }} />
                        </Button>
                    </Dropdown>

                </Col>
            </Row>


            <Row >
                <Col xl={{ span: 24 }} xs={{ span: 24 }}  >
                    <div className="site-card-border-less-wrapper">
                        <Card
                            title={<TitleExtra
                                ref={ytestRef}
                                pagePosition={pagePosition}
                                pageConfigs={pageConfigs}
                                id='o_chart_main'
                                default={"Рыночный поток опционов"}
                            ></TitleExtra>}
                            extra={<Button onClick={onClickButtonSignal}>Сигналы</Button>}
                            bordered={false}
                            size='small'
                        >
                            <Text type="secondary" >{Str.lchart_descr} </Text>
                            <Row style={{ margin: 4 }}></Row>

                            <Card
                                size="small"
                                style={{ marginBottom: 15, width: '100%' }}
                                title={<Col xl={{ span: 3 }} md={{ span: 5 }} xs={{ span: 24 }} align="left"
                                    style={{ marginTop: 2, marginBottom: 2 }}   >
                                    <AntSelect
                                        options={symbolListData}
                                        value={ParamsFilter.selectedSymbol}
                                        onChange={(e) => onChangeSymbolSelect(e)}
                                    ></AntSelect>
                                </Col>}
                            >

                                <Spin spinning={loadings}>
                                    <LightWeightChart
                                        chartData={ChartData}
                                        callData={ChartData.callData}
                                        putData={ChartData.putData}
                                        priceData={ChartData.priceData}
                                        markers={{
                                            data: SignalsData
                                        }}
                                    >
                                    </LightWeightChart>
                                </Spin>

                                <Row>
                                    <Col span={24} align='center'>
                                        <Badge color='white' text={Str.legend_price} />
                                        <Badge color='#229954' text={Str.legend_call} />
                                        <Badge color="#E74C3C" text={Str.legend_put} />
                                    </Col>
                                </Row>
                            </Card>


                            <LightChartExpirations
                                dataArr={ChartDataExpiration}
                                paramsFilter={JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`)) ? JSON.parse(localStorage.getItem(`${pagePosition}_params_filter`))
                                    : CONFIG.defaultParamsFilter}
                            ></LightChartExpirations>


                            <Row style={{ margin: 4 }}></Row>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Row style={{ margin: 4 }}></Row>


            <Spin
                style={{ width: '100%' }}
                spinning={BarMiniLineChartsDataLoading}
            >
                <Row gutter={4} style={{ marginLeft: 3 }}>

                    <MiniChartsBar data={BarMiniLineChartsData}></MiniChartsBar>

                </Row>
            </Spin>


            <Row >
                <Col xl={{ span: 24 }} xs={{ span: 24 }}  >
                    <Spin
                        spinning={loadings}
                    >
                        <div className="site-card-border-less-wrapper">
                            <Card title={<TitleExtra
                                ref={ytestRef}
                                pagePosition={pagePosition}
                                pageConfigs={pageConfigs}
                                id='o_table_main'
                                default={"Сканер опционного потока"}
                            ></TitleExtra>}

                                bordered={false} size='small' >


                                <GaugeChartMetrs
                                    pct_15_min={GaugeChartData.pct_15_min}
                                    pct_60_min={GaugeChartData.pct_60_min}
                                    pct_1_day={GaugeChartData.pct_1_day}
                                    pct_otm_call={GaugeChartData.pct_otm_call}
                                    pct_otm_put={GaugeChartData.pct_otm_put}
                                ></GaugeChartMetrs>

                                <Row justify='center' align="middle">
                                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        <Select
                                            placeholder="Symbol"
                                            mode="multiple"
                                            showArrow
                                            allowClear
                                            tagRender={TypeTagRender}
                                            value={ParamsFilter.selectedTableSymbols ? ParamsFilter.selectedTableSymbols : []}
                                            defaultValue={[]}
                                            style={{
                                                width: '100%',
                                            }}
                                            options={blockTableFilterSymbols}
                                            onChange={onChangeTableSymbols}
                                        />
                                    </Col>

                                    <Col xl={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        <Select
                                            placeholder="Option types"
                                            mode="multiple"
                                            showArrow
                                            allowClear
                                            tagRender={TypeTagRender}
                                            value={ParamsFilter.selectedType ? ParamsFilter.selectedType : ['SWEEP']}
                                            defaultValue={['SWEEP']}
                                            style={{
                                                width: '100%',
                                            }}
                                            options={CONFIG.TableTypeOptions}
                                            onChange={onChangeTableType}
                                        />
                                    </Col>

                                    <Col xl={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        <Select
                                            placeholder="Money types"
                                            mode="multiple"
                                            showArrow
                                            allowClear
                                            tagRender={ContractDetailsRender}
                                            value={ParamsFilter.contractDetails ? ParamsFilter.contractDetails : []}
                                            defaultValue={[]}
                                            style={{
                                                width: '100%',
                                            }}
                                            options={CONFIG.contractDetailsOptions}
                                            onChange={onChangeContractDetails}
                                        />
                                    </Col>

                                    <Col xl={{ span: 4 }} md={{ span: 12 }} xs={{ span: 24 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber
                                                placeholder="Premium"
                                                min={1}
                                                step={100000}
                                                style={{ width: '100%' }}
                                                addonBefore={<SelectBefore></SelectBefore>}
                                                addonAfter="$"
                                                defaultValue={100000}
                                                value={ParamsFilter.premiumLimit ? ParamsFilter.premiumLimit : 100000}
                                                onChange={onChangeTablePremium}
                                            />
                                        </Space>
                                    </Col>

                                    <Col xl={{ span: 3 }} md={{ span: 12 }} xs={{ span: 11 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <InputNumber
                                                placeholder="Before expiration"
                                                step={1}
                                                style={{ width: '100%' }}
                                                addonBefore={<SelectBeforeExpiration></SelectBeforeExpiration>}
                                                addonAfter="дн"
                                                defaultValue={2}
                                                value={ParamsFilter.beforeExpiration}
                                                onChange={onChangeBeforeExpiration}
                                            />
                                        </Space>
                                    </Col>

                                    <Col
                                        align="center"
                                        xl={{ span: 2 }} md={{ span: 6 }} xs={{ span: 6 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        Expired <Switch checked={ParamsFilter.showExpired ? ParamsFilter.showExpired : false}
                                            disabled={MainTableSwitchShowExpiredDisabled}
                                            onChange={onChangeMainTableSwitchShowExpired}
                                        ></Switch>
                                    </Col>

                                    <Col
                                        align="center"
                                        xl={{ span: 2 }} md={{ span: 6 }} xs={{ span: 7 }}
                                        style={{ marginTop: 2, marginBottom: 2 }}
                                    >
                                        Sentiment <Switch checked={ParamsFilter.showSentiment ? ParamsFilter.showSentiment : false}
                                            disabled={MainTableSwitchShowSentimentDisabled}
                                            onChange={onChangeMainTableSwitchShowSentiment}
                                        ></Switch>
                                    </Col>



                                </Row>

                                <div className="example-wrapper ag-theme-alpine-dark" style={{
                                    height: 540, marginTop: 3
                                }}>
                                    <div className="wrapper">
                                        <div style={{ height: 540 }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                columnDefs={t1ColDef}
                                                rowData={t1RowData}
                                                animateRows={true}
                                                asyncTransactionWaitMillis={60}
                                                cellFlashDelay={20000}
                                                pagination={true}
                                                paginationPageSize={10}
                                                cellFadeDelay={20000}
                                                multiSortKey={'ctrl'}
                                                defaultColDef={{
                                                    sortable: true,
                                                    resizable: true
                                                }}

                                                onGridReady={(e) => sizeToFitMain
                                                    //autoSizeAllSignals(e, false)
                                                }
                                                onRowClicked={(e) => {
                                                    const symbol = e.data.symbol
                                                    //console.log(symbol)
                                                    const event = {
                                                        datum: { type: symbol }
                                                    }
                                                    BarListeners(event)
                                                }}
                                            //onGridReady={sizeToFit}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Card>
                        </div>
                    </Spin>
                </Col>
            </Row>

            <Row style={{ margin: 4 }}></Row>

            <Modal title={barModalTitle}
                width='95%'
                //footer={null}
                closable={true}
                bodyStyle={{ height: '30%' }}
                style={{ top: 20 }}
                open={isModalOpen}
                //open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <>
                    <Spin spinning={barModalLoading}>
                        {showBarModal ?
                            <Card size='small' >
                                <LightWeightChartExtra
                                    timeVisible={true}
                                    chartData={{
                                        symbol: modalSymbol,
                                        type: 'Candlestick',
                                        data: barModalChartData
                                    }}
                                    isCustomPriceLine={true}
                                    tableRowSelected={tableRowSelectedData}
                                >
                                </LightWeightChartExtra>
                            </Card>
                            : <></>}

                        <Row style={{ margin: 3 }}></Row>




                        <Card
                            size='small'
                            title={<TitleExtra
                                ref={ytestRef}
                                pagePosition={pagePosition}
                                pageConfigs={pageConfigs}
                                id='o_modal_market_flow'
                                default='Рыночный поток по тикеру'
                            ></TitleExtra>}
                        >
                            <Row>
                                <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                    align={'center'} a>
                                    <GaugeChartMetr
                                        id="mod15min"
                                        nrOfLevels={10}
                                        title={{
                                            enable: true, pos: 'top',
                                            value: '15 мин'
                                        }}
                                        percent={ModalGaugeChartData.pct_15_min}
                                    ></GaugeChartMetr>
                                </Col>

                                {/*<Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                    align={'center'} a>
                                    <GaugeChartMetr
                                        id="1"
                                        nrOfLevels={10}
                                        title={{
                                            enable: true, pos: 'top',
                                            value: '30 мин'
                                        }}
                                        percent={ModalGaugeChartData.pct_30_min}
                                    ></GaugeChartMetr>
                                </Col>
                                */}

                                <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                    align={'center'}>
                                    <GaugeChartMetr
                                        id="2"
                                        nrOfLevels={10}
                                        title={{
                                            enable: true, pos: 'top',
                                            value: '60 мин'
                                        }}
                                        percent={ModalGaugeChartData.pct_60_min}
                                    ></GaugeChartMetr>
                                </Col>
                                <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                    align={'center'}>
                                    <GaugeChartMetr
                                        id="3"
                                        nrOfLevels={10}
                                        title={{
                                            enable: true, pos: 'top',
                                            value: '1 день'
                                        }}
                                        percent={ModalGaugeChartData.pct_1_day}
                                    ></GaugeChartMetr>
                                </Col>

                                {/*<Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                        align={'center'}>
                                        <Statistic title="Call" value={ModalTableStatisticCall} precision={2} ></Statistic>
                                    </Col>

                                    <Col xl={{ span: 3 }} md={{ span: 6 }} xs={{ span: 6 }}
                                        align={'center'}>
                                        <Statistic title="Put" value={ModalTableStatisticPut} precision={2} ></Statistic>
                                    </Col>*/}
                            </Row>

                            <div className="example-wrapper ag-theme-alpine-dark" style={{
                                height: 1500, margin: 0
                            }}>
                                <div className="wrapper">
                                    <div style={{ height: 1500 }}>
                                        <AgGridReact
                                            ref={gridRefModalTable}
                                            columnDefs={ModalTableColDef}
                                            rowData={ModalTableData}
                                            animateRows={true}
                                            asyncTransactionWaitMillis={60}
                                            cellFlashDelay={20000}
                                            pagination={true}
                                            paginationPageSize={50}
                                            rowSelection={'single'}
                                            cellFadeDelay={20000}
                                            multiSortKey={'ctrl'}
                                            defaultColDef={{
                                                sortable: true,
                                                resizable: true
                                            }}
                                            onGridReady={(e) => autoSizeAll(e, false)}


                                            onRowClicked={(e) => {
                                                //console.log(e)
                                                setTableRowSelectedata(e)
                                            }}
                                        //onGridReady={sizeToFit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>

                    </Spin>
                </>
            </Modal>

            <Row>
                <Col xl={{ span: 24 }} xs={{ span: 24 }}  >
                    <div className="site-card-border-less-wrapper">
                        <Card
                            size='small'
                            title={<TitleExtra
                                ref={ytestRef}
                                pagePosition={pagePosition}
                                pageConfigs={pageConfigs}
                                id='o_volume_maps'
                                default={"Карта объемов опционного рынка"}

                            ></TitleExtra>}
                            bordered={false}
                        >

                            <Row justify='center' align="middle">


                                <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                >
                                    <Select
                                        placeholder="Option types"
                                        mode="multiple"
                                        showArrow
                                        allowClear
                                        tagRender={TypeTagRender}
                                        value={ParamsFilter.map_selectedType ? ParamsFilter.map_selectedType : ['SWEEP']}
                                        defaultValue={['SWEEP']}
                                        style={{
                                            width: '100%',
                                        }}
                                        options={CONFIG.TableTypeOptions}
                                        onChange={onChangeMapTopPutType}
                                    />
                                </Col>

                                <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                >
                                    <Select
                                        placeholder="Money types"
                                        mode="multiple"
                                        showArrow
                                        allowClear
                                        tagRender={ContractDetailsRender}
                                        value={ParamsFilter.map_moneyTypes ? ParamsFilter.map_moneyTypes : []}
                                        defaultValue={[]}
                                        style={{
                                            width: '100%',
                                        }}
                                        options={CONFIG.contractDetailsOptions}
                                        onChange={onChangeMapMoneyTypes}
                                    />
                                </Col>

                                <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                >
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber
                                            placeholder="Premium"
                                            min={1}
                                            step={100000}
                                            style={{ width: '100%' }}
                                            addonBefore={<MapSelectPremiumBefore></MapSelectPremiumBefore>}
                                            addonAfter="$"
                                            defaultValue={100000}
                                            value={ParamsFilter.map_premiumLimit ? ParamsFilter.map_premiumLimit : 100000}
                                            onChange={onChangeMapPremium}
                                        />
                                    </Space>
                                </Col>

                                <Col xl={{ span: 6 }} md={{ span: 12 }} xs={{ span: 24 }}
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                >
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <InputNumber
                                            placeholder="Before expiration"
                                            step={1}
                                            style={{ width: '100%' }}
                                            addonBefore={<MapSelectBeforeExpiration />}
                                            addonAfter="дн"
                                            defaultValue={2}
                                            value={ParamsFilter.map_beforeExpiration}
                                            onChange={onChangeMapBeforeExpiration}
                                        />
                                    </Space>
                                </Col>





                            </Row>

                            <Row >
                                <Col xl={{ span: 12 }} xs={{ span: 24 }}  >
                                    <Spin spinning={loadings}>
                                        <div className="site-card-border-less-wrapper">
                                            <Card
                                                title='TOP CALL (>70%)'
                                                bordered={true}
                                                size='small'
                                            >
                                                <Text type="secondary" >Инструменты с перевесом по сумме покупок в CALL>70% по сравнению с PUT.
                                                </Text>
                                                <div className="example-wrapper ag-theme-alpine" style={{ height: 500, marginTop: 0 }}>
                                                    <div className="wrapper">
                                                        <div style={{ height: 500 }}>
                                                            <AgChartsReact
                                                                className='wrapper'
                                                                options={options.options}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </Spin>
                                </Col>

                                <Col xl={{ span: 12 }} xs={{ span: 24 }}  >



                                    <Spin
                                        spinning={loadings}
                                    >
                                        <div className="site-card-border-less-wrapper">
                                            <Card
                                                title='TOP PUT (>70%)'
                                                bordered={true}
                                                size='small'
                                            >
                                                <Text type="secondary" >Инструменты с перевесом по сумме покупок в PUT>70% по сравнению с CALL. Минимальная сумма 5 млн $
                                                </Text>



                                                <div className="example-wrapper ag-theme-alpine" style={{ height: 500, marginTop: 0 }}>
                                                    <div className="wrapper">
                                                        <div style={{ height: 500 }}>
                                                            <AgChartsReact
                                                                className='wrapper'
                                                                options={optionsPut.options}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </Spin>
                                </Col>
                            </Row>

                            <Row style={{ margin: 4 }}></Row>

                            <Row >
                                <Col xl={{ span: 8 }} xs={{ span: 24 }}  >
                                    <Spin spinning={loadings}>
                                        <Card
                                            title={Str.top_etf_title}
                                            bordered={true}
                                            size='small'
                                        >
                                            <div className="example-wrapper ag-theme-alpine" style={{ height: 500, marginTop: 0 }}>
                                                <div className="wrapper">
                                                    <div style={{ height: 500 }}>
                                                        <AgChartsReact
                                                            className='wrapper'
                                                            options={optionsEtf.options}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Spin>
                                </Col>

                                <Col xl={{ span: 8 }} xs={{ span: 24 }}  >
                                    <Spin spinning={loadings}>
                                        <div className="site-card-border-less-wrapper">
                                            <Card
                                                title={Str.top_stock_title}
                                                bordered={true}
                                                size='small'
                                            >
                                                <div className="example-wrapper ag-theme-alpine" style={{ height: 500, marginTop: 0 }}>
                                                    <div className="wrapper">
                                                        <div style={{ height: 500 }}>
                                                            <AgChartsReact
                                                                className='wrapper'
                                                                options={optionsStock.options}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </Spin>
                                </Col>

                                <Col xl={{ span: 8 }} xs={{ span: 24 }}  >
                                    <Spin
                                        spinning={loadings}
                                    >
                                        <div className="site-card-border-less-wrapper">
                                            <Card
                                                title={Str.top_etf_short_title}
                                                bordered={true}
                                                size='small'

                                            >
                                                <div className="example-wrapper ag-theme-alpine" style={{ height: 500, marginTop: 0 }}>
                                                    <div className="wrapper">
                                                        <div style={{ height: 500 }}>
                                                            <AgChartsReact
                                                                className='wrapper'
                                                                options={optionsEtfShort.options}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </Spin>
                                </Col>
                            </Row>

                        </Card>

                    </div>
                </Col>
            </Row>

            <Modal title={signalModalTitle}
                width='60%'
                //footer={null}
                closable={true}
                bodyStyle={{ height: '30%' }}
                open={isModalNotificationOpen}
                //open={isModalOpen}
                onOk={handleOk}
                onCancel={onCancelNotification}
                footer={null}
            >

                <div className="example-wrapper ag-theme-alpine-dark" style={{
                    height: 340, margin: 0
                }}>
                    <div className="wrapper">
                        <div style={{ height: 340 }}>
                            <AgGridReact
                                ref={gridRefSignals}
                                columnDefs={SignalsTableColDef}
                                rowData={SignalsData}
                                animateRows={true}
                                asyncTransactionWaitMillis={60}
                                cellFlashDelay={20000}
                                pagination={true}
                                paginationPageSize={5}
                                rowSelection={'single'}
                                cellFadeDelay={20000}
                                multiSortKey={'ctrl'}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true
                                }}
                                onGridReady={(e) => sizeToFitSignals
                                    //autoSizeAllSignals(e, false)
                                }

                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <Outlet />
        </>
    )
}

export default PageOptions;