export const connects = {
    ch: {
        url: 'https://db.vixprofit.com/?add_http_cors_header=1&user=deep&password=qwerty&default_format=JSON',
        headers: {
            'accept': '*/*',
            'cache-control': 'no-cache',
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'pragma': 'no-cache'
        },
    }
}


export const defaultMainSettings = {
    type: 'default',
    sessionStart: '09:30',
    sessionTimeStamp: 0,
    selectedSetting: 'time',
    signalsNotificationOn: true,
    signalsSoundOn: true,
    signalsSoundVolume: 50,
    options_updateMode: "1"
}

// settings

// time 


export const moneyChat = {
    tariffs: {
        '12_months': 'w18228048',
        '6_months': 'w18228043',
        '3_month': 'w18228034',
        '1_month': 'w18228052',
        'trial': 'w18198213',
        'trial7': 'trial7'
    },
    tariffs_ru: {
        '12 месяцев': 'w18228048',
        '6 месяцев': 'w18228043',
        '3 месяца': 'w18228034',
        '1 месяц': 'w18228052',
        'trial': 'w18198213',
        'trial7': 'trial7'
    },
    tariffs_en: {
        '12_months': '12 месяцев',
        '6_months': '6 месяцев',
        '3_months': '3 месяца',
        '1_month': '1 месяц',
        'trial': 'Пробный 3 дня',
        'trial7': 'Пробный 7 дней'
    },
    tariffsCode2Ru: {
        'w18228048': '12 месяцев',
        'w18228043': '6 месяцев',
        'w18228034': '3 месяца',
        'w18228052': '1 месяц',
        'w18198213': 'Пробный 3 дня',
        'trial7': 'Пробный 7 дней',

        'freemonth': 'Бесплатно 1 месяц',

        'gold1month': '1 месяц',
        'gold3month': '3 месяца'
    }
}


export const SettingsPageOptions = {
    updateModes: [
        { value: '1', label: 'Обновлять вручную', },
        { value: '5', label: 'Каждые 30 сек', },
        { value: '2', label: 'Каждую минуту', },
        { value: '3', label: 'Каждые 3 минуты', },
        { value: '4', label: 'Каждые 5 минут', },
    ]
}
export const SettingsTime = {
    arr: [
        { value: '00:30' },
        { value: '01:30' },
        { value: '02:30' },
        { value: '03:30' },
        { value: '04:30' },
        { value: '05:30' },
        { value: '06:30' },
        { value: '07:30' },
        { value: '08:30' },
        { value: '09:30' },
        { value: '10:30' },
        { value: '11:30' },
        { value: '12:30' },
        { value: '13:30' },
        { value: '14:30' },
        { value: '15:30' },
        { value: '16:30' },
        { value: '17:30' },
        { value: '18:30' },
        { value: '19:30' },
        { value: '20:30' }
    ],
    obj: {
        '00:30': { timestamp: -32400 },
        '01:30': { timestamp: -28800 },
        '02:30': { timestamp: -25200 },
        '03:30': { timestamp: -21600 },
        '04:30': { timestamp: -18000 },
        '05:30': { timestamp: -14400 },
        '06:30': { timestamp: -10800 },
        '07:30': { timestamp: -7200 },
        '08:30': { timestamp: -3600 },
        '09:30': { timestamp: 0 },
        '10:30': { timestamp: 3600 },
        '11:30': { timestamp: 7200 },
        '12:30': { timestamp: 10800 },
        '13:30': { timestamp: 14400 },
        '14:30': { timestamp: 18000 },
        '15:30': { timestamp: 21600 },
        '16:30': { timestamp: 25200 },
        '17:30': { timestamp: 28800 },
        '18:30': { timestamp: 32400 },
        '19:30': { timestamp: 36000 },
        '20:30': { timestamp: 39600 }
    }
}