import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';

const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',
    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',
    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',
    price_color: 'white',
}

const FlowChart = (props) => {
    //console.log('FlowChart')
    //console.log(props)
    let callData = props.callData
    let putData = props.putData
    let priceData = props.priceData

    const chartContainerRef = useRef()
    //console.log(props)
    useEffect(() => {
        const chartWidth = chartContainerRef.current.clientWidth
        const handleResize = () => {
            chart.applyOptions({ width: chartWidth });
        };
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartWidth,
            height: 290,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            }
        });
        chart.timeScale().fitContent()
        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.call_areaTopColor,
        }).setData(callData);

        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.put_areaTopColor
        }).setData(putData);

        chart.addLineSeries({
            priceScaleId: 'left',
            color: colors.price_color
        }).setData(priceData)

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [callData, putData, priceData]);

    return (
        <>
            <div
                ref={chartContainerRef}
            />
        </>
    )
}

export default FlowChart;