import React from "react"
import { Col, Row, Card, Badge } from "antd"
import LightWeightChart from "../../../components/LightWeightChart"

const LightChartExpirations = (props) => {

    const descp = {
        '7_days': '7 дней',
        '15_days': '15 дней',
        '30_days': '30 дней'
    }

    if (props) {

        const paramsFilter = props.paramsFilter

        if (paramsFilter.selectedSymbol === 'main') {
            if (props.dataArr) {
                const dataArr = props.dataArr
                if (dataArr.length > 0) {
               
                    return (<>{dataArr.map((row, i) => {
                        const title = descp[row.period]
                        const ChartData = row.data
                        const callData = row.data.callData
                        const putData = row.data.putData
                        const priceData = row.data.priceData

                        return (<div key={'expir_' + String(i)}>
                            <Card
                                size="small"
                                title={title}
                                style={{ marginTop: 15, width: '100%' }}
                            >
                                <LightWeightChart
                                    chartData={ChartData}
                                    callData={callData}
                                    putData={putData}
                                    priceData={priceData}
                                >
                                </LightWeightChart>
                                <Row>
                                    <Col
                                        span={24}
                                        align='center'
                                    >
                                        <Badge color='white' text='Price' />
                                        <Badge color='#229954' text='Call' />
                                        <Badge color="#E74C3C" text='Put' />
                                    </Col>
                                </Row>

                            </Card>
                        </div>
                        )
                    })}</>)
                } else {
                   
                    return (<></>)
                }

            }
        }
    } else {
       
        return (<></>)
    }

}

export default LightChartExpirations