import React, { useState, useEffect } from 'react';
import { Col, DatePicker, Dropdown, Select, Menu, Row, Button, Tooltip, Typography } from 'antd'
import { Outlet } from "react-router-dom";
import { ReloadOutlined } from '@ant-design/icons'
import 'antd/dist/antd.dark.css';
import FlowCard from './components/FlowCard';
import FlowCardsBlock from './components/FlowCardsBlock';
import { URLs } from '../../urls';
import { STRs } from '../strings';
import AlaSQL from "alasql";
import moment from 'moment';
import * as CONFIG from './config'
import * as Dates from '../../libraries/DatesOld'

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

const _flow_load_mode = 'bd2';

const symbolXRating = {
    SPY: 1,
    QQQ: 2,
    SOXL: 3,
    LABU: 4,
    AAPL: 5,
    MSFT: 6,
    default: 7
}

const symbolXRatingArr = [
    { symbol: 'SPY', rating: 1 },
    { symbol: 'QQQ', rating: 2 }
]
const Flow = () => {

    const [defaultRange, setDefaultRange] = useState({
        from: moment().startOf("month"),
        unix_from: moment().startOf("month").unix(),
        to: moment().endOf("month"),
        unix_to: moment().endOf("month").unix(),
        dateFormat: 'DD-MM-YYYY'
    })
    const [RangePickerValue, setRangePickerValue] = useState(null)
    const [DateSelectRawOptions, setDateSelectRawOptions] = useState(Dates.initDateRange())
    const [RangesRawOptions] = useState(Dates.initDateRange())

    const [FlowDataLoading, setFlowDataLoading] = useState(false)
    const [FlowDataMain, setFlowDataMain] = useState([])

    const [autoUpdate, setAutoUpdate] = useState(false)
    const [autoUpdateInterval, setAutoUpdateInterval] = useState(1000000)
    const [autoUpdateColor, setAutoUpdateColor] = useState('white')
    const [autoUpdateSelectedKey, setAutoUpdateSelectedKey] = useState('1')

    const [PickerRangesValue, setPickerRangesValue] = useState()
    const [TimeData, setTimeData] = useState(defaultRange);

    function setUpdate(props) {
        //console.log('setUpdate')
        //console.log(props.interval)
        setAutoUpdate(props.auto)
        setAutoUpdateInterval(props.interval)
        setAutoUpdateColor(props.color)
        if (props.update_data) {
            updateData(TimeData)
        }
    }

    function onMenuUpdateClick(e) {
        let key = e.key
        //console.log(e)
        setAutoUpdateSelectedKey(String(key))
        switch (key) {
            case '5': setUpdate({ auto: true, interval: 30000, color: "#52c41a", update_data: false })
                break;
            case '1': setUpdate({ auto: false, interval: 1000000, color: 'white', update_data: true })
                break;
            case '2': setUpdate({ auto: true, interval: 60000, color: "#52c41a", update_data: false })
                break;
            case '3': setUpdate({ auto: true, interval: 180000, color: "#52c41a", update_data: false })
                break;
            case '4': setUpdate({ auto: true, interval: 300000, color: "#52c41a", update_data: false })
                break;
            default: break;
        }
    }

    const MenuUpdate = (
        <Menu
            onClick={(e) => onMenuUpdateClick(e)}
            defaultSelectedKeys={['1']}
            selectedKeys={[autoUpdateSelectedKey]}
            items={[{
                key: '1',
                label: 'Обновлять вручную',
            }, {
                key: '5',
                label: 'Каждые 30 сек',
            }, {
                key: '2',
                label: 'Каждую минуту',
            }, {
                key: '3',
                label: 'Каждые 3 минуты',
            }, {
                key: '4',
                label: 'Каждые 5 минут',
            },
            ]}
        />
    );

    const [newDateOptions, setNewDateOptions] = useState()

    function e_get_saved_date_options() {
        //console.log('e_get_saved_date_options')
        let value = 'today'
        let new_date_options = DateSelectRawOptions[value]
        //console.log(DateSelectRawOptions[value])
        if (localStorage.getItem('saved_date_options_flow')) {
            //console.log('date_options foud')
            let stor_data = localStorage.getItem('saved_date_options_flow')
            let p = JSON.parse(stor_data)
            //console.log(p)
            if (p.work) {
                if (p.value === 'fixed') {
                    new_date_options = p
                }
            }
        } else {
            //console.log('date_options not fouded'
        }
        return new_date_options
    }

    const [firstLoaded, setFirstLoaded] = useState(false)

    const [dataFlows, setDataFlows] = useState([])

    function getChartsData(props) {
        //console.log(props)
        setFlowDataLoading(true)
        let resp_0 = [], resp_1 = [], resp_2 = [], resp_3 = [], resp_4 = [], resp_5 = [], resp_6 = {}
        let rating = 0, ratingFound = false
        //console.log('getChartsData')
        //console.log(moment())
        if (_flow_load_mode === 'bd2') {
            fetch(URLs.bd_url, {
                method: 'POST',
                body: CONFIG.getFlowLineBdQuery(props)
            })
                .then((resp_json) => resp_json.json())
                .then((resp) => {
                    //console.log(resp)
                    resp_0 = AlaSQL(`select distinct symbol, datetime,  c,    p,   s from ? 
                    order by rating, symbol, unix
                     `, [resp.data])
                    //console.log(resp_0)

                    //resp_1 = AlaSQL(`select datetime,  sum(c) c,   sum(p) p,  sum(s) s  from ?  
                    //group by datetime 
                    //order by datetime`, [resp_0])
                    //console.log(resp_1)

                    //let resp_main = CONFIG.prepareFlowLineData({ data: resp_1, title: 'test' })
                    //console.log(resp_main)
                    //setDataFlows({ test: resp_main })
                    const dates_range = props.time_range
                    const dates_arr = CONFIG.getFormatedDatesByPeriod('zone', 'YYYY-MM-DD', dates_range.from, dates_range.to)
                    //console.log(dates_arr)

                    let resp_symbol = CONFIG.divideFlowLineDataOnSymbol({ data: resp_0, dates_arr: dates_arr })

                    //console.log(resp_symbol)

                    setDataFlows(resp_symbol)
                    setFlowDataLoading(false)
                })
        }

        if (_flow_load_mode === 'bd') {
            fetch(URLs.bd_url, {
                method: 'POST',
                body: CONFIG.getFlowLineBdQuery(props)
            })
                .then((resp_json) => resp_json.json())
                .then((resp) => {
                    //console.log(resp)
                    if (resp.data) {
                        if (resp.data.length > 0) {
                            resp_1 = resp.data;
                            //console.log(resp_1)
                            resp_1.forEach(row => {
                                let n_row = row;
                                let r_symbol = row.symbol;
                                Object.keys(symbolXRating).forEach(symbol => {
                                    if (r_symbol === symbol) {
                                        rating = symbolXRating[symbol]
                                        ratingFound = true;
                                    }
                                })
                                if (!ratingFound) {
                                    rating = symbolXRating.default
                                }
                                n_row.rating = rating
                                resp_3.push(n_row)
                            })
                            //console.log(resp_3)
                            resp_4 = AlaSQL(`select symbol, datetime, rating,  c,   p,  s  from ?  
                            order by rating`, [resp_3])
                            //console.log(resp_4)
                            let d_symbols = AlaSQL(`select distinct symbol from ?`, [resp_4])
                            //console.log(d_symbols)
                            d_symbols.forEach(row => {
                                resp_5[row.symbol] = {
                                    c: {},
                                    p: {},
                                    s: {}
                                }
                            })
                            resp_4.forEach(row => {
                                resp_5[row.symbol]['c'][row.datetime] = row.c
                                resp_5[row.symbol]['p'][row.datetime] = row.p
                                resp_5[row.symbol]['s'][row.datetime] = row.s
                            })
                            //console.log(resp_5)
                            Object.keys(resp_5).forEach((key, i) => {
                                resp_6[key] = {
                                    symbol: key,
                                    c: resp_5[key].c,
                                    p: resp_5[key].p,
                                    s: resp_5[key].s
                                }
                            })
                            console.log(resp_6)
                            setDataFlows(resp_6)
                            setFlowDataLoading(false)
                        } else {
                            console.log('resp data null')
                        }
                    } else {
                        console.log('resp data err')
                    }

                })
        }
        if (_flow_load_mode === 'api') {
            fetch(URLs.flowline_api, {
                method: 'POST',
                body: JSON.stringify({})
            })
                .then((resp_json) => resp_json.json())
                .then((resp) => {
                    //console.log(resp)

                    if (resp.success) {
                        if (resp.data) {
                            Object.keys(resp.data).forEach(element => {
                                resp_1.push(resp.data[element])
                            });
                            resp_1.forEach((row, i) => {
                                let obj = row;
                                let key = obj.key;
                                let symbol = key.replace(/\s/g, '').replace(/\d/g, '')

                                if (obj.c && obj.p && obj.s) {
                                    Object.keys(symbolXRating).forEach(symbol => {
                                        if (key.match(symbol)) {
                                            rating = symbolXRating[symbol]
                                            ratingFound = true;
                                        }
                                    })
                                    if (!ratingFound) {
                                        rating = symbolXRating.default
                                    }
                                    obj.rating = rating
                                    obj.symbol = symbol;
                                    resp_2.push(obj)
                                }
                            })
                            //console.log(resp_2)
                            resp_3 = AlaSQL('select symbol, rating, key, c, p, s from ? order by rating', [resp_2])
                            //console.log(resp_3)
                            setDataFlows(resp_3)
                            setFlowDataLoading(false)
                        } else {
                            console.log('error flow api: fetch: ok, data: err')
                        }
                    } else {
                        console.log('error flow api: fetch')
                    }
                })
        }
    }

    useEffect(() => {
        if (!firstLoaded) {
            //console.log('firstLoaded')
            setFirstLoaded(true)
            let a = e_get_saved_date_options()
            //console.log(a)
            setNewDateOptions(a)
            setPickerRangesValue(a.value)
            let time_range = {
                from: a.work.date_from,
                to: a.work.date_to,
                unix_from: a.unix.from,
                unix_to: a.unix.to
            }
            setTimeData(time_range)
            setDefaultRange({
                from: moment(a.work.date_from),
                to: moment(a.work.date_to),
                unix_from: a.unix.from,
                unix_to: a.unix.to,
                dateFormat: 'DD-MM-YYYY'
            })
            updateData(time_range)
        }

    }, []);

    useEffect(() => {
        if (autoUpdate) {
            const interval = setInterval(() => {
                //console.log('update')
                updateData(TimeData)
                //console.log(moment()._d)
            }, autoUpdateInterval);
            return () => clearInterval(interval);
        }
    }, [autoUpdate, autoUpdateInterval]);



    function updateData(time_range) {
        getChartsData({ time_range: time_range })
    }

    const onChangeRangePicker = (value) => {
        const date_from = moment(value[0]._d).format('YYYY-MM-DD')
        const date_to = moment(value[1]._d).format('YYYY-MM-DD')

        let time_from = date_from + ' 00:00:00'
        let time_to = date_to + ' 23:59:59'

        let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
        let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to

        let time_range = {
            unix_from: unix_from,
            unix_to: unix_to
        }
        setTimeData(time_range)
        if (PickerRangesValue === 'fixed') {
            setRangePickerValue(value)
            getChartsData({ time_range: time_range });
        } else {
            setPickerRangesValue('fixed')
        }
        let a = {
            value: 'fixed',
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: moment(value[0]._d).format('YYYY-MM-DD'),
                date_to: moment(value[1]._d).format('YYYY-MM-DD')
            }
        }
        setTimeData(a)
        getChartsData({ time_range: a });
        //console.log(a)
        localStorage.setItem('saved_date_options_flow', JSON.stringify(a));
    }

    const onChangePickerRanges = (e) => {
        let picker_ranges_value = RangesRawOptions[e]

        const time_from = picker_ranges_value.work.date_from + ' 00:00:00'
        const time_to = picker_ranges_value.work.date_to + ' 23:59:59'

        setPickerRangesValue(e)
        if (e === 'fixed') {
            localStorage.setItem('saved_date_options_flow', JSON.stringify(RangesRawOptions[e]));
        } else {
            setRangePickerValue([
                moment(picker_ranges_value.visual.date_from, defaultRange.dateFormat),
                moment(picker_ranges_value.visual.date_to, defaultRange.dateFormat)
            ])
            let unix_from = moment.utc(time_from).unix()//pickerRangeValue.unix.from
            let unix_to = moment.utc(time_to).unix()//pickerRangeValue.unix.to

            //console.log('picker_ranges_value')
            //console.log(picker_ranges_value)
            let time_range = {
                from: picker_ranges_value.work.date_from,
                to: picker_ranges_value.work.date_to,
                unix_from: unix_from,
                unix_to: unix_to
            }
            setTimeData(time_range)
            getChartsData({ time_range: time_range });
            localStorage.setItem('saved_date_options_flow', JSON.stringify(RangesRawOptions[e]));
            //console.log(time_range)
        }
    }

    const DateSelectOptions = (values) => {
        let options = DateSelectRawOptions;
        return (
            <Select
                defaultValue='today'
                style={{ width: '100%' }}
                onChange={onChangePickerRanges}
                listHeight={'100%'}
                value={PickerRangesValue}
            >
                {Object.keys(options).map((value, index) => {
                    return (
                        <Option value={options[value].value} key={index}>
                            {options[value].label}
                            <li style={{ fontSize: 12 }}>
                                {options[value].visual.caption}
                            </li>
                        </Option>
                    );
                })}
            </Select>
        );
    }

    //console.log('dataFlows')
    //console.log(dataFlows)

    return (
        <>
            <Row style={{ margin: 4 }}></Row>

            <Row gutter={4} style={{ marginLeft: 3 }}>
                <Col span={24}>
                    <Tooltip
                        overlayStyle={{ maxWidth: '70%' }}
                        placement="bottomLeft" title={STRs.flow_page_description}>
                        <Title level={4}>{STRs.flow_page_title}</Title>
                    </Tooltip>
                </Col>
            </Row>

            <Row className='filter_row' >
                <Col xl={{ span: 12 }} md={{ span: 8 }} xs={{ span: 0 }}     >
                </Col>
                <Col style={{ marginTop: 2, marginBottom: 2 }}
                    xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 24 }}     >
                    <RangePicker
                        style={{ width: '100%' }}
                        defaultValue={[moment(defaultRange.from, defaultRange.dateFormat), moment(defaultRange.to, defaultRange.dateFormat)]}
                        format={defaultRange.dateFormat}
                        value={RangePickerValue || [moment(defaultRange.from, defaultRange.dateFormat), moment(defaultRange.to, defaultRange.dateFormat)]}
                        onChange={value => onChangeRangePicker(value)}
                    />
                </Col>
                <Col style={{ marginTop: 2, }}
                    xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 21 }} >
                    <DateSelectOptions id={'1'}>
                    </DateSelectOptions>
                </Col>
                <Col style={{ marginTop: 2 }}
                    xl={{ span: 2 }} md={{ span: 2 }} xs={{ span: 3 }} >
                    <Dropdown overlay={MenuUpdate}    >
                        <Button align="right" style={{ width: '100%' }} loading={FlowDataLoading} >
                            <ReloadOutlined style={{ color: autoUpdateColor }} />
                        </Button>
                    </Dropdown>

                </Col>
            </Row>



            <Row>
                <FlowCardsBlock
                    spinning={FlowDataLoading}
                    flowData={dataFlows}
                    prepareData={false}
                >
                </FlowCardsBlock>
            </Row>
            <Outlet />
        </>
    )
}

export default Flow;