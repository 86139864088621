export const testData = {
    "title": "DATA NULL: it is example:",
    "callData": [
        {
            "time": 1662049800,
            "value": 0.43
        },
        {
            "time": 1662049860,
            "value": -0.11
        },
        {
            "time": 1662049920,
            "value": -0.14
        },
        {
            "time": 1662049980,
            "value": -0.31
        },
        {
            "time": 1662050040,
            "value": -0.31
        },
        {
            "time": 1662050100,
            "value": -0.31
        },
        {
            "time": 1662050160,
            "value": 0.42
        },
        {
            "time": 1662050220,
            "value": 0.21
        },
        {
            "time": 1662050280,
            "value": 0.12
        },
        {
            "time": 1662050340,
            "value": 0.42
        },
        {
            "time": 1662050400,
            "value": 1.25
        },
        {
            "time": 1662050460,
            "value": 1.54
        },
        {
            "time": 1662050520,
            "value": 1.06
        },
        {
            "time": 1662050580,
            "value": 0.95
        },
        {
            "time": 1662050640,
            "value": 0.93
        },
        {
            "time": 1662050700,
            "value": 0.21
        },
        {
            "time": 1662050760,
            "value": -1.05
        },
        {
            "time": 1662050820,
            "value": -3
        },
        {
            "time": 1662050880,
            "value": -3.1
        },
        {
            "time": 1662050940,
            "value": -3.05
        },
        {
            "time": 1662051000,
            "value": -2.8
        },
        {
            "time": 1662051060,
            "value": -2.75
        },
        {
            "time": 1662051120,
            "value": -2.87
        },
        {
            "time": 1662051180,
            "value": -2.84
        },
        {
            "time": 1662051240,
            "value": -2.47
        },
        {
            "time": 1662051300,
            "value": -2.49
        },
        {
            "time": 1662051360,
            "value": -2.4
        },
        {
            "time": 1662051420,
            "value": -2.15
        },
        {
            "time": 1662051480,
            "value": -2.37
        },
        {
            "time": 1662051540,
            "value": -2.52
        },
        {
            "time": 1662051600,
            "value": -1.94
        },
        {
            "time": 1662051660,
            "value": -1.96
        },
        {
            "time": 1662051720,
            "value": -2.05
        },
        {
            "time": 1662051780,
            "value": -2.56
        },
        {
            "time": 1662051840,
            "value": -2.37
        },
        {
            "time": 1662051900,
            "value": -2.4
        },
        {
            "time": 1662051960,
            "value": -2.13
        },
        {
            "time": 1662052020,
            "value": -1.86
        },
        {
            "time": 1662052080,
            "value": -1.92
        },
        {
            "time": 1662052140,
            "value": -2.04
        },
        {
            "time": 1662052200,
            "value": -2.02
        },
        {
            "time": 1662052260,
            "value": -2.08
        },
        {
            "time": 1662052320,
            "value": -2.26
        },
        {
            "time": 1662052380,
            "value": -2.23
        },
        {
            "time": 1662052440,
            "value": -2.62
        },
        {
            "time": 1662052500,
            "value": -3.18
        },
        {
            "time": 1662052560,
            "value": -3.26
        },
        {
            "time": 1662052620,
            "value": -3.26
        },
        {
            "time": 1662052680,
            "value": -3.08
        },
        {
            "time": 1662052740,
            "value": -2.79
        },
        {
            "time": 1662052800,
            "value": -3.04
        },
        {
            "time": 1662052860,
            "value": -2.85
        },
        {
            "time": 1662052920,
            "value": -4.6
        },
        {
            "time": 1662052980,
            "value": -4.47
        },
        {
            "time": 1662053040,
            "value": -4.62
        },
        {
            "time": 1662053100,
            "value": -4.95
        },
        {
            "time": 1662053160,
            "value": -4.92
        },
        {
            "time": 1662053220,
            "value": -4.63
        },
        {
            "time": 1662053280,
            "value": -4.78
        },
        {
            "time": 1662053340,
            "value": -4.84
        },
        {
            "time": 1662053400,
            "value": -4.98
        },
        {
            "time": 1662053460,
            "value": -4.99
        },
        {
            "time": 1662053520,
            "value": -4.99
        },
        {
            "time": 1662053580,
            "value": -4.85
        },
        {
            "time": 1662053640,
            "value": -4.9
        },
        {
            "time": 1662053700,
            "value": -4.9
        },
        {
            "time": 1662053760,
            "value": -5.15
        },
        {
            "time": 1662053820,
            "value": -5.2
        },
        {
            "time": 1662053880,
            "value": -5.18
        },
        {
            "time": 1662053940,
            "value": -4.78
        },
        {
            "time": 1662054000,
            "value": -4.53
        },
        {
            "time": 1662054060,
            "value": -4.53
        },
        {
            "time": 1662054120,
            "value": -4.47
        },
        {
            "time": 1662054180,
            "value": -4.93
        },
        {
            "time": 1662054240,
            "value": -5.05
        },
        {
            "time": 1662054300,
            "value": -4.98
        },
        {
            "time": 1662054360,
            "value": -5.21
        },
        {
            "time": 1662054420,
            "value": -5.16
        },
        {
            "time": 1662054480,
            "value": -4.54
        },
        {
            "time": 1662054540,
            "value": -4.54
        },
        {
            "time": 1662054600,
            "value": -4.43
        },
        {
            "time": 1662054660,
            "value": -4.04
        },
        {
            "time": 1662054720,
            "value": -3.55
        },
        {
            "time": 1662054780,
            "value": -3.83
        },
        {
            "time": 1662054840,
            "value": -3.74
        },
        {
            "time": 1662054900,
            "value": -3.59
        },
        {
            "time": 1662054960,
            "value": -3.58
        },
        {
            "time": 1662055020,
            "value": -5.04
        },
        {
            "time": 1662055080,
            "value": -5.08
        },
        {
            "time": 1662055140,
            "value": -5.18
        },
        {
            "time": 1662055200,
            "value": -5.34
        },
        {
            "time": 1662055260,
            "value": -5.38
        },
        {
            "time": 1662055320,
            "value": -5.31
        },
        {
            "time": 1662055380,
            "value": -5.35
        },
        {
            "time": 1662055440,
            "value": -5.35
        },
        {
            "time": 1662055500,
            "value": -5.33
        },
        {
            "time": 1662055560,
            "value": -5.63
        },
        {
            "time": 1662055620,
            "value": -5.73
        },
        {
            "time": 1662055680,
            "value": -5.48
        },
        {
            "time": 1662055740,
            "value": -6.07
        },
        {
            "time": 1662055800,
            "value": -5.69
        },
        {
            "time": 1662055860,
            "value": -5.56
        },
        {
            "time": 1662055920,
            "value": -5.58
        },
        {
            "time": 1662055980,
            "value": -5.62
        },
        {
            "time": 1662056040,
            "value": -6.93
        },
        {
            "time": 1662056100,
            "value": -6.96
        },
        {
            "time": 1662056160,
            "value": -7.13
        },
        {
            "time": 1662056220,
            "value": -7.27
        },
        {
            "time": 1662056280,
            "value": -7.25
        },
        {
            "time": 1662056340,
            "value": -7.53
        },
        {
            "time": 1662056400,
            "value": -7.64
        },
        {
            "time": 1662056460,
            "value": -7.68
        },
        {
            "time": 1662056520,
            "value": -7.79
        },
        {
            "time": 1662056580,
            "value": -7.95
        },
        {
            "time": 1662056640,
            "value": -9.02
        },
        {
            "time": 1662056700,
            "value": -8.89
        },
        {
            "time": 1662056760,
            "value": -9.09
        },
        {
            "time": 1662056820,
            "value": -9.38
        },
        {
            "time": 1662056880,
            "value": -10.61
        },
        {
            "time": 1662056940,
            "value": -10.65
        },
        {
            "time": 1662057000,
            "value": -9.44
        },
        {
            "time": 1662057060,
            "value": -9.39
        },
        {
            "time": 1662057120,
            "value": -9.15
        },
        {
            "time": 1662057180,
            "value": -9.42
        },
        {
            "time": 1662057240,
            "value": -9.4
        },
        {
            "time": 1662057300,
            "value": -9.09
        },
        {
            "time": 1662057360,
            "value": -8.72
        },
        {
            "time": 1662057420,
            "value": -8.92
        },
        {
            "time": 1662057480,
            "value": -9.47
        },
        {
            "time": 1662057540,
            "value": -9.4
        },
        {
            "time": 1662057600,
            "value": -9.56
        },
        {
            "time": 1662057660,
            "value": -9.56
        },
        {
            "time": 1662057720,
            "value": -9.51
        },
        {
            "time": 1662057780,
            "value": -9.14
        },
        {
            "time": 1662057840,
            "value": -9.24
        },
        {
            "time": 1662057900,
            "value": -9.23
        },
        {
            "time": 1662057960,
            "value": -9.35
        },
        {
            "time": 1662058020,
            "value": -9.35
        },
        {
            "time": 1662058080,
            "value": -9.41
        },
        {
            "time": 1662058140,
            "value": -9.21
        },
        {
            "time": 1662058200,
            "value": -9.16
        },
        {
            "time": 1662058260,
            "value": -9.04
        },
        {
            "time": 1662058320,
            "value": -9.04
        },
        {
            "time": 1662058380,
            "value": -9.03
        },
        {
            "time": 1662058440,
            "value": -8.97
        },
        {
            "time": 1662058500,
            "value": -8.92
        },
        {
            "time": 1662058560,
            "value": -8.98
        },
        {
            "time": 1662058620,
            "value": -8.99
        },
        {
            "time": 1662058680,
            "value": -8.86
        },
        {
            "time": 1662058740,
            "value": -8.9
        },
        {
            "time": 1662058800,
            "value": -8.86
        },
        {
            "time": 1662058860,
            "value": -8.74
        },
        {
            "time": 1662058920,
            "value": -8.64
        },
        {
            "time": 1662058980,
            "value": -8.68
        },
        {
            "time": 1662059040,
            "value": -8.71
        },
        {
            "time": 1662059100,
            "value": -9.04
        },
        {
            "time": 1662059160,
            "value": -9.04
        },
        {
            "time": 1662059220,
            "value": -9.04
        },
        {
            "time": 1662059280,
            "value": -9.22
        },
        {
            "time": 1662059340,
            "value": -9.26
        },
        {
            "time": 1662059400,
            "value": -9.33
        },
        {
            "time": 1662059460,
            "value": -8.96
        },
        {
            "time": 1662059520,
            "value": -8.75
        },
        {
            "time": 1662059580,
            "value": -8.64
        },
        {
            "time": 1662059640,
            "value": -8.49
        },
        {
            "time": 1662059700,
            "value": -8.58
        },
        {
            "time": 1662059760,
            "value": -8.55
        },
        {
            "time": 1662059820,
            "value": -8.29
        },
        {
            "time": 1662059880,
            "value": -8.79
        },
        {
            "time": 1662059940,
            "value": -8.91
        },
        {
            "time": 1662060000,
            "value": -9.18
        },
        {
            "time": 1662060060,
            "value": -9.25
        },
        {
            "time": 1662060120,
            "value": -9.54
        },
        {
            "time": 1662060180,
            "value": -9.54
        },
        {
            "time": 1662060240,
            "value": -9.49
        },
        {
            "time": 1662060300,
            "value": -9.55
        },
        {
            "time": 1662060360,
            "value": -9.52
        },
        {
            "time": 1662060420,
            "value": -9.62
        },
        {
            "time": 1662060480,
            "value": -9.64
        },
        {
            "time": 1662060540,
            "value": -10.14
        },
        {
            "time": 1662060600,
            "value": -10.29
        },
        {
            "time": 1662060660,
            "value": -10.2
        },
        {
            "time": 1662060720,
            "value": -10.2
        },
        {
            "time": 1662060780,
            "value": -10.17
        },
        {
            "time": 1662060840,
            "value": -10.21
        },
        {
            "time": 1662060900,
            "value": -10.21
        },
        {
            "time": 1662060960,
            "value": -10.24
        },
        {
            "time": 1662061020,
            "value": -9.92
        },
        {
            "time": 1662061080,
            "value": -9.83
        },
        {
            "time": 1662061140,
            "value": -10.07
        },
        {
            "time": 1662061200,
            "value": -10.04
        },
        {
            "time": 1662061260,
            "value": -10.21
        },
        {
            "time": 1662061320,
            "value": -10.08
        },
        {
            "time": 1662061380,
            "value": -9.97
        },
        {
            "time": 1662061440,
            "value": -9.66
        },
        {
            "time": 1662061500,
            "value": -9.72
        },
        {
            "time": 1662061560,
            "value": -9.7
        },
        {
            "time": 1662061620,
            "value": -9.72
        },
        {
            "time": 1662061680,
            "value": -9.87
        },
        {
            "time": 1662061740,
            "value": -10.06
        },
        {
            "time": 1662061800,
            "value": -10.06
        },
        {
            "time": 1662061860,
            "value": -10.28
        },
        {
            "time": 1662061920,
            "value": -10.36
        },
        {
            "time": 1662061980,
            "value": -10.08
        },
        {
            "time": 1662062040,
            "value": -10.31
        },
        {
            "time": 1662062100,
            "value": -10.31
        },
        {
            "time": 1662062160,
            "value": -10.31
        },
        {
            "time": 1662062220,
            "value": -10.82
        },
        {
            "time": 1662062280,
            "value": -10.95
        },
        {
            "time": 1662062340,
            "value": -10.95
        },
        {
            "time": 1662062400,
            "value": -11
        },
        {
            "time": 1662062460,
            "value": -11.16
        },
        {
            "time": 1662062520,
            "value": -10.79
        },
        {
            "time": 1662062580,
            "value": -10.97
        },
        {
            "time": 1662062640,
            "value": -10.96
        },
        {
            "time": 1662062700,
            "value": -11.06
        },
        {
            "time": 1662062760,
            "value": -11.08
        },
        {
            "time": 1662062820,
            "value": -11.16
        },
        {
            "time": 1662062880,
            "value": -11.08
        },
        {
            "time": 1662062940,
            "value": -11.11
        },
        {
            "time": 1662063000,
            "value": -11.48
        },
        {
            "time": 1662063060,
            "value": -11.48
        },
        {
            "time": 1662063120,
            "value": -11.24
        },
        {
            "time": 1662063180,
            "value": -11.24
        },
        {
            "time": 1662063240,
            "value": -11.24
        },
        {
            "time": 1662063300,
            "value": -11.26
        },
        {
            "time": 1662063360,
            "value": -11.41
        },
        {
            "time": 1662063420,
            "value": -11.52
        },
        {
            "time": 1662063480,
            "value": -11.35
        },
        {
            "time": 1662063540,
            "value": -10.8
        },
        {
            "time": 1662063600,
            "value": -11.16
        },
        {
            "time": 1662063660,
            "value": -11.69
        },
        {
            "time": 1662063720,
            "value": -11.69
        },
        {
            "time": 1662063780,
            "value": -11.69
        },
        {
            "time": 1662063840,
            "value": -11.74
        },
        {
            "time": 1662063900,
            "value": -12.04
        },
        {
            "time": 1662063960,
            "value": -11.41
        },
        {
            "time": 1662064020,
            "value": -11.45
        },
        {
            "time": 1662064080,
            "value": -11.95
        },
        {
            "time": 1662064140,
            "value": -11.97
        },
        {
            "time": 1662064200,
            "value": -12.36
        },
        {
            "time": 1662064260,
            "value": -12.64
        },
        {
            "time": 1662064320,
            "value": -12.85
        },
        {
            "time": 1662064380,
            "value": -12.74
        },
        {
            "time": 1662064440,
            "value": -11.45
        },
        {
            "time": 1662064500,
            "value": -11.62
        },
        {
            "time": 1662064560,
            "value": -12.01
        },
        {
            "time": 1662064620,
            "value": -11.92
        },
        {
            "time": 1662064680,
            "value": -11.97
        },
        {
            "time": 1662064740,
            "value": -11.87
        },
        {
            "time": 1662064800,
            "value": -11.82
        },
        {
            "time": 1662064860,
            "value": -12.06
        },
        {
            "time": 1662064920,
            "value": -11.85
        },
        {
            "time": 1662064980,
            "value": -11.85
        },
        {
            "time": 1662065040,
            "value": -11.69
        },
        {
            "time": 1662065100,
            "value": -11.84
        },
        {
            "time": 1662065160,
            "value": -12.36
        },
        {
            "time": 1662065220,
            "value": -12.36
        },
        {
            "time": 1662065280,
            "value": -12.27
        },
        {
            "time": 1662065340,
            "value": -12.18
        },
        {
            "time": 1662065400,
            "value": -12.35
        },
        {
            "time": 1662065460,
            "value": -12.3
        },
        {
            "time": 1662065520,
            "value": -12.19
        },
        {
            "time": 1662065580,
            "value": -12.46
        },
        {
            "time": 1662065640,
            "value": -12.46
        },
        {
            "time": 1662065700,
            "value": -12.39
        },
        {
            "time": 1662065760,
            "value": -12.39
        },
        {
            "time": 1662065820,
            "value": -12.38
        },
        {
            "time": 1662065880,
            "value": -12.38
        },
        {
            "time": 1662065940,
            "value": -12.58
        },
        {
            "time": 1662066000,
            "value": -12.83
        },
        {
            "time": 1662066060,
            "value": -13
        },
        {
            "time": 1662066120,
            "value": -13.15
        },
        {
            "time": 1662066180,
            "value": -13.1
        },
        {
            "time": 1662066240,
            "value": -13.04
        },
        {
            "time": 1662066300,
            "value": -13.08
        },
        {
            "time": 1662066360,
            "value": -12.75
        },
        {
            "time": 1662066420,
            "value": -12.75
        },
        {
            "time": 1662066480,
            "value": -13.13
        },
        {
            "time": 1662066540,
            "value": -13.15
        },
        {
            "time": 1662066600,
            "value": -13.21
        },
        {
            "time": 1662066660,
            "value": -13.2
        },
        {
            "time": 1662066720,
            "value": -13.07
        },
        {
            "time": 1662066780,
            "value": -13.15
        },
        {
            "time": 1662066840,
            "value": -13.18
        },
        {
            "time": 1662066900,
            "value": -13.04
        },
        {
            "time": 1662066960,
            "value": -13.04
        },
        {
            "time": 1662067020,
            "value": -13.25
        },
        {
            "time": 1662067080,
            "value": -13.14
        },
        {
            "time": 1662067140,
            "value": -12.99
        },
        {
            "time": 1662067200,
            "value": -13.19
        },
        {
            "time": 1662067260,
            "value": -12.82
        },
        {
            "time": 1662067320,
            "value": -12.48
        },
        {
            "time": 1662067380,
            "value": -12.19
        },
        {
            "time": 1662067440,
            "value": -12.5
        },
        {
            "time": 1662067500,
            "value": -12.48
        },
        {
            "time": 1662067560,
            "value": -12.37
        },
        {
            "time": 1662067620,
            "value": -11.92
        },
        {
            "time": 1662067680,
            "value": -12.47
        },
        {
            "time": 1662067740,
            "value": -12.5
        },
        {
            "time": 1662067800,
            "value": -12.17
        },
        {
            "time": 1662067860,
            "value": -12.29
        },
        {
            "time": 1662067920,
            "value": -12.29
        },
        {
            "time": 1662067980,
            "value": -12.29
        },
        {
            "time": 1662068040,
            "value": -12.5
        },
        {
            "time": 1662068100,
            "value": -12.56
        },
        {
            "time": 1662068160,
            "value": -12.5
        },
        {
            "time": 1662068220,
            "value": -12.54
        },
        {
            "time": 1662068280,
            "value": -12.45
        },
        {
            "time": 1662068340,
            "value": -12.74
        },
        {
            "time": 1662068400,
            "value": -12.82
        },
        {
            "time": 1662068460,
            "value": -12.95
        },
        {
            "time": 1662068520,
            "value": -12.69
        },
        {
            "time": 1662068580,
            "value": -13.16
        },
        {
            "time": 1662068640,
            "value": -13.2
        },
        {
            "time": 1662068700,
            "value": -13.57
        },
        {
            "time": 1662068760,
            "value": -13.57
        },
        {
            "time": 1662068820,
            "value": -13.58
        },
        {
            "time": 1662068880,
            "value": -13.51
        },
        {
            "time": 1662068940,
            "value": -13.19
        },
        {
            "time": 1662069000,
            "value": -13.15
        },
        {
            "time": 1662069060,
            "value": -13.13
        },
        {
            "time": 1662069120,
            "value": -13.15
        },
        {
            "time": 1662069180,
            "value": -12.9
        },
        {
            "time": 1662069240,
            "value": -13.15
        },
        {
            "time": 1662069300,
            "value": -13.4
        },
        {
            "time": 1662069360,
            "value": -13.58
        },
        {
            "time": 1662069420,
            "value": -13.64
        },
        {
            "time": 1662069480,
            "value": -13.75
        },
        {
            "time": 1662069540,
            "value": -13.74
        },
        {
            "time": 1662069600,
            "value": -13.67
        },
        {
            "time": 1662069660,
            "value": -13.42
        },
        {
            "time": 1662069720,
            "value": -13.56
        },
        {
            "time": 1662069780,
            "value": -13.61
        },
        {
            "time": 1662069840,
            "value": -12.61
        },
        {
            "time": 1662069900,
            "value": -12.58
        },
        {
            "time": 1662069960,
            "value": -12.92
        },
        {
            "time": 1662070020,
            "value": -12.96
        },
        {
            "time": 1662070080,
            "value": -12.52
        },
        {
            "time": 1662070140,
            "value": -12.4
        },
        {
            "time": 1662070200,
            "value": -12.74
        },
        {
            "time": 1662070260,
            "value": -12.35
        },
        {
            "time": 1662070320,
            "value": -12.43
        },
        {
            "time": 1662070380,
            "value": -12.39
        },
        {
            "time": 1662070440,
            "value": -12.32
        },
        {
            "time": 1662070500,
            "value": -12.35
        },
        {
            "time": 1662070560,
            "value": -13.49
        },
        {
            "time": 1662070620,
            "value": -13.14
        },
        {
            "time": 1662070680,
            "value": -13.26
        },
        {
            "time": 1662070740,
            "value": -13.1
        },
        {
            "time": 1662070800,
            "value": -13.19
        },
        {
            "time": 1662070860,
            "value": -13.1
        },
        {
            "time": 1662070920,
            "value": -12.99
        },
        {
            "time": 1662070980,
            "value": -12.77
        },
        {
            "time": 1662071040,
            "value": -13.01
        },
        {
            "time": 1662071100,
            "value": -12.98
        },
        {
            "time": 1662071160,
            "value": -13.08
        },
        {
            "time": 1662071220,
            "value": -12.99
        },
        {
            "time": 1662071280,
            "value": -12.97
        },
        {
            "time": 1662071340,
            "value": -12.84
        },
        {
            "time": 1662071400,
            "value": -13.64
        },
        {
            "time": 1662071460,
            "value": -13.68
        },
        {
            "time": 1662071520,
            "value": -13.93
        },
        {
            "time": 1662071580,
            "value": -13.99
        },
        {
            "time": 1662071640,
            "value": -13.96
        },
        {
            "time": 1662071700,
            "value": -13.9
        },
        {
            "time": 1662071760,
            "value": -13.72
        },
        {
            "time": 1662071820,
            "value": -13.61
        },
        {
            "time": 1662071880,
            "value": -13.77
        },
        {
            "time": 1662071940,
            "value": -13.92
        },
        {
            "time": 1662072000,
            "value": -13.95
        },
        {
            "time": 1662072060,
            "value": -14.03
        },
        {
            "time": 1662072120,
            "value": -14.03
        },
        {
            "time": 1662072180,
            "value": -14.09
        },
        {
            "time": 1662072240,
            "value": -14.12
        },
        {
            "time": 1662072300,
            "value": -13.93
        },
        {
            "time": 1662072360,
            "value": -13.79
        },
        {
            "time": 1662072420,
            "value": -13.94
        },
        {
            "time": 1662072480,
            "value": -13.94
        },
        {
            "time": 1662072540,
            "value": -14.09
        },
        {
            "time": 1662072600,
            "value": -14.12
        },
        {
            "time": 1662072660,
            "value": -14.06
        },
        {
            "time": 1662072720,
            "value": -14.38
        },
        {
            "time": 1662072780,
            "value": -14.37
        },
        {
            "time": 1662072840,
            "value": -14.37
        },
        {
            "time": 1662072900,
            "value": -14.21
        },
        {
            "time": 1662072960,
            "value": -13.74
        },
        {
            "time": 1662073020,
            "value": -13.39
        },
        {
            "time": 1662073080,
            "value": -13.58
        },
        {
            "time": 1662073140,
            "value": -13.75
        },
        {
            "time": 1662073200,
            "value": -13.58
        },
        {
            "time": 1662073320,
            "value": -13.65
        },
        {
            "time": 1662073380,
            "value": -13.65
        },
        {
            "time": 1662073500,
            "value": -13.68
        },
        {
            "time": 1662073560,
            "value": -13.68
        },
        {
            "time": 1662073620,
            "value": -13.65
        },
        {
            "time": 1662073680,
            "value": -13.65
        },
        {
            "time": 1662073740,
            "value": -13.62
        },
        {
            "time": 1662073800,
            "value": -13.68
        },
        {
            "time": 1662073860,
            "value": -13.77
        },
        {
            "time": 1662073920,
            "value": -13.75
        },
        {
            "time": 1662073980,
            "value": -13.56
        },
        {
            "time": 1662074040,
            "value": -13.6
        }
    ],
    "putData": [
        {
            "time": 1662049800,
            "value": -0.13
        },
        {
            "time": 1662049860,
            "value": 1.02
        },
        {
            "time": 1662049920,
            "value": 1.49
        },
        {
            "time": 1662049980,
            "value": 1.52
        },
        {
            "time": 1662050040,
            "value": 1.67
        },
        {
            "time": 1662050100,
            "value": 1.68
        },
        {
            "time": 1662050160,
            "value": 1.27
        },
        {
            "time": 1662050220,
            "value": 0.47
        },
        {
            "time": 1662050280,
            "value": 2.33
        },
        {
            "time": 1662050340,
            "value": 2.42
        },
        {
            "time": 1662050400,
            "value": 2.16
        },
        {
            "time": 1662050460,
            "value": 1.32
        },
        {
            "time": 1662050520,
            "value": 1.07
        },
        {
            "time": 1662050580,
            "value": 2.18
        },
        {
            "time": 1662050640,
            "value": 2.83
        },
        {
            "time": 1662050700,
            "value": 4.19
        },
        {
            "time": 1662050760,
            "value": 3.67
        },
        {
            "time": 1662050820,
            "value": 2.57
        },
        {
            "time": 1662050880,
            "value": 2.5
        },
        {
            "time": 1662050940,
            "value": 1.54
        },
        {
            "time": 1662051000,
            "value": 2.41
        },
        {
            "time": 1662051060,
            "value": 2.68
        },
        {
            "time": 1662051120,
            "value": 2.63
        },
        {
            "time": 1662051180,
            "value": 2.73
        },
        {
            "time": 1662051240,
            "value": 3.11
        },
        {
            "time": 1662051300,
            "value": 3.2
        },
        {
            "time": 1662051360,
            "value": 4.62
        },
        {
            "time": 1662051420,
            "value": 6.28
        },
        {
            "time": 1662051480,
            "value": 6.68
        },
        {
            "time": 1662051540,
            "value": 6.75
        },
        {
            "time": 1662051600,
            "value": 6.21
        },
        {
            "time": 1662051660,
            "value": 6.97
        },
        {
            "time": 1662051720,
            "value": 8.16
        },
        {
            "time": 1662051780,
            "value": 7.4
        },
        {
            "time": 1662051840,
            "value": 7.2
        },
        {
            "time": 1662051900,
            "value": 7.33
        },
        {
            "time": 1662051960,
            "value": 7.17
        },
        {
            "time": 1662052020,
            "value": 6.51
        },
        {
            "time": 1662052080,
            "value": 6.43
        },
        {
            "time": 1662052140,
            "value": 6.26
        },
        {
            "time": 1662052200,
            "value": 6.41
        },
        {
            "time": 1662052260,
            "value": 8.82
        },
        {
            "time": 1662052320,
            "value": 9.76
        },
        {
            "time": 1662052380,
            "value": 9.46
        },
        {
            "time": 1662052440,
            "value": 10.34
        },
        {
            "time": 1662052500,
            "value": 10.38
        },
        {
            "time": 1662052560,
            "value": 11.31
        },
        {
            "time": 1662052620,
            "value": 10.93
        },
        {
            "time": 1662052680,
            "value": 10.74
        },
        {
            "time": 1662052740,
            "value": 10.36
        },
        {
            "time": 1662052800,
            "value": 10.41
        },
        {
            "time": 1662052860,
            "value": 10.25
        },
        {
            "time": 1662052920,
            "value": 10.58
        },
        {
            "time": 1662052980,
            "value": 10.32
        },
        {
            "time": 1662053040,
            "value": 10.17
        },
        {
            "time": 1662053100,
            "value": 9.88
        },
        {
            "time": 1662053160,
            "value": 9.84
        },
        {
            "time": 1662053220,
            "value": 10.1
        },
        {
            "time": 1662053280,
            "value": 9.45
        },
        {
            "time": 1662053340,
            "value": 9.45
        },
        {
            "time": 1662053400,
            "value": 9.36
        },
        {
            "time": 1662053460,
            "value": 9.13
        },
        {
            "time": 1662053520,
            "value": 9.26
        },
        {
            "time": 1662053580,
            "value": 9.46
        },
        {
            "time": 1662053640,
            "value": 10.02
        },
        {
            "time": 1662053700,
            "value": 10.07
        },
        {
            "time": 1662053760,
            "value": 9.77
        },
        {
            "time": 1662053820,
            "value": 9.69
        },
        {
            "time": 1662053880,
            "value": 9.69
        },
        {
            "time": 1662053940,
            "value": 10.11
        },
        {
            "time": 1662054000,
            "value": 11.34
        },
        {
            "time": 1662054060,
            "value": 11.4
        },
        {
            "time": 1662054120,
            "value": 11.59
        },
        {
            "time": 1662054180,
            "value": 8.83
        },
        {
            "time": 1662054240,
            "value": 9.59
        },
        {
            "time": 1662054300,
            "value": 9.99
        },
        {
            "time": 1662054360,
            "value": 8.36
        },
        {
            "time": 1662054420,
            "value": 8.42
        },
        {
            "time": 1662054480,
            "value": 9.08
        },
        {
            "time": 1662054540,
            "value": 9.23
        },
        {
            "time": 1662054600,
            "value": 9.24
        },
        {
            "time": 1662054660,
            "value": 9.01
        },
        {
            "time": 1662054720,
            "value": 8.77
        },
        {
            "time": 1662054780,
            "value": 8.91
        },
        {
            "time": 1662054840,
            "value": 8.65
        },
        {
            "time": 1662054900,
            "value": 8.83
        },
        {
            "time": 1662054960,
            "value": 8.8
        },
        {
            "time": 1662055020,
            "value": 9.66
        },
        {
            "time": 1662055080,
            "value": 9.66
        },
        {
            "time": 1662055140,
            "value": 9.6
        },
        {
            "time": 1662055200,
            "value": 9.8
        },
        {
            "time": 1662055260,
            "value": 10.09
        },
        {
            "time": 1662055320,
            "value": 9.75
        },
        {
            "time": 1662055380,
            "value": 8.92
        },
        {
            "time": 1662055440,
            "value": 9.03
        },
        {
            "time": 1662055500,
            "value": 3.73
        },
        {
            "time": 1662055560,
            "value": 3.7
        },
        {
            "time": 1662055620,
            "value": 3.7
        },
        {
            "time": 1662055680,
            "value": 4.24
        },
        {
            "time": 1662055740,
            "value": 5.33
        },
        {
            "time": 1662055800,
            "value": 4.41
        },
        {
            "time": 1662055860,
            "value": 5.22
        },
        {
            "time": 1662055920,
            "value": 5.09
        },
        {
            "time": 1662055980,
            "value": 5.87
        },
        {
            "time": 1662056040,
            "value": 6.4
        },
        {
            "time": 1662056100,
            "value": 6.15
        },
        {
            "time": 1662056160,
            "value": 6.08
        },
        {
            "time": 1662056220,
            "value": 6.35
        },
        {
            "time": 1662056280,
            "value": 6.44
        },
        {
            "time": 1662056340,
            "value": 5.27
        },
        {
            "time": 1662056400,
            "value": 5.22
        },
        {
            "time": 1662056460,
            "value": 5.38
        },
        {
            "time": 1662056520,
            "value": 5.88
        },
        {
            "time": 1662056580,
            "value": 5.85
        },
        {
            "time": 1662056640,
            "value": 5.39
        },
        {
            "time": 1662056700,
            "value": 5.19
        },
        {
            "time": 1662056760,
            "value": 4.99
        },
        {
            "time": 1662056820,
            "value": 4.58
        },
        {
            "time": 1662056880,
            "value": 4.41
        },
        {
            "time": 1662056940,
            "value": 4.45
        },
        {
            "time": 1662057000,
            "value": 4.37
        },
        {
            "time": 1662057060,
            "value": 4.35
        },
        {
            "time": 1662057120,
            "value": 4.48
        },
        {
            "time": 1662057180,
            "value": 4.52
        },
        {
            "time": 1662057240,
            "value": 4.35
        },
        {
            "time": 1662057300,
            "value": 4.41
        },
        {
            "time": 1662057360,
            "value": 4.39
        },
        {
            "time": 1662057420,
            "value": 1.73
        },
        {
            "time": 1662057480,
            "value": 1.77
        },
        {
            "time": 1662057540,
            "value": 1.8
        },
        {
            "time": 1662057600,
            "value": 1.8
        },
        {
            "time": 1662057660,
            "value": 1.73
        },
        {
            "time": 1662057720,
            "value": 2.54
        },
        {
            "time": 1662057780,
            "value": 2.8
        },
        {
            "time": 1662057840,
            "value": 2.86
        },
        {
            "time": 1662057900,
            "value": 3.15
        },
        {
            "time": 1662057960,
            "value": 3.3
        },
        {
            "time": 1662058020,
            "value": 3.28
        },
        {
            "time": 1662058080,
            "value": 3.27
        },
        {
            "time": 1662058140,
            "value": 3.65
        },
        {
            "time": 1662058200,
            "value": 3.42
        },
        {
            "time": 1662058260,
            "value": 3.26
        },
        {
            "time": 1662058320,
            "value": 3.26
        },
        {
            "time": 1662058380,
            "value": 3.35
        },
        {
            "time": 1662058440,
            "value": 3.32
        },
        {
            "time": 1662058500,
            "value": 4.16
        },
        {
            "time": 1662058560,
            "value": 4.42
        },
        {
            "time": 1662058620,
            "value": 4.47
        },
        {
            "time": 1662058680,
            "value": 4.33
        },
        {
            "time": 1662058740,
            "value": 4.27
        },
        {
            "time": 1662058800,
            "value": 3.63
        },
        {
            "time": 1662058860,
            "value": 3.69
        },
        {
            "time": 1662058920,
            "value": 3.9
        },
        {
            "time": 1662058980,
            "value": 3.59
        },
        {
            "time": 1662059040,
            "value": 3.59
        },
        {
            "time": 1662059100,
            "value": 3.5
        },
        {
            "time": 1662059160,
            "value": 3.65
        },
        {
            "time": 1662059220,
            "value": 3.69
        },
        {
            "time": 1662059280,
            "value": 4.58
        },
        {
            "time": 1662059340,
            "value": 4.27
        },
        {
            "time": 1662059400,
            "value": 3.77
        },
        {
            "time": 1662059460,
            "value": 3.77
        },
        {
            "time": 1662059520,
            "value": 3.19
        },
        {
            "time": 1662059580,
            "value": 3.2
        },
        {
            "time": 1662059640,
            "value": 3.1
        },
        {
            "time": 1662059700,
            "value": 2.77
        },
        {
            "time": 1662059760,
            "value": 2.9
        },
        {
            "time": 1662059820,
            "value": 3.6
        },
        {
            "time": 1662059880,
            "value": 2.6
        },
        {
            "time": 1662059940,
            "value": 3
        },
        {
            "time": 1662060000,
            "value": 2.91
        },
        {
            "time": 1662060060,
            "value": 3
        },
        {
            "time": 1662060120,
            "value": 3.12
        },
        {
            "time": 1662060180,
            "value": 3.08
        },
        {
            "time": 1662060240,
            "value": 3.02
        },
        {
            "time": 1662060300,
            "value": 3.25
        },
        {
            "time": 1662060360,
            "value": 3.22
        },
        {
            "time": 1662060420,
            "value": 3.3
        },
        {
            "time": 1662060480,
            "value": 3.43
        },
        {
            "time": 1662060540,
            "value": 3.51
        },
        {
            "time": 1662060600,
            "value": 3.57
        },
        {
            "time": 1662060660,
            "value": 3.72
        },
        {
            "time": 1662060720,
            "value": 3.67
        },
        {
            "time": 1662060780,
            "value": 3.67
        },
        {
            "time": 1662060840,
            "value": 3.75
        },
        {
            "time": 1662060900,
            "value": 3.67
        },
        {
            "time": 1662060960,
            "value": 3.97
        },
        {
            "time": 1662061020,
            "value": 4.09
        },
        {
            "time": 1662061080,
            "value": 4.11
        },
        {
            "time": 1662061140,
            "value": 4.17
        },
        {
            "time": 1662061200,
            "value": 4.19
        },
        {
            "time": 1662061260,
            "value": 4.31
        },
        {
            "time": 1662061320,
            "value": 4.25
        },
        {
            "time": 1662061380,
            "value": 2.69
        },
        {
            "time": 1662061440,
            "value": 2.75
        },
        {
            "time": 1662061500,
            "value": 2.78
        },
        {
            "time": 1662061560,
            "value": 3.43
        },
        {
            "time": 1662061620,
            "value": 3.54
        },
        {
            "time": 1662061680,
            "value": 3.58
        },
        {
            "time": 1662061740,
            "value": 2.87
        },
        {
            "time": 1662061800,
            "value": 2.85
        },
        {
            "time": 1662061860,
            "value": 3.29
        },
        {
            "time": 1662061920,
            "value": 3.29
        },
        {
            "time": 1662061980,
            "value": 3.14
        },
        {
            "time": 1662062040,
            "value": 3.45
        },
        {
            "time": 1662062100,
            "value": 3.42
        },
        {
            "time": 1662062160,
            "value": 3.65
        },
        {
            "time": 1662062220,
            "value": 3.76
        },
        {
            "time": 1662062280,
            "value": 3.33
        },
        {
            "time": 1662062340,
            "value": 3.04
        },
        {
            "time": 1662062400,
            "value": 2.86
        },
        {
            "time": 1662062460,
            "value": 2.89
        },
        {
            "time": 1662062520,
            "value": 2.8
        },
        {
            "time": 1662062580,
            "value": 2.72
        },
        {
            "time": 1662062640,
            "value": 2.44
        },
        {
            "time": 1662062700,
            "value": 2.16
        },
        {
            "time": 1662062760,
            "value": 2.22
        },
        {
            "time": 1662062820,
            "value": 2.55
        },
        {
            "time": 1662062880,
            "value": 2.55
        },
        {
            "time": 1662062940,
            "value": 2.22
        },
        {
            "time": 1662063000,
            "value": 2.54
        },
        {
            "time": 1662063060,
            "value": 2.56
        },
        {
            "time": 1662063120,
            "value": 2.54
        },
        {
            "time": 1662063180,
            "value": 2.52
        },
        {
            "time": 1662063240,
            "value": 2.63
        },
        {
            "time": 1662063300,
            "value": 2.38
        },
        {
            "time": 1662063360,
            "value": -6.91
        },
        {
            "time": 1662063420,
            "value": -6.67
        },
        {
            "time": 1662063480,
            "value": -6.76
        },
        {
            "time": 1662063540,
            "value": -6.76
        },
        {
            "time": 1662063600,
            "value": -6.99
        },
        {
            "time": 1662063660,
            "value": -6.83
        },
        {
            "time": 1662063720,
            "value": -6.81
        },
        {
            "time": 1662063780,
            "value": -6.5
        },
        {
            "time": 1662063840,
            "value": -6.9
        },
        {
            "time": 1662063900,
            "value": -6.9
        },
        {
            "time": 1662063960,
            "value": -8.18
        },
        {
            "time": 1662064020,
            "value": -8.49
        },
        {
            "time": 1662064080,
            "value": -9.59
        },
        {
            "time": 1662064140,
            "value": -10.79
        },
        {
            "time": 1662064200,
            "value": -12.38
        },
        {
            "time": 1662064260,
            "value": -12.73
        },
        {
            "time": 1662064320,
            "value": -14.95
        },
        {
            "time": 1662064380,
            "value": -14.91
        },
        {
            "time": 1662064440,
            "value": -13.97
        },
        {
            "time": 1662064500,
            "value": -14.26
        },
        {
            "time": 1662064560,
            "value": -13.39
        },
        {
            "time": 1662064620,
            "value": -15.22
        },
        {
            "time": 1662064680,
            "value": -15.05
        },
        {
            "time": 1662064740,
            "value": -14.88
        },
        {
            "time": 1662064800,
            "value": -14.88
        },
        {
            "time": 1662064860,
            "value": -13.82
        },
        {
            "time": 1662064920,
            "value": -13.86
        },
        {
            "time": 1662064980,
            "value": -13.89
        },
        {
            "time": 1662065040,
            "value": -13.7
        },
        {
            "time": 1662065100,
            "value": -13.5
        },
        {
            "time": 1662065160,
            "value": -13.29
        },
        {
            "time": 1662065220,
            "value": -13.24
        },
        {
            "time": 1662065280,
            "value": -13.49
        },
        {
            "time": 1662065340,
            "value": -13.58
        },
        {
            "time": 1662065400,
            "value": -13.82
        },
        {
            "time": 1662065460,
            "value": -13.87
        },
        {
            "time": 1662065520,
            "value": -13.6
        },
        {
            "time": 1662065580,
            "value": -13.63
        },
        {
            "time": 1662065640,
            "value": -13.58
        },
        {
            "time": 1662065700,
            "value": -13.53
        },
        {
            "time": 1662065760,
            "value": -13.45
        },
        {
            "time": 1662065820,
            "value": -13.5
        },
        {
            "time": 1662065880,
            "value": -13.46
        },
        {
            "time": 1662065940,
            "value": -13.26
        },
        {
            "time": 1662066000,
            "value": -13.36
        },
        {
            "time": 1662066060,
            "value": -14.98
        },
        {
            "time": 1662066120,
            "value": -14.52
        },
        {
            "time": 1662066180,
            "value": -14.31
        },
        {
            "time": 1662066240,
            "value": -14.57
        },
        {
            "time": 1662066300,
            "value": -14.54
        },
        {
            "time": 1662066360,
            "value": -14.48
        },
        {
            "time": 1662066420,
            "value": -14.44
        },
        {
            "time": 1662066480,
            "value": -14.35
        },
        {
            "time": 1662066540,
            "value": -14.35
        },
        {
            "time": 1662066600,
            "value": -14.22
        },
        {
            "time": 1662066660,
            "value": -14.19
        },
        {
            "time": 1662066720,
            "value": -14.22
        },
        {
            "time": 1662066780,
            "value": -14.67
        },
        {
            "time": 1662066840,
            "value": -14.83
        },
        {
            "time": 1662066900,
            "value": -15.25
        },
        {
            "time": 1662066960,
            "value": -15.22
        },
        {
            "time": 1662067020,
            "value": -15
        },
        {
            "time": 1662067080,
            "value": -14.77
        },
        {
            "time": 1662067140,
            "value": -14.75
        },
        {
            "time": 1662067200,
            "value": -14.86
        },
        {
            "time": 1662067260,
            "value": -14.95
        },
        {
            "time": 1662067320,
            "value": -15.43
        },
        {
            "time": 1662067380,
            "value": -15.48
        },
        {
            "time": 1662067440,
            "value": -16.02
        },
        {
            "time": 1662067500,
            "value": -16.3
        },
        {
            "time": 1662067560,
            "value": -16.08
        },
        {
            "time": 1662067620,
            "value": -16.26
        },
        {
            "time": 1662067680,
            "value": -16.1
        },
        {
            "time": 1662067740,
            "value": -18.36
        },
        {
            "time": 1662067800,
            "value": -18.26
        },
        {
            "time": 1662067860,
            "value": -18.05
        },
        {
            "time": 1662067920,
            "value": -17.95
        },
        {
            "time": 1662067980,
            "value": -17.89
        },
        {
            "time": 1662068040,
            "value": -17.39
        },
        {
            "time": 1662068100,
            "value": -17.33
        },
        {
            "time": 1662068160,
            "value": -18.55
        },
        {
            "time": 1662068220,
            "value": -17.57
        },
        {
            "time": 1662068280,
            "value": -18.07
        },
        {
            "time": 1662068340,
            "value": -16.57
        },
        {
            "time": 1662068400,
            "value": -15.96
        },
        {
            "time": 1662068460,
            "value": -14.65
        },
        {
            "time": 1662068520,
            "value": -14.79
        },
        {
            "time": 1662068580,
            "value": -14.97
        },
        {
            "time": 1662068640,
            "value": -14.95
        },
        {
            "time": 1662068700,
            "value": -15.01
        },
        {
            "time": 1662068760,
            "value": -15.18
        },
        {
            "time": 1662068820,
            "value": -15.26
        },
        {
            "time": 1662068880,
            "value": -15.04
        },
        {
            "time": 1662068940,
            "value": -16.32
        },
        {
            "time": 1662069000,
            "value": -16.27
        },
        {
            "time": 1662069060,
            "value": -16.04
        },
        {
            "time": 1662069120,
            "value": -16.25
        },
        {
            "time": 1662069180,
            "value": -16.12
        },
        {
            "time": 1662069240,
            "value": -16.29
        },
        {
            "time": 1662069300,
            "value": -16.29
        },
        {
            "time": 1662069360,
            "value": -16.24
        },
        {
            "time": 1662069420,
            "value": -16.24
        },
        {
            "time": 1662069480,
            "value": -16.19
        },
        {
            "time": 1662069540,
            "value": -16.31
        },
        {
            "time": 1662069600,
            "value": -16.46
        },
        {
            "time": 1662069660,
            "value": -16.46
        },
        {
            "time": 1662069720,
            "value": -16.45
        },
        {
            "time": 1662069780,
            "value": -16.38
        },
        {
            "time": 1662069840,
            "value": -15.89
        },
        {
            "time": 1662069900,
            "value": -15.97
        },
        {
            "time": 1662069960,
            "value": -15.95
        },
        {
            "time": 1662070020,
            "value": -15.87
        },
        {
            "time": 1662070080,
            "value": -15.53
        },
        {
            "time": 1662070140,
            "value": -15.38
        },
        {
            "time": 1662070200,
            "value": -15.19
        },
        {
            "time": 1662070260,
            "value": -15
        },
        {
            "time": 1662070320,
            "value": -15.11
        },
        {
            "time": 1662070380,
            "value": -15.11
        },
        {
            "time": 1662070440,
            "value": -14.92
        },
        {
            "time": 1662070500,
            "value": -14.92
        },
        {
            "time": 1662070560,
            "value": -14.71
        },
        {
            "time": 1662070620,
            "value": -14.52
        },
        {
            "time": 1662070680,
            "value": -14.4
        },
        {
            "time": 1662070740,
            "value": -14.25
        },
        {
            "time": 1662070800,
            "value": -14.34
        },
        {
            "time": 1662070860,
            "value": -14.57
        },
        {
            "time": 1662070920,
            "value": -14.58
        },
        {
            "time": 1662070980,
            "value": -14.38
        },
        {
            "time": 1662071040,
            "value": -14.35
        },
        {
            "time": 1662071100,
            "value": -14.17
        },
        {
            "time": 1662071160,
            "value": -14.3
        },
        {
            "time": 1662071220,
            "value": -14.36
        },
        {
            "time": 1662071280,
            "value": -14.42
        },
        {
            "time": 1662071340,
            "value": -14.26
        },
        {
            "time": 1662071400,
            "value": -14.25
        },
        {
            "time": 1662071460,
            "value": -14.53
        },
        {
            "time": 1662071520,
            "value": -14.43
        },
        {
            "time": 1662071580,
            "value": -14.7
        },
        {
            "time": 1662071640,
            "value": -14.7
        },
        {
            "time": 1662071700,
            "value": -14.42
        },
        {
            "time": 1662071760,
            "value": -14.64
        },
        {
            "time": 1662071820,
            "value": -14.57
        },
        {
            "time": 1662071880,
            "value": -14.52
        },
        {
            "time": 1662071940,
            "value": -14.72
        },
        {
            "time": 1662072000,
            "value": -14.52
        },
        {
            "time": 1662072060,
            "value": -14.25
        },
        {
            "time": 1662072120,
            "value": -14.83
        },
        {
            "time": 1662072180,
            "value": -14.83
        },
        {
            "time": 1662072240,
            "value": -15.05
        },
        {
            "time": 1662072300,
            "value": -14.96
        },
        {
            "time": 1662072360,
            "value": -15.04
        },
        {
            "time": 1662072420,
            "value": -15
        },
        {
            "time": 1662072480,
            "value": -15.02
        },
        {
            "time": 1662072540,
            "value": -14.94
        },
        {
            "time": 1662072600,
            "value": -14.94
        },
        {
            "time": 1662072660,
            "value": -14.21
        },
        {
            "time": 1662072720,
            "value": -14.17
        },
        {
            "time": 1662072780,
            "value": -15.91
        },
        {
            "time": 1662072840,
            "value": -17.56
        },
        {
            "time": 1662072900,
            "value": -17.6
        },
        {
            "time": 1662072960,
            "value": -18.11
        },
        {
            "time": 1662073020,
            "value": -19.77
        },
        {
            "time": 1662073080,
            "value": -15.28
        },
        {
            "time": 1662073140,
            "value": -15.07
        },
        {
            "time": 1662073200,
            "value": -15.46
        },
        {
            "time": 1662073320,
            "value": -15.38
        },
        {
            "time": 1662073380,
            "value": -15.34
        },
        {
            "time": 1662073500,
            "value": -15.18
        },
        {
            "time": 1662073560,
            "value": -15.12
        },
        {
            "time": 1662073620,
            "value": -15.12
        },
        {
            "time": 1662073680,
            "value": -15.14
        },
        {
            "time": 1662073740,
            "value": -14.95
        },
        {
            "time": 1662073800,
            "value": -13.94
        },
        {
            "time": 1662073860,
            "value": -13.85
        },
        {
            "time": 1662073920,
            "value": -13.83
        },
        {
            "time": 1662073980,
            "value": -13.48
        },
        {
            "time": 1662074040,
            "value": -13.58
        }
    ],
    "priceData": [
        {
            "time": 1662049800,
            "value": 392.8
        },
        {
            "time": 1662049860,
            "value": 393.25
        },
        {
            "time": 1662049920,
            "value": 392.6
        },
        {
            "time": 1662049980,
            "value": 392.66
        },
        {
            "time": 1662050040,
            "value": 392.29
        },
        {
            "time": 1662050100,
            "value": 392.11
        },
        {
            "time": 1662050160,
            "value": 392.39
        },
        {
            "time": 1662050220,
            "value": 392.56
        },
        {
            "time": 1662050280,
            "value": 392.24
        },
        {
            "time": 1662050340,
            "value": 392.27
        },
        {
            "time": 1662050400,
            "value": 392.61
        },
        {
            "time": 1662050460,
            "value": 393.44
        },
        {
            "time": 1662050520,
            "value": 393.08
        },
        {
            "time": 1662050580,
            "value": 392.83
        },
        {
            "time": 1662050640,
            "value": 393.14
        },
        {
            "time": 1662050700,
            "value": 392.98
        },
        {
            "time": 1662050760,
            "value": 392.98
        },
        {
            "time": 1662050820,
            "value": 392.57
        },
        {
            "time": 1662050880,
            "value": 392.9
        },
        {
            "time": 1662050940,
            "value": 392.81
        },
        {
            "time": 1662051000,
            "value": 392.49
        },
        {
            "time": 1662051060,
            "value": 392.48
        },
        {
            "time": 1662051120,
            "value": 392.46
        },
        {
            "time": 1662051180,
            "value": 392.53
        },
        {
            "time": 1662051240,
            "value": 392.65
        },
        {
            "time": 1662051300,
            "value": 392.71
        },
        {
            "time": 1662051360,
            "value": 392.08
        },
        {
            "time": 1662051420,
            "value": 392.26
        },
        {
            "time": 1662051480,
            "value": 392.65
        },
        {
            "time": 1662051540,
            "value": 392.7
        },
        {
            "time": 1662051600,
            "value": 392.57
        },
        {
            "time": 1662051660,
            "value": 392.65
        },
        {
            "time": 1662051720,
            "value": 392.31
        },
        {
            "time": 1662051780,
            "value": 391.31
        },
        {
            "time": 1662051840,
            "value": 391.48
        },
        {
            "time": 1662051900,
            "value": 391.34
        },
        {
            "time": 1662051960,
            "value": 391.33
        },
        {
            "time": 1662052020,
            "value": 391.68
        },
        {
            "time": 1662052080,
            "value": 391.45
        },
        {
            "time": 1662052140,
            "value": 391.63
        },
        {
            "time": 1662052200,
            "value": 391.58
        },
        {
            "time": 1662052260,
            "value": 391.29
        },
        {
            "time": 1662052320,
            "value": 391.51
        },
        {
            "time": 1662052380,
            "value": 391.4
        },
        {
            "time": 1662052440,
            "value": 390.96
        },
        {
            "time": 1662052500,
            "value": 390.95
        },
        {
            "time": 1662052560,
            "value": 390.72
        },
        {
            "time": 1662052620,
            "value": 390.74
        },
        {
            "time": 1662052680,
            "value": 390.65
        },
        {
            "time": 1662052740,
            "value": 390.97
        },
        {
            "time": 1662052800,
            "value": 390.91
        },
        {
            "time": 1662052860,
            "value": 391.02
        },
        {
            "time": 1662052920,
            "value": 391.42
        },
        {
            "time": 1662052980,
            "value": 391.49
        },
        {
            "time": 1662053040,
            "value": 391.63
        },
        {
            "time": 1662053100,
            "value": 392.23
        },
        {
            "time": 1662053160,
            "value": 392.07
        },
        {
            "time": 1662053220,
            "value": 392.14
        },
        {
            "time": 1662053280,
            "value": 392.23
        },
        {
            "time": 1662053340,
            "value": 392.53
        },
        {
            "time": 1662053400,
            "value": 392.49
        },
        {
            "time": 1662053460,
            "value": 392.4
        },
        {
            "time": 1662053520,
            "value": 392.38
        },
        {
            "time": 1662053580,
            "value": 392.18
        },
        {
            "time": 1662053640,
            "value": 392.13
        },
        {
            "time": 1662053700,
            "value": 391.92
        },
        {
            "time": 1662053760,
            "value": 391.95
        },
        {
            "time": 1662053820,
            "value": 392.14
        },
        {
            "time": 1662053880,
            "value": 392.06
        },
        {
            "time": 1662053940,
            "value": 391.31
        },
        {
            "time": 1662054000,
            "value": 391.03
        },
        {
            "time": 1662054060,
            "value": 391.16
        },
        {
            "time": 1662054120,
            "value": 391.07
        },
        {
            "time": 1662054180,
            "value": 390.86
        },
        {
            "time": 1662054240,
            "value": 390.67
        },
        {
            "time": 1662054300,
            "value": 390.59
        },
        {
            "time": 1662054360,
            "value": 390.38
        },
        {
            "time": 1662054420,
            "value": 390.33
        },
        {
            "time": 1662054480,
            "value": 390.21
        },
        {
            "time": 1662054540,
            "value": 390.19
        },
        {
            "time": 1662054600,
            "value": 390.21
        },
        {
            "time": 1662054660,
            "value": 390.4
        },
        {
            "time": 1662054720,
            "value": 390.67
        },
        {
            "time": 1662054780,
            "value": 390.8
        },
        {
            "time": 1662054840,
            "value": 390.51
        },
        {
            "time": 1662054900,
            "value": 390.56
        },
        {
            "time": 1662054960,
            "value": 390.4
        },
        {
            "time": 1662055020,
            "value": 390.32
        },
        {
            "time": 1662055080,
            "value": 390.39
        },
        {
            "time": 1662055140,
            "value": 390.38
        },
        {
            "time": 1662055200,
            "value": 390.32
        },
        {
            "time": 1662055260,
            "value": 390.19
        },
        {
            "time": 1662055320,
            "value": 390.49
        },
        {
            "time": 1662055380,
            "value": 390.57
        },
        {
            "time": 1662055440,
            "value": 390.17
        },
        {
            "time": 1662055500,
            "value": 390.31
        },
        {
            "time": 1662055560,
            "value": 390.68
        },
        {
            "time": 1662055620,
            "value": 390.8
        },
        {
            "time": 1662055680,
            "value": 391.59
        },
        {
            "time": 1662055740,
            "value": 391.33
        },
        {
            "time": 1662055800,
            "value": 391.32
        },
        {
            "time": 1662055860,
            "value": 391.57
        },
        {
            "time": 1662055920,
            "value": 391.69
        },
        {
            "time": 1662055980,
            "value": 391.63
        },
        {
            "time": 1662056040,
            "value": 391.83
        },
        {
            "time": 1662056100,
            "value": 391.72
        },
        {
            "time": 1662056160,
            "value": 391.51
        },
        {
            "time": 1662056220,
            "value": 391.83
        },
        {
            "time": 1662056280,
            "value": 391.68
        },
        {
            "time": 1662056340,
            "value": 391.51
        },
        {
            "time": 1662056400,
            "value": 391.67
        },
        {
            "time": 1662056460,
            "value": 391.42
        },
        {
            "time": 1662056520,
            "value": 391.34
        },
        {
            "time": 1662056580,
            "value": 391.08
        },
        {
            "time": 1662056640,
            "value": 390.98
        },
        {
            "time": 1662056700,
            "value": 391.17
        },
        {
            "time": 1662056760,
            "value": 391.04
        },
        {
            "time": 1662056820,
            "value": 391.05
        },
        {
            "time": 1662056880,
            "value": 391.36
        },
        {
            "time": 1662056940,
            "value": 390.95
        },
        {
            "time": 1662057000,
            "value": 390.88
        },
        {
            "time": 1662057060,
            "value": 391.47
        },
        {
            "time": 1662057120,
            "value": 391.27
        },
        {
            "time": 1662057180,
            "value": 391.58
        },
        {
            "time": 1662057240,
            "value": 391.75
        },
        {
            "time": 1662057300,
            "value": 391.99
        },
        {
            "time": 1662057360,
            "value": 392.16
        },
        {
            "time": 1662057420,
            "value": 392.38
        },
        {
            "time": 1662057480,
            "value": 392.15
        },
        {
            "time": 1662057540,
            "value": 391.82
        },
        {
            "time": 1662057600,
            "value": 391.76
        },
        {
            "time": 1662057660,
            "value": 391.63
        },
        {
            "time": 1662057720,
            "value": 391.39
        },
        {
            "time": 1662057780,
            "value": 391.39
        },
        {
            "time": 1662057840,
            "value": 391.21
        },
        {
            "time": 1662057900,
            "value": 391.01
        },
        {
            "time": 1662057960,
            "value": 390.97
        },
        {
            "time": 1662058020,
            "value": 391.07
        },
        {
            "time": 1662058080,
            "value": 390.94
        },
        {
            "time": 1662058140,
            "value": 391.16
        },
        {
            "time": 1662058200,
            "value": 391.28
        },
        {
            "time": 1662058260,
            "value": 391.2
        },
        {
            "time": 1662058320,
            "value": 391.18
        },
        {
            "time": 1662058380,
            "value": 391.04
        },
        {
            "time": 1662058440,
            "value": 390.79
        },
        {
            "time": 1662058500,
            "value": 391.01
        },
        {
            "time": 1662058560,
            "value": 390.99
        },
        {
            "time": 1662058620,
            "value": 390.72
        },
        {
            "time": 1662058680,
            "value": 390.78
        },
        {
            "time": 1662058740,
            "value": 390.99
        },
        {
            "time": 1662058800,
            "value": 391.28
        },
        {
            "time": 1662058860,
            "value": 391.45
        },
        {
            "time": 1662058920,
            "value": 391.49
        },
        {
            "time": 1662058980,
            "value": 391.24
        },
        {
            "time": 1662059040,
            "value": 391.38
        },
        {
            "time": 1662059100,
            "value": 391.61
        },
        {
            "time": 1662059160,
            "value": 391.25
        },
        {
            "time": 1662059220,
            "value": 391.4
        },
        {
            "time": 1662059280,
            "value": 391.5
        },
        {
            "time": 1662059340,
            "value": 391.83
        },
        {
            "time": 1662059400,
            "value": 391.84
        },
        {
            "time": 1662059460,
            "value": 391.56
        },
        {
            "time": 1662059520,
            "value": 391.72
        },
        {
            "time": 1662059580,
            "value": 391.66
        },
        {
            "time": 1662059640,
            "value": 391.7
        },
        {
            "time": 1662059700,
            "value": 391.96
        },
        {
            "time": 1662059760,
            "value": 392.07
        },
        {
            "time": 1662059820,
            "value": 391.86
        },
        {
            "time": 1662059880,
            "value": 392.2
        },
        {
            "time": 1662059940,
            "value": 392.2
        },
        {
            "time": 1662060000,
            "value": 391.93
        },
        {
            "time": 1662060060,
            "value": 391.72
        },
        {
            "time": 1662060120,
            "value": 391.68
        },
        {
            "time": 1662060180,
            "value": 391.63
        },
        {
            "time": 1662060240,
            "value": 391.72
        },
        {
            "time": 1662060300,
            "value": 391.68
        },
        {
            "time": 1662060360,
            "value": 391.41
        },
        {
            "time": 1662060420,
            "value": 391.65
        },
        {
            "time": 1662060480,
            "value": 392
        },
        {
            "time": 1662060540,
            "value": 391.88
        },
        {
            "time": 1662060600,
            "value": 391.71
        },
        {
            "time": 1662060660,
            "value": 391.58
        },
        {
            "time": 1662060720,
            "value": 391.55
        },
        {
            "time": 1662060780,
            "value": 391.64
        },
        {
            "time": 1662060840,
            "value": 391.73
        },
        {
            "time": 1662060900,
            "value": 391.82
        },
        {
            "time": 1662060960,
            "value": 391.48
        },
        {
            "time": 1662061020,
            "value": 391.34
        },
        {
            "time": 1662061080,
            "value": 391.09
        },
        {
            "time": 1662061140,
            "value": 391.11
        },
        {
            "time": 1662061200,
            "value": 391.01
        },
        {
            "time": 1662061260,
            "value": 390.95
        },
        {
            "time": 1662061320,
            "value": 391.11
        },
        {
            "time": 1662061380,
            "value": 391.09
        },
        {
            "time": 1662061440,
            "value": 391.22
        },
        {
            "time": 1662061500,
            "value": 391.27
        },
        {
            "time": 1662061560,
            "value": 391.12
        },
        {
            "time": 1662061620,
            "value": 390.9
        },
        {
            "time": 1662061680,
            "value": 391.02
        },
        {
            "time": 1662061740,
            "value": 391.07
        },
        {
            "time": 1662061800,
            "value": 391.16
        },
        {
            "time": 1662061860,
            "value": 391.03
        },
        {
            "time": 1662061920,
            "value": 390.96
        },
        {
            "time": 1662061980,
            "value": 390.82
        },
        {
            "time": 1662062040,
            "value": 390.77
        },
        {
            "time": 1662062100,
            "value": 390.65
        },
        {
            "time": 1662062160,
            "value": 390.72
        },
        {
            "time": 1662062220,
            "value": 390.56
        },
        {
            "time": 1662062280,
            "value": 390.32
        },
        {
            "time": 1662062340,
            "value": 390.7
        },
        {
            "time": 1662062400,
            "value": 390.75
        },
        {
            "time": 1662062460,
            "value": 390.6
        },
        {
            "time": 1662062520,
            "value": 390.69
        },
        {
            "time": 1662062580,
            "value": 390.84
        },
        {
            "time": 1662062640,
            "value": 390.92
        },
        {
            "time": 1662062700,
            "value": 391.13
        },
        {
            "time": 1662062760,
            "value": 391.25
        },
        {
            "time": 1662062820,
            "value": 391.37
        },
        {
            "time": 1662062880,
            "value": 391.25
        },
        {
            "time": 1662062940,
            "value": 391.18
        },
        {
            "time": 1662063000,
            "value": 391.66
        },
        {
            "time": 1662063060,
            "value": 391.43
        },
        {
            "time": 1662063120,
            "value": 391.64
        },
        {
            "time": 1662063180,
            "value": 391.54
        },
        {
            "time": 1662063240,
            "value": 391.91
        },
        {
            "time": 1662063300,
            "value": 392.03
        },
        {
            "time": 1662063360,
            "value": 392.12
        },
        {
            "time": 1662063420,
            "value": 391.93
        },
        {
            "time": 1662063480,
            "value": 392.08
        },
        {
            "time": 1662063540,
            "value": 391.96
        },
        {
            "time": 1662063600,
            "value": 391.96
        },
        {
            "time": 1662063660,
            "value": 392.23
        },
        {
            "time": 1662063720,
            "value": 392.18
        },
        {
            "time": 1662063780,
            "value": 392.26
        },
        {
            "time": 1662063840,
            "value": 392.13
        },
        {
            "time": 1662063900,
            "value": 392.6
        },
        {
            "time": 1662063960,
            "value": 392.57
        },
        {
            "time": 1662064020,
            "value": 392.58
        },
        {
            "time": 1662064080,
            "value": 392.83
        },
        {
            "time": 1662064140,
            "value": 392.94
        },
        {
            "time": 1662064200,
            "value": 393.02
        },
        {
            "time": 1662064260,
            "value": 392.95
        },
        {
            "time": 1662064320,
            "value": 392.94
        },
        {
            "time": 1662064380,
            "value": 392.86
        },
        {
            "time": 1662064440,
            "value": 393
        },
        {
            "time": 1662064500,
            "value": 392.99
        },
        {
            "time": 1662064560,
            "value": 392.81
        },
        {
            "time": 1662064620,
            "value": 392.56
        },
        {
            "time": 1662064680,
            "value": 392.54
        },
        {
            "time": 1662064740,
            "value": 392.68
        },
        {
            "time": 1662064800,
            "value": 392.68
        },
        {
            "time": 1662064860,
            "value": 392.56
        },
        {
            "time": 1662064920,
            "value": 392.39
        },
        {
            "time": 1662064980,
            "value": 392.4
        },
        {
            "time": 1662065040,
            "value": 392.42
        },
        {
            "time": 1662065100,
            "value": 392.31
        },
        {
            "time": 1662065160,
            "value": 392.32
        },
        {
            "time": 1662065220,
            "value": 392.07
        },
        {
            "time": 1662065280,
            "value": 392.03
        },
        {
            "time": 1662065340,
            "value": 391.94
        },
        {
            "time": 1662065400,
            "value": 392.18
        },
        {
            "time": 1662065460,
            "value": 392.2
        },
        {
            "time": 1662065520,
            "value": 392.1
        },
        {
            "time": 1662065580,
            "value": 392.15
        },
        {
            "time": 1662065640,
            "value": 392.02
        },
        {
            "time": 1662065700,
            "value": 392.06
        },
        {
            "time": 1662065760,
            "value": 392.13
        },
        {
            "time": 1662065820,
            "value": 392.19
        },
        {
            "time": 1662065880,
            "value": 392.07
        },
        {
            "time": 1662065940,
            "value": 392.22
        },
        {
            "time": 1662066000,
            "value": 392.27
        },
        {
            "time": 1662066060,
            "value": 392.25
        },
        {
            "time": 1662066120,
            "value": 392.41
        },
        {
            "time": 1662066180,
            "value": 391.86
        },
        {
            "time": 1662066240,
            "value": 392.14
        },
        {
            "time": 1662066300,
            "value": 392.64
        },
        {
            "time": 1662066360,
            "value": 392.77
        },
        {
            "time": 1662066420,
            "value": 392.47
        },
        {
            "time": 1662066480,
            "value": 392.26
        },
        {
            "time": 1662066540,
            "value": 392.27
        },
        {
            "time": 1662066600,
            "value": 392.31
        },
        {
            "time": 1662066660,
            "value": 392.06
        },
        {
            "time": 1662066720,
            "value": 392.32
        },
        {
            "time": 1662066780,
            "value": 392.51
        },
        {
            "time": 1662066840,
            "value": 392.6
        },
        {
            "time": 1662066900,
            "value": 392.55
        },
        {
            "time": 1662066960,
            "value": 392.73
        },
        {
            "time": 1662067020,
            "value": 392.59
        },
        {
            "time": 1662067080,
            "value": 392.59
        },
        {
            "time": 1662067140,
            "value": 392.65
        },
        {
            "time": 1662067200,
            "value": 392.88
        },
        {
            "time": 1662067260,
            "value": 393.15
        },
        {
            "time": 1662067320,
            "value": 393.48
        },
        {
            "time": 1662067380,
            "value": 393.77
        },
        {
            "time": 1662067440,
            "value": 393.82
        },
        {
            "time": 1662067500,
            "value": 393.82
        },
        {
            "time": 1662067560,
            "value": 394.05
        },
        {
            "time": 1662067620,
            "value": 394.25
        },
        {
            "time": 1662067680,
            "value": 394.17
        },
        {
            "time": 1662067740,
            "value": 394.05
        },
        {
            "time": 1662067800,
            "value": 394.29
        },
        {
            "time": 1662067860,
            "value": 394.44
        },
        {
            "time": 1662067920,
            "value": 394.54
        },
        {
            "time": 1662067980,
            "value": 394.45
        },
        {
            "time": 1662068040,
            "value": 394.64
        },
        {
            "time": 1662068100,
            "value": 394.52
        },
        {
            "time": 1662068160,
            "value": 394.87
        },
        {
            "time": 1662068220,
            "value": 394.57
        },
        {
            "time": 1662068280,
            "value": 394.31
        },
        {
            "time": 1662068340,
            "value": 394.18
        },
        {
            "time": 1662068400,
            "value": 394.25
        },
        {
            "time": 1662068460,
            "value": 394.41
        },
        {
            "time": 1662068520,
            "value": 394.34
        },
        {
            "time": 1662068580,
            "value": 394.42
        },
        {
            "time": 1662068640,
            "value": 394.19
        },
        {
            "time": 1662068700,
            "value": 393.93
        },
        {
            "time": 1662068760,
            "value": 394.32
        },
        {
            "time": 1662068820,
            "value": 394.08
        },
        {
            "time": 1662068880,
            "value": 393.81
        },
        {
            "time": 1662068940,
            "value": 393.61
        },
        {
            "time": 1662069000,
            "value": 393.76
        },
        {
            "time": 1662069060,
            "value": 393.69
        },
        {
            "time": 1662069120,
            "value": 393.94
        },
        {
            "time": 1662069180,
            "value": 394.04
        },
        {
            "time": 1662069240,
            "value": 394.22
        },
        {
            "time": 1662069300,
            "value": 394.43
        },
        {
            "time": 1662069360,
            "value": 394.38
        },
        {
            "time": 1662069420,
            "value": 394.11
        },
        {
            "time": 1662069480,
            "value": 394.03
        },
        {
            "time": 1662069540,
            "value": 394.21
        },
        {
            "time": 1662069600,
            "value": 394.11
        },
        {
            "time": 1662069660,
            "value": 394.32
        },
        {
            "time": 1662069720,
            "value": 394.61
        },
        {
            "time": 1662069780,
            "value": 394.51
        },
        {
            "time": 1662069840,
            "value": 394.6
        },
        {
            "time": 1662069900,
            "value": 394.24
        },
        {
            "time": 1662069960,
            "value": 394.64
        },
        {
            "time": 1662070020,
            "value": 394.81
        },
        {
            "time": 1662070080,
            "value": 395.06
        },
        {
            "time": 1662070140,
            "value": 395.26
        },
        {
            "time": 1662070200,
            "value": 395.13
        },
        {
            "time": 1662070260,
            "value": 395.13
        },
        {
            "time": 1662070320,
            "value": 395.26
        },
        {
            "time": 1662070380,
            "value": 395.36
        },
        {
            "time": 1662070440,
            "value": 395.38
        },
        {
            "time": 1662070500,
            "value": 395.28
        },
        {
            "time": 1662070560,
            "value": 395.14
        },
        {
            "time": 1662070620,
            "value": 394.7
        },
        {
            "time": 1662070680,
            "value": 395.01
        },
        {
            "time": 1662070740,
            "value": 394.72
        },
        {
            "time": 1662070800,
            "value": 394.63
        },
        {
            "time": 1662070860,
            "value": 394.26
        },
        {
            "time": 1662070920,
            "value": 394.31
        },
        {
            "time": 1662070980,
            "value": 394.49
        },
        {
            "time": 1662071040,
            "value": 394.87
        },
        {
            "time": 1662071100,
            "value": 394.58
        },
        {
            "time": 1662071160,
            "value": 394.34
        },
        {
            "time": 1662071220,
            "value": 394.22
        },
        {
            "time": 1662071280,
            "value": 394.17
        },
        {
            "time": 1662071340,
            "value": 394.14
        },
        {
            "time": 1662071400,
            "value": 393.93
        },
        {
            "time": 1662071460,
            "value": 394.14
        },
        {
            "time": 1662071520,
            "value": 393.78
        },
        {
            "time": 1662071580,
            "value": 393.83
        },
        {
            "time": 1662071640,
            "value": 394.03
        },
        {
            "time": 1662071700,
            "value": 393.87
        },
        {
            "time": 1662071760,
            "value": 393.92
        },
        {
            "time": 1662071820,
            "value": 394.14
        },
        {
            "time": 1662071880,
            "value": 394.31
        },
        {
            "time": 1662071940,
            "value": 394.37
        },
        {
            "time": 1662072000,
            "value": 394.23
        },
        {
            "time": 1662072060,
            "value": 394.35
        },
        {
            "time": 1662072120,
            "value": 394.06
        },
        {
            "time": 1662072180,
            "value": 394.23
        },
        {
            "time": 1662072240,
            "value": 394.27
        },
        {
            "time": 1662072300,
            "value": 394.47
        },
        {
            "time": 1662072360,
            "value": 394.72
        },
        {
            "time": 1662072420,
            "value": 394.2
        },
        {
            "time": 1662072480,
            "value": 394.33
        },
        {
            "time": 1662072540,
            "value": 394.29
        },
        {
            "time": 1662072600,
            "value": 394.34
        },
        {
            "time": 1662072660,
            "value": 394.91
        },
        {
            "time": 1662072720,
            "value": 394.79
        },
        {
            "time": 1662072780,
            "value": 395
        },
        {
            "time": 1662072840,
            "value": 395.23
        },
        {
            "time": 1662072900,
            "value": 395.44
        },
        {
            "time": 1662072960,
            "value": 395.88
        },
        {
            "time": 1662073020,
            "value": 395.9
        },
        {
            "time": 1662073080,
            "value": 395.94
        },
        {
            "time": 1662073140,
            "value": 396.26
        },
        {
            "time": 1662073200,
            "value": 396.29
        },
        {
            "time": 1662073320,
            "value": 396.24
        },
        {
            "time": 1662073380,
            "value": 396.24
        },
        {
            "time": 1662073500,
            "value": 396.28
        },
        {
            "time": 1662073560,
            "value": 396.32
        },
        {
            "time": 1662073620,
            "value": 396.45
        },
        {
            "time": 1662073680,
            "value": 396.41
        },
        {
            "time": 1662073740,
            "value": 396.4
        },
        {
            "time": 1662073800,
            "value": 396.35
        },
        {
            "time": 1662073860,
            "value": 396.03
        },
        {
            "time": 1662073920,
            "value": 395.94
        },
        {
            "time": 1662073980,
            "value": 395.88
        },
        {
            "time": 1662074040,
            "value": 395.69
        }
    ],
    "priceDataMom": [
        {
            "time": 1662049800,
            "value": 392.8
        },
        {
            "time": 1662049860,
            "value": 393.25
        },
        {
            "time": 1662049920,
            "value": 392.6
        },
        {
            "time": 1662049980,
            "value": 392.66
        },
        {
            "time": 1662050040,
            "value": 392.29
        },
        {
            "time": 1662050100,
            "value": 392.11
        },
        {
            "time": 1662050160,
            "value": 392.39
        },
       
    ],
    "callDataMom": [
        {
            "time": 1662049800,
            "value": 392.8
        },
        {
            "time": 1662049860,
            "value": 393.25
        },
        {
            "time": 1662049920,
            "value": 392.6
        },
        {
            "time": 1662049980,
            "value": 392.66
        },
        {
            "time": 1662050040,
            "value": 392.29
        },
        {
            "time": 1662050100,
            "value": 392.11
        },
        {
            "time": 1662050160,
            "value": 392.39
        },
       
    ],
    "putDataMom": [
        {
            "time": 1662049800,
            "value": 392.8
        },
        {
            "time": 1662049860,
            "value": 393.25
        },
        {
            "time": 1662049920,
            "value": 392.6
        },
        {
            "time": 1662049980,
            "value": 392.66
        },
        {
            "time": 1662050040,
            "value": 392.29
        },
        {
            "time": 1662050100,
            "value": 392.11
        },
        {
            "time": 1662050160,
            "value": 392.39
        },
       
    ]
}