import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';


const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',

    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',

    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',

    price_color: 'white',
}



const LightWeightChart = (props) => {
    //console.log(props)
    let callData = props.callData
    let putData = props.putData
    let priceData = props.priceData
    const markers = props.markers ? props.markers : null
    const chartContainerRef = useRef();
    useEffect(() => {
        
        
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: 300,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            }
        });

        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.call_areaTopColor,
        }).setData(callData);
        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.put_areaTopColor,
        }).setData(putData);

        const preiceSeries = chart.addLineSeries({
            priceScaleId: 'left',
            color: colors.price_color
        })
        preiceSeries.setData(priceData)

        if (markers) {
            if (markers.data) {
                const markerProps = {
                    'short': {
                        position: 'aboveBar',
                        text: 'PUT',
                        shape: 'arrowDown',
                        color: '#E74C3C',
                    },
                    'long': {
                        position: 'belowBar',
                        text: 'CALL',
                        shape: 'arrowUp',
                        color: '#229954'
                    }
                }


                const markersData = markers.data
                let markersArr = []
                markersData.forEach(marker => {
                    markersArr.push({
                        time: marker.timestamp,
                        position: markerProps[marker.type].position,
                        color: markerProps[marker.type].color,
                        shape: markerProps[marker.type].shape,
                    })
                });
                preiceSeries.setMarkers(markersArr);
            }
        }

        chart.timeScale().fitContent()
        const handleResize = () => {
            //chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            //console.log(chartContainerRef)
                if (chartContainerRef) {
                    if (chartContainerRef.current) {
                        if (chartContainerRef.current.clientWidth) {
                            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
                            //chart.timeScale().fitContent()
                        }
                    }
                }
            
        };
        window.addEventListener('resize', handleResize, false);

        //resize();
        //window.addEventListener("resize", resize, false);
        /*function resize() {
            //console.log(chartContainerRef.current.clientWidth)
            if(chartContainerRef){
                if(chartContainerRef.current){
                    if(chartContainerRef.current.clientWidth){
                        chart.applyOptions({  width: chartContainerRef.current.clientWidth });
                        chart.timeScale().fitContent()
                        //setTimeout(() => chart.timeScale().fitContent(), 0);
                    }
                }
            }
        }*/

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [callData, putData, priceData]);


    return (
        <>
            <div
                ref={chartContainerRef}
            />
        </>
    )
}

export default LightWeightChart;