import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import AlaSQL from 'alasql';

const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',

    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',

    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',

    price_color: 'white',
}

const LevelLineStyle = 0 // 0- solid
const dRu = {
    month_1: 'Мес 1 ',
    week_1: 'Нед 1',
    day_1: 'День 1',
    hour_1: '1 час',
    hour_4: '4 часа',
    minute_5: '5 мин',
    minute_15: '15 мин',
    minute_30: '30 мин'
}
const dWd = {
    month_1: 4,
    week_1: 3.5,
    day_1: 3,
    hour_4: 2.5,
    hour_1: 2,
    minute_30: 1.5,
    minute_15: 1,
    minute_5: 0.5,
}

const LightWeightChartLevels = (props) => {
    //console.log('LightWeightChartLevels')


    const priceData = props.priceData
    const tickerData = props.tickerData
    const levelsSelected = props.levelsSelected
    //console.log(priceData) 

    const chartContainerRef = useRef();
    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            /*if(chartContainerRef){
                if(chartContainerRef.current){
                    if(chartContainerRef.current.clientWidth){
                        chart.applyOptions({  width: chartContainerRef.current.clientWidth });
                        //chart.timeScale().fitContent()
                    }
                }
            }*/
        };
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: 350,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true
            },
            rightPriceScale: {
                visible: false
            },
            timeScale: {
                visible: true,
                timeVisible: true,
            }
        });


        //const chartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };
        //const chart = createChart(document.getElementById('container'), chartOptions);
        const priceSeries = chart.addLineSeries({
            color: 'white'
            //topColor: '#2962FF', 
            //bottomColor: 'rgba(41, 98, 255, 0.28)' 
        });
        //if (priceData) {
        //if (priceData.length > 0) {
        //console.log('pricedata + ')
        priceSeries.setData(priceData)
        //}
        //}
        let lastPriceSQL = AlaSQL(`select * from ? order by time desc limit 2`, [priceData])
        let lastPrice = 0
        if (lastPriceSQL.length > 0) {
            lastPrice = lastPriceSQL[0].value
        }

        //console.log(lastPriceSQL)

        if (levelsSelected) {
            if (levelsSelected.length > 0) {
                if (tickerData) {
                    if (tickerData.levelData) {

                        const levels = levelsSelected
                        const levelsData = tickerData.levelData
                        //console.log(levels) //ok
                        //console.log(levelsData) //ok

                        levels.forEach(level => {
                            let ldata = levelsData[level]

                            ldata.forEach(lrow => {
                                let lvalue = lrow.value
                                let ltype = lvalue > lastPrice ? 'ress' : 'supp'
                                let lcolor = lvalue > lastPrice ? '#e24648b3' : '#21cc6db3'
                                let ltitle = lvalue > lastPrice ? 'Сопротивление' : 'Поддержка'
                                const levelLine = {
                                    price: lvalue,
                                    color: lcolor,
                                    lineWidth: dWd[level],
                                    lineStyle: LevelLineStyle,
                                    axisLabelVisible: true,
                                    title: `${lvalue} ${ltitle} (${dRu[level]})`,

                                };
                                priceSeries.createPriceLine(levelLine);

                                
                            })
                        })

                    }
                }
            }
        }
        /*if (props.GlobalShowLevel) {
            if (props.levelData) {
                const levelData = data.levelData
                if (levelData.length > 0) {
                    levelData.forEach(level => {
                        const levelLine = {
                            price: level.value,
                            color: '#26a69a',
                            lineWidth: 2,
                            lineStyle: 2, // LineStyle.Dashed
                            axisLabelVisible: true,
                            title: `${level.value} Поддержка (Мес ${1})`,
                        };
                        priceSeries.createPriceLine(levelLine);
                    })

                }
            }
        }*/

        
        priceSeries.priceScale().applyOptions({
            autoScale: true,
        })

        chart.timeScale().fitContent();
        //chart.priceScale().fitContent();
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [priceData, levelsSelected, tickerData]);


    return (<div
        ref={chartContainerRef}
    />)
}

export default LightWeightChartLevels;