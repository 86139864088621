import { Button, notification, Space } from 'antd';
import React from 'react';
import { pixelCompleteRegistration } from './pixel';
import { doc, setDoc, updateDoc, getFirestore, collection, addDoc, where, query, getDoc, getDocs } from "@firebase/firestore"
import { initializeApp } from "@firebase/app";
import moment from 'moment';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
  signOut,
} from '@firebase/auth';
import { doesNotMatch } from 'assert';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBSb5DBCf0GtM9wLrsG4rLIIrACUVoDQ-s",
  authDomain: "spy2money.firebaseapp.com",
  projectId: "spy2money",
  storageBucket: "spy2money.appspot.com",
  messagingSenderId: "236977803516",
  appId: "1:236977803516:web:352b07ad47e562faee4779",
  measurementId: "G-4XQ8ZBKMH8"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

const db = getFirestore();

//const provider = new firebase.auth.GoogleAuthProvider();
const provider = new GoogleAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });

const auth = getAuth();
export default firebase;


const openNotificationWithIcon = (e) => {
  notification[e.type]({
    message: e.title,
    description: e.description
  });
};


export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(getAuth(), provider);
    const user = res.user;
    const userRef = collection(db, "users");
    const result = await getDocs(query(userRef, where("uid", "==", user.uid)));
    const nowUtc = Number(moment().utc().unix())

    if (result.empty) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        isNewUser: true,
        created: nowUtc
      });
      pixelCompleteRegistration(user.email)
      //console.log('new user')
    } else {
      //console.log('old user')

      //its work
      /*result.forEach(async (d) => {
        //const r = { id: d.id, data: d.data() }
        const docRef = doc(db, 'users', d.id);
        await updateDoc(docRef, {
          isNewUser: false
        });
      });*/
    }
  } catch (err) {
    console.log(err.message)
  }
};


export const FsignInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    //console.log(err)

    if (err.message.match('invalid-email')) {
      openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Неправильная электронная почта.' })
    } else {
      openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Неправильная электронная почта или пароль.' })
      console.log(err.message)
    }
  }
};

export const AddTestDoc = async () => {
  try {
    //console.log('AddTestDoc')
    //const res = await createUserWithEmailAndPassword(auth, email, password);
    //const user = res.user;
    await addDoc(collection(db, "test"), {
      test: true,
    });

  } catch (err) {
    console.log(err.message)
  }
};


export const getFirestoreLevels = async () => {
  const colRef = collection(db, "levels");
  let r = []
  try {
    let docsSnap = await getDocs(colRef);
    docsSnap.forEach(async doc => {
      const ticker = doc.id
      const tickerRef = collection(doc, ticker);
      try {
        let snap = await getDocs(tickerRef);
        snap.forEach(async doc => {
          console.log(doc.id)

        });
      } catch (e) {
        console.log(e)
      }

    });
  } catch (e) {
    console.log(e)
  }
  return r
};

export const FindDocByUid = async (uid) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("uid", "==", uid));
  let r = {}
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      r = { id: doc.id, data: doc.data() }
    });
  } catch (err) {
    console.log(err.message)
  }
  return r
};


export const UpdateDocByUid = async (props) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("uid", "==", props.uid));
  let r = {}
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (d) => {
      r = { id: d.id, data: d.data() }

      const docRef = doc(db, 'users', d.id);
      const updateTimestamp = await updateDoc(docRef, props.updateData);

    });
  } catch (err) {
    console.log(err.message)
  }
  return r
};

export const getUserDocById = async (uid) => {
  //console.log('getUserDocById')
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("uid", "==", uid));
  let r = {}
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (d) => {
      r = { id: d.id, data: d.data() }
      //console.log(d.data())
      r = {
        status: 'ok',
        data: d.data()
      }
    });
  } catch (err) {
    r = {
      status: 'ok',
      message: err.message
    }
  }
  return r
}


export const getTranslationConfig = async (uid) => {
  //console.log('getUserDocById')
  const translationsRef = collection(db, "translations");
  const q = query(translationsRef, where("tid", "==", uid));
  let r = {}
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (d) => {
      r = { id: d.id, data: d.data() }
      //console.log(d.data())
      r = {
        status: 'ok',
        data: d.data()
      }
    });
  } catch (err) {
    r = {
      status: 'ok',
      message: err.message
    }
  }
  return r
}

export const getPageConfigs = async (uid) => {
  //console.log('getUserDocById')
  const translationsRef = collection(db, "page_configs");
  const q = query(translationsRef, where("id", "!=", ' '));
  let r = {}
  try {
    const querySnapshot = await getDocs(q);
    let outData = {}
    querySnapshot.forEach(async (d) => {
      const docData = d.data()
      const id = docData.id
      outData[id] = docData
    });
    r = {
      status: 'ok',
      data: outData
    }
  } catch (err) {
    r = {
      status: 'error',
      message: err.message
    }
  }
  return r
}

export const UpdateDocByUid0 = async (uid) => {
  const doc_id = "cUir19V5u3i6RoMkc85g"
  const docRef = doc(db, 'users', doc_id);
  const updateTimestamp = await updateDoc(docRef, {
    name3: '2'
  });
  return true
};


export const registerWithEmailAndPassword = async (name, email, password, phone) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const nowUtc = Number(moment().utc().unix())
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      displayName: name,
      name: name,
      authProvider: "local",
      phone: phone,
      email,
      created: nowUtc
    });

  } catch (err) {
    //alert(err.message);
    console.log(err.message)
    if (err.message.match('email-already-in-use')) {
      openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Указанная электронная почта уже используется.' })
    } else {
      if (err.message.match('invalid-email')) {
        openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Неправильная электронная почта.' })
      }
      else {
        openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: err.message })
      }
    }

  }
};

// const sendPasswordResetEmail = async (email) => {
//   try {
//     await auth.sendPasswordResetEmail(email);
//     alert("Password reset link sent!");
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
// const logout = () => {
//   auth.signOut();
// };
