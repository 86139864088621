import moment from "moment";
const displayFormat = 'DD.MM.yyyy';
const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'DD-MM-YYYY hh-mm-ss';
const startDayTime = 'T00:00:00.000'
const endtDayTime = '23:59:59'
const unix_day = 86399

export function dateToUnix(date) {
    return moment(date).unix();
}

export function getMomentDateByAction(m_format_work, m_format_visual, action, type) {
    let work = "";
    let visual = "";
    let m = moment().utc();
    if (action.match("subtract")) {
        let a = action.split(":");
        let b = a[1].split("_");
        m = m.subtract(Number(b[0]), b[1]);
    }
    if (action.match("startOf")) {
        let a = action.split(":");
        m = m.startOf(a[1]);
    }
    if (action.match("endOf")) {
        let a = action.split(":");
        m = m.endOf(a[1]);
    }
    if (action == "this") {
    }

    if (action === "previos_month_start") {
        m = m.subtract(1, 'months').startOf('month')
    }
    if (action === "previos_month_end") {
        m = m.subtract(1, 'months').endOf('month')
    }

    let a = 0;
    if (type === 'to') {
        a = unix_day
    }
    let b = m.format(m_format_work)
    let c = String(b) + startDayTime
    let u = moment.utc(c).unix() + a
    return {
        work: m.format(m_format_work),
        visual: m.format(m_format_visual),
        unix: u
    };
}

let today_start_utc = moment().utc().format(dateFormat);
let today_start_utc_work = String(today_start_utc) + startDayTime

let today_start_utc_unix = moment(today_start_utc_work).unix()
let today_end_utc_unix = today_start_utc_unix + unix_day

let today_start_work = moment(today_start_utc_work).format(dateFormat);
let today_end_work = moment(today_start_utc_work).format(dateFormat);
let today_start_display = moment(today_start_utc_work).format(displayFormat);
let today_end_display = moment(today_start_utc_work).format(displayFormat);

export function initDateRange() {

    let local_dr_options_obj = {
        today: {
            value: "today",
            label: "Сегодня",
            action: {
                date_from: "now",
                date_to: "now",
            },
        },
        yesterday: {
            value: "yesterday",
            label: "Вчера",
            action: {
                date_from: "subtract:1_days",
                date_to: "subtract:1_days",
            },
        },
        last_3_days_include_today: {
            value: "last_3_days_include_today",
            label: "Последние 3 дня",
            action: {
                date_from: "subtract:2_days",
                date_to: "now",
            },
        },

        last_7_days_include_today: {
            value: "last_7_days_include_today",
            label: "Последние 7 дней",
            action: {
                date_from: "subtract:6_days",
                date_to: "now",
            },
        },

        last_14_days_include_today: {
            value: "last_14_days_include_today",
            label: "Последние 14 дней",
            action: {
                date_from: "subtract:14_days",
                date_to: "now",
            },
        },

        current_week: {
            value: "current_week",
            label: "Текущая неделя",
            action: {
                date_from: "startOf:isoWeek",
                date_to: "endOf:isoWeek",
            },
        },
        current_month: {
            value: "current_month",
            label: "Текущий месяц",
            action: {
                date_from: "startOf:month",
                date_to: "endOf:month",
            },
        },
        previos_month: {
            value: "previos_month",
            label: "Предыдущий месяц",
            action: {
                date_from: "previos_month_start",
                date_to: "previos_month_end",
            },
        },

        current_quarter: {
            value: "current_quarter",
            label: "Текущий квартал",
            action: {
                date_from: "startOf:quarter",
                date_to: "endOf:quarter",
            },
        },
        fixed: {
            value: "fixed",
            label: "Свой диапазон",
            action: {
                date_from: "this",
                date_to: "this",
            },
        },
    };

    let m_format_work = "YYYY-MM-DD";
    let m_format_visual = "DD.MM.YYYY";
    const defaultDateFormat = 'DD-MM-YYYY';
    let state_dr_options = [];
    let state_dr_options_obj = {};

    let obj = local_dr_options_obj
    Object.keys(obj).map((item, i) => {
        let option = obj[item];
        let action = option.action;
        let date_from = getMomentDateByAction(
            m_format_work,
            m_format_visual,
            action.date_from,
            'from'
        );
        let date_to = getMomentDateByAction(
            m_format_work,
            m_format_visual,
            action.date_to,
            'to'
        );
        let value = option.value;
        let caption = "error";
        if (value === "fixed") {
            caption = "Выберите свой диапазон";
        } else {
            caption = date_from.visual + " - " + date_to.visual;
        }

        let visual = {
            date_from: date_from.visual,
            date_to: date_to.visual,
            caption: caption,
        };
        let unix = {
            from: date_from.unix,
            to: date_to.unix,
        }

        const dwFrom = date_from.work
        const dwTo = date_to.work
        const mFromTo = [
            moment(moment(dwFrom), defaultDateFormat),
            moment(moment(dwTo), defaultDateFormat)
        ]
        //console.log(unix)
        state_dr_options_obj[item] = {
            from: dwFrom,
            to: dwTo,
            from_to: mFromTo,
            ...option,
            work: {
                date_from: date_from.work,
                date_to: date_to.work,
            },
            visual: visual,
            unix: unix
        }

        /*state_dr_options.push({
            ...option,
            work: {
                date_from: date_from.work,
                date_to: date_to.work,
            },
            visual: visual,
            unix: unix
        });*/
    })

    return state_dr_options_obj;
}

/*export function getDateSelectOptions() {
    let today = moment().utc().startOf('day')


    let today_start_utc = moment().utc().format(dateFormat);
    let today_start_utc_work = String(today_start_utc) + startDayTime
    let today_start_utc_unix = moment(today_start_utc_work).utc().unix()
    let today_end_utc_unix = today_start_utc_unix + unix_day
    let today_start_work = moment(today_start_utc_work).format(dateFormat);
    let today_end_work = moment(today_start_utc_work).format(dateFormat);
    let today_start_display = moment(today_start_utc_work).format(displayFormat);
    let today_end_display = moment(today_start_utc_work).format(displayFormat);

    let today_format = moment()
    let today_unix = moment(today_format).utc()
    console.log('today_unix ' + today_unix)

    let yesterday_start_utc = moment().subtract(1, 'days').utc().format(dateFormat);
    let yesterday_start_utc_work = String(yesterday_start_utc) + startDayTime
    let yesterday_start_utc_unix = moment(yesterday_start_utc_work).unix()

    let yesterday = moment().subtract(1, 'days')
    let last_3_days = moment().subtract(2, 'days')
    let last_3_days_unix = last_3_days.utc().unix()
    let last_7_days = moment().subtract(6, 'days')
    let last_7_days_unix = last_7_days.utc().unix()
    let current_month_from = moment().startOf('month')
    let current_month_to = moment().endOf('month')
    let options = {
        today: {
            value: 'today',
            name: 'Сегодня',
            work: {
                from: today_start_work,
                to: today_end_work,
            },
            display: {
                from: today_start_display,
                to: today_end_utc_unix,
                text: `${today_start_display} - ${today_end_display}`
            },
            unix: {
                from: today_start_utc_unix,
                to: today_end_utc_unix
            }
        },
        yesterday: {
            value: 'yesterday',
            name: 'Вчера',
            display: {
                from: yesterday,
                to: yesterday,
                text: `${yesterday.format('DD.MM.YYYY')} - ${yesterday.format('DD.MM.YYYY')}`
            },
            unix: {
                from: yesterday_start_utc_unix,
                to: today_unix
            }
        },
        last_3_days: {
            value: 'last_3_days',
            name: 'Последние 3 дня',
            display: {
                from: last_3_days,
                to: today,
            },
            unix: {
                from: last_3_days_unix,
                to: today_unix
            }
        },
        last_7_days: {
            value: 'last_7_days',
            name: 'Последние 7 дней',
            display: {
                from: last_7_days,
                to: today,
            },
            unix: {
                from: last_7_days_unix,
                to: today_unix
            }
        },
        last_30_days: {
            value: 'last_30_days',
            name: 'Последние 30 дней',
            display: {
                from: last_7_days,
                to: today,
            },
            unix: {
                from: last_7_days_unix,
                to: today_unix
            }
        },
        current_month: {
            value: 'current_month',
            name: 'Текущий месяц',
            display: {
                from: current_month_from,
                to: current_month_to,
            },
            unix: {
                from: last_7_days_unix,
                to: today_unix
            }
        }
    }
    return options
}*/
