import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, Navigate, useLocation, Routes, Route, Outlet } from 'react-router-dom'

import { getAuth } from '@firebase/auth';
import {
    Avatar, Image, Card, Col, Checkbox, Row, Layout,
    Input,
    Menu, Modal,
    notification,
    Dropdown,
    Radio,
    Select, Slider, Switch,
    Typography
} from "antd";
import {
    AlertOutlined,
    LogoutOutlined,
    SettingOutlined, FieldTimeOutlined,
    UserOutlined,
    WalletOutlined, AreaChartOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import './main.css'
import PageOptions from './Options'
import PageLevels from './Levels'
import Flow from './Flow'
import Test from './Test'
import Darkpool from './Darkpool';
//import Translation from './Translation';
import * as CONFIG from './config'
import * as STRS from './strings'
import Test0 from './Test0';
import { Button } from 'react-bootstrap';
import logo from '../img/logo.png'
import { getUserDocById, UpdateDocByUid } from "../services/firebase";
import signalSoundTest from '../pages/Files/signalSoundTest.wav'
import parse from 'html-react-parser';
import pay_usdt_trc_20 from '../pages/Files/usdt_trc_20.png'
import pay_card_kz from '../pages/Files/pay_card_kz.png'
import moment from 'moment';
import * as AppApi from '../services/api'
const { Header, Content, Sider } = Layout;
const { Paragraph, Text, Title } = Typography;
const auth = getAuth();
const pagePosition = 'main';

let trClicked = false

const Main = (props) => {
    //console.log(props)
    const user = props.user;
    const [userInfo] = useState(props.userInfo)
    const [translationConfig] = useState(props.translationConfig)
    const [pageConfigs] = useState(props.pageConfigs)
    const refMain = useRef();

    //console.log(userInfo)

    const [ToUpdatePage, setToUpdatePage] = useState(false)
    const [StorageLoaded, setStorageLoaded] = useState(false)
    const [MainSettings, setMainSettings] = useState(CONFIG.defaultMainSettings)
    const [DisplayName, setDisplayName] = useState(!user.name ? truncateName(user.email) : 'Loading..')

    //Modal trial
    const [ModalTrialOpen, setModalTrialOpen] = useState(false);

    //Modal tariff
    const [ModalPaymentsClosable, setModalPaymentsClosable] = useState(true);
    const [ModalPaymentsOpen, setModalPaymentsOpen] = useState(false);
    const [ModalPaymentsPaysPolicy, setModalPaymentsPaysPolicy] = useState(true);
    const [ModalPaymentsMode, setModalPaymentsMode] = useState('tarifs')
    const [ModalPaymentsPaysTarif, setModalPaymentsPaysTarif] = useState('12 месяцев')
    const [ModalPaymentsCheckVariance, setModalPaymentsCheckVariance] = useState(false)
    const [RubUsdVariance, setRubUsdVariance] = useState(null)
    const [KztUsdVariance, setKztUsdVariance] = useState(null)


    const [CheckExtensionActive, setCheckExtensionActive] = useState(false)
    const [CheckExtensionInterval] = useState(1800000) //1800000
    useEffect(() => {
        if (CheckExtensionActive) {
            const interval = setInterval(() => {
                //console.log('CheckExtensionActive')
                if (!ModalPaymentsOpen) {
                    setModalPaymentsOpen(true)
                    //console.log('open modal')
                } else {
                    //console.log('modal is open')
                }
            }, CheckExtensionInterval);
            return () => clearInterval(interval);
        }
    }, [CheckExtensionActive]);


    //Trial
    const [checkTrialActive, setCheckTrialActive] = useState(false)
    const [checkTrialActiveInterval] = useState(5000)

    async function getUserInfo() {
        const uid = user.uid
        const userDoc = await getUserDocById(uid)
        let r = {}
        try {
            r = { status: 'ok', data: userDoc }
        } catch (e) {
            console.log(e)
            r = { status: 'error', message: e }
        }
        return r
    }

    useEffect(() => {
        //console.log('useEffect1') 
        if (checkTrialActive) {
            const interval = setInterval(async () => {
                //console.log('check trial')
                let r = await getUserInfo()
                //console.log(r)
                try {
                    //r = { status: 'ok', data: userDoc }
                    if (r) {
                        if (r.status === 'ok') {
                            //console.log(r.status)
                            if (r.data.data.payments) {
                                if (r.data.data.payments.tariff) {
                                    const tariff = r.data.data.payments.tariff
                                    if (tariff === "w18198213" || tariff === "trial7") {
                                        //console.log('activated')
                                        setCheckTrialActive(false)
                                        setModalTrialOpen(false)
                                    }
                                }
                            } else {
                                console.log('error payments')
                            }
                        } else {
                            console.log('error r')
                        }
                    }
                } catch (e) {
                    console.log(e)
                }

            }, checkTrialActiveInterval);
            return () => clearInterval(interval);
        }
    }, [checkTrialActive]);

    const onClickTrialActivate = async () => {
        const uid = user.uid
        const mChatActionByTariff = CONFIG.moneyChat.tariffs_ru['trial']
        const mChatUrl = `https://t.me/aivix_bot?start=${mChatActionByTariff}--${uid}`
        setCheckTrialActive(true)
        window.open(mChatUrl)
    }

    //Payment Confirmed
    const [CheckPaymentConfirmedActive, setCheckPaymentConfirmedActive] = useState(false)
    const [CheckPaymentConfirmedInterval] = useState(5000)
    useEffect(() => {
        if (CheckPaymentConfirmedActive) {
            const interval = setInterval(async () => {
                //console.log('check payment')
                let r = await getUserInfo()
                try {
                    if (r) {
                        if (r.status === 'ok') {
                            if (r.data.data.payments) {
                                if (r.data.data.payments.tariff) {
                                    const tariff = r.data.data.payments.tariff
                                    if (tariff === CONFIG.moneyChat.tariffs_ru[ModalPaymentsPaysTarif]) {
                                        //console.log('activated')
                                        setCheckPaymentConfirmedActive(false)
                                        setModalPaymentsOpen(false)
                                    }
                                }
                            } else {
                                console.log('error payments')
                            }
                        } else {
                            console.log('error r')
                        }
                    }
                } catch (e) {
                    console.log(e)
                }

            }, CheckPaymentConfirmedInterval);
            return () => clearInterval(interval);
        }
    }, [CheckPaymentConfirmedActive]);

    //Я оплатил
    const onClickModalPaymentsPayPayed = () => {
        const uid = user.uid
        const mChatActionByTariff = CONFIG.moneyChat.tariffs_ru[ModalPaymentsPaysTarif]
        setCheckPaymentConfirmedActive(true)
        window.open(`https://t.me/aivix_bot?start=${mChatActionByTariff}--${valueModalPaysMethod}`)
    }


    useEffect(() => {
        if (CheckExtensionActive) {
            const interval = setInterval(() => {
                //console.log('CheckExtensionActive')
                if (!ModalPaymentsOpen) {
                    setModalPaymentsOpen(true)
                    //console.log('open modal')
                } else {
                    //console.log('modal is open')
                }
            }, CheckExtensionInterval);
            return () => clearInterval(interval);
        }
    }, [CheckExtensionActive]);

    function getStorageData() {
        let mSettings = {}
        try {
            let mainSettings = localStorage.getItem(`${pagePosition}_settings`)
            if (mainSettings) {
                mSettings = JSON.parse(mainSettings)
                if (!mSettings.options_updateMode) {
                    mSettings.options_updateMode = "1"
                }
            } else {
                mSettings = MainSettings
            }
        } catch (e) {
            console.log(e)
        }
        return mSettings
    }

    function saveStorageData(data) {
        localStorage.setItem(`${pagePosition}_settings`, JSON.stringify(data))
    }
    const [PaymentsOptionTariffsActive, setPaymentsOptionTariffsActive] = useState(true)
    useEffect(() => {
        let props = {}
        if (!StorageLoaded) {
            props = getStorageData()
            setMainSettings(props)
            setStorageLoaded(true)
            setDisplayName(!user.displayName ? user.email : user.displayName)
            getTariffs()
        }
    }, [])

    //check extension
    useEffect(() => {
        //console.log(userInfo)
        //setModalTrialOpen(true)
        if (userInfo) {
            if (userInfo.status === 'ok') {
                const data = userInfo.data
                //console.log('useEffect')
                //console.log(userInfo)
                const pay_status = userInfo.pay_status
                if (pay_status === 'need_extension') {
                    const nowUtc = Number(moment().utc().unix())
                    const timestamp_end = userInfo.data.payments.timestamp_end
                    if (nowUtc >= timestamp_end + 86400) {
                        //console.log('no close')
                        if (!PaymentsOptionTariffsActive) {
                            setModalPaymentsOpen(true)
                            setModalPaymentsClosable(false)
                        }
                    } else {
                        if (!PaymentsOptionTariffsActive) {
                            setModalPaymentsOpen(true)
                        }
                        setCheckExtensionActive(true)
                    }
                    //setModalPaymentsOpen(true)
                }
                if (pay_status === 'need_activate') {
                    setCheckExtensionActive(false)
                    setModalTrialOpen(true)
                }
            }
        }
    }, [userInfo, PaymentsOptionTariffsActive])

    const onChangeSignalsSound = (checked) => {
        let mainSettings = MainSettings;
        mainSettings.signalsSoundOn = checked
        setMainSettings({ ...MainSettings, signalsSoundOn: checked })
        if (checked) {
            let audio = new Audio(signalSoundTest);
            audio.volume = mainSettings.signalsSoundVolume / 100
            audio.play()
        }
        saveStorageData(mainSettings)
    };

    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const showSettingsModal = (props) => {
        setIsSettingsModalOpen(true)
    };
    const handleSettingsCancel = () => {
        setIsSettingsModalOpen(false);
    };


    function getUSDcource(e) {
        fetch(`https://api.exchangerate-api.com/v4/latest/${e}`, {
            method: 'GET'
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                if (resp) {
                    if (resp.rates) {
                        const rates = resp.rates
                        if (rates['USD']) {
                            const a = rates['USD']
                            //console.log(a)
                            if (e === 'RUB') {
                                setRubUsdVariance(a)
                            }
                            if (e === 'KZT') {
                                setKztUsdVariance(a)
                            }
                        } else {
                            console.log(`${e} usd not found`)
                        }
                    } else {
                        console.log('error rates')
                    }
                } else { console.log('error exchangerate resp') }
            })
    }

    const [ModalTariffsText, setModalTariffsText] = useState([])
    function getTariffs() {
        fetch(`https://script.google.com/macros/s/AKfycby83-3Xq6-LM5cFml-JAbJJCEJwlWkrKlaM9iQqm37YotPV6smwh67pVKBdBNR4VLxN_w/exec`, {
            method: 'GET'
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                setModalTariffsText(resp)
                setPaymentsOptionTariffsActive(false)
            })
    }

    const onClickTariffsCard = (e) => {
        //console.log(e)
        setModalPaymentsPaysTarif(CONFIG.moneyChat.tariffs_en[e])
        setModalPaymentsMode('pays')
    }
    const TariffsContent = (props) => {
        if (props) {
            if (props.data) {
                const data = props.data.tariffs
                const title = props.data.title
                return (<>
                    <Row justify="center"><Title>{title[0][0]}</Title></Row>
                    <Row justify="center" style={{ marginBottom: 25 }}>
                        <Col xl={{ span: 4 }} md={{ span: 4 }} xs={{ span: 0 }}>
                        </Col>
                        <Col xl={{ span: 16 }} md={{ span: 16 }} xs={{ span: 24 }}>
                            <Title level={4} style={{ textAlign: 'center' }}>{title[1][0]}</Title>
                        </Col>
                        <Col xl={{ span: 4 }} md={{ span: 4 }} xs={{ span: 0 }}>
                        </Col>
                    </Row>
                    {data.map(d => {
                        const descr = d.descr
                        const rows = d.rows
                        const price = d.price
                        return (
                            <Card className='card-tariffs' size='small' style={{ marginBottom: 25, width: '100%' }} onClick={e => onClickTariffsCard(d.tariff)}>
                                <Row >
                                    <Col xl={{ span: 5 }} md={{ span: 16 }} xs={{ span: 24 }}>
                                        <Row justify="center" >
                                            {descr.map((item, i) => {
                                                if (i == 0) {
                                                    return (<Col xl={{ span: 24 }} md={{ span: 16 }} xs={{ span: 8 }}>
                                                        <Title level={2}
                                                        >{item}</Title>
                                                    </Col>)
                                                }
                                                if (i == 1) {
                                                    return (<Col xl={{ span: 24 }} md={{ span: 16 }} xs={{ span: 16 }}>
                                                        <Title level={2}
                                                        >{item}</Title>
                                                    </Col>)
                                                }
                                            })}
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 14 }} md={{ span: 14 }} xs={{ span: 24 }}>
                                        {rows.map(item => {
                                            return (<Row>{parse(item)}</Row>)
                                        })}
                                    </Col>
                                    <Col xl={{ span: 5 }} md={{ span: 5 }} xs={{ span: 24 }}>
                                        {price.map((item, i) => {
                                            if (i === 0) {
                                                return (<Row justify="center"><Title level={2}>{item}</Title></Row>)
                                            }
                                            return (<Row justify="center">{item}</Row>)
                                        })}
                                    </Col>
                                </Row>
                            </Card>
                        )
                    })}
                </>)
            }
        }
    }

    const showModalPayments = (props) => {
        getTariffs()
        getUSDcource('RUB')
        getUSDcource('KZT')
        setModalPaymentsOpen(true)
    };

    const closeModalPayments = () => {
        if (ModalPaymentsClosable) {
            setModalPaymentsOpen(false)
        }
    };

    const testClick = () => {
        //console.log('test click')
        setModalPaymentsMode('pays')
        //setModalPaymentsOpen(false)
    }

    const onClickModalPaymentsPayBack = () => {
        setModalPaymentsMode('tarifs')
    }

    const onChangeModalPaymentsPaysTarifs = (e) => {
        setModalPaymentsPaysTarif(e)
    }
    const [valueModalPaysMethod, setValueModalPaysMethod] = useState('usdt_trc_20');
    const onChangeModalPaysMethod = (e) => {
        //console.log(e)
        setValueModalPaysMethod(e.target.value);
    };
    const commis = 1.05

    const onChangeModalPaymentsPaysPolicy = (e) => {
        //console.log(`checked = ${e.target.checked}`);
        setModalPaymentsPaysPolicy(e.target.checked)
    };

    const PaysPrice = {
        usdt_trc_20: {
            '12 месяцев': 65 * 12,
            '6 месяцев': 75 * 6,
            '3 месяца': 85 * 3,
            '1 месяц': 95,
            currency: ' $'
        },
        binance_usdt: {
            '12 месяцев': 65 * 12,
            '6 месяцев': 75 * 6,
            '3 месяца': 85 * 3,
            '1 месяц': 95,
            currency: ' $'
        },
        card_ru: {
            '12 месяцев': 65 * 12 * commis / RubUsdVariance,
            '6 месяцев': 75 * 6 * commis / RubUsdVariance,
            '3 месяца': 85 * 3 * commis / RubUsdVariance,
            '1 месяц': 95 * commis / RubUsdVariance,
            currency: ' руб.'
        },
        card_kz: {
            '12 месяцев': 65 * 12 * commis / KztUsdVariance,
            '6 месяцев': 75 * 6 * commis / KztUsdVariance,
            '3 месяца': 85 * 3 * commis / KztUsdVariance,
            '1 месяц': 95 * commis / KztUsdVariance,
            currency: ' тенге'
        }
    }

    const PaymentsPayedContent = (props) => {
        const btnPayDisabled = ModalPaymentsPaysPolicy
        return (<>
            <Button disabled={!btnPayDisabled}>test</Button>
        </>)
    }

    const PaymentsContent = (props) => {
        const strs = STRS.modalPayments
        if (ModalPaymentsMode === 'pays') {
            const Requisites = (props) => {
                if (props.method === 'usdt_trc_20') {
                    return (<>
                        <Row justify='center' style={{ marginBottom: 25 }}>
                            <Image
                                preview={true}
                                width={200}
                                src={pay_usdt_trc_20}
                            />
                        </Row>
                        <Row>
                            <Col span={8}>Адрес для пополнения:</Col>
                            <Col span={16}>TWYRpo7a4PcDg2iAVvRyMNkhHZqbVSoKfx</Col>
                        </Row>
                    </>)
                }

                if (props.method === 'binance_usdt') {
                    return (<>
                        <Row>
                            <Col span={8}>Binance ID :</Col>
                            <Col span={16}>237112542</Col>
                        </Row>
                    </>)
                }

                if (props.method === 'card_ru') {
                    return (<>
                        <Row justify='center' style={{ marginBottom: 25 }}>
                            <Image
                                preview={true}
                                width={200}
                                src={pay_card_kz}
                            />
                        </Row>
                        <Row>
                            <Col span={8}>Tinkoff :</Col>
                            <Col span={16}> <a target='_blank' href="https://www.tinkoff.ru/rm/rogozhkin.konstantin1/LbsEH38383">Оплатить по ссылке</a></Col>
                        </Row>
                        <Row>
                            <Col span={8}>Номер карты :</Col>
                            <Col span={16}>5280413751231247</Col>
                        </Row>
                    </>)
                }
                if (props.method === 'card_kz') {
                    return (<>

                        <Row>
                            <Col span={8}>Номер карты (Kaspi) :</Col>
                            <Col span={16}>4400430227257208</Col>
                        </Row>
                        <Row>
                            <Col span={8}>Имя :</Col>
                            <Col span={16}>KONSTANTIN ROGOZHKIN</Col>
                        </Row>
                    </>)
                }
                return (<>-</>)
            }

            return (<>
                <Row >
                    <Card size='small' style={{ marginBottom: 25, width: '100%' }}>
                        <Row>
                            <Col span={8}>Тариф</Col>
                            <Col span={16}>
                                <Select
                                    style={{ width: '100%', }}
                                    value={ModalPaymentsPaysTarif}
                                    defaultValue={ModalPaymentsPaysTarif ? ModalPaymentsPaysTarif : '12 месяцев'}
                                    options={[{
                                        value: '12 месяцев',
                                    }, {
                                        value: '6 месяцев',
                                    }, {
                                        value: '3 месяца',
                                    }, {
                                        value: '1 месяц',
                                    }]}
                                    onChange={onChangeModalPaymentsPaysTarifs}
                                ></Select>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Card size='small' title='Метод оплаты' style={{ marginBottom: 25, width: '100%' }}>
                    <Row>
                        <Col span={12}>
                            <Radio.Group
                                onChange={onChangeModalPaysMethod}
                                value={valueModalPaysMethod}>
                                <Radio value={'usdt_trc_20'}>USDT Tether (TRC-20)</Radio>
                                <br></br>
                                <Radio value={'binance_usdt'}>Binance ID / USDT</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <Radio.Group
                                onChange={onChangeModalPaysMethod}
                                value={valueModalPaysMethod}>

                                <Radio value={'card_ru'}>Карта RU банка (+5% комиссия)</Radio>
                                <br></br>
                                <Radio value={'card_kz'}>Карта KZ банка (+5% комиссия)</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Card>
                <Card size='small' title='Реквизиты' style={{ marginBottom: 25, width: '100%' }}>
                    <Requisites method={valueModalPaysMethod}></Requisites>
                </Card>

                <Card size='small' style={{ marginBottom: 25, width: '100%' }}>
                    <Row>
                        <Col span={8}>Сумма оплаты :</Col>
                        <Col span={16}>{Math.round(PaysPrice[valueModalPaysMethod][ModalPaymentsPaysTarif])}
                            {PaysPrice[valueModalPaysMethod]['currency']}

                        </Col>
                    </Row>
                </Card>




                <Row justify='center' style={{ marginBottom: 25 }}>
                    <Col align='right' xl={{ span: 8 }} md={{ span: 2 }} xs={{ span: 8 }}>
                        <Checkbox
                            checked={ModalPaymentsPaysPolicy}
                            onChange={onChangeModalPaymentsPaysPolicy}>{" "}
                        </Checkbox>
                    </Col>
                    <Col xl={{ span: 16 }} md={{ span: 22 }} xs={{ span: 16 }}>
                        <a target='_blank' href="https://spy2money.com/policy">Я принимаю политику и условия использования ресурса</a>
                    </Col>
                </Row>


                <Row justify='center'>
                    <Button className="ant-btn-primary ant-btn-lg"
                        onClick={onClickModalPaymentsPayBack}>Назад
                    </Button>
                    <Button className="ant-btn-primary ant-btn-lg"
                        disabled={!ModalPaymentsPaysPolicy}
                        onClick={onClickModalPaymentsPayPayed}>Я оплатил
                    </Button>
                </Row>

                <Row justify='center' style={{ marginTop: 25 }}>
                    После оплаты нажмите кнопку "Я оплатил" , перейдите в Telegram и нажмите кнопку "Start"
                </Row>



            </>)
        }
        if (ModalPaymentsMode === 'tarifs') {
            return (<>
                <TariffsContent data={ModalTariffsText}></TariffsContent>
                <Row justify="center">
                    <Button
                        className="ant-btn-primary ant-btn-lg"
                        onClick={testClick}><WalletOutlined /> Оплатить</Button>
                </Row>
            </>)
        }
    }


    let location = useLocation();
    let pathname = location.pathname
    let menu_default = String(pathname).split(/\//)[2]

    const [pathName] = useState(pathname)

    function onUserMenuClick(e) {
        let key = e.key
        switch (key) {
            case 'signOut':
                auth.signOut()
                return (<Navigate to="/login"></Navigate>)
            case 'settings':
                showSettingsModal()
                break
            case 'payments': showModalPayments(); break
            default: break;
        }
    }

    const userMenu = (
        <Menu
            theme="dark"
            onClick={(e) => onUserMenuClick(e)}
            items={[
                { key: 'settings', label: 'Настройки', icon: <SettingOutlined /> },
                {
                    key: 'payments', label: 'Оплата', icon: <SettingOutlined />,
                    disabled: PaymentsOptionTariffsActive
                },
                { key: 'signOut', label: 'Выход', icon: <LogoutOutlined /> }
            ]}
        />
    );

    const [SettingsMenuItemSelected, setSettingsMenuItemSelected] = useState('time')

    const onClickSettingsMenuItem = (e) => {
        setSettingsMenuItemSelected(e.key)
        setToUpdatePage(false)
    }

    const onChangeMSettTime = (e) => {
        const timestamp = CONFIG.SettingsTime.obj[e].timestamp
        let mainSettings = MainSettings;
        mainSettings.sessionStart = e
        mainSettings.sessionTimeStamp = timestamp
        saveStorageData(mainSettings)
        setMainSettings({ ...MainSettings, sessionStart: e, sessionTimeStamp: timestamp })
        setToUpdatePage(true)
    }

    const onChangeMSettOptionsUpdateMode = (e) => {

        let mainSettings = MainSettings;
        mainSettings.options_updateMode = e
        saveStorageData(mainSettings)
        setMainSettings({ ...MainSettings, options_updateMode: e })
        setToUpdatePage(true)
    }

    const onChangeSettingsSignalsVolume = (e) => {
        let mainSettings = MainSettings;
        mainSettings.signalsSoundVolume = e
        setMainSettings({ ...MainSettings, signalsSoundVolume: e })
        let audio = new Audio(signalSoundTest);
        audio.volume = e / 100
        audio.play()
        saveStorageData(mainSettings)
    }

    const onChangeSignalsNotinication = (checked) => {
        let mainSettings = MainSettings;
        mainSettings.signalsNotificationOn = checked
        setMainSettings({ ...MainSettings, signalsNotificationOn: checked })
        saveStorageData(mainSettings)
    };

    const onClickModalSettingsProfileSave = async () => {
        const a = document.getElementById('settings_profile_displayname').value
        const uid = props.user.uid
        try {
            const b = await UpdateDocByUid({ uid: uid, updateData: { name: a } })
            setDisplayName(a)
        } catch (e) {
            console.log(e)
        }
    }

    const onChangeModalSettingsProfileName = (e) => {
        setDisplayName(e)
    }

    const MainSettingsContent = (props) => {
        const settings = props.props
        let pos = SettingsMenuItemSelected
        let timeDefaultValue = '17:30'
        let timeValue = '17:30'

        let optionsUpdateModeDefaultValue = 'Обновлять вручную'
        let optionsUpdateModeValue = 'Обновлять вручную'


        if (settings) {
            if (settings.sessionStart) {
                timeValue = settings.sessionStart
            }

            if (settings.options_updateMode) {
                optionsUpdateModeValue = settings.options_updateMode
            }
        }

        if (pos === 'profile') {
            let tariff = ''
            let expired = ''
            let period = ''
            let extend_str = ''
            const uNow = moment().utc().unix()
            //console.log(uNow)
            if (userInfo) {
                if (userInfo.status === 'ok') {
                    if (userInfo.data) {
                        if (userInfo.data.payments) {
                            if (userInfo.data.payments.lastAction && userInfo.data.payments.extended_sec) {
                                const lastAction = userInfo.data.payments.lastAction
                                const extended_sec = userInfo.data.payments.extended_sec
                                const extended_days = extended_sec / 60 / 60 / 12
                                if (lastAction === 'extend' && (extended_sec > 0)) {
                                    extend_str = ` ( продлено на ${extended_days} дн. )`
                                }

                            }

                            if (userInfo.data.payments.tariff) {
                                if (userInfo.data.payments.descr) {
                                    tariff = userInfo.data.payments.descr
                                } else {
                                    tariff = CONFIG.moneyChat.tariffsCode2Ru[userInfo.data.payments.tariff]
                                }
                            }
                            if (userInfo.data.payments.timestamp_end) {
                                const e = userInfo.data.payments.timestamp_end
                                //console.log(e)
                                let b = ((((e - uNow) / 60) / 60) / 24).toFixed(1)
                                expired = `${b} дн.`
                            }
                            if (userInfo.data.payments.timestamp_start && userInfo.data.payments.timestamp_end) {
                                const e = userInfo.data.payments.timestamp_end
                                const s = userInfo.data.payments.timestamp_start

                                period = `${moment.unix(s).utc().format('YYYY-MM-DD HH:mm')} - ${moment.unix(e).utc().format('YYYY-MM-DD HH:mm')} (UTC)`
                            }
                        }
                    }
                }
            }
            return (<>
                <Card title='Тариф' size='small' style={{ marginBottom: 25, width: '100%' }}>
                    <Row>
                        <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Наименование:</Col>
                        <Col xl={{ span: 19 }} md={{ span: 12 }} xs={{ span: 24 }}>
                            {`${tariff} ${extend_str}`}
                        </Col>
                    </Row >
                    <Row>
                        <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Период:</Col>
                        <Col xl={{ span: 19 }} md={{ span: 12 }} xs={{ span: 24 }}>
                            {`${period}`}
                        </Col>
                    </Row >
                    <Row>
                        <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >До окончания:</Col>
                        <Col xl={{ span: 19 }} md={{ span: 12 }} xs={{ span: 24 }}>
                            {expired}
                        </Col>
                    </Row >
                </Card>




            </>)
        }
        /*
        <Card title='Основная информация' size='small' style={{ marginBottom: 25, width: '100%' }}>
                    <Row>
                        <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Имя</Col>
                        <Col xl={{ span: 19 }} md={{ span: 12 }} xs={{ span: 24 }}>
                            <Input id='settings_profile_displayname'
                                //prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Имя"
                                defaultValue={DisplayName}
                            //value={DisplayName}
                            //name="name"
                            //onChange={(e) => onChangeModalSettingsProfileName(e.target.value)}
                            />
                        </Col>
                    </Row >
                </Card>
        <Row justify='center'>
                           <Button
                                className="ant-btn-primary ant-btn-lg"
                                onClick={onClickModalSettingsProfileSave}
                            >Сохранить</Button>
                        </Row> 
        */
        if (pos === 'time') {
            return (<>
                <Row style={{ margin: 4 }}></Row>
                <Row>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Начало сессии</Col>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Select
                            defaultValue={timeDefaultValue}
                            style={{ width: '100%' }}
                            onChange={onChangeMSettTime}
                            value={timeValue}
                            options={CONFIG.SettingsTime.arr}
                        >
                        </Select>
                    </Col>
                </Row >
            </>)
        }
        if (pos === 'signals') {
            return (<>
                <Row style={{ margin: 4 }}></Row>
                <Row>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Уведомление</Col>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Switch
                            checked={MainSettings.signalsNotificationOn}
                            onChange={onChangeSignalsNotinication}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                        />
                    </Col>
                </Row >
                <Row style={{ margin: 4 }}></Row>
                <Row>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Звук</Col>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Switch
                            disabled={!MainSettings.signalsNotificationOn}
                            checked={MainSettings.signalsNotificationOn ? MainSettings.signalsSoundOn : MainSettings.signalsSoundOn}
                            onChange={onChangeSignalsSound}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                        />
                    </Col>
                </Row >
                <Row style={{ margin: 4 }}></Row>
                <Row>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >Громкость</Col>
                    <Col xl={{ span: 10 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Slider min={0} max={100}
                            //marks={{0: '0 %',100: '100 %'}}
                            disabled={!MainSettings.signalsNotificationOn || !MainSettings.signalsSoundOn}
                            onChange={onChangeSettingsSignalsVolume}
                            value={typeof MainSettings.signalsSoundVolume === 'number' ? MainSettings.signalsSoundVolume : 50}
                        />
                    </Col>
                </Row >

            </>)
        }
        if (pos === 'options') {
            return (<>
                <Row style={{ margin: 4 }}></Row>
                <Row>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }} >При открытии обновлять:</Col>
                    <Col xl={{ span: 5 }} md={{ span: 12 }} xs={{ span: 24 }}>
                        <Select
                            defaultValue={optionsUpdateModeDefaultValue}
                            style={{ width: '100%' }}
                            onChange={onChangeMSettOptionsUpdateMode}
                            value={optionsUpdateModeValue}
                            options={CONFIG.SettingsPageOptions.updateModes}
                        >
                        </Select>
                    </Col>
                </Row >
            </>)
        }
        return (<></>)
    }

    function truncateName(str) {
        if (str) {
            let str0 = str.split(' ')
            return str0[0]
        }
        return ''
    }
    //const [translationClicked, setTranslationClicked] = useState(false)

    /*
    const Translation = (props) => {
        if (!translationClicked) {
            console.log(props)
            //let trClicked = translationClicked
            if (userInfo.pay_status === 'activated') {
                //console.log(props.translationConfig.data.url)
                const translationUrl = props.translationConfig.data.url
                //if (!translationClicked && !trClicked) {
                 //   trClicked = true
                    //setTranslationClicked(true)
                    //window.open(translationUrl)

                    //return <Navigate to="/app/options" replace />;
                //}
            }
        }
        //return (<>Translation</>)
    }*/
    let navigate = useNavigate();

    useEffect(() => {
        //console.log(pathName)
        if (pathName.match('translation') && !trClicked) {
            if (userInfo.pay_status === 'activated') {
                if (!trClicked) {
                    //console.log('useEffect path')
                    const translationUrl = props.translationConfig.data.url
                    //navigate("options")
                    //window.open(translationUrl, "_blank")
                    //window.open("https://us02web.zoom.us/j/85065822576?pwd=WmZrZml2aGE1RXNsTFlKZFlrQ1hsdz09#success", "_blank")
                    window.location.assign(translationUrl);

                    //window.location.href = translationUrl;
                    //setTranslationClicked(true)
                    trClicked = true
                }

            } else {
                //console.log('need activate')
            }
        }
    }, [])


    const onClickLogOut = (e) => {
        auth.signOut()
        return (<Navigate to="/login"></Navigate>)
    }

    const openNotificationWithIcon = (e) => {
        notification[e.type]({
            message: e.title,
            description: e.description,
            duration: e.duration
        });
    };

    const [apiN, contextHolder] = notification.useNotification();
    const openNotification = (e) => {
        apiN.open({
            message: e.title,
            description: e.description,
            duration: e.duration,
        });
    };

    const onClickN = async (e) => {

        let a = await AppApi.getDataFromCH({
            body: `select * from OptionFlow.economic_events order by date`
        })
        if (a) {
            if (a.status === 'ok') {
                const data0 = a.data
                if (data0) {
                    if (data0.data) {
                        const data = data0.data
                        if (data.length > 0) {


                            data.forEach(row => {
                                const date = row.date
                                const description = row.event
                                openNotification({
                                    //type: 'info',

                                    title: <>
                                        <Row>{date}</Row>

                                    </>,
                                    description: <>

                                        <Row>{description}</Row>
                                        <Row>{description}</Row>
                                    </>,
                                    duration: 0,
                                })
                            })
                        }
                    }

                }
            }
        }
        console.log(a)


    }


    return (
        <>
            <Layout >
                <Header >
                    <Row>
                        <Col span={3}>
                            <Image
                                preview={false}
                                width={100}
                                src={logo}
                            />
                        </Col>
                        <Col span={18}>
                            <Menu
                                theme="dark"
                                mode="horizontal"
                                defaultSelectedKeys={[menu_default]}
                            >
                                <Menu.Item key="options">
                                    <span>Опционы</span>
                                    <Link to="options" />
                                </Menu.Item>
                                <Menu.Item key="levels">
                                    <span>Уровни</span>
                                    <Link to="levels" />
                                </Menu.Item>

                                <Menu.Item key="flow">
                                    <span>Мой поток</span>
                                    <Link to="flow" />
                                </Menu.Item>
                                <Menu.Item key="darkpool">
                                    <span>DarkPool</span>
                                    <Link to="darkpool" />
                                </Menu.Item>

                            </Menu>
                        </Col>

                        <Col span={3} align='left' xl={{ vissible_name: true }} xs={{ vissible_name: false }} >
                            <Modal
                                closable={false}
                                width="60%"
                                visible={ModalTrialOpen}
                                footer={null}
                                bodyStyle={{ height: '60%' }}
                            //onCancel={closeModalTrial}
                            >
                                <>
                                    <Row justify='center' style={{ paddingBottom: 15 }}>
                                        <Title level={4} style={{ textAlign: 'center' }} >
                                            {STRS.STRs.trial_d_p1}
                                        </Title >
                                    </Row>

                                    <Row justify='center' style={{ paddingBottom: 15 }}>
                                        <Title level={4} style={{ textAlign: 'center' }} >
                                            {STRS.STRs.trial_d_p2}
                                        </Title >
                                    </Row>

                                    <Row justify='center' style={{ paddingBottom: 15 }}>
                                        <Title level={4} style={{ textAlign: 'center' }} >
                                            {STRS.STRs.trial_d_p3}
                                        </Title >
                                    </Row>

                                    <Row justify='center'>
                                        <Button className="ant-btn-primary ant-btn-lg"
                                            onClick={onClickTrialActivate}>Активировать пробный режим</Button>
                                    </Row>
                                    <Row justify='center' style={{ marginTop: 30 }}>
                                        <Button className="ant-btn "
                                            onClick={onClickLogOut}>Выйти из платформы</Button>
                                    </Row>
                                </>
                            </Modal>

                            <Modal title="Оплата"
                                closable={ModalPaymentsClosable}
                                width='100%'
                                open={ModalPaymentsOpen}
                                footer={null}
                                style={{ top: -10 }}
                                bodyStyle={{ height: '70%' }}
                                onCancel={closeModalPayments}
                            >
                                <>
                                    <PaymentsContent props={MainSettings} />
                                    <Row justify='center' style={{ marginTop: 30 }}>
                                        <Button className="ant-btn "
                                            onClick={onClickLogOut}>Выйти из платформы</Button>
                                    </Row>
                                </>
                            </Modal>

                            <Modal title="Настройки"
                                width='70%'
                                open={isSettingsModalOpen}
                                footer={null}
                                bodyStyle={{ height: 500 }}
                                onCancel={handleSettingsCancel}>
                                <>
                                    <Layout >
                                        <Sider width={150} className="site-layout-background">
                                            <Menu
                                                onClick={(e) => onClickSettingsMenuItem(e)}
                                                theme="dark"
                                                mode="inline"
                                                style={{
                                                    height: '100%',
                                                    borderRight: 0,
                                                }}
                                                defaultSelectedKeys={['time']}
                                                value={SettingsMenuItemSelected}
                                                items={[
                                                    { key: 'profile', label: 'Профиль', icon: <UserOutlined /> },
                                                    { key: 'time', label: 'Время', icon: <FieldTimeOutlined /> },
                                                    { key: 'signals', label: 'Сигналы', icon: <AlertOutlined /> },
                                                    { key: 'options', label: 'Опционы', icon: <AreaChartOutlined /> },
                                                ]}
                                            />
                                        </Sider>
                                        <Layout style={{ paddingLeft: 10, backgroundColor: '#1f1f1f' }}>
                                            <MainSettingsContent props={MainSettings} />
                                        </Layout>
                                    </Layout>
                                </>
                            </Modal>

                            <Row>
                                <Col xl={{ span: 24 }} md={{ span: 0 }} xs={{ span: 0 }} >
                                    <Dropdown overlay={userMenu}    >
                                        <Button className='user-button'
                                            style={{ width: '100%' }} size='small' >
                                            <Avatar src={user.photoURL} style={{ width: 32 }} />{"  "}
                                            {truncateName(!user.displayName ? user.email : user.displayName)
                                                //DisplayName
                                            }
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col xl={{ span: 0 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                    <Dropdown overlay={userMenu}    >
                                        <Button className='user-button'
                                            style={{ width: '100%' }} size='small' >
                                            <Avatar src={user.photoURL} style={{ width: 32 }} />
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Header>

                <Content style={{ padding: '0 10px', backgroundColor: 'black' }}>
                    <Routes>
                        <Route path="options" element={<PageOptions
                            toUpdatePage={ToUpdatePage}
                            settings={MainSettings}
                            userInfo={userInfo}
                            translationConfig={translationConfig}
                            pageConfigs={pageConfigs}
                        ></PageOptions>} />
                        <Route path="flow" element={<> <Flow /> </>} />
                        <Route path="test" element={<><Test /></>} />
                        <Route path="darkpool" element={<><Darkpool></Darkpool></>} />
                        <Route path="levels" element={<> <PageLevels /> </>} />
                    </Routes>
                </Content>
            </Layout>
            <Outlet />
        </>
    )
}
//<Route path="test0" element={<><Test0 user={user} userInfo={userInfo}></Test0></>} />
/*
<Route path="translation" element={<Translation
                            userInfo={userInfo}
                            translationConfig={translationConfig}
                        ></Translation>} />
*/
export { Main };