import React, { useState } from "react"
import { Typography } from 'antd'
import GaugeChart from 'react-gauge-chart'
//import GaugeChart from 'react-advanced-gauge-chart'

const { Text } = Typography;
const chartStyle = {
    margin: 0,
    //height: '100%'
}
const GaugeChartMetr = (props) => {

    const colors = props.colors ? props.colors : { from: "#E74C3C", to: "#229954" }
    const arcWidth = props.arcWidth ? props.arcWidth : 0.3
    const percent = props.percent ? Number(props.percent) : 0

    const Title = (e) => {
        if (e.titleParams) {
            if (e.titleParams.enable) {
                if (e.titleParams.pos === e.pos) {
                    return (< Text type="secondary" >{e.titleParams.value}</Text>)
                }
            }
        }
        return (<></>)
    }

    return (
        <>
            <Title titleParams={props.title} pos="top"></Title>
            <GaugeChart
                animate={false}
                style={chartStyle}
                id={"gauge-chart-" + props.id}
                nrOfLevels={props.nrOfLevels}
                colors={[colors.from, colors.to]}
                arcWidth={arcWidth}

                percent={percent}
            //formatTextValue={(value)=>{"CALL"}}
            />
            <Title titleParams={props.title} pos="both"></Title>
        </>
    )
}

export default GaugeChartMetr