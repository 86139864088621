import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import AlaSQL from 'alasql'
import moment from 'moment/moment';
import AntCard from '../../components/AntCard';
import AntLegend from '../../components/AntLegend';
import LightWeightChartExtra from '../../components/LightWeightChartExtra';
import LW from '../../components/LW';
import PageContainer from '../components/PageContainer';
import { URLs } from '../../urls';
import { STRs } from '../strings';

import * as Dates from '../../libraries/DatesOld'
import * as CONFIG from './config'
import * as QUERYS from './querys'


const { Title } = Typography;

const pagePosition = 'darkpool';

const Darkpool = (props) => {


    const [RangePickerValue, setRangePickerValue] = useState(null)
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(Dates.initDateRange())
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [ChartLoaded, setChartLoaded] = useState(false)
    const [ChartData, setChartData] = useState({
        callData: [{ time: 1659681000, value: 0 }],
        putData: [{ time: 1659681000, value: 0 }],
        priceData: [{ time: 1659681000, value: 0 }]
    })
    const [ParamsLoaded, setParamsLoaded] = useState(false)
    const [ParamsFilter, setParamsFilter] = useState(CONFIG.defaultParamsFilter)
    const [ParamsChart, setParamsChart] = useState(CONFIG.defaultParamsChart)
    const [ParamsStorage, setParamsStorage] = useState(CONFIG.gefaultParamsStorage)


    function getStorageData() {
        let props = {}
        let paramsFilter = localStorage.getItem(`${pagePosition}_params_filter`)
        if (paramsFilter) {
            props.paramsFilter = JSON.parse(paramsFilter)
            if (props.paramsFilter.timeData.value === 'fixed') {
            } else {
                props.paramsFilter.timeData = DateSelectOptionsRaw[props.paramsFilter.timeData.value]
            }
            setParamsFilter(props.paramsFilter)
        } else {
            props.paramsFilter = ParamsFilter
        }
        setParamsStorage(props)
        return props
    }

    function saveStorageData() {
        localStorage.setItem(`${pagePosition}_params_filter`, JSON.stringify(ParamsFilter))
    }

    const click = () => {
        getChartDarkData({ paramsFilter: ParamsFilter })
    }

    const [ChartDarkData, setChartDarkData] = useState([])

    function getChartDarkData(props) {
        setChartLoaded(true)
        fetch(URLs.bd_url, {
            method: 'POST',
            body: QUERYS.getChartDarkDataQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                let chart_dark_data = []
                if (resp.data) {
                    //console.log(resp)
                    if (resp.data.length > 0) {

                        let resp_0 = AlaSQL(`select date, 
                        round(CAST(val AS NUMBER)/1000000, 2) val from ? order by date `, [resp.data])
                        let resp_1 = AlaSQL(`select distinct date, val from ?  `, [resp_0])
                        let val_data = []
                        resp_1.forEach(row => {
                            val_data.push({
                                time: row.date,
                                value: row.val
                            })
                        })
                        chart_dark_data.push({
                            type: 'histogram',
                            color: '#FFB144',
                            align: 'right',
                            data: val_data
                        })

                        let resp_2 = AlaSQL('select distinct date, spy from ? order by date ', [resp.data])
                        let spy_data = []
                        resp_2.forEach(row => {
                            spy_data.push({
                                time: row.date,
                                value: row.spy
                            })
                        })
                        chart_dark_data.push({
                            type: 'line',
                            color: 'white',
                            align: 'left',
                            data: spy_data
                        })
                        //console.log(chart_dark_data)

                        setChartDarkData(chart_dark_data)
                    }
                }
            })
        setChartLoaded(true)
    }

    useEffect(() => {
        let props = {}
        if (!ParamsLoaded) {
            props = getStorageData()
            updateDataNew(props)
            setParamsLoaded(true)
        }
    }, [])

    function updateDataNew(props) {
        getChartDarkData(props)
    }

    return (
        <>
            <Row style={{ margin: 4 }}></Row>
            <Row gutter={4} style={{ marginLeft: 3 }}>
                <Col span={24}>
                    <Tooltip
                        overlayStyle={{ maxWidth: '70%' }}
                        placement="bottomLeft" title={STRs.darkpool_page_description}>
                        <Title level={4}>{STRs.darkpool_page_title}</Title>
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={24}>

                    <AntCard
                        id={'id'}
                        key={'dpool'}
                        title={STRs.darkpool_chart_title}
                        description={STRs.darkpool_chart_description}
                        size='small'
                        children={<>
                            <LightWeightChartExtra
                                timeVisible={false}
                                chartData={{
                                    type: 'more',
                                    data: ChartDarkData
                                }}
                            >
                            </LightWeightChartExtra>

                            <AntLegend type={'darkpool'}>
                            </AntLegend>
                        </>}
                    >
                    </AntCard>
                </Col>
            </Row>
        </>
    )
}

export default Darkpool