import AlaSQL from 'alasql';
import moment from "moment";

export function getFormatedDatesByPeriod(zone, format, date_start, date_end) {
    let dateArray = new Array();
    let currentDate = new Date(date_start);
    let count = 0
    while (currentDate <= new Date(date_end)) {
        dateArray.push({
            date: moment(currentDate).format(format),
            pos: count
        }
        );
        currentDate = moment(currentDate).add(1, 'days');
        count++
    }
    return dateArray;
}

const symbolXRating = {
    SPY: 1,
    QQQ: 2,
    SOXL: 3,
    LABU: 4,
    AAPL: 5,
    MSFT: 6,
    default: 7
}

export function getFlowLineBdQuery(props) {
    let t_range = props.time_range;
    let q = `select 
symbol,
unix,
toDateTime(concat(dateString,':00'))  datetime,
case
         when symbol ='SPY' then 1 
         when symbol ='QQQ' then 2 
         when symbol ='SOXL' then 3 
         when symbol ='LABU' then 4 
         when symbol ='AAPL' then 5 
         when symbol ='MSFT' then 6 
        else 10 
        end as rating ,
c, p, s

from (select symbol, unix, dateString, max(put) p, max(call) c, max(price) s
from (select * from (select *, toUnixTimestamp(concat(dateString,':00')) unix   from OptionFlow.FlowLine ) a  
where unix >= ${t_range.unix_from} and unix <= ${t_range.unix_to} ) a
group by symbol, unix, dateString ) a
        
        `
   // console.log(q)
    //date_add(HOUR, 10, toDateTime(concat(dateString,':00'))) datetime,
    return q
}

function summLine(props) {
    let ds_call = props.data
    //console.log('summ')
    let data_call_split = []
    ds_call.forEach(row => {
        let dObj = (row.datetime).split(' ')
        data_call_split.push({
            date: dObj[0],
            time: dObj[1],
            val: row[props.value]
        })
    })
    //console.log(data_call_split)
    const dates_d = AlaSQL(`select distinct date from ?`, [data_call_split])
    //console.log(dates_d)
    const date_start = dates_d[0].date
    //console.log(date_start)
    const query_start = `select * from ? where date = '${date_start}' order by time`
    //console.log(query_start)
    const arr_start = AlaSQL(query_start, [data_call_split])
    //console.log(arr_start)
    let last_val = arr_start[arr_start.length - 1].val
    //console.log(last_val)
    let new_out_arr = arr_start;
    for (let i = 1;
        //i < 2;
        i < dates_d.length;
        i++) {
        let date = dates_d[i].date
        //console.log(date)
        let query_next = `select * from ? where date = '${date}' order by time`
        //console.log(query_next)
        let arr_next = AlaSQL(query_next, [data_call_split])
        //console.log(arr_next)
        let new_arr = []
        arr_next.forEach(row => {
            new_arr.push({
                date: row.date,
                time: row.time,
                val: row.val + last_val
            })
        })
        new_out_arr = new_out_arr.concat(new_arr)
        //console.log('new_arr')
        //console.log(new_arr)
        last_val = new_arr[new_arr.length - 1].val
        //console.log(last_val)
    }
    //console.log('new_out_arr')
    //console.log(new_out_arr)
    let call_data_new = []
    new_out_arr.forEach(row => {
        call_data_new.push({
            time: moment(row.date + ' ' + row.time).unix(),
            val: row.val
        })
    });
    let qwer = AlaSQL('select * from ? order by time', [call_data_new])
    let call_data_new2 = []
    qwer.forEach(row => {
        call_data_new2.push({
            time: row.time + 36000,
            value: row.val
        })
    });
    //console.log(call_data_new2)
    return call_data_new2
    //call_data = call_data_new2

}

function prepareRow(props) {
    const dates_arr = props.dates_arr
    //console.log(props)
    let data = props.data
    //console.log('prepare')
    //console.log(props)
    let data_1 = []
    let options = {
        divider: 1000000
    }
    if (props.type === 'price') {
        options.divider = 1
    }
    data.forEach(row => {
        data_1.push({
            datetime: String(row.datetime),
            val: row.val
        })
    })
    //console.log('data_1')
    //console.log(data_1)
    //DATE_ADD(datetime, INTERVAL 10 HOUR) datetime,
    let data_2 = AlaSQL(`select datetime, sum(val) val from (
            select  datetime,
            round(val/${options.divider},2) val from ? 
            ) group by datetime order by datetime`, [data_1])
    let data_3 = []

    let data_2_0 = []
    if (props.type !== 'price') {
        if (dates_arr.length > 1) {
            //console.log('summ')
            //console.log(data_2)
            let data_new = summLine({ data: data_2, value: 'val' })
            //console.log(data_new)
            data_3 = data_new
            //console.log(data_new)
            //call_data = summLine({ data: ds_call, value: 'call2' })
            //put_data = summLine({ data: ds_put, value: 'put' })
        } else {
            data_2.forEach(item => {
                data_3.push({
                    time: moment(item.datetime).unix() + 36000,
                    value: item.val,
                })
            })

        }
    } else {
        data_2.forEach(item => {
            data_3.push({
                time: moment(item.datetime).unix() + 36000,
                value: item.val,
            })
        })
    }

    //console.log('data_2')
    //console.log(data_2_0)



    //console.log('data_3')
    //console.log(data_3)
    return data_3
}




export function prepareFlowLineData(props) {
    let data_src = props.data;
    //console.log(data_src)
    let c = AlaSQL(`select datetime, max(c) val  from ? group by datetime`, [data_src])
    let c_1 = prepareRow({ data: c, type: 'call', dates_arr: props.dates_arr })
    //console.log('C_1')
    //console.log(c_1)

    let put = AlaSQL(`select datetime, sum(p) val  from ? group by datetime`, [data_src])
    let put_1 = prepareRow({ data: put, type: 'put', dates_arr: props.dates_arr })

    let price = AlaSQL(`select datetime, sum(s) val  from ? group by datetime`, [data_src])
    //console.log(price)
    let price_1 = prepareRow({ data: price, type: 'price', dates_arr: props.dates_arr })
    return {
        title: props.title,
        callData_src: c,
        callData: c_1,
        putData: put_1,
        priceData_src: price,
        priceData: price_1
    }
}

export function divideFlowLineDataOnSymbol(props) {
    let data_src = props.data;
    let d_symbol = AlaSQL(`select distinct symbol  from ?`, [data_src])
    //console.log(d_symbol)
    let b = {}, c = {}
    d_symbol.forEach(row => {
        b[row.symbol] = []
    })
    let d = b;
    data_src.forEach(row => {
        d[row.symbol].push(row)
    })
    Object.keys(b).forEach(item => {
        c[item] = prepareFlowLineData({ data: d[item], title: item, dates_arr: props.dates_arr })
    })
    //console.log(c)
    return c
}