const where_timeZone = ` where timeZone !='America/New_York' `
const optionsTable = {
    main: {
        1: `
        expiry,
                                        symbol,
                                        timestamp, 
                                        optionType,
                                        strike, 
                                        spot,
                                        type,
                                        volume,
                                        oi, 
                                        unusual, 
                                        outOfMoney, 
                                        exch,
        sentiment,
        max(premium) premium
        `
    }
}
export function getBarMiniLineChartsQuery(props) {
    let unix_from = props.paramsFilter.timeData.unix.from
    let unix_to = props.paramsFilter.timeData.unix.to
    //let t_range = props.time_range;
    let q = `select *, 
        case
         when symbol ='SPY' then 1 
         when symbol ='QQQ' then 2 
         when symbol ='SOXL' then 3 
         when symbol ='LABU' then 4 
         when symbol ='AAPL' then 5 
         when symbol ='MSFT' then 6 
        else 10 
        end as rating 
    from 
    (select distinct symbol, datetime, p, c, s, unix from ( select * from ( 
        SELECT symbol, 

        toDateTime(concat(dateString,':00'))  datetime,
        put p, call c, price s, toUnixTimestamp(concat(dateString,':00')) unix  
        FROM OptionFlow.FlowLine ) a 
        where 
 
        unix >= ${unix_from} 
        and unix <= ${unix_to}
        ) a ) a`
    //console.log(q)
    //date_add(HOUR, 10, toDateTime(concat(dateString,':00'))) datetime,
    return q
}

export function getBarMiniLineChartsTotalsQuery(props) {
    //console.log(props)
    const unix_from = props.timeData.unix.from
    const unix_to = props.timeData.unix.to
    const tickers = props.tickers
    let tickers_str = ''
    if (tickers) {
        if (tickers.length > 0) {
            tickers_str = `and symbol in ( ${fenceArr(tickers)} ) `
        }
    }
    let q = `select
                symbol,
                sum(premium) premium
                from (select 
                            symbol,
                            premium
                            FROM (
                                select 
                                    symbol,
                                    timestamp,
                                    section,
                                    strike,
                                    expiry,
                                    max(premium) premium

                                    from

                                    OptionFlow.options2 
                                    where timestamp/1000 >= ${unix_from} and timestamp/1000 <= ${unix_to}
                                    ${tickers_str}
                                    group by 
                                        symbol,
                                        timestamp,
                                        section,
                                        strike,
                                        expiry
                            ) a
                            
                            ) a
                group by symbol
                limit 1000 
                FORMAT JSON`

    //console.log(q)
    return q
}


export function getDataBySymbol(props) {

    let unix_from = props.paramsFilter.timeData.unix.from
    let unix_to = props.paramsFilter.timeData.unix.to
    let symbol = props.paramsFilter.selectedSymbol

    let q = `select 
    distinct call2,
    price,
    put,
    datetime,
    datetime_us,
    unix
from (select *
from (
   select symbol,
          round(call / 1000000, 2)                                  call2,
          round(put / 1000000, 2)                                   put,
          price,
          date_add(HOUR, 10, toDateTime(concat(dateString, ':00'))) datetime,
          dateString                                                datetime_us,
          toUnixTimestamp(concat(dateString, ':00'))                unix

   FROM OptionFlow.FlowLine) a

    where symbol = '${symbol}'
    and unix >= ${unix_from} 
    and unix <= ${unix_to} ) a
order by datetime desc
    `
    //console.log(q)
    return q
}

// TableData

/*
OptionFlow.options2

{name: 'timestamp_now_between', type: 'Float64'}
{name: 'timestamp_bd_between', type: 'Float64'}
{name: 'timestamp_now', type: 'UInt32'}
{name: 'timestamp_bd', type: 'Float64'}
{name: 'timestamp_bd_expiry', type: 'Float64'}
{name: 'symbol', type: 'String'}
{name: 'optionType', type: 'String'}
{name: 'premium', type: 'Decimal(38, 20)'}
{name: 'strike', type: 'Decimal(38, 10)'}
{name: 'volume', type: 'Int64'}
{name: 'oi', type: 'Int64'}
{name: 'unusual', type: 'Bool'}
{name: 'outOfMoney', type: 'Bool'}
{name: 'exch', type: 'String'}
*/

export function getModalTableDataQuery(props) {
    //console.log(props)
    const unix_from = props.timeData.unix.from
    const unix_to = props.timeData.unix.to
    const symbol = props.symbol;
    const contractDetails = props.filter.contractDetails

    const premiumСondition = props.premiumСondition ? props.premiumСondition : '>='
    const premiumLimit = props.premiumLimit ? props.premiumLimit : 1

    const sessionTimeStamp = props.settingsMain.sessionTimeStamp ? props.settingsMain.sessionTimeStamp : 0

    const optionTypes = props.optionTypes
    let type_and_str = ''
    if (optionTypes) {
        if (optionTypes.length > 0) {
            type_and_str = `and type in ( ${fenceArr(optionTypes)} ) `
        }
    }

    let money_type_and_str = ''
    if (contractDetails) {
        if (contractDetails.length > 0) {
            money_type_and_str = `where money_type in ( ${fenceArr(contractDetails)} ) `
        }
    }
    let symbol_and_str = ''
    if (symbol) {
        //if (symbol !== 'SPX') {
        symbol_and_str = `and symbol ='${symbol}'`
        //}
    }

    let q = `select distinct * from (select *, timestamp_bd - 18000 +  ${sessionTimeStamp} unix_utc
    
    from (
        
        select 
        
        timestamp_bd_expiry -timestamp_now timestamp_now_between, 
        * 

        from (select timestamp_bd_expiry-timestamp_bd timestamp_bd_between, * 
            from (select *,
                if(outOfMoney = true, premium, 0) otm_premium, 
                if(outOfMoney = false ,premium, 0) oem_premium

               

                from (
        select 

                            toUnixTimestamp(now()) timestamp_now, 
                            timestamp/1000 timestamp_bd, 
                            expiry/1000 timestamp_bd_expiry, 
                            symbol, 

                            case 
                                when optionType = 'C' then 'Call'
                                when optionType = 'P' then 'Put' 
                                else 'Err' 
                            end as optionType, 

                            round(premium, 1) premium, 
                            
                            strike, spot,
                            type,

                            toInt64(volume) volume, 
                            oi, unusual, 
                            
                            outOfMoney, 
                            
                            exch,
                            sentiment,

                            case 
                                when spot >= strike_up and spot <= strike_down then 1 
                            else 0 
                            end as atm,

                            case 
                                when spot >= strike_up and spot <= strike_down then 'ATM'
                            else money_type_0

                            end as money_type

                            
        
        from (
            select 
           
                expiry,
                symbol,
                timestamp, 
                optionType,
                
                strike,
                spot, 
                type,
                volume,
                oi, 
                unusual, 
            
                outOfMoney, 
                sentiment,         
                                    case when outOfMoney = true then 'OTM' 
                                    else 'ITM'
                                    end money_type_0,

                                    exch,
                                    strike * 0.9999 strike_up ,
                                    strike * 1.0001 strike_down,

                premium

        FROM (

            select 
                                            expiry,
                                            symbol,
                                            timestamp, 
                                            optionType,
                                            strike, 
                                            spot,
                                            type,
                                            volume,
                                            oi, 
                                            unusual, 
                                            outOfMoney,
                                            exch,
                                           
                                           
                                            sentiment,

                                            max(premium) premium
                                            
                                            from 
                                            OptionFlow.options2 

                                            where
                                            timestamp/1000 >= ${unix_from} and timestamp/1000 <= ${unix_to} 
                                            
                                            ${symbol_and_str}
                                            group by 
                                                expiry,
                                                symbol,
                                                timestamp, 
                                                optionType,
                                                strike, 
                                                spot,
                                                type,
                                                volume,
                                                oi, 
                                                unusual, 
                                                outOfMoney,
                                                exch,
                                              
                                                
                                                sentiment
                                    ) a
        ) a
        
  
        
       
        ) a

        
       
        ) a ) a 
       
        ) a 
        
        ) a
        

        order by timestamp_bd desc 
        limit 40000 
        FORMAT JSON`

    // where timestamp_bd >= ${unix_from} and timestamp_bd <= ${unix_to} 
    //console.log(q)
    //where timestamp_bd_between > 0 
    //where timestamp_now_between <= 864000 
    return q
}


export function getModalTableDataQuery1() {
    let q = `select distinct * from (select 

        if(atm = 1, 'atm', if(outOfMoney = true, 'otm', 'itm')
        
        ) money_type_0,
        
        money_type,

        unusual, 
        outOfMoney,
        exch, 
        timestamp_now - timestamp_bd timestamp_now_enter, 
        timestamp_now_between,
        optionType, symbol, 
        type,
        
        CAST(strike AS NUMBER) strike, 

        round(CAST(spot AS NUMBER),1) spot, 

        CAST(volume AS NUMBER) volume, 
        CAST(premium AS NUMBER) premium, 
        CAST(oi AS NUMBER) oi, 
        timestamp_bd_expiry,
        timestamp_bd,
        atm,
        
        CAST(unix_utc AS NUMBER) unix_utc,
sentiment
        from  ? ) a 

        order by timestamp_bd desc`

    //CAST(unix_correct AS NUMBER) unix_correct,
    return q
}

function fenceArr(array) {
    let out = ''
    array.forEach(element => {
        out = out + `'${element}' ,`
    });
    //console.log(out)
    return out
}


function fenceArr2(array) {
    let out = ''
    array.forEach((element, i) => {
        out = out + `'${element}' ,`
    });
    out = `( ${out} )`
    let out2 = out.replace(', )', ' )')
    //console.log(out)
    return out2
}
function genContactDetailsCondition(array) {
    const b = {
        'OTM': true,
        'ITM': false
    }
    //console.log(array)
    let query = 'and'
    if (array.length > 0) {
        const a = array.join('_')
        //console.log(a)
        if (array.length === 1) {
            if (a.match('ATM')) {
                query = query + ' atm = 1 '
            } else {
                query = query + ` outOfMoney = ${b[array[0]]}`
            }
        }
        if (array.length === 2) {
            if (a.match('ATM')) {
                query = query + ' atm = 1 and '
                if (a.match('OTM')) {
                    query = query + ` outOfMoney = ${b['OTM']}`
                }
                if (a.match('ITM')) {
                    query = query + ` outOfMoney = ${b['ITM']}`
                }
            } else {
                query = query + ` outOfMoney = true and outOfMoney = false`
            }
        }

        if (array.length === 3) {
            query = query + ` outOfMoney = true and outOfMoney = false`
        }

    }

    if (query === 'and') {
        return ''
    }
    //console.log(query)
    return query
}

export function getBlockTableSymbols(props) {

    const unix_from = props.paramsFilter.timeData.unix.from
    const unix_to = props.paramsFilter.timeData.unix.to

    let q = `select 
        symbol , sum(premium) premium
        from (
            select timestamp_bd_expiry -timestamp_now timestamp_now_between, * 
            from (
                select timestamp_bd_expiry-timestamp_bd timestamp_bd_between, * 
                from (
                    select *

                    from (
                        select 

                            toUnixTimestamp(now()) timestamp_now, 
                            timestamp/1000 timestamp_bd, 
                            expiry/1000 timestamp_bd_expiry, 
                            symbol,
                            premium

                            from (
                                select 
                                   distinct
                                    symbol,
                                    expiry,
                                    timestamp,
                                    premium

                                    FROM (
                                        select 
                                            expiry,
                                            symbol,
                                            timestamp, 
                                            optionType,
                                            strike, 
                                            spot,
                                            type,
                                            volume,
                                            oi, 
                                            unusual, 
                                            outOfMoney,
                                            exch,
                                            max(premium) premium

                                            from 
                                            OptionFlow.options2 

                                            where
                                            timestamp/1000 >= ${unix_from} and timestamp/1000 <= ${unix_to}    

                                            group by 
                                                expiry,
                                                symbol,
                                                timestamp, 
                                                optionType,
                                                strike, 
                                                spot,
                                                type,
                                                volume,
                                                oi, 
                                                unusual, 
                                                outOfMoney,
                                                exch
                                    ) a
                                    
                                    
                                    
                                ) a
                        ) a 

                                           
                                    
                                    ) a ) a 
                                     ) a 
               group by symbol
                                  
                                    order by premium desc 
                                    limit 2000  FORMAT JSON`

    // where timestamp_bd >= ${unix_from} and timestamp_bd <= ${unix_to} 

    // console.log(q)
    return q
}


export function getTableDataQuery0(props) {
    const premiumСondition = props.paramsFilter.premiumСondition ? props.paramsFilter.premiumСondition : '>='
    const premiumLimit = props.paramsFilter.premiumLimit ? props.paramsFilter.premiumLimit : 100000

    let beforeExpirationCondition = props.paramsFilter.beforeExpirationCondition ? props.paramsFilter.beforeExpirationCondition : '>='
    const beforeExpiration = props.paramsFilter.beforeExpiration
    let timestamp_now_between_str = ''
    if (beforeExpiration) {

        if (beforeExpiration === 0) {
            timestamp_now_between_str = `where             
            timestamp_now_between ${beforeExpirationCondition} ${400} 
            `
        } else {
            timestamp_now_between_str = `where             
            timestamp_now_between ${beforeExpirationCondition} ${beforeExpiration * 86400} 
            `
        }
    }
    const unix_from = props.paramsFilter.timeData.unix.from
    const unix_to = props.paramsFilter.timeData.unix.to
    const symbol = props.paramsFilter.selectedSymbol;
    const type = props.paramsFilter.selectedType
    //const contractDetails = props.paramsFilter.contractDetails
    //const contractDetailsQuery = genContactDetailsCondition(contractDetails)
    let type_and_str = ''
    if (type) {
        if (type.length > 0) {
            type_and_str = `and type in ( ${fenceArr(type)} ) `
        }
    }
    const selected_table_symbols = props.paramsFilter.selectedTableSymbols

    let selected_table_symbols_str = ''
    if (selected_table_symbols) {
        if (selected_table_symbols.length > 0) {
            selected_table_symbols_str = `and symbol in  ${fenceArr2(selected_table_symbols)}  `
        }
    }
    let money_type_and_str = ''
    //if (contractDetails) {
    //    if (contractDetails.length > 0) {
    //        money_type_and_str = `and money_type in ( ${fenceArr(contractDetails)} ) `
    //    }
    //}

    let q = `select distinct
        * 
        from (
            select timestamp_bd_expiry -timestamp_now timestamp_now_between, * 
            from (
                select timestamp_bd_expiry-timestamp_bd timestamp_bd_between, * 
                from (
                    select *,
                    if(outOfMoney = true, premium, 0) otm_premium, 
                    if(outOfMoney = false ,premium, 0) oem_premium
                    from (
                        select 

                            toUnixTimestamp(now()) timestamp_now, 
                            timestamp/1000 timestamp_bd, 
                            expiry/1000 timestamp_bd_expiry, 
                            symbol, 
                            case 
                                when optionType = 'C' then 'Call'
                                when optionType = 'P' then 'Put' 
                                else 'Err' 
                            end as optionType, 
                            round(premium, 1) premium, 
                            strike, spot,
                            type,
                            toInt64(volume) volume, 
                            oi, 
                            unusual, 
                            outOfMoney, 
                            exch,
                            sentiment,

                            case 
                                when spot >= strike_up and spot <= strike_down then 1 
                            else 0 
                            end as atm,

                            case 
                                when spot >= strike_up and spot <= strike_down then 'ATM'
                            else money_type_0
                            end as money_type

                            from (
                                select 

                                    expiry,
                                    symbol,
                                    timestamp, 
                                    optionType,
                                    premium, 
                                    strike, 
                                    spot,
                                    type,
                                    volume,
                                    oi, 
                                    unusual, 
                                    outOfMoney, 
                                    exch,
                                    sentiment,

                                    case when outOfMoney = true then 'OTM' 
                                    else 'ITM'
                                    end money_type_0,

                                    strike * 0.9999 strike_up ,
                                    strike * 1.0001 strike_down

                                    FROM (

                                        select 

                                        expiry,
                                        symbol,
                                        timestamp, 
                                        optionType,
                                        strike, 
                                        spot,
                                        type,
                                        volume,
                                        oi, 
                                        unusual, 
                                        outOfMoney, 
                                        exch,
                                        sentiment,

                                        max(premium) premium
                                        
                                    
                                        from
                                        OptionFlow.options2 

                                        where 
                                        timestamp/1000 >= ${unix_from} and timestamp/1000 <= ${unix_to} 

                                        group by

                                        expiry,
                                        symbol,
                                        timestamp, 
                                        optionType,
                                        strike, 
                                        spot,
                                        type,
                                        volume,
                                        oi, 
                                        unusual, 
                                        outOfMoney, 
                                        exch,
                                        sentiment

                                    ) a 
                                    where premium ${premiumСondition} ${premiumLimit}                                    
                                    ${type_and_str}
                                    ${selected_table_symbols_str}
                                ) a
                        ) a 


                        ${money_type_and_str}
                                    
                                    ) a ) a 
                                   
                                    ) a 

                                    ${timestamp_now_between_str}
                                   

                                    order by timestamp_bd desc 
                                    limit 50000  FORMAT JSON`

    // where timestamp_bd >= ${unix_from} and timestamp_bd <= ${unix_to} 
    //console.log('unix_from')
    //console.log(unix_from)
    //where timestamp_bd_between > 0
    return q
}


export function getTableDataGauge2Query(props) {
    const selected_table_symbols = props.paramsFilter.selectedTableSymbols
    //const contractDetails = props.paramsFilter.contractDetails
    let selected_table_symbols_str = ''
    if (selected_table_symbols) {
        if (selected_table_symbols.length > 0) {
            selected_table_symbols_str = `where symbol in  ${fenceArr2(selected_table_symbols)}  `
        }
    }

    let q = `
    select
        premium_call / ( premium_call + premium_put ) premium_call_pct,
        premium_put / ( premium_call + premium_put )  premium_put_pct,
        *
        from (
        select
            sum(if(optionType='Call', premium, 0)) premium_call,
            sum(if(optionType='Put', premium, 0)) premium_put
            from (select * from (select 

            if(atm = 1, 'atm', if(outOfMoney = true, 'otm', 'itm')
            
            ) money_type_0,
            
            money_type,

            unusual, 
            outOfMoney,
            exch, 
            timestamp_now - timestamp_bd timestamp_now_enter, 
            timestamp_now_between,
            optionType, symbol, 
            CAST(strike AS NUMBER) strike, 

            round(CAST(spot AS NUMBER),1) spot, 
            type,

            CAST(volume AS NUMBER) volume, 
            CAST(premium AS NUMBER) premium, 
            CAST(oi AS NUMBER) oi, 
            timestamp_bd_expiry,
            timestamp_bd,
            atm

            from  ? 
            where money_type = 'OTM'
            ) a 
            
        ) a
        ) a
            `

    //where timestamp_now_between > 0
    //console.log(q) ${selected_table_symbols_str}
    return q
}


export function getTableDataQuery1(props) {
    const selected_table_symbols = props.paramsFilter.selectedTableSymbols
    const contractDetails = props.paramsFilter.contractDetails
    let selected_table_symbols_str = ''
    if (selected_table_symbols) {
        if (selected_table_symbols.length > 0) {
            selected_table_symbols_str = `where symbol in  ${fenceArr2(selected_table_symbols)}  `
        }
    }
    let money_type_and_str = ''
    if (contractDetails) {
        if (contractDetails.length > 0) {
            let pre_str = 'where'
            //if (selected_table_symbols) {
            //    if (selected_table_symbols.length > 0) {
            //       pre_str = 'and'
            //   }
            //}
            money_type_and_str = `${pre_str} money_type in ${fenceArr2(contractDetails)}  `
        }
    }


    let q = `select * from (select 

        if(atm = 1, 'atm', if(outOfMoney = true, 'otm', 'itm')
        
        ) money_type_0,
        
        money_type,

        unusual, 
        outOfMoney,
        exch, 
        timestamp_now - timestamp_bd timestamp_now_enter, 
        timestamp_now_between,
        optionType, symbol, 
        CAST(strike AS NUMBER) strike, 

        round(CAST(spot AS NUMBER),1) spot, 
        type,

        CAST(volume AS NUMBER) volume, 
        CAST(premium AS NUMBER) premium, 
        CAST(oi AS NUMBER) oi, 
        timestamp_bd_expiry,
        timestamp_bd,
        atm,
        sentiment

        from  ? ) a 
        
        ${money_type_and_str}
        order by timestamp_bd desc`

    //where timestamp_now_between > 0
    //${selected_table_symbols_str}
    //console.log(q)
    return q
}

export function getTableDataQuery2(props) {
    const timestamp_bd_max = props.timestamp_bd_max
    const short_etfs_arr = props.short_etfs_arr

    const selected_table_symbols = props.paramsFilter.selectedTableSymbols
    //console.log(selected_table_symbols)

    let selected_table_symbols_str = ''
    if (selected_table_symbols) {
        if (selected_table_symbols.length > 0) {
            selected_table_symbols_str = `and symbol in  ${fenceArr2(selected_table_symbols)}  `
        }
    }

    let q = `select  

    prem_put_15_min,
    prem_call_15_min,
    prem_call_60_min,
    prem_put_60_min,

    prem_call_15_min  / (prem_put_15_min + prem_call_15_min)  pct_15_min,
    prem_call_30_min  / (prem_put_30_min + prem_call_30_min)  pct_30_min,
    prem_call_60_min  / (prem_put_60_min + prem_call_60_min)  pct_60_min,
    prem_call_1_day  / (prem_put_1_day + prem_call_1_day)  pct_1_day

    from (select 
    sum( if(optionType ='Call' and t_index = 1, premium, 0) ) prem_call_15_min,
    sum( if(optionType ='Call' and t_index in(1,2) , premium, 0) ) prem_call_30_min,
    sum( if(optionType ='Call' and t_index != 0, premium, 0) ) prem_call_60_min,
    sum( if(optionType ='Call', premium, 0) ) prem_call_1_day,

    sum( if(optionType ='Put' and t_index = 1, premium, 0) ) prem_put_15_min,
    sum( if(optionType ='Put' and t_index in(1,2) , premium, 0) ) prem_put_30_min,
    sum( if(optionType ='Put' and t_index != 0, premium, 0) ) prem_put_60_min,
    sum( if(optionType ='Put', premium, 0) ) prem_put_1_day

    from (select

    premium,
    optionType,
    case 
        when timestamp_bd >= ${timestamp_bd_max - 900} then 1
        when timestamp_bd >= ${timestamp_bd_max - 1800} then 2
        when timestamp_bd >= ${timestamp_bd_max - 3600} then 3
    else 0
    end as t_index
    from ? 
    where timestamp_bd >= ${timestamp_bd_max - 86400}
    and symbol not in ${short_etfs_arr}
    ${selected_table_symbols_str}
    ) a
    ) a
    
    `
    //console.log(q)
    //  timestamp_bd >= ${timestamp_bd_max - 86400}
    return q
}

export function geModalTableDataQuery2(props) {
    const timestamp_bd_max = props.timestamp_bd_max
    const short_etfs_arr = props.short_etfs_arr


    let q = `select  

    prem_put_15_min,
    prem_call_15_min,
    prem_call_60_min,
    prem_put_60_min,

    prem_call_15_min  / (prem_put_15_min + prem_call_15_min)  pct_15_min,
    prem_call_30_min  / (prem_put_30_min + prem_call_30_min)  pct_30_min,
    prem_call_60_min  / (prem_put_60_min + prem_call_60_min)  pct_60_min,
    prem_call_1_day  / (prem_put_1_day + prem_call_1_day)  pct_1_day

    from (select 
    sum( if(optionType ='Call' and t_index = 1, premium, 0) ) prem_call_15_min,
    sum( if(optionType ='Call' and t_index in(1,2) , premium, 0) ) prem_call_30_min,
    sum( if(optionType ='Call' and t_index != 0, premium, 0) ) prem_call_60_min,
    sum( if(optionType ='Call', premium, 0) ) prem_call_1_day,

    sum( if(optionType ='Put' and t_index = 1, premium, 0) ) prem_put_15_min,
    sum( if(optionType ='Put' and t_index in(1,2) , premium, 0) ) prem_put_30_min,
    sum( if(optionType ='Put' and t_index != 0, premium, 0) ) prem_put_60_min,
    sum( if(optionType ='Put', premium, 0) ) prem_put_1_day

    from (select

    premium,
    optionType,
    case 
        when timestamp_bd >= ${timestamp_bd_max - 900} then 1
        when timestamp_bd >= ${timestamp_bd_max - 1800} then 2
        when timestamp_bd >= ${timestamp_bd_max - 3600} then 3
    else 0
    end as t_index
    from ? 
    where timestamp_bd >= ${timestamp_bd_max - 86400}
    

    ) a
    ) a
    
    `
    //console.log(q)
    //  timestamp_bd >= ${timestamp_bd_max - 86400} and symbol not in ${short_etfs_arr}
    return q
}


export function getCallPutQuery01() {
    let q = `select *
    from 
    
    (select * 
    from 
    
    (
    select 
    type,
    section,
    outOfMoney,
    sum(premium) premium,
    sum(premium_c) premium_c,
    sum(premium_p) premium_p,
    sum(premium_c_exp_d10) premium_c_exp_d10, 
    sum(premium_c_exp_d40) premium_c_exp_d40,
    sum(premium_c_exp_d60) premium_c_exp_d60,
    sum(premium_c_exp_m_d60) premium_c_exp_m_d60, 
    
    sum(premium_p_exp_d10) premium_p_exp_d10, 
    sum(premium_p_exp_d40) premium_p_exp_d40,
    sum(premium_p_exp_d60) premium_p_exp_d60,
    sum(premium_p_exp_m_d60) premium_p_exp_m_d60
    
    from 
    (select
    
    type,
    section,
    outOfMoney,
    premium,
    premium_c,
    premium_p,
    case when (optionType  ='C' and t1<=10) then premium_c else 0 end as premium_c_exp_d10, 
    case when (optionType  ='C' and t1>10 and t1<= 40) then premium_c else 0 end as premium_c_exp_d40,
    case when (optionType  ='C' and t1>40 and t1<= 60) then premium_c else 0 end as premium_c_exp_d60,
    case when (optionType  ='C' and t1>=60) then premium_c else 0 end as premium_c_exp_m_d60, 
    
    case when (optionType  ='P' and t1<=10) then premium_p else 0 end as premium_p_exp_d10, 
    case when (optionType  ='P' and t1>10 and t1<= 40) then premium_p else 0 end as premium_p_exp_d40,
    case when (optionType  ='P' and t1>40 and t1<= 60) then premium_p else 0 end as premium_p_exp_d60,
    case when (optionType  ='P' and t1>=60) then premium_p else 0 end as premium_p_exp_m_d60
    
    from ?
    ) a
    group by type, section, outOfMoney
    ) a 
    ) a
    order by premium desc
    `
    return q
}

// m a p
export function getCallPutQuery00(props) {

    const unix_from = props.paramsFilter.timeData.unix.from
    const unix_to = props.paramsFilter.timeData.unix.to

    const map_premiumCondition = props.paramsFilter.map_premiumCondition ? props.paramsFilter.map_premiumCondition : '>='
    const map_premiumLimit = props.paramsFilter.map_premiumLimit ? props.paramsFilter.map_premiumLimit : 100000



    let where_type_str = ''
    if (props.paramsFilter.map_selectedType) {
        const map_selectedType = props.paramsFilter.map_selectedType
        if (map_selectedType.length > 0) {
            where_type_str = ` where type in ${fenceArr2(map_selectedType)}`
        }
    }


    const map_beforeExpirationCondition = props.paramsFilter.map_beforeExpirationCondition ? props.paramsFilter.map_beforeExpirationCondition : '>='
    const map_beforeExpiration = props.paramsFilter.map_beforeExpiration
    let where_timestamp_now_between = ''
    if (map_beforeExpiration) {
        if (map_beforeExpiration === 0) {
            where_timestamp_now_between = `
            where             
            timestamp_now_between ${map_beforeExpirationCondition} ${400} 
            `
        } else {
            where_timestamp_now_between = `
            where             
            timestamp_now_between ${map_beforeExpirationCondition} ${map_beforeExpiration * 86400} 
            `
        }
    }



    let where_money_types = ''
    if (props.paramsFilter.map_moneyTypes) {
        const moneyTypes = props.paramsFilter.map_moneyTypes
        if (moneyTypes.length > 0) {
            where_money_types = `where money_type in ${fenceArr2(moneyTypes)}`
        }
    }


    const q = ` 
    select * from (

    select 
    
    * 
    
    from (select 
        *,
        timestamp_bd_expiry - timestamp_now  as timestamp_now_between,
        round(((expiry/1000)  - toUnixTimestamp(now()))/60/60/24,2) t1
    
    from ( select 

        *,
        timestamp_bd_expiry - timestamp_bd  as timestamp_bd_between,

        case 
          when spot >= strike_up and spot <= strike_down then 'ATM'
        else money_type_0
        end as money_type
        
        from (
            select 
            
            *,
toUnixTimestamp(now()) timestamp_now, 
                            timestamp/1000 timestamp_bd, 
                            expiry/1000 timestamp_bd_expiry,

                            case when outOfMoney = true then 'OTM' 
                                    else 'ITM'
                                    end money_type_0,
                                    
                                    strike * 0.9999 strike_up ,
                                    strike * 1.0001 strike_down
        
        from (
            select 
                symbol,
                section,
                outOfMoney,
                optionType,
                
                expiry,
                timestamp,
                type,
                strike,
                spot,

                max(premium) premium

            from 
            OptionFlow.options2 

            where 
            timestamp >= ${unix_from * 1000} and timestamp <= ${unix_to * 1000} 
           

            group by
                symbol,
                section,
                outOfMoney,
                optionType,
                expiry,
                timestamp,
                type,
                strike,
                spot
        ) a
        ) a
${where_type_str} ) a 
where premium ${map_premiumCondition} ${map_premiumLimit}  

) a

${where_timestamp_now_between}

) a
${where_money_types}

    order by premium desc
    
    LIMIT 100000 FORMAT JSON`

    //console.log(q)
    return q
}

export function getCallPutQuery0A(props) {

    let q = `select distinct *
    from 
    
    (select * 
    from 
    
    (
    select 
    type,
    section,

    sum(premium) premium,
    sum(premium_c) premium_c,
    sum(premium_p) premium_p,
    sum(premium_c_exp_d10) premium_c_exp_d10, 
    sum(premium_c_exp_d40) premium_c_exp_d40,
    sum(premium_c_exp_d60) premium_c_exp_d60,
    sum(premium_c_exp_m_d60) premium_c_exp_m_d60, 
    

    sum(premium_c_exp_d10_otm) premium_c_exp_d10_otm, 
    sum(premium_c_exp_d40_otm) premium_c_exp_d40_otm,
    sum(premium_c_exp_d60_otm) premium_c_exp_d60_otm,
    sum(premium_c_exp_m_d60_otm) premium_c_exp_m_d60_otm,

    sum(premium_c_exp_d10_oem) premium_c_exp_d10_oem, 
    sum(premium_c_exp_d40_oem) premium_c_exp_d40_oem,
    sum(premium_c_exp_d60_oem) premium_c_exp_d60_oem,
    sum(premium_c_exp_m_d60_oem) premium_c_exp_m_d60_oem,


    sum(premium_p_exp_d10) premium_p_exp_d10, 
    sum(premium_p_exp_d40) premium_p_exp_d40,
    sum(premium_p_exp_d60) premium_p_exp_d60,
    sum(premium_p_exp_m_d60) premium_p_exp_m_d60,

    sum(premium_p_exp_d10_otm) premium_p_exp_d10_otm, 
    sum(premium_p_exp_d40_otm) premium_p_exp_d40_otm,
    sum(premium_p_exp_d60_otm) premium_p_exp_d60_otm,
    sum(premium_p_exp_m_d60_otm) premium_p_exp_m_d60_otm,

    sum(premium_p_exp_d10_oem) premium_p_exp_d10_oem, 
    sum(premium_p_exp_d40_oem) premium_p_exp_d40_oem,
    sum(premium_p_exp_d60_oem) premium_p_exp_d60_oem,
    sum(premium_p_exp_m_d60_oem) premium_p_exp_m_d60_oem

    from 
    (select
    
    type,
    section,
    outOfMoney,
    premium,
    premium_c,
    premium_p,

    case when (optionType  ='C' and t1<=10) then premium_c else 0 end as premium_c_exp_d10, 
    case when (optionType  ='C' and t1>10 and t1<= 40) then premium_c else 0 end as premium_c_exp_d40,
    case when (optionType  ='C' and t1>40 and t1<= 60) then premium_c else 0 end as premium_c_exp_d60,
    case when (optionType  ='C' and t1>=60) then premium_c else 0 end as premium_c_exp_m_d60,

    case when (optionType  ='C' and t1<=10 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d10_otm, 
    case when (optionType  ='C' and t1>10 and t1<= 40 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d40_otm,
    case when (optionType  ='C' and t1>40 and t1<= 60 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d60_otm,
    case when (optionType  ='C' and t1>=60 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_m_d60_otm,
    
    case when (optionType  ='C' and t1<=10 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d10_oem, 
    case when (optionType  ='C' and t1>10 and t1<= 40 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d40_oem,
    case when (optionType  ='C' and t1>40 and t1<= 60 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d60_oem,
    case when (optionType  ='C' and t1>=60 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_m_d60_oem,

    case when (optionType  ='P' and t1<=10) then premium_p else 0 end as premium_p_exp_d10, 
    case when (optionType  ='P' and t1>10 and t1<= 40) then premium_p else 0 end as premium_p_exp_d40,
    case when (optionType  ='P' and t1>40 and t1<= 60) then premium_p else 0 end as premium_p_exp_d60,
    case when (optionType  ='P' and t1>=60) then premium_p else 0 end as premium_p_exp_m_d60,

    case when (optionType  ='P' and t1<=10 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d10_otm, 
    case when (optionType  ='P' and t1>10 and t1<= 40 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d40_otm,
    case when (optionType  ='P' and t1>40 and t1<= 60 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d60_otm,
    case when (optionType  ='P' and t1>=60 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_m_d60_otm,

    case when (optionType  ='P' and t1<=10 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d10_oem, 
    case when (optionType  ='P' and t1>10 and t1<= 40 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d40_oem,
    case when (optionType  ='P' and t1>40 and t1<= 60 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d60_oem,
    case when (optionType  ='P' and t1>=60 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_m_d60_oem

    from 
    (
        SELECT 

        distinct

        symbol as type, 
        section,
        case when outOfMoney =true then 1 else 0 end
        outOfMoney,
        optionType,
        premium, 
        case when optionType = 'C' then premium else 0 end premium_c,
        case when optionType = 'P' then premium else 0 end premium_p,
        t1

        FROM ?

    ) a
    
    ) a
    group by type, section
    ) a 
    ) a
    order by premium desc
    
    `
    return q
}



export function getCallPutQuery0(props) {
    const unix_from = props.paramsFilter.timeData.unix.from
    const unix_to = props.paramsFilter.timeData.unix.to
    //const symbol = props.paramsFilter.selectedSymbol;

    /*let where_option_type = ''
    if (props.paramsFilter.map_TopPut_selectedType) {
        const map_TopPut_selectedType = props.paramsFilter.map_TopPut_selectedType
        where_option_type = ` where optionType in ${fenceArr2(map_TopPut_selectedType)}`
    }*/

    let symbol_and_str = ''
    //if (symbol !== 'main') {
    //    symbol_and_str = `and symbol ='${symbol}'`
    //}

    let q = `select distinct *
    from 
    
    (select * 
    from 
    
    (
    select 
    type,
    section,

    sum(premium) premium,
    sum(premium_c) premium_c,
    sum(premium_p) premium_p,
    sum(premium_c_exp_d10) premium_c_exp_d10, 
    sum(premium_c_exp_d40) premium_c_exp_d40,
    sum(premium_c_exp_d60) premium_c_exp_d60,
    sum(premium_c_exp_m_d60) premium_c_exp_m_d60, 
    

    sum(premium_c_exp_d10_otm) premium_c_exp_d10_otm, 
    sum(premium_c_exp_d40_otm) premium_c_exp_d40_otm,
    sum(premium_c_exp_d60_otm) premium_c_exp_d60_otm,
    sum(premium_c_exp_m_d60_otm) premium_c_exp_m_d60_otm,

    sum(premium_c_exp_d10_oem) premium_c_exp_d10_oem, 
    sum(premium_c_exp_d40_oem) premium_c_exp_d40_oem,
    sum(premium_c_exp_d60_oem) premium_c_exp_d60_oem,
    sum(premium_c_exp_m_d60_oem) premium_c_exp_m_d60_oem,


    sum(premium_p_exp_d10) premium_p_exp_d10, 
    sum(premium_p_exp_d40) premium_p_exp_d40,
    sum(premium_p_exp_d60) premium_p_exp_d60,
    sum(premium_p_exp_m_d60) premium_p_exp_m_d60,

    sum(premium_p_exp_d10_otm) premium_p_exp_d10_otm, 
    sum(premium_p_exp_d40_otm) premium_p_exp_d40_otm,
    sum(premium_p_exp_d60_otm) premium_p_exp_d60_otm,
    sum(premium_p_exp_m_d60_otm) premium_p_exp_m_d60_otm,

    sum(premium_p_exp_d10_oem) premium_p_exp_d10_oem, 
    sum(premium_p_exp_d40_oem) premium_p_exp_d40_oem,
    sum(premium_p_exp_d60_oem) premium_p_exp_d60_oem,
    sum(premium_p_exp_m_d60_oem) premium_p_exp_m_d60_oem

    from 
    (select
    
    type,
    section,
    outOfMoney,
    premium,
    premium_c,
    premium_p,

    case when (optionType  ='C' and t1<=10) then premium_c else 0 end as premium_c_exp_d10, 
    case when (optionType  ='C' and t1>10 and t1<= 40) then premium_c else 0 end as premium_c_exp_d40,
    case when (optionType  ='C' and t1>40 and t1<= 60) then premium_c else 0 end as premium_c_exp_d60,
    case when (optionType  ='C' and t1>=60) then premium_c else 0 end as premium_c_exp_m_d60,

    case when (optionType  ='C' and t1<=10 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d10_otm, 
    case when (optionType  ='C' and t1>10 and t1<= 40 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d40_otm,
    case when (optionType  ='C' and t1>40 and t1<= 60 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_d60_otm,
    case when (optionType  ='C' and t1>=60 and outOfMoney=1 ) then premium_c else 0 end as premium_c_exp_m_d60_otm,
    
    case when (optionType  ='C' and t1<=10 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d10_oem, 
    case when (optionType  ='C' and t1>10 and t1<= 40 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d40_oem,
    case when (optionType  ='C' and t1>40 and t1<= 60 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_d60_oem,
    case when (optionType  ='C' and t1>=60 and outOfMoney=0 ) then premium_c else 0 end as premium_c_exp_m_d60_oem,

    case when (optionType  ='P' and t1<=10) then premium_p else 0 end as premium_p_exp_d10, 
    case when (optionType  ='P' and t1>10 and t1<= 40) then premium_p else 0 end as premium_p_exp_d40,
    case when (optionType  ='P' and t1>40 and t1<= 60) then premium_p else 0 end as premium_p_exp_d60,
    case when (optionType  ='P' and t1>=60) then premium_p else 0 end as premium_p_exp_m_d60,

    case when (optionType  ='P' and t1<=10 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d10_otm, 
    case when (optionType  ='P' and t1>10 and t1<= 40 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d40_otm,
    case when (optionType  ='P' and t1>40 and t1<= 60 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_d60_otm,
    case when (optionType  ='P' and t1>=60 and outOfMoney=1 ) then premium_p else 0 end as premium_p_exp_m_d60_otm,

    case when (optionType  ='P' and t1<=10 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d10_oem, 
    case when (optionType  ='P' and t1>10 and t1<= 40 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d40_oem,
    case when (optionType  ='P' and t1>40 and t1<= 60 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_d60_oem,
    case when (optionType  ='P' and t1>=60 and outOfMoney=0 ) then premium_p else 0 end as premium_p_exp_m_d60_oem

    from 
    (
        SELECT 

        distinct

        symbol as type, 
        section,
        case when outOfMoney =true then 1 else 0 end
        outOfMoney,
        optionType,
        premium, 
        case when optionType = 'C' then premium else 0 end premium_c,
        case when optionType = 'P' then premium else 0 end premium_p,
        round(((expiry/1000)  - toUnixTimestamp(now()))/60/60/24,2) t1

        FROM (
            select 
                symbol,
                section,
                outOfMoney,
                optionType,
                max(premium) premium,
                expiry,
                timestamp
            from 
            OptionFlow.options2 

            where 
            timestamp >= ${unix_from * 1000} and timestamp <= ${unix_to * 1000} 
           

            group by
                symbol,
                section,
                outOfMoney,
                optionType,
                expiry,
                timestamp
        )

    ) a
    
    ) a
    group by type, section
    ) a 
    ) a
    order by premium desc
    
    LIMIT 100000 FORMAT JSON`
    return q
}

export function getCallPutQuery1() {
    let q = `
        select *,
        case 
            when premium_c / premium >= 0.7 then 1
            when premium_p / premium >= 0.7 then 2
         else 0 end bar

from (
    select 
        type,
        sum(premium) premium,
        sum(premium_c) premium_c,
        sum(premium_p) premium_p,

        round(sum(premium_c_exp_d10),1) premium_c_exp_d10,
        round(sum(premium_c_exp_d40),1) premium_c_exp_d40,
        round(sum(premium_c_exp_d60),1) premium_c_exp_d60,
        round(sum(premium_c_exp_m_d60),1) premium_c_exp_m_d60,

        round(sum(premium_c_exp_d10_otm),1) premium_c_exp_d10_otm,
        round(sum(premium_c_exp_d40_otm),1) premium_c_exp_d40_otm,
        round(sum(premium_c_exp_d60_otm),1) premium_c_exp_d60_otm,
        round(sum(premium_c_exp_m_d60_otm),1) premium_c_exp_m_d60_otm,

        round(sum(premium_c_exp_d10_oem),1) premium_c_exp_d10_oem,
        round(sum(premium_c_exp_d40_oem),1) premium_c_exp_d40_oem,
        round(sum(premium_c_exp_d60_oem),1) premium_c_exp_d60_oem,
        round(sum(premium_c_exp_m_d60_oem),1) premium_c_exp_m_d60_oem,


        round(sum(premium_p_exp_d10),1) premium_p_exp_d10,
        round(sum(premium_p_exp_d40),1) premium_p_exp_d40,
        round(sum(premium_p_exp_d60),1) premium_p_exp_d60,
        round(sum(premium_p_exp_m_d60),1) premium_p_exp_m_d60,

        round(sum(premium_p_exp_d10_otm),1) premium_p_exp_d10_otm,
        round(sum(premium_p_exp_d40_otm),1) premium_p_exp_d40_otm,
        round(sum(premium_p_exp_d60_otm),1) premium_p_exp_d60_otm,
        round(sum(premium_p_exp_m_d60_otm),1) premium_p_exp_m_d60_otm,

        round(sum(premium_p_exp_d10_oem),1) premium_p_exp_d10_oem,
        round(sum(premium_p_exp_d40_oem),1) premium_p_exp_d40_oem,
        round(sum(premium_p_exp_d60_oem),1) premium_p_exp_d60_oem,
        round(sum(premium_p_exp_m_d60_oem),1) premium_p_exp_m_d60_oem

        from ?  
        group by type 
    ) 
   
`
    return q
}

export function getCallPutQuery_Call() {
    let q = `select
    type,
    sum(premium) premium,
    
    round(sum(premium_c_exp_d10)/1000000,1) premium_c_exp_d10,
    round(sum(premium_c_exp_d10_otm)/1000000,1) premium_c_exp_d10_otm,
    round(sum(premium_c_exp_d10_oem)/1000000,1) premium_c_exp_d10_oem,

    round(sum(premium_c_exp_d40)/1000000,1) premium_c_exp_d40,
    round(sum(premium_c_exp_d40_otm)/1000000,1) premium_c_exp_d40_otm,
    round(sum(premium_c_exp_d40_oem)/1000000,1) premium_c_exp_d40_oem,

    round(sum(premium_c_exp_d60)/1000000,1) premium_c_exp_d60,
    round(sum(premium_c_exp_d60_otm)/1000000,1) premium_c_exp_d60_otm,
    round(sum(premium_c_exp_d60_oem)/1000000,1) premium_c_exp_d60_oem,

    round(sum(premium_c_exp_m_d60)/1000000,1) premium_c_exp_m_d60,
    round(sum(premium_c_exp_m_d60_otm)/1000000,1) premium_c_exp_m_d60_otm,
    round(sum(premium_c_exp_m_d60_oem)/1000000,1) premium_c_exp_m_d60_oem,

    round(sum(premium_p_exp_d10)/1000000,1) premium_p_exp_d10,
    round(sum(premium_p_exp_d10_otm)/1000000,1) premium_p_exp_d10_otm,
    round(sum(premium_p_exp_d10_oem)/1000000,1) premium_p_exp_d10_oem,

    round(sum(premium_p_exp_d40)/1000000,1) premium_p_exp_d40,
    round(sum(premium_p_exp_d40_otm)/1000000,1) premium_p_exp_d40_otm,
    round(sum(premium_p_exp_d40_oem)/1000000,1) premium_p_exp_d40_oem,

    round(sum(premium_p_exp_d60)/1000000,1) premium_p_exp_d60,
    round(sum(premium_p_exp_d60_otm)/1000000,1) premium_p_exp_d60_otm,
    round(sum(premium_p_exp_d60_oem)/1000000,1) premium_p_exp_d60_oem,

    round(sum(premium_p_exp_m_d60)/1000000,1) premium_p_exp_m_d60,
    round(sum(premium_p_exp_m_d60_otm)/1000000,1) premium_p_exp_m_d60_otm,
    round(sum(premium_p_exp_m_d60_oem)/1000000,1) premium_p_exp_m_d60_oem

    from ? where bar = 1
    group by type 
    order by premium desc
    limit 10

`
    return q
}


export function getCallPutQuery_Put() {
    let q = `select distinct * from (select
    type,
    sum(premium) premium,

    round(sum(premium_c_exp_d10)/1000000,1) premium_c_exp_d10,
    round(sum(premium_c_exp_d10_otm)/1000000,1) premium_c_exp_d10_otm,
    round(sum(premium_c_exp_d10_oem)/1000000,1) premium_c_exp_d10_oem,

    round(sum(premium_c_exp_d40)/1000000,1) premium_c_exp_d40,
    round(sum(premium_c_exp_d40_otm)/1000000,1) premium_c_exp_d40_otm,
    round(sum(premium_c_exp_d40_oem)/1000000,1) premium_c_exp_d40_oem,

    round(sum(premium_c_exp_d60)/1000000,1) premium_c_exp_d60,
    round(sum(premium_c_exp_d60_otm)/1000000,1) premium_c_exp_d60_otm,
    round(sum(premium_c_exp_d60_oem)/1000000,1) premium_c_exp_d60_oem,

    round(sum(premium_c_exp_m_d60)/1000000,1) premium_c_exp_m_d60,
    round(sum(premium_c_exp_m_d60_otm)/1000000,1) premium_c_exp_m_d60_otm,
    round(sum(premium_c_exp_m_d60_oem)/1000000,1) premium_c_exp_m_d60_oem,

    round(sum(premium_p_exp_d10)/1000000,1) premium_p_exp_d10,
    round(sum(premium_p_exp_d10_otm)/1000000,1) premium_p_exp_d10_otm,
    round(sum(premium_p_exp_d10_oem)/1000000,1) premium_p_exp_d10_oem,

    round(sum(premium_p_exp_d40)/1000000,1) premium_p_exp_d40,
    round(sum(premium_p_exp_d40_otm)/1000000,1) premium_p_exp_d40_otm,
    round(sum(premium_p_exp_d40_oem)/1000000,1) premium_p_exp_d40_oem,

    round(sum(premium_p_exp_d60)/1000000,1) premium_p_exp_d60,
    round(sum(premium_p_exp_d60_otm)/1000000,1) premium_p_exp_d60_otm,
    round(sum(premium_p_exp_d60_oem)/1000000,1) premium_p_exp_d60_oem,

    round(sum(premium_p_exp_m_d60)/1000000,1) premium_p_exp_m_d60,
    round(sum(premium_p_exp_m_d60_otm)/1000000,1) premium_p_exp_m_d60_otm,
    round(sum(premium_p_exp_m_d60_oem)/1000000,1) premium_p_exp_m_d60_oem

    from ? where bar = 2
    group by type ) a
    order by premium desc
    limit 10

`
    return q
}

export function getCallPutQuery_Etf(props) {
    const short_etfs_arr = props.short_etfs_arr
    let q = `select
    type,
    sum(premium) premium,
    round(sum(premium_c_exp_d10)/1000000,1) premium_c_exp_d10,
    round(sum(premium_c_exp_d10_otm)/1000000,1) premium_c_exp_d10_otm,
    round(sum(premium_c_exp_d10_oem)/1000000,1) premium_c_exp_d10_oem,

    round(sum(premium_c_exp_d40)/1000000,1) premium_c_exp_d40,
    round(sum(premium_c_exp_d40_otm)/1000000,1) premium_c_exp_d40_otm,
    round(sum(premium_c_exp_d40_oem)/1000000,1) premium_c_exp_d40_oem,

    round(sum(premium_c_exp_d60)/1000000,1) premium_c_exp_d60,
    round(sum(premium_c_exp_d60_otm)/1000000,1) premium_c_exp_d60_otm,
    round(sum(premium_c_exp_d60_oem)/1000000,1) premium_c_exp_d60_oem,

    round(sum(premium_c_exp_m_d60)/1000000,1) premium_c_exp_m_d60,
    round(sum(premium_c_exp_m_d60_otm)/1000000,1) premium_c_exp_m_d60_otm,
    round(sum(premium_c_exp_m_d60_oem)/1000000,1) premium_c_exp_m_d60_oem,

    round(sum(premium_p_exp_d10)/1000000,1) premium_p_exp_d10,
    round(sum(premium_p_exp_d10_otm)/1000000,1) premium_p_exp_d10_otm,
    round(sum(premium_p_exp_d10_oem)/1000000,1) premium_p_exp_d10_oem,

    round(sum(premium_p_exp_d40)/1000000,1) premium_p_exp_d40,
    round(sum(premium_p_exp_d40_otm)/1000000,1) premium_p_exp_d40_otm,
    round(sum(premium_p_exp_d40_oem)/1000000,1) premium_p_exp_d40_oem,

    round(sum(premium_p_exp_d60)/1000000,1) premium_p_exp_d60,
    round(sum(premium_p_exp_d60_otm)/1000000,1) premium_p_exp_d60_otm,
    round(sum(premium_p_exp_d60_oem)/1000000,1) premium_p_exp_d60_oem,

    round(sum(premium_p_exp_m_d60)/1000000,1) premium_p_exp_m_d60,
    round(sum(premium_p_exp_m_d60_otm)/1000000,1) premium_p_exp_m_d60_otm,
    round(sum(premium_p_exp_m_d60_oem)/1000000,1) premium_p_exp_m_d60_oem

    from ? where  premium >= 5000000 and section in ('ETF', 'INDEX') and type not in ${short_etfs_arr}
    group by type 
    order by premium desc
    limit 10
    `
    return q
}


export function getCallPutQuery_Stock() {
    let q = `select
    type,
    sum(premium) premium,
    round(sum(premium_c_exp_d10)/1000000,1) premium_c_exp_d10,
    round(sum(premium_c_exp_d10_otm)/1000000,1) premium_c_exp_d10_otm,
    round(sum(premium_c_exp_d10_oem)/1000000,1) premium_c_exp_d10_oem,

    round(sum(premium_c_exp_d40)/1000000,1) premium_c_exp_d40,
    round(sum(premium_c_exp_d40_otm)/1000000,1) premium_c_exp_d40_otm,
    round(sum(premium_c_exp_d40_oem)/1000000,1) premium_c_exp_d40_oem,

    round(sum(premium_c_exp_d60)/1000000,1) premium_c_exp_d60,
    round(sum(premium_c_exp_d60_otm)/1000000,1) premium_c_exp_d60_otm,
    round(sum(premium_c_exp_d60_oem)/1000000,1) premium_c_exp_d60_oem,

    round(sum(premium_c_exp_m_d60)/1000000,1) premium_c_exp_m_d60,
    round(sum(premium_c_exp_m_d60_otm)/1000000,1) premium_c_exp_m_d60_otm,
    round(sum(premium_c_exp_m_d60_oem)/1000000,1) premium_c_exp_m_d60_oem,

    round(sum(premium_p_exp_d10)/1000000,1) premium_p_exp_d10,
    round(sum(premium_p_exp_d10_otm)/1000000,1) premium_p_exp_d10_otm,
    round(sum(premium_p_exp_d10_oem)/1000000,1) premium_p_exp_d10_oem,

    round(sum(premium_p_exp_d40)/1000000,1) premium_p_exp_d40,
    round(sum(premium_p_exp_d40_otm)/1000000,1) premium_p_exp_d40_otm,
    round(sum(premium_p_exp_d40_oem)/1000000,1) premium_p_exp_d40_oem,

    round(sum(premium_p_exp_d60)/1000000,1) premium_p_exp_d60,
    round(sum(premium_p_exp_d60_otm)/1000000,1) premium_p_exp_d60_otm,
    round(sum(premium_p_exp_d60_oem)/1000000,1) premium_p_exp_d60_oem,

    round(sum(premium_p_exp_m_d60)/1000000,1) premium_p_exp_m_d60,
    round(sum(premium_p_exp_m_d60_otm)/1000000,1) premium_p_exp_m_d60_otm,
    round(sum(premium_p_exp_m_d60_oem)/1000000,1) premium_p_exp_m_d60_oem

    from ? where 
    
   
    
    section in ('STOCK') 
    group by type 
    order by premium desc
    limit 10 `

    // premium >= 5000000 and 
    return q
}


export function getCallPutQuery_EtfShort(props) {
    const short_etfs_arr = props.short_etfs_arr
    let q = `select
    type,
    sum(premium) premium,
    
    round(sum(premium_c_exp_d10)/1000000,1) premium_c_exp_d10,
    round(sum(premium_c_exp_d10_otm)/1000000,1) premium_c_exp_d10_otm,
    round(sum(premium_c_exp_d10_oem)/1000000,1) premium_c_exp_d10_oem,

    round(sum(premium_c_exp_d40)/1000000,1) premium_c_exp_d40,
    round(sum(premium_c_exp_d40_otm)/1000000,1) premium_c_exp_d40_otm,
    round(sum(premium_c_exp_d40_oem)/1000000,1) premium_c_exp_d40_oem,

    round(sum(premium_c_exp_d60)/1000000,1) premium_c_exp_d60,
    round(sum(premium_c_exp_d60_otm)/1000000,1) premium_c_exp_d60_otm,
    round(sum(premium_c_exp_d60_oem)/1000000,1) premium_c_exp_d60_oem,

    round(sum(premium_c_exp_m_d60)/1000000,1) premium_c_exp_m_d60,
    round(sum(premium_c_exp_m_d60_otm)/1000000,1) premium_c_exp_m_d60_otm,
    round(sum(premium_c_exp_m_d60_oem)/1000000,1) premium_c_exp_m_d60_oem,

    round(sum(premium_p_exp_d10)/1000000,1) premium_p_exp_d10,
    round(sum(premium_p_exp_d10_otm)/1000000,1) premium_p_exp_d10_otm,
    round(sum(premium_p_exp_d10_oem)/1000000,1) premium_p_exp_d10_oem,

    round(sum(premium_p_exp_d40)/1000000,1) premium_p_exp_d40,
    round(sum(premium_p_exp_d40_otm)/1000000,1) premium_p_exp_d40_otm,
    round(sum(premium_p_exp_d40_oem)/1000000,1) premium_p_exp_d40_oem,

    round(sum(premium_p_exp_d60)/1000000,1) premium_p_exp_d60,
    round(sum(premium_p_exp_d60_otm)/1000000,1) premium_p_exp_d60_otm,
    round(sum(premium_p_exp_d60_oem)/1000000,1) premium_p_exp_d60_oem,

    round(sum(premium_p_exp_m_d60)/1000000,1) premium_p_exp_m_d60,
    round(sum(premium_p_exp_m_d60_otm)/1000000,1) premium_p_exp_m_d60_otm,
    round(sum(premium_p_exp_m_d60_oem)/1000000,1) premium_p_exp_m_d60_oem

    from ? where premium >= 1000000 and type in ${short_etfs_arr}
    group by type `
    return q
}