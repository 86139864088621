import React from "react"
import { Col , Row, Tooltip } from 'antd';

const Clue = (props) => {
    const params = props.props
    return (
        <>
            <Tooltip 
            placement={props.placement} 
            title={props.text} 
            arrow={'center'}
            >
                <Row>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none" stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    //class="feather feather-help-circle icon-sm mr-1"
                    data-toggle="tooltip" title=""
                    data-original-title="Levels based on dealer positioning of different options greeks.">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
                <Col span={1}>{' '}</Col>
                </Row>
            </Tooltip>
        </>
    )
}

export default Clue