import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';

const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',
    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',
    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',
    price_color: 'white',
}

const FlowChartWithMom = (props) => {
    //console.log('FlowChartWithMom')
    //console.log(props)
    let callData = props.callData
    let putData = props.putData
    let priceData = props.priceData
    let priceDataMom = props.priceDataMom
    let callDataMom = props.callDataMom
    let putDataMom = props.putDataMom
    let priceMarkers = props.priceMarkersTest
    const chartContainerRef = useRef()

    useEffect(() => {
        const chartWidth = chartContainerRef.current.clientWidth
        const handleResize = () => {
            chart.applyOptions({ width: chartWidth });
            chart2.applyOptions({ width: chartWidth });
            chart_call_mom.applyOptions({ width: chartWidth });
            chart_put_mom.applyOptions({ width: chartWidth });
        };
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartWidth,
            height: props.height,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            }
        });

        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.call_areaTopColor,
        }).setData(callData);

        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.put_areaTopColor,
            pane: '1'
        }).setData(putData);

        const priceSeries = chart.addLineSeries({
            priceScaleId: 'left',
            color: colors.price_color
        })
        priceSeries.setData(priceData)
        priceSeries.setMarkers(priceMarkers);


        chart.timeScale().fitContent()

        //price mom
        const chart2 = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartWidth,
            height: '100',
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            }
        });


        chart2.addHistogramSeries({
            base: props.histogramBase,
            color: '#26a69a',
            //above: 300,
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '2',
            scaleMargins: {
                top: 0,
                bottom: 0.4,
            },
        }).setData(priceDataMom)

        chart2.timeScale().fitContent()

        //call
        const chart_call_mom = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartWidth,
            height: '100',
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            },
            
        });
        chart_call_mom.addHistogramSeries({
            base: props.histogramBase,
            color: '#26a69a',
            //above: 300,
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '2',
            scaleMargins: {
                top: 0,
                bottom: 0.4,
            },
        }).setData(callDataMom)
        chart_call_mom.timeScale().fitContent()

        //put mom
        const chart_put_mom = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartWidth,
            height: '100',
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: true
            }
        });
        chart_put_mom.addHistogramSeries({
            base: 0,
            color: '#26a69a',
            //above: 300,
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: '2',
            scaleMargins: {
                top: 0,
                bottom: 0.4,
            },
        }).setData(putDataMom)
        chart_put_mom.timeScale().fitContent()

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
            chart2.remove();
            chart_call_mom.remove()
            chart_put_mom.remove()
        };
    }, [callData, putData, priceData, priceDataMom]);

    return (
        <>
            <div
                ref={chartContainerRef}
            />
        </>
    )
}

export default FlowChartWithMom;