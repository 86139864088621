import React from "react"
import AlaSQL from 'alasql';
import moment from "moment";
import { Tag } from 'antd';

const symbolXRating = {
    SPY: 1,
    QQQ: 2,
    SOXL: 3,
    LABU: 4,
    AAPL: 5,
    MSFT: 6,
    default: 7
}

export function getSymbolListQuery(props) {
    let q = `select *, 
    case
     when symbol ='SPY' then 1 
     when symbol ='QQQ' then 2 
     when symbol ='SOXL' then 3 
     when symbol ='LABU' then 4 
     when symbol ='AAPL' then 5 
     when symbol ='MSFT' then 6 
    else 10 
    end as rating 
from 
(select  symbol from ( select * from ( 
    SELECT distinct symbol FROM OptionFlow.FlowLine ) a 
      ) a ) a order by rating`
    return q
}

export function getFlowLineBdQuery(props) {
    let t_range = props.time_range;
    let q = `select *, 
        case
         when symbol ='SPY' then 1 
         when symbol ='QQQ' then 2 
         when symbol ='SOXL' then 3 
         when symbol ='LABU' then 4 
         when symbol ='AAPL' then 5 
         when symbol ='MSFT' then 6 
        else 10 
        end as rating 
    from 
    (select distinct symbol, datetime, p, c, s, unix from ( select * from ( 
        SELECT symbol, 

        date_add(HOUR, 10, toDateTime(concat(dateString,':00'))) datetime,
        put p, call c, price s, toUnixTimestamp(concat(dateString,':00')) unix  
        FROM OptionFlow.FlowLine ) a 
        where unix >= ${t_range.unix_from} and unix <= ${t_range.unix_to}  ) a ) a`
    //console.log(q)
    return q
}

function prepareRow(props) {
    let data = props.data
    //console.log('prepare')
    //console.log(props)
    let data_1 = []
    let options = {
        divider: 1000000
    }
    if (props.type === 'price') {
        options.divider = 1
    }

    data.forEach(row => {
        data_1.push({
            datetime: String(row.datetime),
            val: row.val
        })
    })
    //console.log('data_1')
    //console.log(data_1)
    //DATE_ADD(datetime, INTERVAL 10 HOUR) datetime,

    let data_2 = AlaSQL(`select datetime, sum(val) val from (
            select  datetime,
            round(val/${options.divider},2) val from ? 
            ) group by datetime order by datetime`, [data_1])
    let data_3 = []


    //console.log('data_2')
    //console.log(data_2)

    data_2.forEach(item => {
        data_3.push({
            time: moment(item.datetime).unix(),
            value: item.val,
        })
    })

    //console.log('data_3')
    //console.log(data_3)
    return data_3
}

export function prepareFlowLineData(props) {
    let data_src = props.data;
    //console.log(data_src)
    let c = AlaSQL(`select datetime, sum(c) val  from ? group by datetime`, [data_src])
    let c_1 = prepareRow({ data: c })

    let put = AlaSQL(`select datetime, sum(p) val  from ? group by datetime`, [data_src])
    let put_1 = prepareRow({ data: put })

    let price = AlaSQL(`select datetime, sum(s) val  from ? group by datetime`, [data_src])
    //console.log(price)
    let price_1 = prepareRow({ data: price, type: 'price' })
    return {
        title: props.title,
        callData_src: c,
        callData: c_1,
        putData: put_1,
        priceData_src: price,
        priceData: price_1
    }
}

export function divideFlowLineDataOnSymbol(props) {
    let data_src = props.data;
    let d_symbol = AlaSQL(`select distinct symbol  from ?`, [data_src])
    //console.log(d_symbol)
    let b = {}, c = {}
    d_symbol.forEach(row => {
        b[row.symbol] = []
    })
    let d = b;
    data_src.forEach(row => {
        d[row.symbol].push(row)
    })
    Object.keys(b).forEach(item => {
        c[item] = prepareFlowLineData({ data: d[item], title: item })
    })
    //console.log(c)
    return c
}


export function LimitMarker(props) {
    //console.log('LimitMarker')
    //console.log(props)
    let options = {
        color: 'green'
    }
    if (props.type === 'sell') {
        options.color = 'red'
    }
    let markers = props.data;
    let first_time = markers[0].time
    let new_markers = []
    let marker_count = 1;
    markers.forEach((row, i) => {
        if (props.count_limit !== marker_count) {
            let time = row.time
            if (time === first_time) {
                let new_row = row;
                new_row.color = options.color
                new_row.text = String(marker_count)
                new_row.key = props.type + String(marker_count)
                new_markers.push(new_row)
            }
            let diff_time = time - first_time
            if (diff_time >= props.min_limit * 60) {
                marker_count = marker_count + 1
                let new_row = row;
                new_row.key = props.type + String(marker_count)
                new_row.color = options.color
                new_row.text = String(marker_count)
                new_markers.push(new_row)
                first_time = row.time
            }
        }
    })
    return new_markers;
}

export function createMarkers(props) {
    let m = []
    let priceData = []
    props.data.priceData.forEach(item => {
        item.val = item.value
        priceData.push(item)
    })
    let priceDataMom = props.data.priceDataMom
    let callDataMom = props.data.callDataMom
    let putDataMom = props.data.putDataMom

    let m_sell = []
    priceDataMom.forEach((row, i) => {
        let price = props.data.priceData[i].value
        let price_mom = row.value
        let call = props.data.callData[i].value
        let call_mom = callDataMom[i].value
        let put = props.data.putData[i].value
        let put_mom = putDataMom[i].value
        if (
            (call > put) && (
                price_mom > props.PriceMarkerParamsBuyPriceMom &&
                call_mom > props.PriceMarkerParamsBuyCallMom &&
                put_mom < props.PriceMarkerParamsBuyPutMom)
        ) {
            let max_price_sql = AlaSQL(`select max(val) max_val from ? 
            where time >= ${row.time}                
            `, [priceData])
            let max_price = max_price_sql[0].max_val
            let buy_pct = (100 - ((price / max_price) * 100)).toFixed(2)
            m.push({
                val: price,
                time: row.time,
                position: 'belowBar',
                color: '#2196F3',
                shape: 'arrowUp',
                text: 'Buy ' + i,
                exit_price: max_price,
                pct: buy_pct + ' %'
            })
        }
        if (
            (call < put) && (
                price_mom < props.PriceMarkerParamsSellPriceMom &&
                call_mom < props.PriceMarkerParamsSellCallMom &&
                put_mom > props.PriceMarkerParamsSellPutMom)
        ) {
            let min_price_sql = AlaSQL(`select min(val) min_val from ? 
                where time >= ${row.time}                
                `, [priceData])
            let min_price = min_price_sql[0].min_val
            let sell_pct = (100 - ((price / min_price) * 100)).toFixed(2) * -1

            m_sell.push({
                val: price,
                time: row.time,
                position: 'aboveBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'Sell ' + i,
                exit_price: min_price,
                pct: sell_pct + ' %'
            })
        }
    })
    let n_m = LimitMarker({
        data: m, type: 'buy', min_limit: props.PriceMarkerParamsBuyTimeLimit,
        count_limit: props.PriceMarkerParamsBuyCountLimit
    })
    //console.log(n_m)
    let m_sell_limit = LimitMarker({
        data: m_sell,
        type: 'sell',
        min_limit: props.PriceMarkerParamsSellTimeLimit,
        count_limit: props.PriceMarkerParamsSellCountLimit
    })
    //console.log(m_sell_limit)
    let n_m_out = [...m_sell_limit, ...n_m]

    return {
        priceMarkers: m,
        //priceMarkersTest: [...m, ...n_m],
        priceMarkersTest: n_m_out,

    }
}


export const tableColumns = [
    {
        title: 'time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'color',
        dataIndex: 'color',
        key: 'color',
        render: text => (
            <Tag color={text}> {text !== "green" ? "SHORT" : "LONG"}  </Tag>
        )
    },
    {
        title: 'price',
        dataIndex: 'val',
        key: 'val',
    },
    {
        title: 'exit_price',
        dataIndex: 'exit_price',
        key: 'exit_price',
    },
    {
        title: 'pct',
        dataIndex: 'pct',
        key: 'pct',
    }
];