import React from "react"
import { Badge, Col, Row } from 'antd'

const STR = {
    legend_call: 'Call',
    legend_put: 'Put',
    legend_price: 'Price',
    legend_spy: 'SPY',
    legend_spy_price: 'SPY Price',
    legend_darkpool: 'DarkPool Flows (M)'
}

const AntLegend = ({ type }) => {
    const Legend = () => {
        switch (type) {
            case 'flow': return (
                <Row>
                    <Col span={24} align='center'>
                        <Badge color='white' text={STR.legend_price} />
                        <Badge color='#229954' text={STR.legend_call} />
                        <Badge color="#E74C3C" text={STR.legend_put} />
                    </Col>
                </Row>
            )
            case 'darkpool': return (
                <Row>
                    <Col span={24} align='center'>
                        <Badge color="#FFB144" text={STR.legend_darkpool} />
                        <Badge color='white' text={STR.legend_spy_price} />

                    </Col>
                </Row>
            )
            default: return (<></>)
        }
    }
    return (<Legend></Legend>)
}

export default AntLegend;

