export const URLs = {
    flowline_api: 'https://europe-west3-aivix-324522.cloudfunctions.net/reload_flowclan_symbol',
    bd_url: 'https://db.vixprofit.com/?add_http_cors_header=1&user=deep&password=qwerty&default_format=JSON',
    //flowline_api: 'https://us-central1-aivix-324522.cloudfunctions.net/reload_flowclan_symbol'
    bd_headers: {
        accept: '*/*',
        'content-type': 'text/plain;charset=UTF-8',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors', //and type='SWEEP'
    }

}