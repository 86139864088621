import { Button } from "antd"
import moment from "moment"
import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AddTestDoc, FindDocByUid } from "../../services/firebase";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import alasql from "alasql";
import Sound from 'react-sound';
import ReactAudioPlayer from 'react-audio-player';
import {
    Avatar, Image, Col, Row, Layout,
    Menu, Modal, Dropdown,
    Radio,
    Select, Switch,
    Tabs
} from "antd";
import soundfile from './files/test.wav'
const pickerRangeValue = {
    work: {
        date_from: '2022-10-07',
        date_to: '2022-10-07'
    }
}


const TabPane = Tabs.TabPane;
const Test0 = (props) => {
    console.log(props)
    const [mode, setMode] = useState('top');
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const gridRef = useRef();
    const [rowData] = useState([
        { make: "Toyota", model: "Celica", price: 35000 },
        { make: "Ford", model: "Mondeo", price: 32000 },
        { make: "Porsche", model: "Boxster", price: 72000 }
    ]);

    const [columnDefs] = useState([
        { field: 'make' },
        { field: 'model' },
        { field: 'price' }
    ])

    const onClick = () => {
        //let time_from = pickerRangeValue.work.date_from + ' 00:00:00'
        //let time_to = pickerRangeValue.work.date_to + ' 23:59:59'

        //console.log(moment.utc(time_from).unix())
        //console.log(moment.utc(time_to).unix())

        /*console.log(pickerRangeValue.work.date_from)
        console.log(moment(pickerRangeValue.work.date_from + ' 00:00:00'))
        const t = moment(pickerRangeValue.work.date_from + ' 00:00:00').utc()
        console.log(t)
        const t2 = moment().utc(pickerRangeValue.work.date_from + ' 00:00:00').tz("America/Toronto").unix()
        console.log(t2)
        console.log(moment().unix())
        console.log(moment().tz("America/Los_Angeles").format())
        console.log(moment(pickerRangeValue.work.date_from + ' 00:00:00'))
        console.log(moment(pickerRangeValue.work.date_from + ' 00:00:00').tz("America/Toronto").unix())*/

        setIsSettingsModalOpen(true)
    }

    const onClickTestNowUtc = () => {
        console.log(moment().utc().unix())
    }

    const onClickAlaSQLTestValue = () => {
        const a = [{ time: 1, value: 1 }, { time: 2, value: 2 }, { time: 3, value: 3 }]
        const out = alasql(' select *, value as  val from ? ', [a])
        console.log(out)
    }

    const onClickJoinArrs = () => {
        console.log('onClickJoinArrs')
        const a = [{ time: 1, value: 1 }, { time: 2, value: 2 }, { time: 3, value: 3 }]
        const b = [{ time: 1, value: 1 }, { time: 2, value: 2 }, { time: 3, value: 3 }]
        const out = alasql(' select * from ? as b left join ? as a on a.time =b.time  where a.time is NULL', [b, a])
        console.log(out)
    }

    const onClickSound = () => {
        let audio = new Audio(soundfile);
        audio.play()

    }
    const [tMenu, setTMenu] = useState()
    const onClickSettingsMenuItem = (e) => {
        //console.log(e)
        setTMenu(e)
    }
    const TestContent = (props) => {
        console.log('TestContent')
        if (props) {
            if (props.props) {
                //console.log(props)
                if (props.props.key === 'darkpool') {
                    return (<><Button>e</Button></>)
                }
                return (<>{props.props.key}</>)
            }
        } return (<></>)
    }

    const handleSettingsCancel = () => {
        setIsSettingsModalOpen(false)
    }

    const onClickMomentTestUnix = () => {
        const u = 1672328880
        //console.log(moment.unix(u)) //1672343280000
        //console.log(moment.unix(u).format('YYYY-MM-DD hh:mm')) //2022-12-30 12:48
        console.log(moment.unix(u).utc().format('YYYY-MM-DD HH:mm')) //2022-12-29 07:48
        var oldDate = moment.unix(u).unix(); //1672343280 
        let b = oldDate
        // here I got the Date in string format
        var newDate = moment.utc('1970-03-27T18:29:59+02:00', "YYYY-MM-DD");
        // now I want to convert it again into unix timestamp and I don't know how to do it.
        //console.log(oldDate, newDate.unix());
    }

    const onClickFirebaseTest = async () => {
        const uid = props.user.uid
        console.log('onClickFirebaseTest')
        console.log(uid)
        //AddTestDoc();
        try {
            const a = await FindDocByUid(uid)
            console.log(a)
        } catch (e) {
            console.log(e)
        }

    }

    return (<>

<Button onClick={onClickTestNowUtc}>TestNowUtc</Button>
        <Button onClick={onClickFirebaseTest}>FirebaseTest</Button>
        <Button onClick={onClickMomentTestUnix}>Moment - test unix</Button>
        <Button onClick={onClickAlaSQLTestValue}>AlaSQL - test value</Button>
        <Button onClick={onClick}> test</Button>

        <div className="example-wrapper ag-theme-alpine" style={{ height: 600 }}>
            <div className='inner-col' style={{ height: 400, width: 600 }}>
                <AgGridReact
                    ref={gridRef}
                    className='inner-col'
                    rowHeight={34}
                    style={{ height: '100%' }}
                    rowData={rowData}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
        </div>
        <Button onClick={onClickJoinArrs}> Join Arrs</Button>
        <Button onClick={onClickSound}>Sound</Button>

        <div>
            <Radio.Group
                onChange={handleModeChange}
                value={mode}
                style={{
                    marginBottom: 8,
                }}
            >
                <Radio.Button value="top">Horizontal</Radio.Button>
                <Radio.Button value="left">Vertical</Radio.Button>
            </Radio.Group>
            <Tabs
                defaultActiveKey="1"
                tabPosition={mode}
                style={{
                    height: 220,
                }}
                items={new Array(30).fill(null).map((_, i) => {
                    const id = String(i);
                    return {
                        label: `Tab-${id}`,
                        key: id,
                        disabled: i === 28,
                        children: `Content of tab ${id}`,
                    };
                })}
            />
        </div>
        <Tabs
            tabPosition={mode}
            style={{
                height: 220,
            }}
            defaultActiveKey="1"
        >
            <TabPane tab="基础信息" key="1">
                1
            </TabPane>
            <TabPane tab="创建组件" key="2">
                <Button>1</Button>
            </TabPane>
            <TabPane tab="组件列表" key="3">
                3
            </TabPane>
        </Tabs>


        <Modal title="Настройки"
            width='70%'
            open={isSettingsModalOpen}
            footer={null}
            bodyStyle={{ height: 500 }}
            onCancel={handleSettingsCancel}
        >
            <>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['options']}
                    onClick={(e) => onClickSettingsMenuItem(e)}
                >
                    <Menu.Item key="options">
                        <Button>1</Button>
                    </Menu.Item>
                    <Menu.Item key="flow">
                        2
                    </Menu.Item>
                    <Menu.Item key="darkpool">
                        3
                    </Menu.Item>

                </Menu>

                <Layout>
                    <TestContent props={tMenu}></TestContent>
                </Layout>
            </>
        </Modal>

    </>)
}

export default Test0
