import React from 'react';
import { Col, Row } from 'antd'
import FlowCard from './FlowCard';
import AntCard from '../../../components/AntCard';

const FlowCardsBlock = (props) => {
    const flowData = props.flowData

    /*return (
        Object.keys(flowData).map((flow, i) =>
            <Col key={i} xl={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }}>
                <FlowCard
                    code={i}
                    title={flowData[flow].symbol}
                    callData={flowData[flow].c}
                    putData={flowData[flow].p}
                    priceData={flowData[flow].s}
                    prepareData={props.prepareData}
                    spinning={props.spinning}
                    legend={{
                        enable: true,
                        type: 'flow'
                    }}
                />
                <Row style={{ margin: 4 }}></Row>
            </Col>
        )
    )*/

    return (
        Object.keys(flowData).map((flow, i) =>
            <Col key={i} xl={{ span: 8 }} md={{ span: 12 }} xs={{ span: 24 }}>
                <FlowCard
                    code={i}
                    title={flowData[flow].title}
                    callData={flowData[flow].callData}
                    putData={flowData[flow].putData}
                    priceData={flowData[flow].priceData}
                    prepareData={props.prepareData}
                    dataPrepared={false}
                    spinning={props.spinning}
                    legend={{
                        enable: true,
                        type: 'flow'
                    }}
                />
                <Row style={{ margin: 4 }}></Row>
            </Col>
        )

    )
}

export default FlowCardsBlock;