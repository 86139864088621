import { signInWithGoogle, FsignInWithEmailAndPassword, registerWithEmailAndPassword } from "../services/firebase";
import { Tabs, notification } from 'antd';
import React from 'react';
import { LockOutlined, UserOutlined, GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Row, Col, Card, Image, Checkbox, Form, Input } from 'antd';
import { useEffect, useState } from "react";
import logo from '../img/logo.png'
import { MaskedInput } from 'antd-mask-input';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'
import './LoginPage.css'

const openNotificationWithIcon = (e) => {
  notification[e.type]({
    message: e.title,
    description: e.description
  });
};

const LoginPage = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [phone, setPhone] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");


  const onClickLogin = () => {
    FsignInWithEmailAndPassword(loginEmail, loginPassword);
  }

  const onClickRegister = () => {
    if (phone.length <= 1) {
      openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Номер телефона не заполнен' })
    } else {
      if (password === passwordCheck) {
        registerWithEmailAndPassword(name, email, password, phone)
      } else {
        openNotificationWithIcon({ type: 'error', title: 'Ошибка', description: 'Пароли не совпадают' })
      }
    }
  }


  return (<>
    <Row style={{ margin: 20 }}></Row>
    <Row>
      <Col span={24} align="center">
        <Image
          preview={false}
          width={150}
          src={logo}
        />
      </Col>
    </Row>
    <Row style={{ margin: 10 }}></Row>
    <Row>
      <Col xl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 2 }}></Col>
      <Col xl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 20 }} >

        <Card
          style={{ background: '#131313' }}
        >
          <Tabs defaultActiveKey="login">


            <Tabs.TabPane tab="Вход" key="login" >
              <Form
                name="normal_login"
                className="login-form" >

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your e-mail !' }]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="E-mail"
                    name="email"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                  />
                </Form.Item>


                <Form.Item name="login_password"
                  rules={[{ required: true, message: 'Please input your Password !' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button"
                    style={{ width: '100%' }}
                    onClick={() => { onClickLogin() }}
                  >
                    Войти
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button

                    className="login-form-button"
                    onClick={signInWithGoogle}
                    style={{ width: '100%' }}
                  >
                    <GoogleOutlined />Войти с помощью Google
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane>



            <Tabs.TabPane tab="Регистрация" key="register">

              <Form name="normal_login"
                className="login-form" >

                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Необходимо заполнить поле "Имя".' }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Имя"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Необходимо заполнить поле "Электронная почта".' }]}
                >
                  <Input
                    name="email"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Электронная почта"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: 'Необходимо заполнить поле "Номер телефона".' }]}
                >
                  <PhoneInput
                    localization={ru}
                    country={'ru'}
                    placeholder="Номер телефона"
                    value={phone}
                    onChange={setPhone}
                    inputProps={{
                      name: 'phone',
                      required: true
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Необходимо заполнить поле "Пароль".' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="passwordCheck"
                  rules={[{ required: true, message: 'Необходимо заполнить поле "Подтвердить пароль".' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Подтвердить пароль"
                    value={passwordCheck}
                    onChange={(e) => setPasswordCheck(e.target.value)}
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button"
                    style={{ width: '100%' }}
                    onClick={() => { onClickRegister() }}
                  >
                    Регистрация
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    onClick={signInWithGoogle}
                    style={{ width: '100%' }}
                  >
                    <GoogleOutlined /> Регистрация с помощью Google
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane>

          </Tabs>
        </Card>
      </Col>
      <Col xl={{ span: 8 }} md={{ span: 8 }} xs={{ span: 2 }}></Col>
    </Row>
  </>
  )
}


/*
const LoginPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  return (
    <div className="container-fluid" style={{ marginTop: "10%" }}>
      <div className="row">
        <div className="col col-2"></div>
        <div className="col col-8">
          <div className="row">
            <div className="col col-6">
              <Card>
                <Card.Body>
                  <Card.Title>User Login</Card.Title>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                    </Form.Group>
                    <hr style={{ margin: "5%" }} />

                    <div className="d-grid gap-2">
                      <Button
                        variant="outline-success"
                        type="submit"
                        onClick={() => {
                          FsignInWithEmailAndPassword(loginEmail, loginPassword);
                        }}
                      >
                        Login
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={signInWithGoogle}
                      >
                        <i className="fab fa-google"></i>Sign-in with Goolge
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col col-6">
              <Card>

                <Card.Body>
                  <Card.Title>User Registration</Card.Title>
                  <Card.Text>First time visiting? register Here</Card.Text>
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <hr style={{ margin: "5%" }} />

                    <div className="d-grid gap-2">
                      <Button
                        variant="outline-danger"
                        type="submit"
                        onClick={() => {
                          registerWithEmailAndPassword(name, email, password);
                        }}
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-col-2"></div>
      </div>
    </div>
  );
};



*/
export default LoginPage;