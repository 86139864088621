import React from "react"
import { Col } from "antd"
import MiniCardChart from "../MiniCardChart"

const MiniChartsBar = (props) => {
    const arr = props.data
    if (arr) {
        if (arr.length > 0) {
            return (<>
                {arr.map((item, i) => {
                    return (<Col xl={{ span: 3 }} sm={{ span: 6 }} xs={{ span: 12 }} key={i}>
                        <MiniCardChart
                            key={i}
                            style={{ marginRight: 15, width: '100%', marginBottom: 10 }}
                            props={item}>
                        </MiniCardChart>
                    </Col>
                    )
                })}

            </>
            )
        } else {
            return (<></>)
        }
    }
}

export default MiniChartsBar