import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
import AlaSQL from 'alasql';

const colors = {
    backgroundColor: '#141414',
    textColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',

    call_areaTopColor: '#229954',
    call_areaBottomColor: 'rgba(41, 98, 255, 0.88)',

    put_areaTopColor: '#E74C3C',
    put_areaBottomColor: '#F5B7B1',

    price_color: 'white',
}



const LightWeightChartExtra = (props) => {
    //console.log('LightWeightChartExtra')
    //console.log(props)
    //const chartDatas = props.chartDatas;
    const data = props.chartData.data;
    const type = props.chartData.type
    const timeVisible = props.timeVisible
    const customPriceLine = props.isCustomPriceLine ? props.isCustomPriceLine : false
    const tableRowSelected = props.tableRowSelected ? props.tableRowSelected : null;

    const chartContainerRef = useRef();
    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        };
        const chart = createChart(chartContainerRef.current, {
            layout: {
                backgroundColor: colors.backgroundColor,
                textColor: colors.textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: 300,
            grid: {
                vertLines: {
                    color: colors.backgroundColor
                },
                horzLines: {
                    color: colors.backgroundColor
                }
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            timeScale: {
                timeVisible: timeVisible
            }
        });



        //console.log(visibleRange)

        if (type === 'Candlestick') {
            const cursorSpy = true
            const max_time = AlaSQL('select max(time) time from ? ', [data])[0].time
            const min_time = AlaSQL('select min(time) time from ? ', [data])[0].time
            //.fitContent()
            let visibleRange = {
                from: min_time - 86400,
                //from: max_time - 86400,
                to: max_time,
            }
            const minPriceLine = {
                price: 17,
                color: '#ef5350',
                lineWidth: 2,
                lineStyle: 2, // LineStyle.Dashed
                axisLabelVisible: true,
                title: 'min price',
            };



            const candlestickSeries = chart.addCandlestickSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
                borderVisible: false,
                wickUpColor: '#26a69a',
                wickDownColor: '#ef5350',
                lastValueVisible: false,
                priceLineVisible: false,
            })
            candlestickSeries.setData(data)
            //
            const lineColor = {
                'Put': '#E74C3C',
                'Call': '#229954'
            }
            const markerProps = {
                'Put': {
                    position: 'aboveBar',
                    text: 'PUT',
                    shape: 'arrowDown',
                },
                'Call': {
                    position: 'belowBar',
                    text: 'CALL',
                    shape: 'arrowUp',
                }
            }
            //let timestamp_bd = 1670529540
            //let strike = 180
            //let spot = 180
            let colorDef = '#ef5350'
            if (tableRowSelected) {
                if (tableRowSelected.data) {
                    //console.log(tableRowSelected.data)
                    if (tableRowSelected.data.unix_utc) {
                        let timestamp_bd = tableRowSelected.data.unix_utc
                        const strike = tableRowSelected.data.strike
                        const spot = tableRowSelected.data.spot
                        const optionType = tableRowSelected.data.optionType
                        colorDef = lineColor[optionType]

                        const markerSeries = chart.addLineSeries({
                            price: strike,
                            color: colorDef,
                            lineWidth: 2,
                            //lineStyle: 2, // LineStyle.Dashed
                            axisLabelVisible: true,
                            title: 'STRIKE',
                        })
                        markerSeries.setData([{ time: timestamp_bd, value: strike }])

                        const spotSeries = chart.addLineSeries({
                            price: strike,
                            color: colorDef,
                            lineWidth: 2,
                            type: 'solid',
                            //lineStyle: 2, // LineStyle.Dashed
                            axisLabelVisible: true,
                            title: 'SPOT',
                        })
                        spotSeries.setData([{ time: timestamp_bd, value: spot }])

                        const markers = [{
                            time: timestamp_bd,
                            position: markerProps[optionType].position,
                            color: colorDef,
                            //shape: 'circle',
                            text: `${markerProps[optionType].text} ${strike}`,
                            shape: markerProps[optionType].shape,
                        },
                        ];
                        spotSeries.setMarkers(markers);
                    }
                }
            }
            //const timestamp_bd = tableRowSelected.data.unix_correct


            //.createPriceLine(minPriceLine);

            if (cursorSpy) {

                if (customPriceLine) {
                    if (tableRowSelected) {
                        const timestamp_bd = tableRowSelected.data.unix_utc
                        const timeFromType = 1

                        let range_from = min_time
                        if (timeFromType === 2) {
                            range_from = timestamp_bd - 259200
                        }

                        if (timestamp_bd) {
                            visibleRange.from = range_from
                            visibleRange.to = timestamp_bd + 259200
                        } else {
                            console.log('timestamp_bd null')
                        }
                    }
                }
            }
            chart.timeScale().setVisibleRange(visibleRange);

        }

        if (type === 'more') {
            data.forEach(e => {
                const type = e.type;
                if (type === 'histogram') {
                    chart.addHistogramSeries({
                        priceScaleId: e.align,
                        color: e.color,
                    }).setData(e.data);
                }
                if (type === 'line') {
                    chart.addLineSeries({
                        priceScaleId: e.align,
                        color: e.color,

                    }).setData(e.data)
                }
            })

        }

        if (type === 'more') {
            chart.timeScale().fitContent()
        }





        /*chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.call_areaTopColor,
        }).setData(callData);
        chart.addLineSeries({
            priceScaleId: 'right',
            color: colors.put_areaTopColor,
        }).setData(putData);
        chart.addLineSeries({
            priceScaleId: 'left',
            color: colors.price_color
        }).setData(priceData)*/

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data, type, timeVisible, tableRowSelected]);


    return (
        <>
            <div
                ref={chartContainerRef}
            />
        </>
    )
}

export default LightWeightChartExtra;