import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Navigate, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import { Spin } from 'antd';
import './App.css';
import { Main } from './pages/main'
import Analytics from './services/analytics'
import { getAuth } from '@firebase/auth';
import { getUserDocById, getTranslationConfig, getPageConfigs } from "./services/firebase";
import moment from "moment"

const auth = getAuth();
const menu_default = 'options'


function AppFirebase2() {

    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [translationConfig, setTranslationConfig] = useState(null);
    const [pageConfigs, setPageConfigs] = useState(null);

    const [usedPathName, setUsedPathName] = useState('/app/' + menu_default)
    const [pathnameDetected, setPathNameDetected] = useState(false)
    const [spinning, setSpinning] = useState(true)

    let navigate = useNavigate();
    let menu = 'options'
    let location = useLocation();
    let pathname = location.pathname

    const { search } = useLocation();
    const analytics = useCallback(() => {
        Analytics({ path: pathname, search: search, title: pathname.split("/")[1] });
    }, [pathname, search]);

    useEffect(() => {
        analytics();
    }, [analytics]);

    if (!pathnameDetected) {
        if (pathname.length > 5) {
            menu = String(pathname).split(/\//)[2]
            if (!menu) {
                menu = 'options'
            }
            setUsedPathName('/app/' + menu)
            setPathNameDetected(true)
        }
    }
    if (!menu) {
        setUsedPathName('/app/options')
    }


    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (user) {
                const uid = user.uid

                try {

                    const getTranslation = async () => {
                        const translationDoc = await getTranslationConfig('zoom')
                        setTranslationConfig(translationDoc)
                    }
                    getTranslation()

                    const FgetPageConfigs = async () => {
                        const aDoc = await getPageConfigs()
                        setPageConfigs(aDoc)
                    }
                    FgetPageConfigs()

                    const getUsers = async () => {
                        //const users = await fetchUsers();
                        const userDoc = await getUserDocById(uid)
                        if (userDoc.status === 'ok') {
                            if (userDoc.data) {
                                const data = userDoc.data
                                if (data.payments) {
                                    if (data.payments.tariff && data.payments.timestamp_end) {
                                        const timestamp_end = data.payments.timestamp_end
                                        const nowUtc = Number(moment().utc().unix())
                                        if (nowUtc >= timestamp_end) {
                                            //console.log(timestamp_end)
                                            userDoc.pay_status = 'need_extension'
                                        } else {
                                            userDoc.pay_status = 'activated'
                                        }
                                    } else {
                                        userDoc.pay_status = 'need_activate'
                                    }
                                } else {
                                    userDoc.pay_status = 'need_activate'
                                }
                            }
                        }
                        //setUsers(users);
                        setUserInfo(userDoc)


                        setSpinning(false)
                        navigate('/app')
                    };
                    getUsers()



                } catch (e) {
                    console.log(e)
                }



            }
            if (!user) {
                navigate('/login')
                setSpinning(false)
            }
            setUser(user)

        })
    }, [user])


    const ProtectedRoute = ({ user, children }) => {
        if (!user) {
            return <Navigate to="/login" replace />;
        }
        return children;
    };

    const MainTest = () => {
        return (<>MainTest</>)
    }
    return (
        <Routes>
            <Route exact path="/app" element={<Navigate to={usedPathName} />} />
            <Route path="/app" element={<ProtectedRoute user={user}>
                <Main user={user} userInfo={userInfo} translationConfig={translationConfig}
                    pageConfigs={pageConfigs}
                />
            </ProtectedRoute>} >
                <Route path=":type" >
                </Route>
            </Route>
            <Route path="/login" element={
                <Spin tip="Loading..." spinning={spinning} style={{ background: 'black' }}>
                    <LoginPage />
                </Spin>
            } />
        </Routes>
    )
}

export default AppFirebase2;