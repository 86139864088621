import React from "react"
import { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Button, Modal, Row,
    Space,
    Tooltip, Typography
} from "antd"
import Clue from "../Clue";
const { Text } = Typography;

const YouTubeOptsDefault = {
    height: 450, width: '100%',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
};

const ModalOptsDefault = {
    width: '70%',
    height: 500
}

let idDatas = {
    options: {}
}


const TitleExtra = (props) => {
    const YouTubeRef = useRef()
    const id = props.id
    const pagePosition = props.pagePosition
    const pageConfigs = props.pageConfigs
    const [clueModalOpen, setClueModalOpen] = useState(false)

    const onClickClueButton = (e) => {
        setClueModalOpen(true)
    }

    const handleOk = () => {
        setClueModalOpen(false);
    };

    const handleCancel = () => {
        setClueModalOpen(false);
    }


    const TitleContent = (e) => {
        //console.log('TitleContent')
        if (pageConfigs) {
            if (pageConfigs.data) {
                const pagesData = pageConfigs.data
                if (pagesData[pagePosition]) {
                    const pageData = pagesData[pagePosition]
                    if (pageData.data) {
                        //console.log(pageData.data)
                        const data = JSON.parse(pageData.data)
                        if (data[id]) {
                            const idData = data[id]
                            /*const idData = {
                                text: 'TEST TEXT',
                                clue_type: 'button',
                                clue_text: 'Описание',
                                clue_placement: 'bottomLeft',
                                modal_type: 'youtube',
                                modal_opts: {
                                    width: '70%',
                                    height: 500
                                },
                                ytube_video_id: 'UtDRNegjH0A',
                                ytube_opts: {
                                    height: 450,
                                    width: '100%',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 1,
                                        start: 40
                                    },
                                },
                                //ytube_descr: "Описание"
                            }*/
                            idDatas[pagePosition] = idData
                            if (idData.text) {
                                const text = idData.text
                                if (idData.clue_type) {
                                    const clue_type = idData.clue_type
                                    const clue_text = idData.clue_text ? idData.clue_text : text
                                    const clue_placement = idData.clue_placement ? idData.clue_placement : 'bottomLeft'

                                    if (clue_type === 'button') {
                                        return (<>
                                            <Space direction="vertical">
                                                <Space wrap>
                                                    <Tooltip title={clue_text}>
                                                        <Button
                                                            className='title-extra'
                                                            type="text"
                                                            shape="circle"
                                                            size="small"
                                                            icon={<QuestionCircleOutlined />}
                                                            onClick={onClickClueButton}
                                                        />
                                                    </Tooltip>
                                                    <Text>{text}</Text>
                                                </Space>
                                            </Space>
                                        </>)
                                    }
                                    if (clue_type === 'tooltip') {
                                        return (<>
                                            <Row align='middle'>
                                                <Clue
                                                    placement={clue_placement}
                                                    text={clue_text}
                                                ></Clue> <Text>{text}</Text>
                                            </Row>
                                        </>)
                                    }
                                    return (<>{idData.text}</>)
                                } else {
                                    return (<>{idData.text}</>)
                                }
                            } else {
                                return (<>{props.default}</>)
                            }

                        } else {
                            return (<>{props.default}</>)
                        }
                    } else {
                        return (<>{props.default}</>)
                    }

                } else {
                    return (<>{props.default}</>)
                }
            }
        }
    }

    const onPlayerReady = (event) => {
        event.target.pauseVideo();
    }

    const ModalContent = (props) => {
        const idData = idDatas[pagePosition]
        if (idData.modal_type === 'youtube') {
            if (idData.ytube_video_id) {
                const videoId = idData.ytube_video_id
                if (videoId.length > 5) {
                    const DescrContent = (props) => {
                        if (props.descr) {
                            if (props.descr.length > 2) {
                                return (<Text>{props.descr}</Text>)
                            }
                        }
                        return (<></>)
                    }
                    return (
                        <>
                            <YouTube
                                syle={{
                                    height: idData.ytube_opts ? (
                                        idData.ytube_opts.height ?
                                            idData.ytube_opts.height : YouTubeOptsDefault.height
                                    ) : YouTubeOptsDefault.height,

                                    width: idData.ytube_opts ? (
                                        idData.ytube_opts.width ?
                                            idData.ytube_opts.width : YouTubeOptsDefault.width
                                    ) : YouTubeOptsDefault.width,
                                }}
                                ref={YouTubeRef}
                                videoId={videoId}
                                opts={idData.ytube_opts ? idData.ytube_opts : YouTubeOptsDefault}
                                onReady={onPlayerReady}
                            />
                            <DescrContent descr={idData.ytube_descr}></DescrContent>
                        </>
                    )
                } else {
                    return (
                        <>Скоро здесь будет видео!</>
                    )
                }
            } else {
                return (
                    <>Скоро здесь будет видео!</>
                )
            }
        }
        return (<>Информация</>)
    }

    return (
        <>
            <TitleContent></TitleContent>
            <Modal
                open={clueModalOpen}
                
                title={idDatas[pagePosition] ? (
                    idDatas[pagePosition][id] ? (
                        idDatas[pagePosition][id].text ? idDatas[pagePosition][id].text : props.default
                    ) : props.default
                ) : props.default}

                width={
                    idDatas[pagePosition].modal_opts ? (
                        idDatas[pagePosition].modal_opts.width ?
                            idDatas[pagePosition].modal_opts.width : ModalOptsDefault.width
                    ) : ModalOptsDefault.width
                }
                footer={null}
                bodyStyle={{
                    height: idDatas[pagePosition].modal_opts ? (
                        idDatas[pagePosition].modal_opts.height ?
                            idDatas[pagePosition].modal_opts.height : ModalOptsDefault.height
                    ) : ModalOptsDefault.height

                }}
                onCancel={handleCancel}
            >
                <ModalContent></ModalContent>
            </Modal>
        </>
    )
}

export default TitleExtra