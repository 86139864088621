import React, { useState, useEffect } from 'react';
import { Spin, Col } from 'antd'
import AntCard from '../../../components/AntCard';
import FlowChart from "./FlowChart";
import AntLegend from '../../../components/AntLegend';
import AlaSQL from "alasql";
import moment from "moment";

function prepare(props) {
    let data = props.data
    console.log('prepare')
    console.log(props)
    let data_1 = []
    let options = {
        divider: 1000000
    }
    if (props.type === 'price') {
        options.divider = 1
    }

    Object.keys(data).forEach(item => {
        data_1.push({
            datetime: String(item),
            val: data[item]
        })
    })
    let data_2 = AlaSQL(`select datetime, sum(val) val from (
            select  DATE_ADD(datetime, INTERVAL 10 HOUR) datetime,
            round(val/${options.divider},2) val from ? 
            ) group by datetime order by datetime`, [data_1])
    let data_3 = []
    data_2.forEach(item => {
        data_3.push({
            time: moment(item.datetime).unix(),
            value: item.val,
        })
    })
    return data_3
}

const FlowCard = (props) => {
    //console.log(props)
    const FlowData = props
   /* const [dataPrepared, setDataPrepared] = useState(props.dataPrepared)
    const [prepare, setPrepare] = useState(props.prepare)
    const prepareData = props.prepareData;
    //const [callData, setCallData] = useState(props.callData)
    //const [putData, setPutData] = useState(props.putData)
    //const [priceData, setPriceData] = useState(props.priceData)

    const [FlowData, setFlowData] = useState(props)
    if (prepareData) {
        if (!dataPrepared) {
            console.log('data not prepared')
            let callData = prepare({ data: props.callData, type: 'call' })
            let putData = prepare({ data: props.putData, type: 'put' })
            let priceData = prepare({ data: props.priceData, type: 'price' })
            setFlowData({ callData: callData, putData: putData, priceData: priceData })
            setDataPrepared(true)
        }
    }
*/
    return (
        <Spin spinning={props.spinning}>
            <AntCard
                id={props.code}
                key={props.code}
                title={props.title}
                children={<>
                    <FlowChart
                        title={props.title}
                        callData={FlowData.callData}
                        putData={FlowData.putData}
                        priceData={FlowData.priceData}
                    ></FlowChart>
                    <AntLegend type={props.legend.type}></AntLegend>
                </>}
            >
            </AntCard>
        </Spin >
    )
}

export default FlowCard;