import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
const PixelId = '3372707062957824'

export const pixelPageView = (email) => {
    const advancedMatching = { em: email };
    ReactPixel.init(PixelId, advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view
    //pr.track(event, data);
   
}


export const pixelCompleteRegistration = (email) => {
    const advancedMatching = { em: email };
    ReactPixel.init(PixelId, advancedMatching, options);
    ReactPixel.track('CompleteRegistration', advancedMatching); 
}