import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Select } from "antd"
import LightWeightChartLevels from "../../../components/LightWeightChartLevels"

const LevelChart = (props) => {

    //console.log(props) 
    const tickerData = props.tickerData
    const priceData = props.priceData
    const levelsSelected = props.levels
    //console.log(priceData)
    return (
        <>
            <LightWeightChartLevels
                //props={data}
                
                priceData={priceData}
                tickerData={tickerData}
                timeFrame={props.timeFrame}
                levelsSelected={levelsSelected}
            />
        </>
    )
}

export default LevelChart

/*
 
*/