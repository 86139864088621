import React,{ useState, useEffect } from 'react';
import * as trendyways from 'trendyways'
import * as td from '../Flow/components/testData';
import { Card, Col, DatePicker, Divider, Table, Tag, InputNumber, Row, Select, Slider } from 'antd';
import AlaSQL from "alasql";
import moment from 'moment';

import FlowChartWithMom from '../Flow/components/FlowChartWithMom';
import AntDateSelectOptions from '../../components/AntDateSelectOptions';
import { URLs } from '../../urls';
import * as Dates from '../../libraries/DatesOld'
import * as CONFIG from './config'

const storageApp = 'XProfit'
const pagePosition = 'Test'
const { RangePicker } = DatePicker;
const { Option } = Select;

const Test = () => {

    const [symbolListData, setSymbolListData] = useState([{ symbol: 'SPY' }, { symbol: 'QQQ' }])
    const [symbolListValue, setSymbolListValue] = useState('SPY')
    const [symbolListLoaded, setSymbolListLoaded] = useState(false)

    useEffect(() => {
        if (!symbolListLoaded) {
            fetch(URLs.bd_url, {
                method: 'POST',
                body: CONFIG.getSymbolListQuery()
            })
                .then((resp_json) => resp_json.json())
                .then((resp) => {
                    setSymbolListLoaded(true)
                    setSymbolListData(resp.data)
                })
        }
    }, [symbolListLoaded]);

    const onChangeSymbolSelect = (value) => {
        console.log(`selected ${value}`);
        setSymbolListValue(value)
        getChartsData({ time_range: TimeData, symbol: value })
    };

    const SymbolSelect = (props) => {
        return (
            <Select
                style={{ width: '100%' }}
                listHeight={'30%'}
                defaultValue={symbolListValue}
                onChange={onChangeSymbolSelect}
            >
                {(props.list).map((row, index) => {
                    //console.log(row)
                    return (
                        <Option key={index} value={row.symbol}>
                            {row.symbol}
                        </Option>
                    )
                })}
            </Select >
        )
    }

    const [defaultRange, setDefaultRange] = useState({
        from: moment().startOf("month"),
        to: moment().endOf("month"),
        unix_from: moment().startOf("month").unix(),
        unix_to: moment().endOf("month").unix(),
        dateFormat: 'DD-MM-YYYY'
    })

    const [TimeData, setTimeData] = useState(defaultRange);
    //const [ChartData, setChartData] = useState(td.testData)
    const [ChartData, setChartData] = useState(td.testData)

    // Filters
    const [RangePickerValue, setRangePickerValue] = useState(null)
    const [DateSelectOptionsRaw, setDateSelectOptionsRaw] = useState(Dates.initDateRange())
    const [DateSelectOptionsValue, setDateSelectOptionsValue] = useState()
    const [inputValue, setInputValue] = useState(1);
    const [inputValueMarkerPriceMom, setInputValueMarkerPriceMom] = useState(0);
    const onChangeMarkerPriceMom = (newValue) => {
        setInputValueMarkerPriceMom(newValue);
    };
    const [inputValueMarkerCallMom, setInputValueMarkerCallMom] = useState(0);
    const onChangeMarkerCallMom = (newValue) => {
        setInputValueMarkerCallMom(newValue);
    };
    const [inputValueMarkerPutMom, setInputValueMarkerPutMom] = useState(0);
    const onChangeMarkerPutMom = (newValue) => {
        setInputValueMarkerPutMom(newValue);
    };
    const [inputValueCallMomMark, setInputValueCallMomMark] = useState(0);

    const [inputValueMarkerPriceTimeLimit, setInputValueMarkerPriceTimeLimit] = useState(10);
    const onChangeMarkerPriceTimeLimit = (newValue) => {
        setInputValueMarkerPriceTimeLimit(newValue);
    };


    // price buy params
    // min limit
    const [PriceMarkerParamsBuyPriceMom, setPriceMarkerParamsBuyPriceMom] = useState(0);
    const onChangePriceMarkerParamsBuyPriceMom = (newValue) => { setPriceMarkerParamsBuyPriceMom(newValue); };
    // count limit
    const [PriceMarkerParamsBuyCountLimit, setPriceMarkerParamsBuyCountLimit] = useState(5);
    const onChangePriceMarkerParamsBuyCountLimit = (newValue) => { setPriceMarkerParamsBuyCountLimit(newValue); };

    const [PriceMarkerParamsBuyCallMom, setPriceMarkerParamsBuyCallMom] = useState(0);
    const onChangePriceMarkerParamsBuyCallMom = (newValue) => { setPriceMarkerParamsBuyCallMom(newValue); };
    // put
    const [PriceMarkerParamsBuyPutMom, setPriceMarkerParamsBuyPutMom] = useState(0);
    const onChangePriceMarkerParamsBuyPutMom = (newValue) => { setPriceMarkerParamsBuyPutMom(newValue); };
    const [PriceMarkerParamsBuyTimeLimit, setPriceMarkerParamsBuyTimeLimit] = useState(10);
    const onChangePriceMarkerParamsBuyTimeLimit = (newValue) => { setPriceMarkerParamsBuyTimeLimit(newValue); };


    // price sell params
    // min limit
    const [PriceMarkerParamsSellPriceMom, setPriceMarkerParamsSellPriceMom] = useState(0);
    const onChangePriceMarkerParamsSellPriceMom = (newValue) => { setPriceMarkerParamsSellPriceMom(newValue); };
    // count limit
    const [PriceMarkerParamsSellCountLimit, setPriceMarkerParamsSellCountLimit] = useState(5);
    const onChangePriceMarkerParamsSellCountLimit = (newValue) => { setPriceMarkerParamsSellCountLimit(newValue); };

    const [PriceMarkerParamsSellCallMom, setPriceMarkerParamsSellCallMom] = useState(0);
    const onChangePriceMarkerParamsSellCallMom = (newValue) => { setPriceMarkerParamsSellCallMom(newValue); };
    // put
    const [PriceMarkerParamsSellPutMom, setPriceMarkerParamsSellPutMom] = useState(0);
    const onChangePriceMarkerParamsSellPutMom = (newValue) => { setPriceMarkerParamsSellPutMom(newValue); };
    const [PriceMarkerParamsSellTimeLimit, setPriceMarkerParamsSellTimeLimit] = useState(10);
    const onChangePriceMarkerParamsSellTimeLimit = (newValue) => { setPriceMarkerParamsSellTimeLimit(newValue); };


    const [inputValueMomentum, setinputValueMomentum] = useState(10);
    const [TableData, setTableDate] = useState()
    const [MarkersData, setMarkersData] = useState({ priceMarkersTest: [] })


    function getChartsData(props) {
        console.log('getChartsData')
        let resp_0 = [], resp_1 = [], resp_2 = [], resp_3 = [], resp_4 = [], resp_5 = [], resp_6 = {}
        let rating = 0, ratingFound = false
        fetch(URLs.bd_url, {
            method: 'POST',
            body: CONFIG.getFlowLineBdQuery(props)
        })
            .then((resp_json) => resp_json.json())
            .then((resp) => {
                //console.log(resp)
                resp_0 = AlaSQL(`select symbol, datetime,  c,    p,   s from ? 
                order by rating, symbol, unix
                 `, [resp.data])

                resp_1 = AlaSQL(`select datetime,  sum(c) c,   sum(p) p,  sum(s) s  from ?  
                group by datetime 
                order by datetime`, [resp_0])

                let resp_symbol = CONFIG.divideFlowLineDataOnSymbol({ data: resp_0 })
                if (resp_symbol[props.symbol]) {
                    let chartData = resp_symbol[props.symbol]
                    console.log('dataWork')
                    console.log(chartData)
                    let b = [];
                    let call_data_mom = [],
                        put_data_mom = [];
                    (chartData.priceData).forEach(row => {
                        b.push({
                            time: row.time,
                            'c': row.value
                        })
                    });
                    (chartData.callData).forEach(row => {
                        call_data_mom.push({
                            time: row.time,
                            'c': row.value
                        })
                    });
                    (chartData.putData).forEach(row => {
                        put_data_mom.push({
                            time: row.time,
                            'c': row.value
                        })
                    })
                    let callDataMom = trendyways.momentum(b, inputValueMomentum);
                    let call_data_mom_1 = trendyways.momentum(call_data_mom, inputValueMomentum);
                    let put_data_mom_1 = trendyways.momentum(put_data_mom, inputValueMomentum);
                    let d = [];
                    let call_data_mom_2 = [], put_data_mom_2 = [];
                    callDataMom.forEach(row => {
                        d.push({
                            time: row.time,
                            value: !row.mom ? null : row.mom * 10,
                            color: !row.mom ? null : (row.mom < 0 ? 'red' : 'green')
                        })
                    })
                    call_data_mom_1.forEach(row => {
                        call_data_mom_2.push({
                            time: row.time,
                            value: !row.mom ? null : row.mom * 10,
                            color: !row.mom ? null : (row.mom < 0 ? 'red' : 'green')
                        })
                    })
                    put_data_mom_1.forEach(row => {
                        put_data_mom_2.push({
                            time: row.time,
                            value: !row.mom ? null : row.mom * 10,
                            color: !row.mom ? null : (row.mom < 0 ? 'red' : 'green')
                        })
                    })
                    chartData.priceDataMom = d
                    chartData.callDataMom = call_data_mom_2
                    chartData.putDataMom = put_data_mom_2
                    setChartData(chartData)
                    let markers = CONFIG.createMarkers({
                        data: chartData,
                        PriceMarkerParamsBuyPriceMom: PriceMarkerParamsBuyPriceMom,
                        PriceMarkerParamsBuyCallMom: PriceMarkerParamsBuyCallMom,
                        PriceMarkerParamsBuyPutMom: PriceMarkerParamsBuyPutMom,
                        PriceMarkerParamsSellPriceMom: PriceMarkerParamsSellPriceMom,
                        PriceMarkerParamsSellCallMom: PriceMarkerParamsSellCallMom,
                        PriceMarkerParamsSellPutMom: PriceMarkerParamsSellPutMom,
                        PriceMarkerParamsBuyTimeLimit: PriceMarkerParamsBuyTimeLimit,
                        PriceMarkerParamsBuyCountLimit: PriceMarkerParamsBuyCountLimit,
                        PriceMarkerParamsSellTimeLimit: PriceMarkerParamsSellTimeLimit,
                        PriceMarkerParamsSellCountLimit: PriceMarkerParamsSellCountLimit
                    })
                    console.log(markers)
                    setMarkersData(markers)
                    let tableData = AlaSQL('select * from  ? order by time desc', [markers.priceMarkersTest])
                    setTableDate(tableData)
                } else {
                    setChartData(td.testData)
                }
            })
    }

    function updateData(props) {
        getChartsData({
            time_range: props.time_range,
            symbol: props.symbol
        })
    }
/*
    const [firstLoaded, setFirstLoaded] = useState(false)
    function e_get_saved_date_options() {
        let value = 'today'
        let new_date_options = DateSelectOptionsRaw[value]
        if (localStorage.getItem(`${storageApp}_${pagePosition}_${storageDateOptionsId}`)) {
            let stor_data = localStorage.getItem(`${storageApp}_${pagePosition}_${storageDateOptionsId}`)
            console.log(stor_data)
            let p = JSON.parse(stor_data)
            if (p.work) {
                if (p.value === 'fixed') {
                    new_date_options = p
                }
            }
        } else {
        }
        return new_date_options
    }
    useEffect(() => {
        if (!firstLoaded) {
            //console.log('firstLoaded')
            setFirstLoaded(true)
            let a = e_get_saved_date_options()
            //console.log(a)
            setRangePickerValue(a.value)
            let time_range = {
                from: a.work.date_from,
                to: a.work.date_to,
                unix_from: a.unix.from,
                unix_to: a.unix.to
            }
            setTimeData(time_range)
            setDefaultRange({
                from: moment(a.work.date_from),
                to: moment(a.work.date_to),
                unix_from: a.unix.from,
                unix_to: a.unix.to,
                dateFormat: 'DD-MM-YYYY'
            })
            updateData({ time_range: time_range, symbol: symbolListValue })
        }
    }, []);*/


    const [inputValueHeight, setinputValueHeight] = useState(300);
    const onChangeHeight = (newValue) => {
        setinputValueHeight(newValue);
    };
    const [inputValueScaleMarginsTop, setinputValueScaleMarginsTop] = useState(0.5);
    const [inputValueScaleMarginsBottom, setinputValueScaleMarginsBottom] = useState(0.5);
    const [inputValueHistogramBase, setinputValueHistogramBase] = useState(0);

    const onChangeMomentum = (newValue) => {
        setinputValueMomentum(newValue);
    };


    // Filters
    const storageDateOptionsId = 'dateOptions'

    // AntD RangePicker
    const onChangeRangePicker = (value) => {
        let unix_from = Number((((value[0]._d).getTime()) / 1000).toFixed(0))
        let unix_to = Number((((value[1]._d).getTime()) / 1000).toFixed(0)) + 86399
        let time_range = {
            unix_from: unix_from,
            unix_to: unix_to
        }
        setTimeData(time_range)
        if (RangePickerValue === 'fixed') {
            setRangePickerValue(value)
            // ==> ! getChartsData({ time_range: time_range });
        } else {
            RangePickerValue('fixed')
        }
        const a = {
            value: 'fixed',
            from: moment(value[0]._d),
            to: moment(value[1]._d),
            unix: {
                from: unix_from,
                to: unix_to
            },
            work: {
                date_from: moment(value[0]._d).format('YYYY-MM-DD'),
                date_to: moment(value[1]._d).format('YYYY-MM-DD')
            }
        }
        localStorage.setItem(`${storageApp}_${pagePosition}_${storageDateOptionsId}`, JSON.stringify(a));
    }

    // AntDateSelectOptions
    const onChangeAntDateSelectOptions = (e) => {
        //const storageId = 'dateOptions'
        const pickerRangeValue = DateSelectOptionsRaw[e]
        setDateSelectOptionsValue(e)
        if (e === 'fixed') {
            localStorage.setItem(`${storageApp}_${pagePosition}_${storageDateOptionsId}`, JSON.stringify(pickerRangeValue));
        } else {
            setRangePickerValue([
                moment(pickerRangeValue.visual.date_from, defaultRange.dateFormat),
                moment(pickerRangeValue.visual.date_to, defaultRange.dateFormat)
            ])
            let unix_from = pickerRangeValue.unix.from
            let unix_to = pickerRangeValue.unix.to
            //console.log('picker_ranges_value')
            //console.log(picker_ranges_value)
            let time_range = {
                from: pickerRangeValue.work.date_from,
                to: pickerRangeValue.work.date_to,
                unix_from: unix_from,
                unix_to: unix_to
            }
            setTimeData(time_range)
            getChartsData({ time_range: time_range, symbol: symbolListValue });
            localStorage.setItem(`${storageApp}_${pagePosition}_${storageDateOptionsId}`, JSON.stringify(pickerRangeValue));
            //console.log(time_range)
        }
        //console.log(localStorage.getItem(`XProfit_${pagePosition}_dateOptions`))
    }

    return (
        <>

            <Row className='filter_row' >
                <Col xl={{ span: 5 }} md={{ span: 8 }} xs={{ span: 0 }} ></Col>
                <Col xl={{ span: 5 }} md={{ span: 8 }} xs={{ span: 0 }} >
                    <SymbolSelect
                        list={symbolListData}>
                    </SymbolSelect>
                </Col>
                <Col xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 24 }} style={{ marginTop: 2, marginBottom: 2 }}>
                    <RangePicker
                        style={{ width: '100%' }}
                        defaultValue={[moment(defaultRange.from, defaultRange.dateFormat), moment(defaultRange.to, defaultRange.dateFormat)]}
                        format={defaultRange.dateFormat}
                        value={RangePickerValue || [moment(defaultRange.from, defaultRange.dateFormat), moment(defaultRange.to, defaultRange.dateFormat)]}
                        onChange={value => onChangeRangePicker(value)}
                    />
                </Col>
                <Col xl={{ span: 5 }} md={{ span: 7 }} xs={{ span: 21 }} style={{ marginTop: 2 }}
                >
                    <AntDateSelectOptions
                        options={DateSelectOptionsRaw}
                        onChange={onChangeAntDateSelectOptions}
                        value={DateSelectOptionsValue}
                    ></AntDateSelectOptions>
                </Col>

                <Col xl={{ span: 2 }} md={{ span: 2 }} xs={{ span: 3 }} style={{ marginTop: 2 }}>
                </Col>

            </Row>



            <Row style={{ margin: 4 }}></Row>
            <Row>
                <Col span={18}>
                    <Card title={ChartData.title} size="small">
                        <FlowChartWithMom
                            title={ChartData.title}
                            callData={ChartData.callData}
                            putData={ChartData.putData}

                            priceData={ChartData.priceData}
                            priceDataMom={ChartData.priceDataMom}

                            priceMarkersTest={MarkersData.priceMarkersTest}

                            callDataMom={ChartData.callDataMom}
                            putDataMom={ChartData.putDataMom}
                            height={inputValueHeight}
                            scaleMarginsTop={inputValueScaleMarginsTop}
                            scaleMarginsBottom={inputValueScaleMarginsBottom}
                            histogramBase={inputValueHistogramBase}
                        ></FlowChartWithMom>
                    </Card>

                    <Table dataSource={TableData} columns={CONFIG.tableColumns} size="small" />

                </Col>
                <Col span={6}>


                    <Card title="Gloabal" size="small">
                        <Col span={4}>Momentum</Col>
                        <Col span={20}>
                            <Slider
                                min={0}
                                max={500}
                                onChange={onChangeMomentum}
                                value={typeof inputValueMomentum === 'number' ? inputValueMomentum : 10}
                            />
                        </Col>
                        <Col span={4}>Height</Col>
                        <Col span={20}>
                            <Slider
                                min={50}
                                max={1000}
                                onChange={onChangeHeight}
                                value={typeof inputValueHeight === 'number' ? inputValueHeight : 50}
                            />
                        </Col>
                    </Card>
                    <Row style={{ margin: 6 }}></Row>
                    <Card>
                        <Card title="Price Buy" size="small">
                            <Row>
                                <Col span={7}>min limit</Col>
                                <Col span={10}>
                                    <Slider min={0} max={30} step={1}
                                        onChange={onChangePriceMarkerParamsBuyTimeLimit}
                                        value={typeof PriceMarkerParamsBuyTimeLimit === 'number' ? PriceMarkerParamsBuyTimeLimit : 10}
                                    />
                                </Col>
                                <Col span={7} style={{ width: '100%' }}>
                                    <InputNumber min={0} max={30} step={1} value={PriceMarkerParamsBuyTimeLimit}
                                        onChange={onChangePriceMarkerParamsBuyTimeLimit}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>count limit</Col>
                                <Col span={10}>
                                    <Slider min={0} max={30} step={1}
                                        onChange={onChangePriceMarkerParamsBuyCountLimit}
                                        value={typeof PriceMarkerParamsBuyCountLimit === 'number' ? PriceMarkerParamsBuyCountLimit : 5}
                                    />
                                </Col>
                                <Col span={4} style={{ width: '100%' }}>
                                    <InputNumber min={0} max={30} step={1} value={PriceMarkerParamsBuyCountLimit}
                                        onChange={onChangePriceMarkerParamsBuyCountLimit}
                                    />
                                </Col>
                            </Row>
                            <Card title="momentum" size="small">
                                <Row>
                                    <Col span={5}>price</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsBuyPriceMom}
                                            value={typeof PriceMarkerParamsBuyPriceMom === 'number' ? PriceMarkerParamsBuyPriceMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsBuyPriceMom}
                                            onChange={onChangePriceMarkerParamsBuyPriceMom}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={5}>call</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsBuyCallMom}
                                            value={typeof PriceMarkerParamsBuyCallMom === 'number' ? PriceMarkerParamsBuyCallMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsBuyCallMom}
                                            onChange={onChangePriceMarkerParamsBuyCallMom}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={5}>put</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsBuyPutMom}
                                            value={typeof PriceMarkerParamsBuyPutMom === 'number' ? PriceMarkerParamsBuyPutMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsBuyPutMom}
                                            onChange={onChangePriceMarkerParamsBuyPutMom}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Card>

                        <Row style={{ margin: 6 }}></Row>
                        <Card title="Price Sell" size="small">
                            <Row>
                                <Col span={7}>min limit</Col>
                                <Col span={10}>
                                    <Slider min={0} max={30} step={1}
                                        onChange={onChangePriceMarkerParamsSellTimeLimit}
                                        value={typeof PriceMarkerParamsSellTimeLimit === 'number' ? PriceMarkerParamsSellTimeLimit : 10}
                                    />
                                </Col>
                                <Col span={7} style={{ width: '100%' }}>
                                    <InputNumber min={0} max={30} step={1} value={PriceMarkerParamsSellTimeLimit}
                                        onChange={onChangePriceMarkerParamsSellTimeLimit}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>count limit</Col>
                                <Col span={10}>
                                    <Slider min={0} max={30} step={1}
                                        onChange={onChangePriceMarkerParamsSellCountLimit}
                                        value={typeof PriceMarkerParamsSellCountLimit === 'number' ? PriceMarkerParamsSellCountLimit : 5}
                                    />
                                </Col>
                                <Col span={4} style={{ width: '100%' }}>
                                    <InputNumber min={0} max={30} step={1} value={PriceMarkerParamsSellCountLimit}
                                        onChange={onChangePriceMarkerParamsSellCountLimit}
                                    />
                                </Col>
                            </Row>
                            <Card title="momentum" size="small">
                                <Row>
                                    <Col span={5}>price</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsSellPriceMom}
                                            value={typeof PriceMarkerParamsSellPriceMom === 'number' ? PriceMarkerParamsSellPriceMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsSellPriceMom}
                                            onChange={onChangePriceMarkerParamsSellPriceMom}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={5}>call</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsSellCallMom}
                                            value={typeof PriceMarkerParamsSellCallMom === 'number' ? PriceMarkerParamsSellCallMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsSellCallMom}
                                            onChange={onChangePriceMarkerParamsSellCallMom}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={5}>put</Col>
                                    <Col span={10}>
                                        <Slider min={-100} max={100} step={0.1}
                                            onChange={onChangePriceMarkerParamsSellPutMom}
                                            value={typeof PriceMarkerParamsSellPutMom === 'number' ? PriceMarkerParamsSellPutMom : 0}
                                        />
                                    </Col>
                                    <Col span={9}>
                                        <InputNumber min={-100} max={100} step={0.1} value={PriceMarkerParamsSellPutMom}
                                            onChange={onChangePriceMarkerParamsSellPutMom}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Card>

                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default Test;