import React from "react"
import { Card } from "antd";

const AntCard = ({ title, size, children }) => {
    const sizeDefault = 'small'
    return (
        <Card
            title={title} size = {size?  size: sizeDefault}
        >
            {children}
        </Card>
    )
}

export default AntCard;