import React from "react"
import { useState } from 'react';
import { Select } from 'antd'
import * as Dates from '../../libraries/DatesOld'

const { Option } = Select;

const AntSelect = (props) => {

    let options = []
    if (props.global) {
        if (props.global.options) {
            options = Dates.initDateRange()
        } else {
            options = props.options
        }
    } else {
        options = props.options
    }

    const Resp = ({ children }) => { return (<>{children}</>) }
    if (options) {
        return (
            <Resp>
                <Select
                    defaultValue={props.value}
                    style={{ width: '100%' }}
                    onChange={props.onChange}
                    listHeight={'100%'}
                >
                    {(options).map((row, index) => {
                        return (
                            <Option value={row.value} key={index}>
                                {row.label}
                            </Option>
                        );
                    })}
                </Select>
            </Resp >
        )
    } else {
        console.log('AntSelect error')
        return (
            <Resp>
                AntSelect error
            </Resp>
        )
    }
}

export default AntSelect